<h1 mat-dialog-title>
    {{'fileUploader.uploaderTitle' | translate}}({{uploader.queue.length}})
    <div class="uploader-subtitle">
        <mat-icon>info</mat-icon>
        {{'fileUploader.uploadInformationStart' | translate}}
        {{maxFileSize / 1024 / 1024 | number:'.0'}} {{'fileUploader.uploadInformationEnd' | translate}}
    </div>
</h1>
<mat-dialog-content
        fxLayout="row wrap"
        fxLayoutWrap
        fxLayoutGap="4px"
        fxLayoutAlign="start"
>
    <ng-container *ngFor="let item of uploader.queue">
        <span class="filename" fxFlex="300px">{{ item?.file?.name }}</span>
        <span fxFlex="100px" class="message"
        >{{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB</span
        >
        <span class="file-upload-bar" fxFlex="200px" class="message">
			<mat-progress-bar
                    *ngIf="!item.isError && !item.isCancel"
                    mode="determinate"
                    [value]="item.progress"
            ></mat-progress-bar>
			<ng-container *ngIf="item.isError">
				<p class="error-message">{{ response.error }}</p>
			</ng-container>
			<ng-container *ngIf="item.isCancel">
				<p class="cancel-message">{{'fileUploader.cancelledUpload' | translate}}</p>
			</ng-container>
		</span>

        <button
                mat-icon-button
                class="uploaded-file-status"
                disableRipple="true"
                fxFlex="40px"
                color="accent"
                *ngIf="item.isSuccess && item.isUploaded"
        >
            <mat-icon>check_circle_outline</mat-icon>
        </button>

        <button
                mat-icon-button
                class="uploaded-file-status"
                disableRipple="true"
                fxFlex="40px"
                color="warn"
                *ngIf="item.isError "
        >
            <mat-icon>error_outline</mat-icon>
        </button>

        <button
                mat-icon-button
                fxFlex="40px"
                class="uploaded-file-status"
                disableRipple="true"
                *ngIf="item.isCancel"
                disabled
        >
            <mat-icon>clear</mat-icon>
        </button>

        <button
                mat-icon-button
                fxFlex="40px"
                (click)="uploader.cancelItem(item)"
                *ngIf="item.isUploading && item.isReady"
                class="cancel-action"
                disableRipple="true"
        >
            <mat-icon>clear</mat-icon>
        </button>

        <button
                mat-icon-button
                fxFlex="40px"
                (click)="uploader.removeFromQueue(item)"
                *ngIf="!item.isUploading && item.isReady"
                class="cancel-action"
                disableRipple="true"
        >
            <mat-icon>clear</mat-icon>
        </button>
    </ng-container>

    <span fxFlex="100%" class="upload-footer"
    >{{successCounter}} {{'fileUploader.successUpload' | translate}}</span
    >
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button
            mat-button
            color="primary"
            type="button"
            (click)="uploader.cancelAll()"
            [disabled]="isCompleteAll || uploader.queue.length === 0"
    >
        {{'fileUploader.cancel' | translate}}
    </button>

    <button
            mat-button
            type="button"
            color="primary"
            (click)="onNoClick()"
            [disabled]="!isCompleteAll && uploader.queue.length !== 0"
            [mat-dialog-close]="isCompleteAll || uploader.queue.length === 0"
            cdkFocusInitial
    >
        {{'fileUploader.close' | translate}}
    </button>
</mat-dialog-actions>
