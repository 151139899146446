<h1 mat-dialog-title>{{data.outcome}}</h1>
<div mat-dialog-content>
    <mat-form-field fxFlex="100%" appearance="outline">
    <textarea
            matInput
            [formControl]="message"
            rows="4"
            placeholder="{{'comment.message'| translate}}"
            required
    >
    </textarea>
    </mat-form-field>
</div>

<div mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="onNoClick()">
        {{'comment.cancel'| translate}}
    </button>
    <button
            mat-button
            color="primary"
            (click)="handleActionConfirm()"
            [disabled]="message.status === 'INVALID'"
    >
        {{'comment.confirm'| translate}}
    </button>
</div>
