<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [opened]="false">
        <img
                class="sidenav-logo"
                src="assets/konta-vertical-logo.png"
                alt="KONTA Platform"
        />
        <mat-divider></mat-divider>
        <div class="workspace-name">{{ workspaceName |async}}</div>


        <mat-nav-list>
            <a mat-list-item
               [routerLink]="[resourceType=='PURCHASE_ORDER' ? '/poworkspaces/' + workspaceId : '/workspaces/' + workspaceId]"
               routerLinkActive="router-link-active"
               (click)="drawer.toggle()">
                <mat-icon mat-list-icon>home</mat-icon>
                {{ 'topBar.home' | translate }}
            </a>

            <a mat-list-item
               [routerLink]="[workspaceId + '/tasks']"
               routerLinkActive="router-link-active"
               (click)="drawer.toggle()"
               selected="true">
                <mat-icon mat-list-icon>inbox</mat-icon>
                {{ 'global.tasksList'| translate}}
            </a>

            <a mat-list-item
               [routerLink]="[workspaceId + (resourceType=='PURCHASE_ORDER' ? '/rejected-purchase-orders': '/rejected-invoices')]"
               outerLinkActive="router-lin-active"
               (click)="drawer.toggle()"
               selected="true">
                <mat-icon mat-list-icon>inbox</mat-icon>
                {{'global.rejectedDocs' | translate}}
            </a>

            <a mat-list-item
               [routerLink]="[workspaceId + (resourceType=='PURCHASE_ORDER' ? '/purchase-orders': '/purchase-invoices')]"
               routerLinkActive="router-link-active"
               (click)="drawer.toggle()"
               selected="true">
                <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                {{ 'global.approvedDocs'| translate}}
            </a>

            <mat-divider></mat-divider>
            <a mat-list-item
               [routerLink]="['/workspaces/']"
               routerLinkActive="router-link-active"
               (click)="drawer.toggle()">
                <mat-icon mat-list-icon>business</mat-icon>
                {{ 'topBar.listEntreprise'| translate}}
            </a>

        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-toolbar class="mat-elevation-z4" color="primary">
            <button
                    mat-icon-button
                    type="button"
                    aria-label="Toggle sidenav"
                    *ngIf="isSideNavActive()"
                    (click)="drawer.open()"
            >
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>

            <span class="font-changes"
            ><h6>{{ workspaceName|async}}</h6>
			</span>

            <ng-container *ngFor="let breadcrumb of breadcrumbs">
                <mat-icon style="margin-left: 16px;">chevron_right</mat-icon>
                <span class="font-changes"
                ><h6>{{ breadcrumb.label }}</h6>
				</span>
            </ng-container>

            <span class="navbar-spacer"></span>
            <span
            ><p>
					{{ authUser.userInfo.user.firstName }}
                {{ authUser.userInfo.user.lastName }}
				</p></span
            >
            <button mat-icon-button>
                <mat-icon aria-label="notifications" matTooltip="Bientôt disponible"
                >notifications
                </mat-icon
                >
            </button>
            <button mat-icon-button>
                <mat-icon
                        aria-label="Example user verified icon"
                        matTooltip="Bientôt disponible"
                >settings
                </mat-icon
                >
            </button>
            <button mat-icon-button (click)="logout()">
                <mat-icon
                        aria-label="Example user verified icon"
                        matTooltip="Se déconnecter"
                >power_settings_new
                </mat-icon
                >
            </button>
        </mat-toolbar>
        <router-outlet></router-outlet>
    </mat-sidenav-content>

</mat-sidenav-container>
