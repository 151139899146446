import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    constructor(http) {
        this.http = http;
        this.REGULAR_USERS_URL = environment.backendUrl + '/kontaflow/task/app/users/regularUsers/emails';
    }
    getRegularUsers(filter) {
        const httpParams = new HttpParams().set('filter', filter);
        return this.http.post(this.REGULAR_USERS_URL, null, { params: httpParams });
    }
    getRegularUserEmails(filter) {
        return this.getRegularUsers(filter)
            .pipe(map(response => response.map(user => user.id)));
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
