import * as i0 from "@angular/core";
export class ClearFilterGuard {
    canDeactivate(component, currentRoute, currentState, nextState) {
        if (!nextState.url.includes(currentState.url)) {
            localStorage.removeItem('filters');
        }
        return true;
    }
}
ClearFilterGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClearFilterGuard_Factory() { return new ClearFilterGuard(); }, token: ClearFilterGuard, providedIn: "root" });
