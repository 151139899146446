import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PoTaskDetailsComponent} from './po-task-details.component';

const routes: Routes = [{path: '', component: PoTaskDetailsComponent}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PoTaskDetailsRoutingModule {
}
