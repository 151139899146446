import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { CrossFieldErrorMatcher } from 'src/app/shared/crossFieldErrorMatcher';
import { MY_DATE_FORMATS } from 'src/app/shared/formHepler';
const ɵ0 = MY_DATE_FORMATS, ɵ1 = { useStrict: true, useUtc: true };
export class PoRejectedFilterDialogComponent {
    constructor(translateService, fb, dialogRef, data) {
        this.translateService = translateService;
        this.fb = fb;
        this.dialogRef = dialogRef;
        this.parameters = new Map();
        this.listMap = new Map();
        this.errorMatcher = new CrossFieldErrorMatcher();
        this.reg = /^-?[0-9]?(([.,][0-9]+)|([0-9]+([.,][0-9]+)?))$/;
        this.data = data.filters;
        this.source = data.source;
        this.listMap = data.listMap;
    }
    stringConditions() {
        return [
            new Condition('equals', this.equals),
            new Condition('contains', this.contains),
        ];
    }
    otherTypeConditions() {
        return [
            new Condition('equals', this.equals),
            new Condition('greaterThanOrEqual', this.greaterThanOrEqualTo),
            new Condition('lessThanOrEqual', this.lessThanOrEqualTo),
        ];
    }
    listConditions() {
        return [
            new Condition('equals', this.equals)
        ];
    }
    ngOnInit() {
        this.initConditions();
        const group = [];
        if (this.data.length === 0) {
            group.push(this.createFilter());
        }
        else {
            for (const f of this.data) {
                group.push(this.fb.group({
                    parameter: new FormControl(f.parameter, [Validators.required]),
                    condition: new FormControl(f.condition, [Validators.required]),
                    value: new FormControl(f.value, [Validators.required]),
                }));
            }
        }
        this.form = this.fb.group({
            filters: this.fb.array(group),
        });
        this.parameters.set('supplierPartyName', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('supplierPartyLegalId', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('invoiceNumber', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('orderReference', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('issueDate', {
            type: 'date',
            conditions: this.otherTypeConditions(),
        });
        this.parameters.set('receiptDate', {
            type: 'date',
            conditions: this.otherTypeConditions(),
        });
        this.parameters.set('approvalDate', {
            type: 'date',
            conditions: this.otherTypeConditions(),
        });
        this.parameters.set('taxExclusiveAmount', {
            type: 'number',
            conditions: this.otherTypeConditions(),
        });
        this.parameters.set('payableAmount', {
            type: 'number',
            conditions: this.otherTypeConditions(),
        });
        this.parameters.set('rejectionNote', {
            type: 'string',
            conditions: [
                new Condition('contains', this.contains),
            ],
        });
        this.parameters.set('taskDefinitionKey', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        if (this.source === 'invoice') {
            this.parameters.set('publicationStatus', {
                type: 'list',
                conditions: this.listConditions(),
            });
        }
        this.initFilters();
    }
    createFilter() {
        return this.fb.group({
            parameter: new FormControl('', [Validators.required]),
            condition: new FormControl('', [Validators.required]),
            value: new FormControl('', [Validators.required]),
        });
    }
    addItem() {
        this.filters.push(this.createFilter());
    }
    onSubmit() {
        if (this.isEmptyForm()) {
            this.dialogRef.close({ filters: [] });
        }
        else {
            this.dialogRef.close(this.form.value);
        }
    }
    clearFilters() {
        this.filters.clear();
        this.filters.push(this.createFilter());
    }
    clearFilter(i) {
        this.filters.removeAt(i);
    }
    isValidForm() {
        if (this.isEmptyForm()) {
            return true;
        }
        else {
            for (const c of this.filters.controls) {
                if (c.value.parameter === 'supplierPartyName') {
                    return true;
                }
                if (!c.valid) {
                    return false;
                }
            }
            return true;
        }
    }
    // TODO: REFACTOR: simplify
    isEmptyForm() {
        if (this.form.value.filters.length === 1) {
            return Object.values(this.form.value.filters[0]).every((o) => o === '' || o === undefined);
        }
    }
    isStringParam(param) {
        return (param === 'supplierParty.name' ||
            param === 'supplierParty.legalId' ||
            param === 'invoiceNumber' ||
            param === 'orderReference');
    }
    isOtherParam(param) {
        return !this.isDateParam(param) && !this.isNumberParam(param);
    }
    isDateParam(param) {
        return (param === 'issueDate' ||
            param === 'receiptDate' ||
            param === 'approvalDate');
    }
    isNumberParam(param) {
        return param === 'taxExclusiveAmount' || param === 'payableAmount';
    }
    onParameterChanges(filter, index) {
        this.filters.setControl(index, (this.filters[index] = this.fb.group({
            parameter: new FormControl(filter.value.parameter, [
                Validators.required,
            ]),
            condition: new FormControl('equals', [Validators.required]),
            value: new FormControl('', [Validators.required]),
        })));
    }
    getControls() {
        return this.form.get('filters').controls;
    }
    get filters() {
        return this.form.get('filters');
    }
    initConditions() {
        this.translateService
            .get('filters.equalsTo')
            .subscribe((res) => (this.equals = res));
        this.translateService
            .get('filters.contains')
            .subscribe((res) => (this.contains = res));
        this.translateService
            .get('filters.greaterThanOrEqualTo')
            .subscribe((res) => (this.greaterThanOrEqualTo = res));
        this.translateService
            .get('filters.lessThanOrEqualTo')
            .subscribe((res) => (this.lessThanOrEqualTo = res));
    }
    initFilters() {
        let fournisser;
        let nom;
        let ice;
        let references;
        let invoiceNumber;
        let orderNumber;
        let issueDate;
        let receiptDate;
        let approvalDate;
        let amounts;
        let taxExclusiveAmount;
        let payableAmount;
        let rejectionNote;
        let taskDefinitionKey;
        let other;
        let publicationStatus;
        this.translateService
            .get('global.provider')
            .subscribe((value) => (fournisser = value));
        this.translateService.get('global.name').subscribe((value) => (nom = value));
        this.translateService
            .get('global.legalID')
            .subscribe((value) => (ice = value));
        this.translateService
            .get('global.references')
            .subscribe((value) => (references = value));
        this.translateService
            .get('global.invoiceNumber')
            .subscribe((value) => (invoiceNumber = value));
        this.translateService
            .get('global.orderNo')
            .subscribe((value) => (orderNumber = value));
        this.translateService
            .get('global.issueDate')
            .subscribe((value) => (issueDate = value));
        this.translateService
            .get('global.receptionDate')
            .subscribe((value) => (receiptDate = value));
        this.translateService
            .get('global.ongoingTask')
            .subscribe((value) => (taskDefinitionKey = value));
        if (this.source === 'rejected') {
            this.translateService
                .get('global.rejectionDate')
                .subscribe((value) => (approvalDate = value));
        }
        else {
            this.translateService
                .get('global.validationDate')
                .subscribe((value) => (approvalDate = value));
        }
        this.translateService
            .get('invoiceExplorer.amounts')
            .subscribe((value) => (amounts = value));
        this.translateService
            .get('global.taxExclusiveAmount')
            .subscribe((value) => (taxExclusiveAmount = value));
        this.translateService
            .get('global.payableAmount')
            .subscribe((value) => (payableAmount = value));
        this.translateService
            .get('global.other')
            .subscribe((value) => (other = value));
        this.translateService
            .get('global.rejectionNote')
            .subscribe((value) => (rejectionNote = value));
        this.translateService
            .get('global.publicationStatus')
            .subscribe((value) => (publicationStatus = value));
        this.filterGroup = [
            {
                name: fournisser,
                children: [
                    { label: nom, value: 'supplierPartyName' },
                    { label: ice, value: 'supplierPartyLegalId' },
                ],
            },
            {
                name: references,
                children: [
                    { label: invoiceNumber, value: 'invoiceNumber' },
                    { label: orderNumber, value: 'orderReference' },
                ],
            },
            {
                name: 'Dates',
                children: [
                    { label: issueDate, value: 'issueDate' },
                    { label: receiptDate, value: 'receiptDate' },
                    { label: approvalDate, value: 'approvalDate' },
                ],
            },
            {
                name: amounts,
                children: [
                    {
                        label: taxExclusiveAmount,
                        value: 'taxExclusiveAmount',
                        type: 'number',
                        conditions: this.otherTypeConditions(),
                    },
                    {
                        label: payableAmount,
                        value: 'payableAmount',
                        type: 'number',
                        conditions: this.otherTypeConditions(),
                    },
                ],
            }
        ];
        if (this.source === 'rejected') {
            this.filterGroup.push({
                name: other,
                children: [
                    { label: rejectionNote, value: 'rejectionNote' }
                ],
            });
        }
        if (this.source === 'invoice') {
            this.filterGroup.push({
                name: other,
                children: [
                    {
                        label: publicationStatus,
                        value: 'publicationStatus'
                    }
                ],
            });
        }
        if (this.source === 'ongoing') {
            this.filterGroup.push({
                name: other,
                children: [
                    { label: taskDefinitionKey, value: 'taskDefinitionKey' }
                ],
            });
        }
    }
}
export class Condition {
    constructor(value, label) {
        this.value = value;
        this.label = label;
    }
}
export { ɵ0, ɵ1 };
