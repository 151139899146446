import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

export interface IProductTemplateLine {
    id?: number;
    lineDescription?: string;
    orderLineReference?: string;
    quantity?: number;
    unitPrice?: number;
    taxExclusiveAmount?: number;
    taxAmount?: number;
    taxInclusiveAmount?: number;
    taxCodings?: ITaxCoding[];
    accountCodings?: IAccountCoding[];
    expenseCodings?: IExpenseCoding[];
    invoiceId?: number;
}

export interface ILineTemplate {
    id: string;
    fieldType: string;
    name: string;
    type: string;
    value: string;
    required: boolean;
    readOnly: boolean;
    overrideId: any;
    placeholder: any;
    layout: any;
    params: {
        lineModel: ILineModel[];
        canAddLines: boolean | string;
        readOnly: boolean;
        importFromOrder: boolean;
    };
}

export interface ILineModel {
    id: string;
    attribute: string;
    label: string;
    type: string;
    expenseCodingType: string;
    order: string | number;
    size: string;
}

export interface IExpenseCoding {
    id?: number;
    type?: string;
    code?: string;
    label?: string;
    tenantIdentifier?: string;
    tenantId?: number;
    workspaceId?: number;
}

export interface ITaxCoding {
    id?: number;
    label?: string;
    code?: string;
    rate?: number;
    tenantIdentifier?: string;
    tenantId?: number;
    wokspaceId?: number;
}

export interface IAccountCoding {
    id?: number;
    label?: string;
    code?: string;
}

@Injectable({providedIn: 'root'})
export class FormService {
    readonly URL = environment.backendUrl + '/kontaflow/task/app/rest/task-forms';

    readonly commentURL = environment.backendUrl + '/kontaflow/task/app/rest/process-instances';
    readonly sendMessageURL = environment.backendUrl + '/kontaflow/task/app/rest/tasks';
    readonly taxCodingsURL = environment.backendUrl + '/invoicestore/app/api/md-tax-codings';
    readonly accountCodingURL = environment.backendUrl + '/invoicestore/app/api/accounting-account/';
    readonly expenseCodingsURL = environment.backendUrl + '/invoicestore/app/api/md-expense-codings';
    readonly invoiceLinesURL = environment.backendUrl + '/invoicestore/app/api/invoices';
    readonly orderLinesURL = environment.backendUrl + '/invoicestore/app/api/purchase-orders';
    readonly purchaseOrderURL = environment.backendUrl + '/invoicestore/app/api/po-copy/po-candidates';
    readonly codingProposalURL = environment.backendUrl + '/invoicestore/app/api/coding-proposal';

    constructor(private http: HttpClient) {
    }

    getTaxCodingsFromAPI(legalEntityId: string): Promise<ITaxCoding[]> {
        let httpParams = {};
        if (isNotNullOrUndefined(legalEntityId)) {
            httpParams = new HttpParams().set('legalEntityId.equals', legalEntityId)
                .set('size', '10000');
        } else {
            httpParams = new HttpParams().set('legalEntityId.specified', 'false')
                .set('size', '10000');
        }
        return this.http.get<any>(this.taxCodingsURL, {params: httpParams}).toPromise();
    }

    getAccountingAccounts(legalEntityId: string): Observable<ITaxCoding[]> {
        let httpParams = {};
        httpParams = new HttpParams().set('legalEntityId.equals', legalEntityId)
            .set('size', '10000');
        return this.http.get<any>(this.accountCodingURL, {params: httpParams});
    }

    getExpenseCodings(): Promise<IExpenseCoding[]> {
        const httpParams = new HttpParams().set('size', '10000');
        return this.http.get<any>(this.expenseCodingsURL, {params: httpParams}).toPromise();
    }

    getInvoiceFromApi(formId: string): Observable<any> {
        return this.http.get(this.URL + '/' + formId);
    }

    getInvoicePurchaseOrderFromAPI(customerAssignedAccId: string, orderNumber: string): Observable<any> {
        const httpParams = new HttpParams()
            .set('customerAssignedAccId', customerAssignedAccId)
            .set('orderNumber', orderNumber);

        return this.http.get(this.purchaseOrderURL, {params: httpParams});
    }


    fetchCodingProposal(workspaceId: string, customerAccId: string, supplierAccId: string, taxExclusiveAmount: any): Observable<any> {
        const requestBody = {
            workspaceId,
            customerPartyAccId: customerAccId,
            supplierPartyAccId: supplierAccId,
            taxExclusiveAmount
        };

        return this.http.post(this.codingProposalURL, requestBody);
    }


    submitWorkflowForm(taskId: string, values: any, formId: string, outcome: string): Observable<any> {
        return this.http.post<any>(`${this.URL}/${formId}`, {
            values,
            formId: taskId,
            outcome,
        });
    }

    sendCommentsToAPI(taskId: string, message: string): Observable<any> {
        return this.http.post<any>(`${this.sendMessageURL}/${taskId}/comments`, {message});
    }

    getCommentsFromAPI(processInstanceId: string): Observable<any> {
        const httpParams = new HttpParams().set('latestFirst', 'true');

        return this.http.get<any>(
            `${this.commentURL}/${processInstanceId}/comments`,
            {params: httpParams}
        );
    }

    getInvoiceLines(invoiceId: string): Promise<any> {
        return this.http.get<any>(`${this.invoiceLinesURL}/${invoiceId}/invoice-lines`).toPromise();
    }

    getOrderLines(orderId: string): Promise<any> {
        return this.http.get<any>(`${this.orderLinesURL}/${orderId}/order-lines`).toPromise();
    }

    createInvoiceLines(invoiceId: string, invoiceLines: any): Observable<any> {
        return this.http.post<any>(
            `${this.invoiceLinesURL}/${invoiceId}/invoice-lines`,
            [...invoiceLines]
        );
    }

    createOrderLines(orderId: string, orderLines: any): Observable<any> {
        return this.http.post<any>(
            `${this.orderLinesURL}/${orderId}/order-lines`,
            [...orderLines]
        );
    }


}
