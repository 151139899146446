import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {WorkspacesService} from '../../core/services';
import {Subscription} from 'rxjs';
import {ConsolidatedWorkspace, ResourceType} from 'src/app/models/workspaces.model';

@Component({
    selector: 'app-workspaces',
    templateUrl: './workspaces.component.html',
    styleUrls: ['./workspaces.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class WorkspacesComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    public workspaceNameValue: string;

    dataSource: MatTableDataSource<ConsolidatedWorkspace>;
    displayedColumns: string[] = [
        'name',
        'myActiveTasksCount',
        'ongoingProcessesCount',
    ];

    private workspacesServiceSubscription: Subscription;
    public selectedResourceTypes = [ResourceType.INVOICE, ResourceType.PURCHASE_ORDER];


    constructor(
        private router: Router,
        private workspacesService: WorkspacesService
    ) {
        this.workspacesService.setWorkspaceId('');
    }

    ngOnInit() {
        this.loadWorkspaces();
    }

    private loadWorkspaces() {
        this.workspacesServiceSubscription = this.workspacesService
            .getWorkspacesList(this.selectedResourceTypes)
            .subscribe((data: ConsolidatedWorkspace[]) => {
                this.dataSource = new MatTableDataSource(data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.dataSource.filterPredicate = (
                    data: ConsolidatedWorkspace,
                    filter: string
                ): boolean => {
                    return data.name.toLowerCase().includes(filter);
                };
            });
    }

    ngOnDestroy() {
        this.workspacesServiceSubscription.unsubscribe();
    }

    public applyFilter(filterValue: string): void {
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    public clearSearchInput(): void {
        this.workspaceNameValue = '';
        this.applyFilter('');
    }

    public redirectToActivityOverview(workspaceId: string, resourceType: string): void {
        this.workspacesService.setWorkspaceId(workspaceId);

        switch (resourceType) {

            case ResourceType.INVOICE: {
                this.router.navigate(['/workspaces', workspaceId]).then();
                break;
            }

            case ResourceType.PURCHASE_ORDER: {
                this.router.navigate(['/poworkspaces', workspaceId]).then();
                break;
            }
            default: {
                this.router.navigate(['/workspaces', workspaceId]).then();
                break;
            }
        }


    }

    onSelectionChange($event: any) {
        this.selectedResourceTypes = $event;
        this.loadWorkspaces();
    }
}
