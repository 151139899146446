import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {Observable, Subject, throwError, timer} from 'rxjs';
import {environment} from '../../../environments/environment';
import {concatMap, map, retry} from 'rxjs/operators';
import {Filter} from '../../models/filter-dialog';
import {AuthenticationService} from './authentication.service';
import * as moment from 'moment';

interface ErrorMessage {
    status: number;
    message: string;
    key: string;
}

@Injectable({providedIn: 'root'})
export class TaskService {
    readonly processURL = environment.backendUrl + '/kontaflow/task/app/rest/query/user-task-definitions';
    readonly tasksURL = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/mytaskswithvars';
    readonly poTasksURL = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/mypotaskswithvars';

    readonly exportUrl = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/export';
    // environment.backendUrl + '/kontaflow/task/app/rest/query/tasks';
    readonly claimURL = environment.backendUrl + '/kontaflow/task/app/rest/tasks';

    // tslint:disable-next-line:variable-name
    _taskErrors: Subject<ErrorMessage> = new Subject<ErrorMessage>();
    taskErrors$: Observable<ErrorMessage> = this._taskErrors.asObservable();
    filtersChanged = new Subject<Filter[]>();
    public readonly filtersChanged$: Observable<Filter[]> = this.filtersChanged.asObservable();

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    }

    public handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            switch (error.status) {
                case 400:
                    this._taskErrors.next({
                        status: error.status,
                        message: error.error.message,
                        key: error.error.messageKey
                    });
                    break;
                case 404:
                    this._taskErrors.next({
                        status: error.status,
                        message: error.error.message,
                        key: error.error.messageKey
                    });
                    break;
                default:
                    break;
            }
        }

        return throwError('An error has occurred. Try later');
    }

    getProcessDefinitionFromAPI(processId: string): Observable<any> {
        return this.http.post<any>(this.processURL, {
            processDefinitionId: processId
        });
    }

    /**
     * Http request with timer to poll process metrics
     * @param processDefinitionId the process Id
     * @param interval the timer interval duration for polling
     */
    refreshProcessDefinitionFromAPI(
        workspaceId: string,
        processDefinitionId: string,
        interval: number
    ): Observable<any> {
        const request$ = this.http.post<any>(this.processURL, {
            workspaceId,
            processDefinitionId
        });

        return timer(1000, interval).pipe(
            concatMap(_ => request$),
            retry(5),
            map(response => response)
        );
    }

    getTaskListFromAPI(
        workspaceId: string,
        processId: string,
        taskDefinitionKey: string,
        processDefinitionId: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number,
        filters: Filter[]
    ): Observable<any> {
        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        httpParams = httpParams.set('page', pageIndex.toString());
        httpParams = httpParams.set('size', pageSize.toString());
        return this.http.post<any>(this.tasksURL, {
            workspaceId,
            processId,
            taskDefinitionKey,
            processDefinitionId,
            state: 'open',
            assignment: 'myTasks',
            sort: sortDirection === 'desc' ? 'created-desc' : 'created-asc',
            includeCompleteProcessInstance: true
        }, {params: httpParams});
    }

    getPoTaskListFromAPI(
        workspaceId: string, processId: string,
        taskDefinitionKey: string, processDefinitionId: string,
        sortDirection: string, pageIndex: number, pageSize: number,
        filters: Filter[]): Observable<any> {

        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        httpParams = httpParams.set('page', pageIndex.toString());
        httpParams = httpParams.set('size', pageSize.toString());
        return this.http.post<any>(this.poTasksURL,
            {
                workspaceId,
                processId,
                taskDefinitionKey,
                processDefinitionId,
                state: 'open',
                assignment: 'myTasks',
                sort: sortDirection === 'desc' ? 'created-desc' : 'created-asc',
                includeCompleteProcessInstance: true
            }, {params: httpParams});
    }

    getTaskByIdFromAPI(taskId): Promise<any> {
        return this.http.get<any>(`${this.claimURL}/${taskId}`).toPromise();
    }

    claimAndGetTaskFromApi(taskId: string): Observable<any> {
        return this.http.put(`${this.claimURL}/${taskId}/action/claimAndGet`, {
            observe: 'response'
        });
    }

    unclaimTaskFromAPI(taskId: string): Observable<any> {
        return this.http.put(`${this.claimURL}/${taskId}/action/unclaim`, {
            observe: 'response'
        });
    }

    buildFiltersParam(filters: Filter[], params: HttpParams): HttpParams {
        for (const f of filters) {
            if (f.parameter === 'receiptDate') {
                params = params.set(f.parameter.concat('.').concat(f.condition), moment(f.value).valueOf() + '');
            } else {
                if (!f.value && f.condition === 'equals') {
                    params = params.set(
                        f.parameter.concat('.specified'),
                        'false'
                    );
                } else {
                    params = params.set(
                        f.parameter.concat('.').concat(f.condition),
                        f.value
                    );
                }
            }
        }
        return params;
    }

    getFiltersFromLocalStorage(): Filter[] {
        return JSON.parse(localStorage.getItem('filters'));
    }

    exportTasks(
        workspaceId: string,
        processId: string,
        taskDefinitionKey: string,
        processDefinitionId: string,
        filters: Filter[]) {

        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        httpHeaders.set(
            'Authorization',
            'bearer ' + this.authenticationService.getUserToken() + ''
        );
        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        return this.http.request(
            new HttpRequest(
                'POST',
                `${this.exportUrl}`,
                {
                    workspaceId,
                    processId,
                    taskDefinitionKey,
                    processDefinitionId,
                    state: 'open',
                    assignment: 'myTasks',
                    includeCompleteProcessInstance: true
                },
                {
                    headers: httpHeaders,
                    reportProgress: true,
                    responseType: 'text',
                    params: httpParams
                },
            )
        );

    }
}
