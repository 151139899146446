import {PurchaseOrder} from '../models/purchase-order';

export const purchaseOrder: PurchaseOrder = {
    poLifecycleType: '',
    note: '',
    orderNumber: '',
    taxAmount: 0,
    taxExclusiveAmount: 0,
    taxInclusiveAmount: 0,
    approvalDate: null,
    approvalUserId: '',
    approvalFirstName: '',
    approvalLastName: '',
    deliveryAddress: '',
    paymentConditions: '',
    rejectionNote: '',
    paymentMeans: '',
    initiatorUserId: '',
    documentCurrencyCode: '',
    lastAssigneeUserId: '',
    supplierParty: {
        name: '',
        legalId: '',
        taxId: '',
        contactElectronicMail: '',
        customerAssignedAccountId: '',
        financialAccount: ''
    },
    purchaseOrderLines: [{
        index: 0,
        orderLineNumber: '',
        description: '',
        quantity: 0,
        unitPrice: 0,
        taxExclusiveAmount: 0,
        taxAmount: 0,
        taxInclusiveAmount: 0,
        taxCodings: [{
            label: '',
            code: '',
            rate: 0,
            tenantIdentifier: '',
            tenantId: 0,
            wokspaceId: 0
        }],
        accountCodings: [{
            label: '',
            code: ''
        }],
        expenseCodings: [{
            type: '',
            code: '',
            label: '',
            tenantIdentifier: '',
            tenantId: 0,
            workspaceId: 0
        }],
        tenantId: 0,
        purchaseOrderId: 0
    }],
    supplierId: '',
    poApprovalStatus: '',
    billingAddress: null,
    creationDate: null,
    orderDate: null,
    deliveryDate: null,
    attachedDocuments: null,
    tenantId: 0
};
