<div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="start center">
    <button
            class="back-button"
            mat-icon-button
            color="primary"
            (click)="navigateToActivityOverview()"
    >
        <mat-icon class="back-icon">arrow_back_ios</mat-icon>
    </button>

    <mat-tab-group
        #matTabGroup
        (focusChange)="handleFocusChange($event)"
        animationDuration="0ms"
        mat-align-tabs="center"
    >
        <mat-tab #matTab *ngFor="let key of taskDefKeys" [label]="key.name">

            <ng-template matTabContent>
                <app-invoices-table
                        [processId]="processId"
                        [workspaceId]="workspaceId"
                        [processDefinitionId]="processDefinitionId"
                        [taskDefinitionKey]="key.id"
                        [parentTab]="matTab"
                ></app-invoices-table>
            </ng-template>
        </mat-tab>
    </mat-tab-group>

    <!--    <button class="export-action"-->
    <!--            mat-stroked-button-->
    <!--            fxFlex="nogrow"-->
    <!--            color="primary"-->
    <!--            (click)="onExport()">-->
    <!--        <mat-icon class="button-icon">save_alt</mat-icon>-->
    <!--    </button>-->

    <!--    <button class="filter-action"-->
    <!--            mat-stroked-button-->
    <!--            fxFlex="nogrow"-->
    <!--            color="primary"-->
    <!--            (click)="openFilterDialog()">-->
    <!--        <mat-icon class="button-icon">filter_list</mat-icon>-->
    <!--        {{ filters.length }}-->
    <!--    </button>-->
</div>
