import {SelectionModel} from '@angular/cdk/collections';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSlideToggleChange, MatSort} from '@angular/material';
import {saveAs} from 'file-saver';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {AttachmentDetails} from '../../models/attachment-details.model';
import {AuthenticationService, WorkspacesService} from '../../core/services';
import {HttpEventType} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {fixDateFormat} from 'src/app/shared/FormatHelper';
import {OngoingPoDataSource} from './ongoing-po-data-source';
import {PurchaseOrder} from '../../models/purchase-order';
import {TaskFilterDialogComponent} from '../task-details/components/filter-dialog/filter-dialog.component';
import {OngoingPoService} from '../../core/services/ongoing-po.service';
import {ArchiveInProgressDialogComponent} from '../invoice-inprogress-explorer/invoice-inprogress-explorer.component';
import {Task} from 'src/app/models/task';

export interface Filter {
    parameter: string;
    condition: string;
    value: string;
}

@Component({
    selector: 'app-po-explorer',
    templateUrl: './ongoing-po-explorer.component.html',
    styleUrls: ['./ongoing-po-explorer.component.scss'],
})
export class OngoingPoExplorerComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(
        private dialog: MatDialog,
        private ongoingPoService: OngoingPoService,
        private authenticationService: AuthenticationService,
        private workspaceService: WorkspacesService,
        private router: Router,
        private route: ActivatedRoute,
        public translateService: TranslateService,
    ) {
        this.getRouteParams();
    }

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('drawer', {static: false}) drawer;

    displayedColumns = [
        'select',
        'orderDate',
        'supplierParty.name',
        'orderNumber',
        'taxExclusiveAmount',
        'taxAmount',
        'taxInclusiveAmount',
        'documentCurrencyCode',
        'name'
    ];

    dataSource: OngoingPoDataSource;
    selectionPO = new SelectionModel<string>(true, []);

    unpublishedOnly = false;
    public filters: Filter[] = [];
    numRows: number;
    allIds: string[] = [];
    public attachmentDetails: AttachmentDetails;
    private poApiSubscription: Subscription;
    private workspaceId: string;
    viewedPO: Task;
    public processDefinitionId: string;

    public openFilterDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '720px';
        dialogConfig.height = 'auto';
        dialogConfig.disableClose = true;
        const map = new Map<string, string[]>();
        this.ongoingPoService.getTaskNames(this.workspaceId, this.processDefinitionId).subscribe(resp => {
            map.set('taskName', resp.data);
        });

        dialogConfig.data = {
            filters: this.filters,
            source: 'ongoing',
            listMap: map
        };

        const dialogRef = this.dialog.open(TaskFilterDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe((data) => {
            this.selectionPO.clear();
            if (data) {
                this.filters = data.filters;
                this.dataSource.loadTasks(
                    this.workspaceId,
                    '',
                    this.processDefinitionId,
                    null,
                    this.paginator.pageIndex,
                    this.paginator.pageSize,
                    this.filters
                );

                this.refreshIdsList();
            }
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selectionPO.selected.length;
        return numSelected === this.numRows;
    }

    masterToggle() {
        this.isAllSelected() ? this.selectionPO.clear() : this.selectRows();
    }

    private selectRows() {
        for (const item of this.allIds) {
            this.selectionPO.select(item);
        }
    }

    isEmpty() {
        const numSelected = this.selectionPO.selected.length;
        return numSelected === 0;
    }

    onChange(ob: MatSlideToggleChange) {
        this.unpublishedOnly = ob.checked;
        this.selectionPO.clear();
        this.refreshIdsList();
        this.loadPoPage();
    }

    viewPOOverview(element: Task) {
        this.viewedPO = element;
        this.drawer.open();
    }

    ngOnInit() {

        this.workspaceService
            .getWorkspaceProcessDefinitionId(this.workspaceId)
            .subscribe(response => {
                this.processDefinitionId = response;
                this.dataSource.loadTasks(
                    this.workspaceId,
                    '',
                    this.processDefinitionId,
                    'asc',
                    0,
                    20,
                    this.filters
                );
                this.refreshIdsList();
            });

        this.dataSource = new OngoingPoDataSource(this.ongoingPoService);

        this.dataSource.totalOperations$.subscribe(
            (length: number) => (this.numRows = length)
        );

    }

    refreshIdsList(): void {
        this.ongoingPoService.getTaskIds(
            this.workspaceId,
            '',
            this.processDefinitionId,
            null,
            this.filters
        ).subscribe(data => this.allIds = data.map(String));
    }

    ngAfterViewInit() {
        this.paginator.page.pipe(tap(() => this.loadPoPage())).subscribe();
    }

    loadPoPage(): void {
        this.dataSource.loadTasks(
            this.workspaceId,
            '',
            this.processDefinitionId,
            null,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filters
        );
    }


    public onExport() {
        const selectedTaskIds: string[] = [];
        this.selectionPO.selected.forEach((elem) => {
            selectedTaskIds.push(elem);
        });
        const tenantIdentifier = JSON.parse(localStorage.getItem('tenantIdentifier'));

        this.ongoingPoService
            .exportSelectedTasks({taskIds: selectedTaskIds, tenantIdentifier})
            .subscribe((data: any) => {
                // if (data.type === HttpEventType.Response) {
                const file = new Blob([data], {type: 'text/csv;charset=utf-8'});
                const date = moment(new Date()).format('YYYY-MM-DD').toString();
                saveAs(file, 'export_ongoing-'.concat(date).concat('.csv'));
                // }
            });
    }

    public onCancel() {
        const selectedIds: string[] = [];
        this.selectionPO.selected.forEach((elem) => selectedIds.push(elem));
        let transKey = 'ongoingTask.';
        transKey += (selectedIds.length <= 1) ? 'singular' : 'plural';

        let message = '';
        this.translateService.get(transKey, {invoiceCount: selectedIds.length + ''}).subscribe(
            value => message += value
        );

        // const message = `You are about to archive ${invoiceCount} invoices. Do you want to proceed anyway?`;

        const dialogRef = this.dialog.open(ArchiveInProgressDialogComponent, {
            width: '720px',
            height: 'auto',
            data: {message},
            disableClose: true
        });

        dialogRef.afterClosed().subscribe((confirmed) => {
                if (confirmed) {
                    this.ongoingPoService.cancelProcesses(selectedIds).subscribe(() => {
                        this.selectionPO.clear();
                        this.loadPoPage();
                    });
                }
            }
        );
    }


    public downloadAttachment(poId: string | undefined) {

        this.getAttachmentDetailsFromApi(poId);
        this.ongoingPoService
            .downloadFile(poId)
            .subscribe((data) => {
                if (data.type === HttpEventType.Response) {
                    const downloadedFile = new Blob([data.body], {
                        type: data.body.type,
                    });
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = this.attachmentDetails.originalFileName;
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            });
    }

    private getAttachmentDetailsFromApi(poId: string) {
        this.poApiSubscription = this.ongoingPoService
            .getAttachmentDetailsFromAPI(poId, 'original-file')
            .subscribe((response) => {
                this.attachmentDetails = response[0];
            });
    }

    ngOnDestroy(): void {
        if (this.poApiSubscription) {
            this.poApiSubscription.unsubscribe();
        }
    }

    public navigateToWorkspaceHome() {
        this.router.navigate(['poworkspaces', this.workspaceId]);
    }

    private getRouteParams() {
        this.route.paramMap.subscribe((params) => {
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }

    fixDateFormat(date: Date): string {
        return fixDateFormat(date);
    }
}
