import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class SupplierMdmService {
    constructor(http) {
        this.http = http;
        this.URL = environment.backendUrl + '/invoicestore/app/api/suppliers';
        this.ENTITY_SUPPLIERS_API = environment.backendUrl + '/invoicestore/app/api/suppliers-for-legal-entity';
    }
    buildFiltersParam(filters, params) {
        for (const f of filters) {
            params = params.set(f.parameter.concat('.').concat('contains'), f.value);
        }
        return params;
    }
    getSuppliersFromAPI(filters, workspaceId) {
        let httpParams = new HttpParams()
            .set('size', '10000')
            .set('isActive.equals', 'true');
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        return this.http.get(this.URL, { params: httpParams });
    }
    getSuppliersForWorkspace(filters, workspaceId) {
        let httpParams = new HttpParams()
            .set('size', '1_000')
            .set('isActive.equals', 'true');
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        const body = { workspaceId };
        return this.http.post(this.ENTITY_SUPPLIERS_API, body, { params: httpParams });
    }
}
SupplierMdmService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SupplierMdmService_Factory() { return new SupplierMdmService(i0.ɵɵinject(i1.HttpClient)); }, token: SupplierMdmService, providedIn: "root" });
