import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoaderService} from '../services/loader.service';

@Injectable({providedIn: 'root'})
export class LoaderInterceptor implements HttpInterceptor {

    private activeRequests = 0;

    constructor(public loader$: LoaderService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.activeRequests++;

        if (this.activeRequests > 0) {
            this.loader$.startLoading();
        }

        return next.handle(request).pipe(
            finalize(() => {
                this.activeRequests--;
                if (this.activeRequests === 0) {
                    this.loader$.stopLoading();
                }
            })
        );
    }
}
