import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TabService} from 'src/app/core/services/tab.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AuthenticationService, LoaderService, WorkspacesService} from '../../core/services';
import {environment} from '../../../environments/environment';
// import * as moment from 'moment';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InvoiceStoreMetrics, WorkspaceStats} from '../../models/workspaces.model';
import {Group, IAuthUser} from '../../models/token-user.model';
import {RolesService} from '../../core/services/roles.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {HttpClient} from '@angular/common/http';

const uploadAPI =
    environment.backendUrl + '/invoicestore/app/api/invoice-upload';

interface TaskDefinitions {
    id: string;
    name: string;
    documentation: string;
    nbActiveTasks: number;
}

interface UserTaskDefinitions {
    size: number;
    total: number;
    start: number;
    data: TaskDefinitions[];
}

interface InitPoApprovalProcessReq {
    userId: string;
    workspaceId: string;
}

@Component({
    selector: 'app-activity-overview',
    templateUrl: './po-activity-overview.component.html',
    styleUrls: ['./po-activity-overview.component.scss'],
})
export class PoActivityOverviewComponent implements OnInit, OnDestroy {
    readonly newOrderURL = environment.backendUrl + '/kontaflow/task/app/v2/rest/process-instances/po';

    @ViewChild('fileInput', {static: false}) input: ElementRef;
    private workspaceId: string;
    public authUser: IAuthUser;
    public metrics: WorkspaceStats = {
        workspaceId: undefined,
        myActiveTasksCount: undefined,
        ongoingProcessesCount: undefined,
    };
    public invoiceStoreMetrics: InvoiceStoreMetrics = {
        invoiceReadingCount: undefined,
    };
    public tasks: UserTaskDefinitions = {
        size: undefined,
        total: undefined,
        start: undefined,
        data: [],
    };

    public canAccessWorkspace = new BehaviorSubject<boolean>(false);

    public purchaseOrderCounter: number;
    public purchaseOrderCount: number;
    public rejectedPurchaseOrderCount: number;

    private routerSubscription: Subscription;
    private workspaceServiceSubscription: Subscription;
    private workspaceStatServiceSubscription: Subscription;
    private purchaseOrderStatsSubscription: Subscription;
    private totalOrderStatsSubscription: Subscription;
    private totalRejectedStatsSubscription: Subscription;
    private invoiceStoreSub: Subscription;


    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        public workspacesService: WorkspacesService,
        private tabService: TabService,
        public dialog: MatDialog,
        private loaderService: LoaderService,
        private rolesService: RolesService,
        private permissionsService: NgxPermissionsService
    ) {
        this.getRouterParams();
        this.getAuthUserData();
// task definition
        this.workspaceServiceSubscription = this.workspacesService
            .getProcessStatsFromAPI(this.workspaceId)
            .subscribe(res => this.tasks = res);

        this.workspaceStatServiceSubscription = this.workspacesService
            .pollWorkspaceStatsFromAPI(this.workspaceId)
            .subscribe(res => this.metrics = res);

        this.purchaseOrderStatsSubscription = this.workspacesService
            .refreshPurchaseOrderStats(this.workspaceId, 5000)
            .subscribe(data => this.purchaseOrderCounter = data);

        this.totalOrderStatsSubscription = this.workspacesService
            .refreshPurchaseOrderStats(this.workspaceId, 5000)
            .subscribe(data => this.purchaseOrderCount = data);

        this.totalRejectedStatsSubscription = this.workspacesService
            .refreshRejectedOrderStats(this.workspaceId, 5000)
            .subscribe(data => this.rejectedPurchaseOrderCount = data);

        this.invoiceStoreSub = this.workspacesService
            .pollInvoiceStoreStats(this.workspaceId)
            .subscribe(res => this.invoiceStoreMetrics.invoiceReadingCount = res);
    }

    ngOnInit(): void {
        this.workspacesService.getWorkspaceName(this.workspaceId).subscribe(
            res => this.canAccessWorkspace.next(true),
            error => this.canAccessWorkspace.next(false)
        );
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
        this.workspaceServiceSubscription.unsubscribe();
        this.workspaceStatServiceSubscription.unsubscribe();
        this.purchaseOrderStatsSubscription.unsubscribe();
        this.totalOrderStatsSubscription.unsubscribe();
        this.totalRejectedStatsSubscription.unsubscribe();
        this.invoiceStoreSub.unsubscribe();
    }

    private getRouterParams(): void {
        this.routerSubscription = this.route.paramMap.subscribe((params) => {
            this.workspaceId = params.get('workspaceId');
            this.workspacesService.setWorkspaceId(this.workspaceId);
        });
    }

    /**
     * Get the current user data.
     */
    private getAuthUserData(): void {
        this.authUser = this.authenticationService.getUserAuthData();
    }

    /*public myDateAdapter(): string {
        const API_DATE_FORMAT = 'YYYY-MM-DD';
        moment.locale('fr-FR');
        const date = new Date();
        const newDate = date.setDate(date.getDate() - 30);
        return moment(newDate).format(API_DATE_FORMAT);
    }*/

    /**
     * Look for the given groupName in the user groups array.
     * @param groups user group list.
     * @param groupName group name within the user group list.
     * @returns return true if the provided groupName is in the user groups array.
     */
    public userHasGroup(groups: Group[], groupName: string): boolean {
        const result = groups.filter((item) => {
            return item.id === groupName;
        });
        return result.length !== 0;
    }

    /**
     * Handle user click event on the upload button.
     */
    public handleOnClick(): void {
        const dialogRef = this.dialog.open(NewOrderDialogComponent, {disableClose: true});
        const wp = this.workspaceId + '';
        this.newOrder({userId: this.authUser.userInfo.user.id, workspaceId: wp})
            .then(taskId => this.navigateToPoProcessing(taskId))
            .then(() => dialogRef.close(''));
    }

    /**
     *
     * Export selected invoices
     */

    newOrder(req: InitPoApprovalProcessReq): Promise<any> {
        return this.http.post(this.newOrderURL, req, {responseType: 'text'}).toPromise();
    }

    navigateToPoProcessing(taskId: string): Promise<boolean> {
        return this.router.navigate(['poworkspaces', this.workspaceId, 'tasks', taskId]);
    }

    /**
     * Navigate the user to task-details module
     * @param taskDefKey index of the taskDef used to setSelectedTab
     */
    public navigateToTaskDetails(taskDefKey: number): void {
        this.tabService.setSelectedIndex(taskDefKey);
        this.router.navigate(['poworkspaces', this.workspaceId, 'tasks']).then();
    }

    public navigateToPOExplorer(): void {
        this.router
            .navigate(['poworkspaces', this.workspaceId, 'purchase-orders'])
            .then();
    }

    public navigateToPORejectedExplorer(): void {
        this.router
            .navigate(['poworkspaces', this.workspaceId, 'rejected-purchase-orders'])
            .then();
    }

    public navigateToOngoingPoExplorer(): void {
        this.router
            .navigate(['poworkspaces', this.workspaceId, 'ongoing-purchase-orders'])
            .then();
    }
}


@Component({
    selector: 'app-neworder-dialog',
    templateUrl: './dialogs/neworder-dialog.html'
})
export class NewOrderDialogComponent {
    constructor(public dialogRef: MatDialogRef<NewOrderDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }


}
