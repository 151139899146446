/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./workspace-suppliers.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i3 from "@angular/material/card";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./workspace-suppliers.component";
var styles_WorkspaceSuppliersComponent = [i0.styles];
var RenderType_WorkspaceSuppliersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkspaceSuppliersComponent, data: {} });
export { RenderType_WorkspaceSuppliersComponent as RenderType_WorkspaceSuppliersComponent };
export function View_WorkspaceSuppliersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "mat-card", [["class", "suppliers-card mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i2.View_MatCard_0, i2.RenderType_MatCard)), i1.ɵdid(1, 49152, null, 0, i3.MatCard, [[2, i4.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "mat-card-header", [["class", "mat-card-header"]], null, null, null, i2.View_MatCardHeader_0, i2.RenderType_MatCardHeader)), i1.ɵdid(3, 49152, null, 0, i3.MatCardHeader, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 1, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i3.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, [" Fournisseurs "])), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "mat-card-content", [["class", "mat-card-content"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i3.MatCardContent, [], null, null), (_l()(), i1.ɵted(-1, null, [" Aucun fournisseur configur\u00E9 "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0); }); }
export function View_WorkspaceSuppliersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-workspace-suppliers", [], null, null, null, View_WorkspaceSuppliersComponent_0, RenderType_WorkspaceSuppliersComponent)), i1.ɵdid(1, 114688, null, 0, i5.WorkspaceSuppliersComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkspaceSuppliersComponentNgFactory = i1.ɵccf("app-workspace-suppliers", i5.WorkspaceSuppliersComponent, View_WorkspaceSuppliersComponent_Host_0, {}, {}, []);
export { WorkspaceSuppliersComponentNgFactory as WorkspaceSuppliersComponentNgFactory };
