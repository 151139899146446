import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PoTaskDetailsRoutingModule} from './po-task-details-routing.module';
import {PoTaskDetailsComponent, TaskClaimedComponent, TaskCompletedComponent} from './po-task-details.component';
import {PoTableComponent} from './components/po-table/po-table.component';
import {TranslateModule} from '@ngx-translate/core';

import {
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule
} from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {PoTaskFilterDialogComponent} from './components/po-filter-dialog/po-filter-dialog.component';

@NgModule({
    declarations: [
        PoTaskDetailsComponent,
        PoTableComponent,
        TaskCompletedComponent,
        TaskClaimedComponent,
        PoTaskFilterDialogComponent
    ],
    entryComponents: [TaskCompletedComponent, TaskClaimedComponent, PoTaskFilterDialogComponent],
    imports: [
        CommonModule,
        PoTaskDetailsRoutingModule,
        MatButtonModule,
        MatIconModule,
        MatGridListModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        MatDialogModule,
        TranslateModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDatepickerModule,
        MatInputModule
    ]
})
export class PoTaskDetailsModule {
}
