/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./po-task-details.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/po-table/po-table.component.ngfactory";
import * as i3 from "./components/po-table/po-table.component";
import * as i4 from "../../core/services/task.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i9 from "@angular/material/tabs";
import * as i10 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/a11y";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i15 from "@angular/material/icon";
import * as i16 from "@angular/common";
import * as i17 from "./po-task-details.component";
import * as i18 from "../../core/services/workspaces.service";
import * as i19 from "../../core/services/tab.service";
import * as i20 from "../../core/services/previous-route.service";
var styles_PoTaskDetailsComponent = [i0.styles];
var RenderType_PoTaskDetailsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PoTaskDetailsComponent, data: {} });
export { RenderType_PoTaskDetailsComponent as RenderType_PoTaskDetailsComponent };
function View_PoTaskDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-invoices-table", [], null, null, null, i2.View_PoTableComponent_0, i2.RenderType_PoTableComponent)), i1.ɵdid(1, 4440064, null, 0, i3.PoTableComponent, [i4.TaskService, i5.Router, i6.MatDialog, i7.TranslateService], { parentTab: [0, "parentTab"], processId: [1, "processId"], workspaceId: [2, "workspaceId"], taskDefinitionKey: [3, "taskDefinitionKey"], processDefinitionId: [4, "processDefinitionId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v.parent, 1); var currVal_1 = _co.processId; var currVal_2 = _co.workspaceId; var currVal_3 = _v.parent.context.$implicit.id; var currVal_4 = _co.processDefinitionId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_PoTaskDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "mat-tab", [], null, null, null, i8.View_MatTab_0, i8.RenderType_MatTab)), i1.ɵdid(1, 770048, [[2, 4], ["matTab", 4]], 2, i9.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 3, { templateLabel: 0 }), i1.ɵqud(335544320, 4, { _explicitContent: 0 }), (_l()(), i1.ɵand(0, [[4, 2]], 0, 1, null, View_PoTaskDetailsComponent_2)), i1.ɵdid(5, 16384, null, 0, i9.MatTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PoTaskDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { matTabGroup: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["fxLayout", "row wrap"], ["fxLayoutAlign", "start center"], ["fxLayoutGap", "4px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "back-button"], ["color", "primary"], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToActivityOverview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(3, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "mat-icon", [["class", "back-icon mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i14.View_MatIcon_0, i14.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i15.MatIcon, [i1.ElementRef, i15.MatIconRegistry, [8, null], [2, i15.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_back_ios"])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "mat-tab-group", [["animationDuration", "0ms"], ["class", "mat-tab-group"], ["mat-align-tabs", "center"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "focusChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focusChange" === en)) {
        var pd_0 = (_co.handleFocusChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_MatTabGroup_0, i8.RenderType_MatTabGroup)), i1.ɵdid(8, 3325952, [[1, 4], ["matTabGroup", 4]], 1, i9.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i9.MAT_TABS_CONFIG], [2, i13.ANIMATION_MODULE_TYPE]], { animationDuration: [0, "animationDuration"] }, { focusChange: "focusChange" }), i1.ɵqud(603979776, 2, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PoTaskDetailsComponent_1)), i1.ɵdid(11, 278528, null, 0, i16.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "primary"; _ck(_v, 3, 0, currVal_2); _ck(_v, 5, 0); var currVal_7 = "0ms"; _ck(_v, 8, 0, currVal_7); var currVal_8 = _co.taskDefKeys; _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3).disabled || null); var currVal_1 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 5).inline; var currVal_4 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 8).dynamicHeight; var currVal_6 = (i1.ɵnov(_v, 8).headerPosition === "below"); _ck(_v, 7, 0, currVal_5, currVal_6); }); }
export function View_PoTaskDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-details", [], null, null, null, View_PoTaskDetailsComponent_0, RenderType_PoTaskDetailsComponent)), i1.ɵdid(1, 4440064, null, 0, i17.PoTaskDetailsComponent, [i5.ActivatedRoute, i5.Router, i6.MatDialog, i18.WorkspacesService, i4.TaskService, i19.TabService, i20.PreviousRouteService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PoTaskDetailsComponentNgFactory = i1.ɵccf("app-task-details", i17.PoTaskDetailsComponent, View_PoTaskDetailsComponent_Host_0, {}, {}, []);
export { PoTaskDetailsComponentNgFactory as PoTaskDetailsComponentNgFactory };
var styles_TaskClaimedComponent = [];
var RenderType_TaskClaimedComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TaskClaimedComponent, data: {} });
export { RenderType_TaskClaimedComponent as RenderType_TaskClaimedComponent };
export function View_TaskClaimedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i6.MatDialogTitle, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["T\u00E2che en cours de traitement"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i6.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["align", "end"], ["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i6.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloadPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" RECHARGER LA LISTE "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["color", "primary"], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dialogRef.close(i1.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(14, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["FERMER"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = "primary"; _ck(_v, 10, 0, currVal_4); var currVal_9 = "primary"; _ck(_v, 13, 0, currVal_9); var currVal_10 = ""; _ck(_v, 14, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.message; _ck(_v, 6, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 10).disabled || null); var currVal_3 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_5 = (i1.ɵnov(_v, 13).disabled || null); var currVal_6 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); var currVal_7 = (i1.ɵnov(_v, 14).ariaLabel || null); var currVal_8 = i1.ɵnov(_v, 14).type; _ck(_v, 12, 0, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_TaskClaimedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-error-message", [], null, null, null, View_TaskClaimedComponent_0, RenderType_TaskClaimedComponent)), i1.ɵdid(1, 49152, null, 0, i17.TaskClaimedComponent, [i6.MatDialogRef, i6.MAT_DIALOG_DATA], null, null)], null, null); }
var TaskClaimedComponentNgFactory = i1.ɵccf("app-task-error-message", i17.TaskClaimedComponent, View_TaskClaimedComponent_Host_0, {}, {}, []);
export { TaskClaimedComponentNgFactory as TaskClaimedComponentNgFactory };
var styles_TaskCompletedComponent = [];
var RenderType_TaskCompletedComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TaskCompletedComponent, data: {} });
export { RenderType_TaskCompletedComponent as RenderType_TaskCompletedComponent };
export function View_TaskCompletedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i6.MatDialogTitle, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["T\u00E2che d\u00E9j\u00E0 trait\u00E9"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i6.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 8, "div", [["align", "end"], ["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i6.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reloadPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, [" RECHARGER LA LISTE "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["color", "primary"], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dialogRef.close(i1.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(14, 606208, null, 0, i6.MatDialogClose, [[2, i6.MatDialogRef], i1.ElementRef, i6.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["FERMER"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = "primary"; _ck(_v, 10, 0, currVal_4); var currVal_9 = "primary"; _ck(_v, 13, 0, currVal_9); var currVal_10 = ""; _ck(_v, 14, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.message; _ck(_v, 6, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 10).disabled || null); var currVal_3 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_5 = (i1.ɵnov(_v, 13).disabled || null); var currVal_6 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); var currVal_7 = (i1.ɵnov(_v, 14).ariaLabel || null); var currVal_8 = i1.ɵnov(_v, 14).type; _ck(_v, 12, 0, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_TaskCompletedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-complete-modal", [], null, null, null, View_TaskCompletedComponent_0, RenderType_TaskCompletedComponent)), i1.ɵdid(1, 49152, null, 0, i17.TaskCompletedComponent, [i6.MatDialogRef, i6.MAT_DIALOG_DATA], null, null)], null, null); }
var TaskCompletedComponentNgFactory = i1.ɵccf("app-task-complete-modal", i17.TaskCompletedComponent, View_TaskCompletedComponent_Host_0, {}, {}, []);
export { TaskCompletedComponentNgFactory as TaskCompletedComponentNgFactory };
