import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Filter} from './po-rejected-explorer.component';
import {PurchaseOrder} from '../../models/purchase-order';
import {PoService} from '../../core/services/po.service';

export class PoDataSource implements DataSource<PurchaseOrder> {
    public poSubject = new BehaviorSubject<PurchaseOrder[]>([]);
    public poIdsSubject = new BehaviorSubject<string[]>([]);
    private totalOperations: BehaviorSubject<number> = new BehaviorSubject(0);
    private rowsMap = new Map<string, PurchaseOrder>();
    public readonly totalOperations$: Observable<number> = this.totalOperations.asObservable();
    public readonly poIds: Observable<string[]> = this.poIdsSubject.asObservable();
    public readonly datasourceMap: Map<string, PurchaseOrder> = this.rowsMap;
    private poServiceSubscription: Subscription;

    constructor(private poService: PoService) {
    }

    connect(collectionViewer: CollectionViewer): Observable<PurchaseOrder[]> {
        return this.poSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.poSubject.complete();
        this.poIdsSubject.complete();
        this.poServiceSubscription.unsubscribe();
    }

    loadPOs(
        sortDirection: string,
        pageIndex: number,
        pageSize: number,
        filter: Filter[],
        workspaceId: string,
        status: string
    ) {
        this.poServiceSubscription = this.poService
            .getPOListFromAPI(
                sortDirection,
                pageIndex,
                pageSize,
                filter,
                workspaceId,
                status
            )
            .pipe(catchError(() => of([])))
            .subscribe(pos => {
                this.poSubject.next(pos.content);
                this.totalOperations.next(pos.totalElements);
                const ids: string[] = [];
                pos.content.forEach(po => {
                    ids.push(po.id);
                    this.rowsMap.set(po.id, po);
                });
                this.poIdsSubject.next(ids);
            });
    }


}
