import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Constraint} from '../../models/validation-result';
import {debounceTime, filter, map} from 'rxjs/operators';
import * as _ from 'lodash';
import {InstanceVariable} from '../../models/task';

@Injectable({
    providedIn: 'root'
})
export class AuditService {

    readonly auditUrl = environment.backendUrl + '/invoicestore/app/api/audit';
    public processVariable: InstanceVariable;
    public validationErrors = new Map<string, BehaviorSubject<Constraint[]>>();

    // used to avoid refreshing audit service too early...
    // TODO: need simpler design or at least a doc
    private refreshEmitter = new EventEmitter<string>();

    constructor(private http: HttpClient) {
        this.refreshEmitter
            .pipe(debounceTime(500))
            .subscribe((value) => this.refresh());
    }

    public fire(): void {
        this.refreshEmitter.emit('');
    }

    private refresh(): void {

        this.clearValidationErrors();

        this.auditInvoiceFromApi().pipe(
            filter(value => this.hasConstraints(value)),
            map(value => {
                const constraintsByScope = _.groupBy(value.constraints, 'scope');
                const scopes = Object.keys(constraintsByScope);
                for (const scope of scopes) {
                    let subject = this.validationErrors.get(scope);
                    if (!subject) {
                        this.validationErrors.set(scope, new BehaviorSubject<Constraint[]>(null));
                        subject = this.validationErrors.get(scope);
                    }
                    subject.next(constraintsByScope[scope]);
                }
            })
        ).subscribe();
    }

    public clearValidationErrors() {
        this.validationErrors
            .forEach(subject => subject.next(null));
    }

    private hasConstraints(value) {
        if (value && value.constraints) {
            return true;
        } else {
            return false;
        }
    }

    auditInvoiceFromApi(): Observable<any> {
        return this.http.post<any>(this.auditUrl, this.processVariable);
    }

}
