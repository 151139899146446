import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services';

@Injectable({providedIn: 'root'})
export class JwtInterceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.authService.keycloakService.keycloak.token;

        if (accessToken) {
            request = request.clone(
                {
                    setHeaders: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
        }

        return next.handle(request);
    }

}
