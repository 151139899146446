<mat-card class="mat-elevation-z2">
    <table
            mat-table
            [dataSource]="dataSource"
            class="width-table"
            matSort
            aria-label="Elements"
    >

        <ng-container matColumnDef="po_creationDate">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'po.creationDate'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{
                fixDateFormat(row.processInstanceVariables.po_creationDate)| date: 'shortDate': '': translateService.currentLang
                }}
            </td>
        </ng-container>


        <ng-container matColumnDef="po_orderDate">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'po.orderDate'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{
                fixDateFormat(row.processInstanceVariables.po_orderDate)| date: 'shortDate': '': translateService.currentLang
                }}
            </td>
        </ng-container>


        <ng-container matColumnDef="po_supplierParty_name">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.provider'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.processInstanceVariables.po_supplierParty_name }}
            </td>
        </ng-container>


        <ng-container matColumnDef="po_orderNumber">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'po.orderNumber'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.processInstanceVariables.po_orderNumber }}
            </td>
        </ng-container>


        <ng-container matColumnDef="po_taxExclusiveAmount">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.taxExclusiveAmount'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.processInstanceVariables.po_taxExclusiveAmount | number:'': translateService.currentLang}}
            </td>
        </ng-container>


        <ng-container matColumnDef="po_taxAmount">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.taxAmount'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.processInstanceVariables.po_taxAmount | number:'': translateService.currentLang}}
            </td>
        </ng-container>


        <ng-container matColumnDef="po_taxInclusiveAmount">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.taxInclusiveAmount'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.processInstanceVariables.po_taxInclusiveAmount| number:'': translateService.currentLang}}
            </td>
        </ng-container>

        <ng-container matColumnDef="po_documentCurrencyCode">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.documentCurrencyCode'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.processInstanceVariables.po_documentCurrencyCode }}
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"
            (click)="navigateToPoProcessing(row.id)">
        </tr>
    </table>

    <mat-paginator
            [length]="dataSource.totalOperations$ | async"
            [pageIndex]="0"
            [pageSize]="20"
            [pageSizeOptions]="[5, 10, 20, 50]"
            showFirstLastButtons
    >
    </mat-paginator>
</mat-card>
