<h1 mat-dialog-title>{{'invoiceRejectedExplorer.archiveConfirmDialog.title' | translate}}</h1>
<mat-dialog-content>
    <p>
        {{data.message}}
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" mat-dialog-close cdkFocusInitial>
        {{'invoiceRejectedExplorer.archiveConfirmDialog.cancel' | translate}}
    </button>
    <button mat-button color="primary" [mat-dialog-close]="true">
        {{'invoiceRejectedExplorer.archiveConfirmDialog.confirm' | translate}}
    </button>
</mat-dialog-actions>



