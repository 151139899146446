import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { concatMap, map, retry, switchMap, toArray } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./task.service";
function myDateAdapter() {
    const API_DATE_FORMAT = 'YYYY-MM-DD';
    moment.locale('fr-FR');
    const date = new Date();
    const newDate = date.setDate(date.getDate() - 30);
    return moment(newDate).format(API_DATE_FORMAT);
}
export class WorkspacesService {
    constructor(http, taskService$) {
        this.http = http;
        this.taskService$ = taskService$;
        this.poURL = environment.backendUrl + '/invoicestore/app/api/purchase-orders';
        this.MY_WORKSPACES_URL = environment.backendUrl + '/invoicestore/app/api/my-workspaces';
        this.WORKSPACE_STATS_URL = environment.backendUrl + '/kontaflow/task/app/rest/query/process-metrics';
        this.WORKSPACE_STATS_URL_2 = environment.backendUrl + '/invoicestore/app/api/invoices?size=1';
        this.INVOICESTORE_STATS_URL = environment.backendUrl + '/invoicestore/app/api/invoice-uploads/count';
        this.workspaceId = new BehaviorSubject('');
        this.workspaceId$ = this.workspaceId.asObservable();
    }
    static toCommaSeparated(arr) {
        if (arr) {
            return arr.join(',');
        }
        return '';
    }
    setWorkspaceId(workspaceId) {
        this.workspaceId.next(workspaceId);
    }
    getWorkspace(workspaceId) {
        return this.http
            .get(`${this.MY_WORKSPACES_URL}/${workspaceId}`)
            .pipe(map((response) => {
            localStorage.setItem('tenantIdentifier', JSON.stringify(response.tenantIdentifier));
            localStorage.setItem('legalEntityId', JSON.stringify(response.legalEntityId));
            return response;
        }));
    }
    getWorkspaceName(workspaceId) {
        return this.http
            .get(`${this.MY_WORKSPACES_URL}/${workspaceId}`)
            .pipe(map((response) => {
            localStorage.setItem('tenantIdentifier', JSON.stringify(response.tenantIdentifier));
            localStorage.setItem('legalEntityId', JSON.stringify(response.legalEntityId));
            return response.name;
        }));
    }
    getWorkspaceProcessDefinitionId(workspaceId) {
        return this.http
            .get(`${this.MY_WORKSPACES_URL}/${workspaceId}`)
            .pipe(map((response) => response.processDefId));
    }
    getWorkspacesList(resourceTypes) {
        const value = WorkspacesService.toCommaSeparated(resourceTypes);
        const httpParams = new HttpParams()
            .set('resourceTypes', value);
        return this.http.get(this.MY_WORKSPACES_URL, { params: httpParams })
            .pipe(switchMap((response) => response.content), map((item) => {
            this.getWorkspaceStatsFromAPI(item.id, item.processDefId).subscribe((resp) => {
                item.ongoingProcessesCount = resp.ongoingProcessesCount;
                item.myActiveTasksCount = resp.myActiveTasksCount;
            });
            return item;
        }), toArray());
    }
    getWorkspaceStatsFromAPI(workspaceId, processDefId) {
        return this.http.post(this.WORKSPACE_STATS_URL, { workspaceId, processDefId });
    }
    /**
     * Get the workspace metrics with polling
     */
    pollWorkspaceStatsFromAPI(workspaceId) {
        return this.http.get(`${this.MY_WORKSPACES_URL}/${workspaceId}`).pipe(switchMap((response) => {
            return this.refreshWorkspaceStatsFromAPI(response.id, response.processDefId, 5000);
        }));
    }
    /**
     * Get the workspace invoice store metrics with polling
     */
    pollInvoiceStoreStats(workspaceId) {
        const request$ = this.http.get(`${this.INVOICESTORE_STATS_URL}?workspaceId.equals=${workspaceId}&captured.equals=false`);
        return timer(1000, 4000).pipe(concatMap((_) => request$), retry(10), map((response) => response));
    }
    refreshWorkspaceStatsFromAPI(workspaceId, processDefId, interval) {
        const request$ = this.http.post(this.WORKSPACE_STATS_URL, {
            workspaceId,
            processDefId,
        });
        return timer(1000, interval).pipe(concatMap((_) => request$), retry(10), map((response) => response));
    }
    /**
     * Get workspace processId metrics with polling
     */
    getProcessStatsFromAPI(workspaceId) {
        return this.http.get(`${this.MY_WORKSPACES_URL}/${workspaceId}`).pipe(switchMap((response) => {
            return this.taskService$.refreshProcessDefinitionFromAPI(response.id, response.processDefId, 5000);
        }));
    }
    /**
     * Get workspace processId tasks
     */
    getProcessTasksFromAPI(workspaceId) {
        return this.http.get(`${this.MY_WORKSPACES_URL}/${workspaceId}`).pipe(switchMap((response) => {
            return this.taskService$.getProcessDefinitionFromAPI(response.processDefId);
        }));
    }
    // TODO: STILL USED ?? remove it !
    refreshPurchaseInvoiceStats(workspaceId, interval) {
        const httpParams = new HttpParams()
            .set('size', '1')
            .set('approvalStatus.equals', 'APPROVED')
            .set('workspaceId.equals', workspaceId)
            .set('approvalDate.greaterOrEqualThan', myDateAdapter());
        const request$ = this.http
            .get(this.WORKSPACE_STATS_URL_2, {
            params: httpParams,
        })
            .pipe(map((response) => response.totalElements));
        return timer(1000, interval).pipe(concatMap((_) => request$), retry(5), map((response) => response));
    }
    // TODO: use /count api
    refreshTotalPurchaseInvoiceStats(workspaceId, interval) {
        const httpParams = new HttpParams()
            .set('size', '1')
            .set('approvalStatus.equals', 'APPROVED')
            .set('workspaceId.equals', workspaceId);
        const request$ = this.http
            .get(this.WORKSPACE_STATS_URL_2, {
            params: httpParams,
        })
            .pipe(map((response) => response.totalElements));
        return timer(1000, interval).pipe(concatMap((_) => request$), retry(5), map((response) => response));
    }
    refreshRejectedInvoiceStats(workspaceId, interval) {
        const httpParams = new HttpParams()
            .set('size', '1')
            .set('approvalStatus.equals', 'REJECTED')
            .set('workspaceId.equals', workspaceId);
        const request$ = this.http
            .get(this.WORKSPACE_STATS_URL_2, {
            params: httpParams
        }).pipe(map(res => res.totalElements));
        return timer(1000, interval).pipe(concatMap(_ => request$), retry(5), map(res => res));
    }
    refreshRejectedOrderStats(workspaceId, interval) {
        const httpParams = new HttpParams()
            .set('size', '1')
            .set('poLifecycleType.equals', 'MANAGED')
            .set('poApprovalStatus.equals', 'REJECTED')
            .set('workspaceId.equals', workspaceId);
        const request$ = this.http.get(this.poURL, {
            params: httpParams
        }).pipe(map(res => res.totalElements));
        return timer(1000, interval).pipe(concatMap(_ => request$), retry(5), map(res => res));
    }
    refreshPurchaseOrderStats(workspaceId, interval) {
        const httpParams = new HttpParams()
            .set('size', '1')
            .set('poLifecycleType.equals', 'MANAGED')
            .set('poApprovalStatus.equals', 'APPROVED')
            .set('workspaceId.equals', workspaceId);
        const request$ = this.http.get(this.poURL, {
            params: httpParams,
        })
            .pipe(map((response) => response.totalElements));
        return timer(1000, interval).pipe(concatMap((_) => request$), retry(5), map((response) => response));
    }
}
WorkspacesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WorkspacesService_Factory() { return new WorkspacesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TaskService)); }, token: WorkspacesService, providedIn: "root" });
