import {environment} from '../../../environments/environment';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {HttpClient, HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import {AuthenticationService} from '../../core/services';


@Component({
    selector: 'app-bundle-downloader',
    templateUrl: './bundle-downloader.component.html',
    styleUrls: ['./bundle-downloader.component.scss'],
})
export class BundleDownloaderComponent implements OnInit, OnDestroy {

    private routerSubscription: Subscription;
    private downloadSubscription: Subscription;
    private bundleId: string;

    readonly bundleUrl = (bundleId: string) => `${environment.backendUrl}/invoicestore/app/api/bundles/${bundleId}`;

    constructor(private route: ActivatedRoute,
                private http: HttpClient,
                private authenticationService: AuthenticationService) {
        this.getRouterParams();
    }

    ngOnInit(): void {
        this.downloadSubscription = this.downloadBundleById(this.bundleId).subscribe((response) => {
            if (response.type === HttpEventType.Response) {
                // tslint:disable-next-line:no-non-null-assertion
                const downloadedFile = new Blob([response.body!], {
                    // tslint:disable-next-line:no-non-null-assertion
                    type: response.body!.type,
                });

                // Extract filename from headers, assuming it’s sent in the 'content-disposition' header
                const contentDisposition = response.headers.get('content-disposition');
                let filename = 'invoices-bundle.zip'; // Default filename

                if (contentDisposition) {
                    const matches = /filename="([^"]*)"/.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1];
                    }
                }

                const a = document.createElement('a');
                a.setAttribute('style', 'display:none;');
                document.body.appendChild(a);
                a.download = filename;
                a.href = URL.createObjectURL(downloadedFile);
                a.target = '_blank';
                a.click();
                document.body.removeChild(a);
            }
        });
    }

    private downloadBundleById(bundleId: string): Observable<HttpResponse<Blob>> {
        const headers = new HttpHeaders()
            .set('Accept', '*/*')
            .set('Authorization', `Bearer ${this.authenticationService.getUserToken()}`);
        return this.http.get(this.bundleUrl(bundleId), {
            headers,
            observe: 'response',  // Ensure response includes headers
            responseType: 'blob'   // Blob type to handle binary data
        });
    }

    private getRouterParams(): void {
        this.routerSubscription = this.route.paramMap.subscribe((params) => {
            this.bundleId = params.get('bundleId');
        });
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
        this.downloadSubscription.unsubscribe();
    }


}