import { BehaviorSubject } from 'rxjs';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class PreviousRouteService {
    constructor(router) {
        this.router = router;
        this.currentUrl = this.router.url;
        this.previousUrl = new BehaviorSubject(null);
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
            this.previousUrl.next(this.currentUrl);
            this.currentUrl = event.urlAfterRedirects;
        });
    }
}
PreviousRouteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PreviousRouteService_Factory() { return new PreviousRouteService(i0.ɵɵinject(i1.Router)); }, token: PreviousRouteService, providedIn: "root" });
