<h1 mat-dialog-title>{{'searchSupplier.title'| translate}}</h1>
<form [formGroup]="form" (submit)="onSubmit()" autocomplete="off">
    <p style="color: grey; text-transform: uppercase;">
        {{'searchSupplier.request'| translate}}
    </p>
    <mat-dialog-content
            fxLayout="row wrap"
            fxLayoutWrap
            fxLayoutGap="2%"
            fxLayoutAlign="flex center"
            formArrayName="filters">

        <ng-container
                *ngFor="let filtre of getControls(); let i = index"
                [formGroupName]="i">

            <mat-form-field fxFlex="35%">
                <mat-label>
                    {{'searchSupplier.attribute'| translate}}
                </mat-label>

                <mat-select
                        formControlName="parameter"
                        (ngModelChange)="onParameterChanges(filtre,i)"
                >
                    <mat-optgroup
                            *ngFor="let group of filterGroup"
                            label="{{group.name}}"
                    >
                        <mat-option
                                *ngFor="let param of group.children"
                                value="{{param.value}}"
                        >{{param.label}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="52%">
                <mat-label>{{ 'global.value'| translate}}</mat-label>
                <input matInput type="search" formControlName="value"/>
            </mat-form-field>

            <button
                    mat-icon-button
                    class=""
                    type="submit"
                    color="primary"
                    [disabled]="form.status === 'INVALID'"
            >
                <mat-icon>search</mat-icon>
            </button>
        </ng-container>

        <div fxFlex="100%">
            <p style="color: grey; text-transform: uppercase;">
                {{'searchSupplier.result'| translate}}
            </p>
        </div>

        <ng-container>
            <mat-radio-group
                    [(ngModel)]="selectedSupplier"
                    [ngModelOptions]="{standalone: true}"
                    aria-label="Select a supplier"
                    color="primary"
            >
                <mat-radio-button
                        style="display: flex; flex-direction: column; margin: 15px 0;"
                        *ngFor="let supplier of supplierList | async"
                        [value]="supplier"
                >{{supplier.name}} ({{ 'global.identifier'| translate}}:
                    {{supplier.customerAssignedAccountId}} | {{ 'global.legalID'|
                        translate}}: {{supplier.legalId}})
                </mat-radio-button
                >
            </mat-radio-group>
        </ng-container>
    </mat-dialog-content>

    <div mat-dialog-actions align="end">
        <button mat-button color="primary" (click)="onNoClick()" matDialogClose>
            {{'searchSupplier.cancel'| translate}}
        </button>
        <button
                mat-button
                color="primary"
                type="button"
                [disabled]="selectedSupplier === undefined"
                (click)="handleSelect()"
        >
            {{'searchSupplier.select'| translate}}
        </button>
    </div>
</form>
