import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TabService, TaskService, WorkspacesService} from '../../core/services';
import {Subscription} from 'rxjs';
import {MatTabGroup} from '@angular/material';
import {Filter} from '../../models/filter-dialog';
import {TaskFilterDialogComponent} from './components/filter-dialog/filter-dialog.component';
import {PreviousRouteService} from '../../core/services/previous-route.service';
import * as moment from 'moment';
import {saveAs} from 'file-saver';
import {HttpEventType} from '@angular/common/http';


interface TaskDefKey {
    id: string;
    name: string;
}

interface Payload {
    status: number | string;
    message: string;
    key: string;
}

@Component({
    selector: 'app-task-details',
    templateUrl: './task-details.component.html',
    styleUrls: ['./task-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TaskDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('matTabGroup', {static: false}) matTabGroup: MatTabGroup;

    public workspaceId: string;
    public processId: string;
    public processDefinitionId: string;
    public taskId: string;
    public taskDefKeys: TaskDefKey[] = [];
    public filters: Filter[] = [];

    private routerSubscription: Subscription;
    private workspaceServiceSubscription: Subscription;
    private taskErrorsSubscription: Subscription;
    private tabServiceSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public dialog: MatDialog,
        private workspaceService: WorkspacesService,
        private taskService: TaskService,
        private tabService: TabService,
        private previousRouteService: PreviousRouteService
    ) {
        this.getRouteParams();

        this.workspaceService
            .getWorkspaceProcessDefinitionId(this.workspaceId)
            .subscribe(response => (this.processDefinitionId = response));

        this.workspaceServiceSubscription = this.workspaceService
            .getProcessTasksFromAPI(this.workspaceId)
            .subscribe(response => {
                response.data.forEach(item => {
                    this.taskDefKeys.push({id: item.id, name: item.name});
                });
                this.setSelectedTab();
            });

        this.previousRouteService.previousUrl.subscribe(url => {
            if (url === null) {
                localStorage.removeItem('filters');
            }
        });
    }

    ngOnInit() {
        this.filters = this.taskService.getFiltersFromLocalStorage();
        if (!this.filters) {
            this.filters = [];
        }
    }

    ngAfterViewInit() {
        this.taskErrorsSubscription = this.taskService.taskErrors$.subscribe(
            msg => {
                switch (msg.status) {
                    case 400:
                        this.notifyClaimedTask(msg);
                        break;
                    case 404:
                        this.notifyCompletedTask(msg);
                        break;
                    default:
                        break;
                }
            }
        );
    }

    /**
     * Angular destroy component used to unsubscribe to all services
     */
    ngOnDestroy(): void {
        if (this.taskErrorsSubscription) {
            this.taskErrorsSubscription.unsubscribe();
        }
        if (this.workspaceServiceSubscription) {
            this.workspaceServiceSubscription.unsubscribe();
        }
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
        if (this.tabServiceSubscription) {
            this.tabServiceSubscription.unsubscribe();
        }
    }

    /**
     * Subscribe to angular router to get the route params
     */
    private getRouteParams(): void {
        this.routerSubscription = this.route.paramMap.subscribe(params => {
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }

    /**
     * Trigger the already claimed modal event.
     * @param payload handleError service
     */
    private openClaimedModalMessage(payload: Payload): void {
        this.dialog.open(TaskClaimedComponent, {
            width: '900px',
            data: {...payload}
        });
    }

    /**
     * Trigger the already completed modal event.
     * @param payload handleError service
     */
    private openCompletedModalMessage(payload: Payload): void {
        this.dialog.open(TaskCompletedComponent, {
            width: '400px',
            data: {...payload}
        });
    }

    /**
     * Display a modal with error message task already claimed
     * @param error the error object returned by the claim task request.
     */
    private notifyClaimedTask(error: Payload): void {
        this.openClaimedModalMessage(error);
    }

    /**
     * Display a modal with error message task already completed
     * @param error the error object returned by the claim task request.
     */
    private notifyCompletedTask(error: Payload): void {
        this.openCompletedModalMessage(error);
    }

    /**
     * select the right tab index in Task-details MatTabGroup
     */
    private setSelectedTab(): void {
        this.tabServiceSubscription = this.tabService.selectedTab$.subscribe(
            index => this.matTabGroup.selectedIndex = index
        );
    }

    /**
     * Handle change tab event.
     * @param event return event when user changes Tab.
     */
    public handleFocusChange(event): void {
        this.tabService.setSelectedIndex(event.index);
    }

    /**
     * Use angular router to navigate to next url.
     */
    public navigateToActivityOverview(): void {
        this.router.navigate(['/workspaces', this.workspaceId]).then();
    }

    public openFilterDialog(): void {
        const dialogRef = this.dialog.open(TaskFilterDialogComponent, {
            width: '720px',
            height: 'auto',
            data: {
                filters: this.filters,
                source: 'tasksDetails'
            },
            disableClose: true,
        });

        dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                this.filters = data.filters;
                this.taskService.filtersChanged.next(this.filters);
            }
        });
    }

    onExport() {
        this.taskService.exportTasks(this.workspaceId,
            this.processId,
            this.taskDefKeys[this.matTabGroup.selectedIndex].id,
            this.processDefinitionId,
            this.filters).subscribe((data: any) => {
            if (data.type === HttpEventType.Response) {
                console.log(data);
                const file = new Blob([data.body], {type: 'text/csv;charset=utf-8'});
                const date = moment(new Date()).format('YYYY-MM-DD').toString();
                saveAs(file, 'export_task_'.concat(this.taskDefKeys[this.matTabGroup.selectedIndex].id).concat('-').concat(date).concat('.csv'));
            }
        });
    }
}

@Component({
    selector: 'app-task-error-message',
    templateUrl: './components/error-message/task-claimed-message.html'
})
export class TaskClaimedComponent {
    public assignee: any = {};

    constructor(
        public dialogRef: MatDialogRef<TaskClaimedComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    reloadPage() {
        // TODO: ONLY RELOAD THE OBSERVABLE ! table refresh is a side effect !
        // and do not reload the whole page ;)
        location.reload();
    }
}

@Component({
    selector: 'app-task-complete-modal',
    templateUrl: './components/error-message/task-completed-message.html'
})
export class TaskCompletedComponent {
    constructor(
        public dialogRef: MatDialogRef<TaskCompletedComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    reloadPage() {
        location.reload();
    }
}
