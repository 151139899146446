import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {PoProcessingComponent} from '../../modules/po-processing/po-processing.component';

@Injectable({
    providedIn: 'root'
})
export class CanDeactivatePoGuard implements CanDeactivate<PoProcessingComponent> {

    canDeactivate(
        component: PoProcessingComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> | boolean {

        if (component.submitSuccess) {
            return true;
        } else {
            return component.canLeaveForm();
        }

    }
}
