import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {PoActivityOverviewRoutingModule} from './po-activity-overview-routing.module';
import {NewOrderDialogComponent, PoActivityOverviewComponent} from './po-activity-overview.component';

import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutModule} from '@angular/cdk/layout';

import {
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {NgxPermissionsModule} from 'ngx-permissions';

@NgModule({
    declarations: [PoActivityOverviewComponent, NewOrderDialogComponent],
    entryComponents: [NewOrderDialogComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        PoActivityOverviewRoutingModule,
        FlexLayoutModule,
        LayoutModule,

        /* Angular material */
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        TranslateModule,
        NgxPermissionsModule
    ],
    exports: [PoActivityOverviewComponent]
})
export class PoActivityOverviewModule {
}
