import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./authentication.service";
export class OngoingInvoiceService {
    constructor(http, authenticationService) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.tasksURL = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/processes/ongoing';
        this.taskIdsURL = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/processes/ongoing/taskIds';
        this.cancelProcessesUrl = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/processes/cancel';
        this.taskNames = environment.backendUrl + '/kontaflow/task/app/rest/query/task-definitions';
        this.invoiceURL = environment.backendUrl + '/invoicestore/app/api/invoices';
        this.attachmentURL = environment.backendUrl + '/invoicestore/app/api/attached-documents';
        this.exportUrl = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/exportongoing';
        this.updateStatusUrl = environment.backendUrl + '/invoicestore/app/api/invoices/update-status';
    }
    getInvoiceListFromAPI(sort, pageIndex, pageSize, filters, onlyPublished, workspaceId) {
        if (!sort) {
            sort = 'issueDate,desc';
        }
        let httpParams = new HttpParams()
            .set('approvalStatus.equals', 'DRAFT')
            .set('sort', sort)
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString())
            .set('workspaceId.equals', workspaceId);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        if (onlyPublished) {
            httpParams = httpParams.set('publicationStatus.equals', 'PUBLISHED');
        }
        return this.http.get(this.invoiceURL, { params: httpParams });
    }
    getInvoiceByIDFromAPI(invoiceId) {
        const httpParams = new HttpParams().set('id.equals', invoiceId);
        return this.http.get(this.invoiceURL, { params: httpParams });
    }
    buildFiltersParam(filters, params) {
        for (const f of filters) {
            if (f.parameter === 'issueDate' ||
                f.parameter === 'receiptDate') {
                params = params.set(f.parameter.concat('.').concat(f.condition), moment(f.value).valueOf() + '');
            }
            else {
                if (!f.value && f.condition === 'equals') {
                    params = params.set(f.parameter.concat('.specified'), 'false');
                }
                else {
                    params = params.set(f.parameter.concat('.').concat(f.condition), f.value);
                }
            }
        }
        return params;
    }
    getAttachmentDetailsFromAPI(invoiceId, documentType) {
        const httpParams = new HttpParams()
            .set('invoiceId.equals', invoiceId)
            .set('documentType.equals', documentType);
        return this.http.get(this.attachmentURL, {
            params: httpParams,
        });
    }
    exportSelectedTasks(tasksIds) {
        const headers = new HttpHeaders();
        headers.set('Accept', 'application/csv');
        return this.http.post(this.exportUrl, tasksIds, { headers, responseType: 'text' });
    }
    archiveInvoices(invoiceIds) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/csv');
        const httpParams = new HttpParams()
            .set('newStatus', 'ARCHIVED');
        return this.http.post(this.updateStatusUrl, invoiceIds, {
            headers,
            params: httpParams
        });
    }
    downloadFile(invoiceId) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        httpHeaders.set('Authorization', 'bearer ' + this.authenticationService.getUserToken() + '');
        return this.http.request(new HttpRequest('POST', `${environment.backendUrl}/invoicestore/app/api/invoices/${invoiceId}/attachment-content`, { attachmentType: 'original-file' }, {
            headers: httpHeaders,
            reportProgress: true,
            responseType: 'blob',
        }));
    }
    getTaskList(workspaceId, taskDefinitionKey, processDefinitionId, sortDirection, pageIndex, pageSize, filters) {
        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        httpParams = httpParams
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString());
        return this.http.post(this.tasksURL, Object.assign({ workspaceId,
            taskDefinitionKey,
            processDefinitionId, state: 'open', assignment: 'myTasks', sort: sortDirection === 'desc' ? 'created-desc' : 'created-asc', includeCompleteProcessInstance: true }, filters), { params: httpParams });
    }
    getTaskIds(workspaceId, taskDefinitionKey, processDefinitionId, sortDirection, filters) {
        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        return this.http.post(this.taskIdsURL, Object.assign({ workspaceId,
            taskDefinitionKey,
            processDefinitionId, state: 'open', assignment: 'myTasks', sort: sortDirection === 'desc' ? 'created-desc' : 'created-asc', includeCompleteProcessInstance: false }, filters), { params: httpParams });
    }
    cancelProcesses(taskIds) {
        return this.http.post(this.cancelProcessesUrl, taskIds);
    }
    getTaskNames(workspaceId, processDefinitionId) {
        return this.http.post(this.taskNames, {
            workspaceId,
            processDefinitionId
        });
    }
}
OngoingInvoiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OngoingInvoiceService_Factory() { return new OngoingInvoiceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthenticationService)); }, token: OngoingInvoiceService, providedIn: "root" });
