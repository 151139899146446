import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {Filter} from '../../modules/invoice-explorer/invoice-explorer.component';
import {Observable} from 'rxjs';
import {Invoice} from '../../models/invoice';
import * as moment from 'moment';
import {AttachmentDetails} from '../../models/attachment-details.model';

@Injectable({
    providedIn: 'root'
})
export class OngoingInvoiceService {

    readonly tasksURL = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/processes/ongoing';
    readonly taskIdsURL = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/processes/ongoing/taskIds';
    readonly cancelProcessesUrl = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/processes/cancel';
    readonly taskNames = environment.backendUrl + '/kontaflow/task/app/rest/query/task-definitions';
    readonly invoiceURL = environment.backendUrl + '/invoicestore/app/api/invoices';
    readonly attachmentURL = environment.backendUrl + '/invoicestore/app/api/attached-documents';
    readonly exportUrl = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/exportongoing';
    readonly updateStatusUrl = environment.backendUrl + '/invoicestore/app/api/invoices/update-status';

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    }

    public getInvoiceListFromAPI(
        sort: string,
        pageIndex: number,
        pageSize: number,
        filters: Filter[],
        onlyPublished: boolean,
        workspaceId: string): Observable<any> {
        if (!sort) {
            sort = 'issueDate,desc';
        }
        let httpParams = new HttpParams()
            .set('approvalStatus.equals', 'DRAFT')
            .set('sort', sort)
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString())
            .set('workspaceId.equals', workspaceId);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        if (onlyPublished) {
            httpParams = httpParams.set('publicationStatus.equals', 'PUBLISHED');
        }
        return this.http.get<any>(this.invoiceURL, {params: httpParams});
    }

    public getInvoiceByIDFromAPI(invoiceId: string): Observable<Invoice> {
        const httpParams = new HttpParams().set('id.equals', invoiceId);
        return this.http.get<Invoice>(this.invoiceURL, {params: httpParams});
    }

    buildFiltersParam(filters: Filter[], params: HttpParams): HttpParams {
        for (const f of filters) {
            if (f.parameter === 'issueDate' ||
                f.parameter === 'receiptDate') {
                params = params.set(f.parameter.concat('.').concat(f.condition), moment(f.value).valueOf() + '');
            } else {
                if (!f.value && f.condition === 'equals') {
                    params = params.set(
                        f.parameter.concat('.specified'),
                        'false'
                    );
                } else {
                    params = params.set(
                        f.parameter.concat('.').concat(f.condition),
                        f.value
                    );
                }
            }
        }
        return params;
    }

    public getAttachmentDetailsFromAPI(
        invoiceId: string,
        documentType: string
    ): Observable<AttachmentDetails[]> {
        const httpParams = new HttpParams()
            .set('invoiceId.equals', invoiceId)
            .set('documentType.equals', documentType);

        return this.http.get<AttachmentDetails[]>(this.attachmentURL, {
            params: httpParams,
        });
    }

    exportSelectedTasks(tasksIds: { taskIds: string[]; tenantIdentifier: any }): Observable<any> {

        const headers = new HttpHeaders();
        headers.set('Accept', 'application/csv');

        return this.http.post(this.exportUrl,
            tasksIds,
            {headers, responseType: 'text'}
        );
    }

    public archiveInvoices(invoiceIds: string[]): Observable<any> {
        let headers = new HttpHeaders();

        headers = headers.set('Accept', 'application/csv');

        const httpParams = new HttpParams()
            .set('newStatus', 'ARCHIVED');

        return this.http.post(
            this.updateStatusUrl,
            invoiceIds,
            {
                headers,
                params: httpParams
            });


    }

    downloadFile(invoiceId: string): Observable<HttpEvent<Blob>> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        httpHeaders.set(
            'Authorization',
            'bearer ' + this.authenticationService.getUserToken() + ''
        );
        return this.http.request(
            new HttpRequest(
                'POST',
                `${environment.backendUrl}/invoicestore/app/api/invoices/${invoiceId}/attachment-content`,
                {attachmentType: 'original-file'},
                {
                    headers: httpHeaders,
                    reportProgress: true,
                    responseType: 'blob',
                }
            )
        );
    }

    getTaskList(
        workspaceId: string, taskDefinitionKey: string, processDefinitionId: string,
        sortDirection: string, pageIndex: number, pageSize: number,
        filters: Filter[]): Observable<any> {

        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        httpParams = httpParams
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString());

        return this.http.post<any>(this.tasksURL, {
            workspaceId,
            taskDefinitionKey,
            processDefinitionId,
            state: 'open',
            assignment: 'myTasks',
            sort: sortDirection === 'desc' ? 'created-desc' : 'created-asc',
            includeCompleteProcessInstance: true,
            ...filters
        }, {params: httpParams});
    }

    public getTaskIds(
        workspaceId: string, taskDefinitionKey: string, processDefinitionId: string,
        sortDirection: string, filters: Filter[]): Observable<any> {

        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }

        return this.http.post<any>(this.taskIdsURL, {
            workspaceId,
            taskDefinitionKey,
            processDefinitionId,
            state: 'open',
            assignment: 'myTasks',
            sort: sortDirection === 'desc' ? 'created-desc' : 'created-asc',
            includeCompleteProcessInstance: false,
            ...filters
        }, {params: httpParams});
    }


    cancelProcesses(taskIds: string[]): Observable<any> {
        return this.http.post<any>(this.cancelProcessesUrl, taskIds);
    }


    getTaskNames(
        workspaceId: string,
        processDefinitionId: string
    ): Observable<any> {
        return this.http.post<any>(this.taskNames, {
            workspaceId,
            processDefinitionId
        });
    }
}
