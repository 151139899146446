import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {TaskService} from '../../../../core/services';
import {catchError} from 'rxjs/operators';
import {Filter} from '../../../../models/filter-dialog';
import {Task} from '../../../../models/task';


/**
 * DataSource implementation supporting server-side pagination
 */
export class TasksDataSource implements DataSource<Task> {
    private tasksSubject = new BehaviorSubject<Task[]>([]);
    private totalOperations: BehaviorSubject<number> = new BehaviorSubject(0);

    public readonly totalOperations$: Observable<number> = this.totalOperations.asObservable();

    private taskServiceSubscription: Subscription;

    constructor(private taskService: TaskService) {
    }

    connect(collectionViewer: CollectionViewer): Observable<Task[]> {
        return this.tasksSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.tasksSubject.complete();
        this.taskServiceSubscription.unsubscribe();
    }

    loadTasks(
        workspaceId: string,
        processId: string,
        taskDefinitionKey: string,
        processDefinitionId: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number,
        filters: Filter[]
    ) {
        this.taskServiceSubscription = this.taskService.getPoTaskListFromAPI(
            workspaceId,
            processId,
            taskDefinitionKey,
            processDefinitionId,
            sortDirection,
            pageIndex,
            pageSize,
            filters
        )
            .pipe(catchError(() => of([])))
            .subscribe(tasks => {
                this.tasksSubject.next(tasks.data);
                this.totalOperations.next(tasks.total);
            });
    }
}

