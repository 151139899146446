import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class LoaderService {
    constructor(router$) {
        this.router$ = router$;
        this.loader = new Subject();
        this.loader$ = this.loader.asObservable();
    }
    /**
     * Start the app loading state
     */
    startLoading() {
        const param = this.router$.url.split('/').pop();
        const url = `/workspaces/${param}`;
        const url2 = `/poworkspaces/${param}`;
        if (this.router$.isActive(url, true) || this.router$.isActive(url2, true)) { // match url exactly !
            // skip loader for this url
            this.loader.next(false);
        }
        else {
            this.loader.next(true);
        }
    }
    /**
     * Stop the app loading state
     */
    stopLoading() {
        this.loader.next(false);
    }
}
LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(i0.ɵɵinject(i1.Router)); }, token: LoaderService, providedIn: "root" });
