import { Routes } from '@angular/router';
import { AuthGuard, CanDeactivateGuard } from './core/guards';
import { WorkspaceInfoComponent } from './modules/params/components/workspace-info/workspace-info.component';
import { WorkspaceCategoriesComponent } from './modules/params/components/workspace-categories/workspace-categories.component';
import { WorkspaceSuppliersComponent } from './modules/params/components/workspace-suppliers/workspace-suppliers.component';
import { ParamsComponent } from './modules/params/params.component';
import { KontaAppComponent } from './modules/konta-app/konta-app.component';
import { InvoiceProcessingComponent } from './modules/invoice-processing/invoice-processing.component';
import { CanClaimTaskGuard } from './core/guards/can-activate.guard';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { TaskDetailsComponent } from './modules/task-details/task-details.component';
import { ActivityOverviewComponent } from './modules/activity-overview/activity-overview.component';
import { WorkspacesComponent } from './modules/workspaces/workspaces.component';
import { InvoiceExplorerComponent } from './modules/invoice-explorer/invoice-explorer.component';
import { InvoiceRejectedExplorerComponent } from './modules/invoice-rejected-explorer/invoice-rejected-explorer.component';
import { ClearFilterGuard } from './core/guards/clear-filter.guard';
import { InvoiceInProgressExplorerComponent } from './modules/invoice-inprogress-explorer/invoice-inprogress-explorer.component';
import { PermissionAuthGuard } from './core/guards/permission-auth.guard';
import { PoActivityOverviewComponent } from './modules/activity-overview-po/po-activity-overview.component';
import { PoProcessingComponent } from './modules/po-processing/po-processing.component';
import { PoTaskDetailsComponent } from './modules/po-task-details/po-task-details.component';
import { CanDeactivatePoGuard } from './core/guards/can-deactivate-po.guard';
import { POExplorerComponent } from './modules/po-explorer/po-explorer.component';
import { PoRejectedExplorerComponent } from './modules/po-rejected-explorer/po-rejected-explorer.component';
import { OngoingPoExplorerComponent } from './modules/ongoing-po-explorer/ongoing-po-explorer.component';
import { BundleDownloaderComponent } from './modules/bundle-downloader/bundle-downloader.component';
const ɵ0 = {
    breadcrumb: '',
}, ɵ1 = {
    breadcrumb: ''
}, ɵ2 = {
    breadcrumb: '',
}, ɵ3 = {
    breadcrumb: '',
}, ɵ4 = {
    breadcrumb: '',
    permissions: {
        only: ['workspace.viewer']
    }
}, ɵ5 = {
    breadcrumb: 'global.tasksList',
    permissions: {
        only: ['tasks.user']
    }
}, ɵ6 = {
    breadcrumb: 'global.purchasingInvoices',
    permissions: {
        only: ['explorer.approved.editor', 'explorer.approved.viewer']
    }
}, ɵ7 = {
    breadcrumb: 'global.rejectedTaskPageTitle',
    permissions: {
        only: ['explorer.rejected.editor', 'explorer.rejected.viewer']
    }
}, ɵ8 = {
    breadcrumb: 'global.ongoingTaskPageTitle',
    permissions: {
        only: ['explorer.ongoing.editor', 'explorer.ongoing.viewer']
    }
}, ɵ9 = {
    breadcrumb: 'global.tasksList',
}, ɵ10 = {
    breadcrumb: '',
}, ɵ11 = {
    breadcrumb: '',
}, ɵ12 = {
    breadcrumb: '',
    permissions: {
        only: ['workspace.viewer']
    }
}, ɵ13 = {
    breadcrumb: 'global.tasksList',
    permissions: {
        only: ['tasks.user']
    }
}, ɵ14 = {
    breadcrumb: 'global.approvedOrders',
    permissions: {
        only: ['explorer.approved.editor', 'explorer.approved.viewer']
    }
}, ɵ15 = {
    breadcrumb: 'ongoingPoExplorer.ongoingOrders',
    permissions: {
        only: ['explorer.ongoing.editor', 'explorer.ongoing.viewer']
    }
}, ɵ16 = {
    breadcrumb: 'global.rejectedOrders',
    permissions: {
        only: ['explorer.rejected.editor', 'explorer.rejected.viewer']
    }
}, ɵ17 = {
    breadcrumb: 'Factures rejetées',
    permissions: {
        only: ['explorer.rejected.editor', 'explorer.rejected.viewer']
    }
}, ɵ18 = {
    breadcrumb: 'global.tasksList',
};
const routes = [
    {
        path: 'bundles',
        component: KontaAppComponent,
        canActivate: [AuthGuard],
        data: ɵ0,
        children: [
            {
                path: ':bundleId',
                component: BundleDownloaderComponent,
                data: ɵ1,
            },
        ]
    },
    {
        path: 'workspaces',
        component: KontaAppComponent,
        canActivate: [AuthGuard],
        data: ɵ2,
        children: [
            {
                path: '',
                component: WorkspacesComponent,
                canActivate: [AuthGuard],
                data: ɵ3,
            },
            {
                path: ':workspaceId',
                component: ActivityOverviewComponent,
                canActivate: [PermissionAuthGuard],
                data: ɵ4,
            },
            {
                path: ':workspaceId/tasks',
                component: TaskDetailsComponent,
                canActivate: [PermissionAuthGuard],
                canDeactivate: [ClearFilterGuard],
                data: ɵ5,
            },
            {
                path: ':workspaceId/purchase-invoices',
                component: InvoiceExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: ɵ6,
            },
            {
                path: ':workspaceId/rejected-invoices',
                component: InvoiceRejectedExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: ɵ7
            }, {
                path: ':workspaceId/in-progress-invoices',
                component: InvoiceInProgressExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: ɵ8
            },
            {
                path: ':workspaceId/params',
                component: ParamsComponent,
                canActivate: [AuthGuard],
                children: [
                    { path: 'general', component: WorkspaceInfoComponent },
                    {
                        path: 'categories',
                        component: WorkspaceCategoriesComponent,
                    },
                    {
                        path: 'suppliers',
                        component: WorkspaceSuppliersComponent,
                    },
                    {
                        path: '',
                        redirectTo: 'general',
                        pathMatch: 'full',
                    },
                ],
            },
            {
                path: ':workspaceId/tasks/:taskId',
                component: InvoiceProcessingComponent,
                canActivate: [AuthGuard, CanClaimTaskGuard],
                canDeactivate: [CanDeactivateGuard],
                data: ɵ9,
            },
            {
                path: '',
                redirectTo: 'workspaces',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'poworkspaces',
        component: KontaAppComponent,
        canActivate: [AuthGuard],
        data: ɵ10,
        children: [
            {
                path: '',
                component: WorkspacesComponent,
                canActivate: [AuthGuard],
                data: ɵ11,
            },
            {
                path: ':workspaceId',
                component: PoActivityOverviewComponent,
                canActivate: [PermissionAuthGuard],
                data: ɵ12,
            },
            {
                path: ':workspaceId/tasks',
                component: PoTaskDetailsComponent,
                canActivate: [PermissionAuthGuard],
                canDeactivate: [ClearFilterGuard],
                data: ɵ13,
            },
            {
                path: ':workspaceId/purchase-orders',
                component: POExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: ɵ14,
            },
            {
                path: ':workspaceId/ongoing-purchase-orders',
                component: OngoingPoExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: ɵ15,
            },
            {
                path: ':workspaceId/rejected-purchase-orders',
                component: PoRejectedExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: ɵ16,
            },
            {
                path: ':workspaceId/rejected-invoices',
                component: InvoiceRejectedExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: ɵ17
            },
            /*{
                path: ':workspaceId/in-progress-invoices',
                component: InvoiceInProgressExplorerComponent,
                canActivate: [PermissionAuthGuard],
                data: {
                    breadcrumb: 'Factures en cours de traitement',
                    permissions: {
                        only: ['explorer.ongoing.editor', 'explorer.ongoing.viewer']
                    }
                }
            },*/
            {
                path: ':workspaceId/tasks/:taskId',
                component: PoProcessingComponent,
                canActivate: [AuthGuard, CanClaimTaskGuard],
                canDeactivate: [CanDeactivatePoGuard],
                data: ɵ18,
            },
            {
                path: '',
                redirectTo: 'poworkspaces',
                pathMatch: 'full',
            },
        ],
    },
    {
        path: 'notfound',
        component: PageNotFoundComponent,
    },
    {
        path: '**',
        redirectTo: 'workspaces',
        pathMatch: 'full',
    },
    {
        path: '',
        redirectTo: 'workspaces',
        pathMatch: 'full',
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
