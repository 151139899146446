import { EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuditService {
    constructor(http) {
        this.http = http;
        this.auditUrl = environment.backendUrl + '/invoicestore/app/api/audit';
        this.validationErrors = new Map();
        // used to avoid refreshing audit service too early...
        // TODO: need simpler design or at least a doc
        this.refreshEmitter = new EventEmitter();
        this.refreshEmitter
            .pipe(debounceTime(500))
            .subscribe((value) => this.refresh());
    }
    fire() {
        this.refreshEmitter.emit('');
    }
    refresh() {
        this.clearValidationErrors();
        this.auditInvoiceFromApi().pipe(filter(value => this.hasConstraints(value)), map(value => {
            const constraintsByScope = _.groupBy(value.constraints, 'scope');
            const scopes = Object.keys(constraintsByScope);
            for (const scope of scopes) {
                let subject = this.validationErrors.get(scope);
                if (!subject) {
                    this.validationErrors.set(scope, new BehaviorSubject(null));
                    subject = this.validationErrors.get(scope);
                }
                subject.next(constraintsByScope[scope]);
            }
        })).subscribe();
    }
    clearValidationErrors() {
        this.validationErrors
            .forEach(subject => subject.next(null));
    }
    hasConstraints(value) {
        if (value && value.constraints) {
            return true;
        }
        else {
            return false;
        }
    }
    auditInvoiceFromApi() {
        return this.http.post(this.auditUrl, this.processVariable);
    }
}
AuditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuditService_Factory() { return new AuditService(i0.ɵɵinject(i1.HttpClient)); }, token: AuditService, providedIn: "root" });
