<mat-card class="suppliers-card">
    <mat-card-header>
        <mat-card-title>
            Fournisseurs
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        Aucun fournisseur configuré
    </mat-card-content>
</mat-card>
