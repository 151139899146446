import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {InvoiceProcessingComponent} from 'src/app/modules/invoice-processing/invoice-processing.component';

@Injectable({
    providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<InvoiceProcessingComponent> {

    canDeactivate(
        component: InvoiceProcessingComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> | boolean {

        if (component.submitSuccess) {
            return true;
        } else {
            return component.canLeaveForm();
        }

    }
}
