import { timer } from 'rxjs';
import { concatMap, map, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpParams, HttpRequest, } from '@angular/common/http';
import * as moment from 'moment';
import { parseDecimal } from '../../shared/formHepler';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./authentication.service";
export class InvoiceService {
    constructor(http, authenticationService) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.invoiceURL = environment.backendUrl + '/invoicestore/app/api/invoices';
        this.invoiceIdURL = environment.backendUrl + '/invoicestore/app/api/invoiceIds';
        this.invoiceIdsPubStatusURL = environment.backendUrl + '/invoicestore/app/api/invoices/pub-status';
        this.attachmentURL = environment.backendUrl + '/invoicestore/app/api/attached-documents';
        this.exportUrl = this.invoiceURL + '/download/csv';
        this.updatePublicationStatusUrl = environment.backendUrl + '/invoicestore/app/api/invoices/update-publication-status';
        this.fetchPublicationStatusUrl = environment.backendUrl + '/invoicestore/app/api/invoices/publication-status';
        this.updatePaymentStatusUrl = environment.backendUrl + '/invoicestore/app/api/invoices/payment-status';
        this.paymentStatusCountersReportURL = environment.backendUrl + '/invoicestore/app/api/invoices/reports/payment-status-counters';
    }
    updatePaymentStatus(updatePaymentStatusReq) {
        return this.http.post(this.updatePaymentStatusUrl, updatePaymentStatusReq);
    }
    clearPaymentStatus(invoiceIdArray) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: invoiceIdArray,
        };
        return this.http.delete(this.updatePaymentStatusUrl, httpOptions);
    }
    getPaymentStatusReport(invoiceIds) {
        return this.http.post(this.paymentStatusCountersReportURL, invoiceIds);
    }
    getInvoiceListFromAPI(sort, pageIndex, pageSize, filters, onlyPublished, workspaceId) {
        if (!sort) {
            sort = 'issueDate,desc';
        }
        let httpParams = new HttpParams()
            .set('approvalStatus.equals', 'APPROVED')
            .set('sort', sort)
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString())
            .set('workspaceId.equals', workspaceId);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        if (onlyPublished) {
            httpParams = httpParams.set('publicationStatus.equals', 'PUBLISHED');
        }
        return this.http.get(this.invoiceURL, { params: httpParams });
    }
    // TODO: REMOVE THIS REQUEST FROM LOADING SPINNER
    refreshInvoicePublicationStatusWithIds(invoiceIds, workspaceId) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        const request$ = this.http.post(this.fetchPublicationStatusUrl, { invoiceIds, workspaceId }, { headers });
        return timer(1000, 20000).pipe(concatMap((_) => request$), retry(5), map((response) => response));
    }
    getInvoiceByIDFromAPI(invoiceId) {
        const httpParams = new HttpParams().set('id.equals', invoiceId);
        return this.http.get(this.invoiceURL, { params: httpParams });
    }
    getInvoiceIdsFromAPI(filters, onlyPublished, workspaceId) {
        let httpParams = new HttpParams()
            .set('approvalStatus.equals', 'APPROVED')
            .set('workspaceId.equals', workspaceId);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        if (onlyPublished) {
            httpParams = httpParams.set('publicationStatus.equals', 'PUBLISHED');
        }
        return this.http.get(this.invoiceIdURL, { params: httpParams });
    }
    buildFiltersParam(filters, params) {
        // TODO: refactor: use paramater type instead of a name
        for (const f of filters) {
            if (f.parameter === 'issueDate' ||
                f.parameter === 'dueDate' ||
                f.parameter === 'receiptDate' ||
                f.parameter === 'approvalDate' ||
                f.parameter === 'paymentDate') {
                params = params.set(f.parameter.concat('.').concat(f.condition), moment(f.value).format('YYYY-MM-DD').toString());
            }
            else {
                params = params.set(f.parameter.concat('.').concat(f.condition), parseDecimal(f.value));
            }
        }
        return params;
    }
    /**
     * Get specific invoiceId details
     */
    getAttachmentDetailsFromAPIAsync(invoiceId, documentType) {
        const httpParams = new HttpParams()
            .set('invoiceId.equals', invoiceId)
            .set('documentType.equals', documentType);
        return this.http.get(this.attachmentURL, {
            params: httpParams,
        });
    }
    /**
     * Get specific invoiceId details
     */
    getAttachmentDetailsFromAPI(invoiceId, documentType) {
        const httpParams = new HttpParams()
            .set('invoiceId.equals', invoiceId)
            .set('documentType.equals', documentType);
        return this.http.get(this.attachmentURL, {
            params: httpParams,
        }).toPromise();
    }
    /**
     *
     * Export selected invoices
     */
    exportSelectedInvoices(invoiceIds) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/csv');
        return this.http.post(this.exportUrl, invoiceIds, {
            headers,
            responseType: 'text'
        });
    }
    downloadFile(invoiceId) {
        const myHeadears = new HttpHeaders();
        myHeadears.set('Content-Type', 'application/json');
        myHeadears.set('Authorization', 'bearer ' + this.authenticationService.getUserToken() + '');
        return this.http.request(new HttpRequest('POST', `${environment.backendUrl}/invoicestore/app/api/invoices/${invoiceId}/attachment-content`, { attachmentType: 'original-file' }, {
            headers: myHeadears,
            reportProgress: true,
            responseType: 'blob',
        }));
    }
    matchGrIr(file, invoiceId) {
        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('invoiceId', invoiceId);
        const myHeaders = new HttpHeaders();
        myHeaders.set('Content-Type', 'application/json');
        myHeaders.set('Authorization', 'bearer ' + this.authenticationService.getUserToken() + '');
        const req = new HttpRequest('POST', `${this.invoiceURL}/matchingGR`, formdata, {
            reportProgress: true,
            responseType: 'text',
            headers: myHeaders,
        });
        return this.http.request(req);
    }
    updateInvoicePublicationStatus(invoiceIds, workspaceId) {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post(this.updatePublicationStatusUrl, { invoiceIds, workspaceId, publicationStatus: 'ONGOING' }, {
            headers,
        });
    }
}
InvoiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvoiceService_Factory() { return new InvoiceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthenticationService)); }, token: InvoiceService, providedIn: "root" });
