import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Filter} from './ongoing-po-explorer.component';
import {PurchaseOrder} from '../../models/purchase-order';
import {Task} from '../../models/task';
import {OngoingPoService} from '../../core/services/ongoing-po.service';

export class OngoingPoDataSource implements DataSource<Task> {
    public poSubject = new BehaviorSubject<Task[]>([]);
    private totalOperations: BehaviorSubject<number> = new BehaviorSubject(0);
    private rowsMap = new Map<string, PurchaseOrder>();
    public readonly totalOperations$: Observable<number> = this.totalOperations.asObservable();
    public readonly datasourceMap: Map<string, PurchaseOrder> = this.rowsMap;
    private poServiceSubscription: Subscription;

    constructor(private ongoingPoService: OngoingPoService) {
    }

    connect(collectionViewer: CollectionViewer): Observable<Task[]> {
        return this.poSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.poSubject.complete();
        this.poServiceSubscription.unsubscribe();
    }

    public loadTasks(
        workspaceId: string,
        taskDefinitionKey: string,
        processDefinitionId: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number,
        filters: Filter[]
    ) {
        this.poServiceSubscription = this.ongoingPoService
            .getTaskList(
                workspaceId,
                taskDefinitionKey,
                processDefinitionId,
                sortDirection,
                pageIndex,
                pageSize,
                filters
            )
            .pipe(catchError(() => of([])))
            .subscribe(tasks => {
                this.poSubject.next(tasks.data);
                this.totalOperations.next(tasks.total);
            });
    }


}
