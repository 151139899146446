<div class="grid-container">
    <mat-grid-list cols="2" rowHeight="80px">
        <mat-grid-tile [colspan]="1" [rowspan]="6">
            <mat-card class="activity-overview-card">
                <mat-card-header>
                    <mat-card-title>
                        {{ 'global.tasksList' | translate }}
                        <button *ngxPermissionsOnly="'tasks.uploader'"
                                mat-stroked-button
                                (click)="handleOnClick()"
                                color="primary"
                                class="upload-button">
                            {{ 'poActivityOverview.newOrder' | translate }}
                        </button>

                    </mat-card-title>
                    <mat-card-subtitle><br/>
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content class="activity-overview-card-content">
                    <mat-list>
                        <!--apply the class depending on the number of task types : ex. 3 task types => task-list-items-3 goes from 1 to 5-->
                        <ng-container
                                *ngFor="let task of tasks.data; let taskDefIndex = index"
                                [attr.data-index]="taskDefIndex"
                        >
                            <mat-list-item *ngxPermissionsOnly="'tasks.user'" class="mat-list-item-hover" [ngClass]="{
									'task-list-items-7': tasks.size === 7,
									'task-list-items-6': tasks.size === 6,
									'task-list-items-5': tasks.size === 5,
									'task-list-items-4': tasks.size === 4,
									'task-list-items-3': tasks.size === 3,
									'task-list-items-2': tasks.size === 2,
									'task-list-items-1': tasks.size === 1
								}"
                                           (click)="navigateToTaskDetails(taskDefIndex)"
                            >
                                <h4 matLine>{{ task.name | uppercase }}</h4>
                                <p matLine>{{ task.documentation }}</p>
                                <div class="task-counter">
                                    {{ task.nbActiveTasks }}
                                </div>
                            </mat-list-item>
                            <mat-list-item *ngxPermissionsExcept="'tasks.user'"
                                           [ngClass]="{
									'task-list-items-7': tasks.size === 7,
									'task-list-items-6': tasks.size === 6,
									'task-list-items-5': tasks.size === 5,
									'task-list-items-4': tasks.size === 4,
									'task-list-items-3': tasks.size === 3,
									'task-list-items-2': tasks.size === 2,
									'task-list-items-1': tasks.size === 1
								}"
                            >
                                <h4 matLine>{{ task.name | uppercase }}</h4>
                                <p matLine>{{ task.documentation }}</p>
                                <div class="task-counter">
                                    {{ task.nbActiveTasks }}
                                </div>
                            </mat-list-item>
                            <mat-divider
                                    *ngIf="taskDefIndex !== tasks.size - 1"
                            ></mat-divider>
                        </ng-container>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="2">
            <mat-card class="activity-overview-card invoice-overview-card"
                      *ngxPermissionsOnly="['explorer.approved.editor', 'explorer.approved.viewer']"
                      (click)="navigateToPOExplorer()">
                <div class="invoice-status-card">
                    <h3>
                        {{ 'poActivityOverview.poSince' | translate }}
                    </h3>
                    <p>{{ 'poActivityOverview.poApproved' | translate }}</p>
                    <div class="invoice-status-counter overview-green">
                        {{ purchaseOrderCount }}
                    </div>
                </div>
            </mat-card>
            <mat-card class="activity-overview-card"
                      *ngxPermissionsExcept="['explorer.approved.editor', 'explorer.approved.viewer']">
                <div class="invoice-status-card">
                    <h3>{{ 'poActivityOverview.poSince' | translate }}</h3>
                    <p>{{ 'poActivityOverview.poApproved' | translate }}</p>
                    <div class="invoice-status-counter overview-green">
                        {{ purchaseOrderCount }}
                    </div>
                </div>
            </mat-card>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="2">
            <mat-card class="activity-overview-card invoice-overview-card"
                      *ngxPermissionsOnly="['explorer.rejected.editor', 'explorer.rejected.viewer']"
                      (click)="navigateToPORejectedExplorer()">
                <div class="invoice-status-card">
                    <h3>{{'poActivityOverview.rejectedPo' | translate}}</h3>
                    <p>{{'poActivityOverview.rejectedPoDesc' | translate}}</p>
                    <div class="invoice-status-counter overview-red">
                        {{rejectedPurchaseOrderCount}}
                    </div>
                </div>
            </mat-card>
            <mat-card class="activity-overview-card"
                      *ngxPermissionsExcept="['explorer.rejected.editor', 'explorer.rejected.viewer']">
                <div class="invoice-status-card">
                    <h3>{{'poActivityOverview.rejectedPo' | translate}}</h3>
                    <p>{{'poActivityOverview.rejectedPoDesc' | translate}}</p>
                    <div class="invoice-status-counter overview-red">
                        {{rejectedPurchaseOrderCount}}
                    </div>
                </div>
            </mat-card>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" [rowspan]="2">
            <mat-card class="activity-overview-card invoice-overview-card"
                      *ngxPermissionsOnly="['explorer.ongoing.editor', 'explorer.ongoing.viewer']"
                      (click)="navigateToOngoingPoExplorer()">

                <div class="invoice-status-card">
                    <h3>{{ 'poActivityOverview.poDuringProcessing' | translate }}</h3>
                    <p>{{'poActivityOverview.poBeingProcessed'| translate}}</p>
                    <div class="invoice-status-counter overview-grey">
                        {{ metrics.ongoingProcessesCount }}
                    </div>
                </div>
            </mat-card>
            <mat-card class="activity-overview-card"
                      *ngxPermissionsExcept="['explorer.ongoing.editor', 'explorer.ongoing.viewer']">

                <div class="invoice-status-card">
                    <h3>{{ 'poActivityOverview.poDuringProcessing' | translate }}</h3>
                    <p>
                        {{
                        'poActivityOverview.poBeingProcessed'
                            | translate
                        }}
                    </p>
                    <div class="invoice-status-counter overview-grey">
                        {{ metrics.ongoingProcessesCount }}
                    </div>
                </div>
            </mat-card>
        </mat-grid-tile>
    </mat-grid-list>
</div>
