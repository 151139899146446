import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

import {TaskService} from '../../../../core/services';
import {merge, Subscription} from 'rxjs';
import {TasksDataSource} from './po.datasource';
import {MatDialog} from '@angular/material/dialog';
import {Filter} from '../../../../models/filter-dialog';
import {TranslateService} from '@ngx-translate/core';
import {fixDateFormat} from 'src/app/shared/FormatHelper';


@Component({
    selector: 'app-invoices-table',
    templateUrl: './po-table.component.html',
    styleUrls: ['./po-table.component.scss']
})
export class PoTableComponent
    implements OnInit, OnDestroy, AfterViewInit {
    @Input() parentTab;
    @Input() processId;
    @Input() workspaceId;
    @Input() taskDefinitionKey;
    @Input() processDefinitionId;

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;

    dataSource: TasksDataSource;

    public filters: Filter[] = [];

    private taskServiceSubscription: Subscription;
    private taskClaimServiceSubscription: Subscription;

    displayedColumns: string[] = [
        'po_creationDate',
        'po_orderDate',
        'po_supplierParty_name',
        'po_orderNumber',
        'po_taxExclusiveAmount',
        'po_taxAmount',
        'po_taxInclusiveAmount',
        'po_documentCurrencyCode'
    ];

    constructor(
        private taskService: TaskService,
        private router: Router,
        private dialog: MatDialog,
        public translateService: TranslateService
    ) {
    }

    private filterSubscription: Subscription;

    ngOnInit(): void {
        this.filters = this.taskService.getFiltersFromLocalStorage();

        this.dataSource = new TasksDataSource(this.taskService);

        this.dataSource.loadTasks(
            this.workspaceId,
            this.processId,
            this.taskDefinitionKey,
            this.processDefinitionId,
            'asc',
            0,
            20,
            this.filters
        );

        this.filterSubscription = this.taskService.filtersChanged$.subscribe(value => {
            this.filters = value;
            localStorage.setItem('filters', JSON.stringify(value));
            this.loadTasksPage();
        });
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        merge(this.sort.sortChange, this.paginator.page).subscribe(() =>
            this.loadTasksPage()
        );
    }

    ngOnDestroy(): void {
        if (this.taskServiceSubscription) {
            this.taskServiceSubscription.unsubscribe();
        }
        if (this.taskClaimServiceSubscription) {
            this.taskClaimServiceSubscription.unsubscribe();
        }
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
    }

    loadTasksPage() {
        let sortDirection = 'asc';
        if (this.sort) {
            sortDirection = this.sort.direction;
        }

        let pageIndex = 0;
        if (this.sort) {
            pageIndex = this.paginator.pageIndex;
        }

        let pageSize = 20;
        if (this.sort) {
            pageSize = this.paginator.pageSize;
        }

        this.dataSource.loadTasks(
            this.workspaceId,
            this.processId,
            this.taskDefinitionKey,
            this.processDefinitionId,
            sortDirection,
            pageIndex,
            pageSize,
            this.filters
        );
    }

    navigateToPoProcessing(taskId: string): void {
        this.router.navigate(['poworkspaces', this.workspaceId, 'tasks', taskId]);
    }

    fixDateFormat(date: any) {
        return fixDateFormat(date);
    }
}
