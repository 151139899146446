import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Invoice} from '../../models/invoice';
import {InvoiceService} from '../../core/services';
import {Filter} from './invoice-explorer.component';

export class InvoiceDataSource implements DataSource<Invoice> {
    public invoiceSubject = new BehaviorSubject<Invoice[]>([]);
    public invoiceIdsSubject = new BehaviorSubject<string[]>([]);
    private totalOperations: BehaviorSubject<number> = new BehaviorSubject(0);
    private rowsMap = new Map<string, Invoice>();
    public readonly totalOperations$: Observable<number> = this.totalOperations.asObservable();
    public readonly invoiceIds: Observable<string[]> = this.invoiceIdsSubject.asObservable();
    public readonly datasourceMap: Map<string, Invoice> = this.rowsMap;
    private invoiceServiceSubscription: Subscription;

    constructor(private invoiceService: InvoiceService) {
    }

    connect(collectionViewer: CollectionViewer): Observable<Invoice[]> {
        return this.invoiceSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.invoiceSubject.complete();
        this.invoiceIdsSubject.complete();
        this.invoiceServiceSubscription.unsubscribe();
    }

    loadInvoices(
        sortDirection: string,
        pageIndex: number,
        pageSize: number,
        filter: Filter[],
        onlyPublished: boolean,
        workspaceId: string
    ) {
        this.invoiceServiceSubscription = this.invoiceService
            .getInvoiceListFromAPI(
                sortDirection,
                pageIndex,
                pageSize,
                filter,
                onlyPublished,
                workspaceId
            )
            .pipe(catchError(() => of([])))
            .subscribe(invoices => {
                this.invoiceSubject.next(invoices.content);
                this.totalOperations.next(invoices.totalElements);
                const ids: string[] = [];
                invoices.content.forEach(invoice => {
                    ids.push(invoice.id);
                    this.rowsMap.set(invoice.id, invoice);
                });
                this.invoiceIdsSubject.next(ids);
            });
    }


}
