<mat-card class="error-card">
    <mat-card-header>
        <mat-card-title class="error-title">Page non trouvée</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p class="error-message">
            La page à laquelle vous essayez d'accéder n'est pas disponible
        </p>
    </mat-card-content>
    <mat-card-actions fxLayout fxLayoutWrap fxLayoutAlign="flex-start">
        <span fxFlex></span>
        <button mat-button color="primary" class="back-home-button">
            <a routerLink="/workspaces" routerLinkActive="active">Revenir à la page d'accueil</a></button>
    </mat-card-actions>
</mat-card>
