import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "../services/task.service";
export class CanClaimTaskGuard {
    constructor(location, router, taskService) {
        this.location = location;
        this.router = router;
        this.taskService = taskService;
    }
    canActivate(route, state) {
        // Resolve data here (not in a resolver) since guard needs API response
        //
        const comingFromTaskDetails = this.location.path().endsWith('/tasks');
        return this.taskService
            .claimAndGetTaskFromApi(route.params.taskId)
            .pipe(catchError((error, e) => {
            switch (error.status) {
                case 400:
                case 403:
                case 404:
                    if (comingFromTaskDetails) {
                        this.taskService.handleError(error);
                    }
                    else {
                        // TODO: tackle back boutton from list to claim ;)
                        this.router.navigate(['/notfound']);
                    }
                    break;
                default:
                    break;
            }
            return throwError('An error has occurred. Try later');
        }))
            .pipe(map(formMetadata => {
            route.data = { formMetadata };
            return true;
        }));
    }
}
CanClaimTaskGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanClaimTaskGuard_Factory() { return new CanClaimTaskGuard(i0.ɵɵinject(i1.Location), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.TaskService)); }, token: CanClaimTaskGuard, providedIn: "root" });
