import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-workspace-suppliers',
    templateUrl: './workspace-suppliers.component.html',
    styleUrls: ['./workspace-suppliers.component.scss']
})
export class WorkspaceSuppliersComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
