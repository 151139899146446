import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-konta-app',
    templateUrl: './konta-app.component.html',
    styleUrls: ['./konta-app.component.scss']
})
export class KontaAppComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
