import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import {registerLocaleData} from '@angular/common';

const supportedLanguages = ['fr', 'en'];
const defaultLanguage = 'en';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(private translate: TranslateService) {
        translate.setDefaultLang(defaultLanguage);
        this.switchLanguage(translate.getBrowserLang());
        // Registered Locales for data formatting
        registerLocaleData(localeEn, 'en');
        registerLocaleData(localeFr, 'fr');
    }

    switchLanguage(language: string) {
        if (supportedLanguages.includes(language)) {
            this.translate.use(language);
        } else {
            this.translate.use(defaultLanguage);
        }

    }
}
