import * as tslib_1 from "tslib";
import { AfterViewInit, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { adaptTaskComments, adaptToAPI, amountParser, checkFormOutcomesConfig, consolidateOutcomes, formMap, generateFallbackOutcomes, InvoiceLinesHelper, MY_DATE_FORMATS, normalizeSupplierForOrderForm, parseDecimal, removeOrderLines, searchInitialTaxCoding, taxAmount, taxInclusiveAmount, } from '../../shared/formHepler';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, of, ReplaySubject, Subject } from 'rxjs';
import { CrossFieldErrorMatcher } from 'src/app/shared/crossFieldErrorMatcher';
import { Condition } from '../invoice-explorer/filter-dialog/filter-dialog.component';
import { KontaFormControl } from '../../shared/KontaFormControl';
import { takeUntil } from 'rxjs/operators';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { fixDateFormat } from 'src/app/shared/FormatHelper';
import * as _ from 'lodash';
const ɵ0 = MY_DATE_FORMATS, ɵ1 = { useStrict: true };
export class PoProcessingComponent {
    constructor(authenticationService, currencyService, taskService, workspaceService, formService, route, dialogService, router, dialog) {
        this.authenticationService = authenticationService;
        this.currencyService = currencyService;
        this.taskService = taskService;
        this.workspaceService = workspaceService;
        this.formService = formService;
        this.route = route;
        this.dialogService = dialogService;
        this.router = router;
        this.dialog = dialog;
        this.errorMatcher = new CrossFieldErrorMatcher();
        this.submitSuccess = false;
        this.messageCount = new BehaviorSubject(0);
        this.messageCount$ = this.messageCount.asObservable();
        this.filters = [];
        this.outcomes = [];
        /** Subject that emits when the component has been destroyed. */
        this.onDestroy = new Subject();
        /** control for the MatSelect filter keyword */
        this.taxCodingFilterCtrl = new FormControl();
        /** list of dropdown filtered by search keyword */
        this.filteredTaxCoding = new ReplaySubject(1);
        /** Regex used to validate amount fields */
        this.reg = /^-?[0-9]?(([.,][0-9]+)|([0-9]+([.,][0-9]+)?))$/;
        this.invoiceMatchingEnable = false;
        this.requiredFieldIds = ['po_orderDate', 'po_documentCurrencyCode', 'po_requesterDepartment', 'po_supplierParty_name'];
        this.getRouteParams();
        this.invoiceFormBuilder(); // initialise: this.invoiceLineMetadata
    }
    /** Listen to beforeunload event for reload page and close tab/browser, then execute an event handler function */
    beforeUnloadHandler(event) {
        this.hasUnsavedData() ? (event.returnValue = true) : event.preventDefault();
    }
    unloadHandler(event) {
        this.unclaimTask(this.taskId);
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // await needed since
            // it calls loadExpenseCodings that
            // will init this.invoiceLineHelper in underlying calls
            yield this.loadTaxCodings();
            this.invoiceLinesHelper = new InvoiceLinesHelper(this.orderLineMetadata);
            yield this.getTaskOrderId(this.taskId);
            if (checkFormOutcomesConfig(this.formMetadata.fields) === false) {
                this.outcomes = generateFallbackOutcomes(this.formMetadata.outcomes);
            }
            //Update amounts after getting po lines
            //see: https://kontatech.atlassian.net/browse/KT-1876
            this.updateTotalAmounts();
        });
    }
    validateAllFormFields(formGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
        if (this.invoiceFormApiSubscription) {
            this.invoiceFormApiSubscription.unsubscribe();
        }
        if (this.claimApiSubscription) {
            this.claimApiSubscription.unsubscribe();
        }
        if (this.sendMessageSubscription) {
            this.sendMessageSubscription.unsubscribe();
        }
        if (this.getMessageSubscription) {
            this.getMessageSubscription.unsubscribe();
        }
        if (!this.submitSuccess) {
            this.unclaimTask(this.taskId);
        }
    }
    openComments() {
        this.commentDrawer.open();
    }
    filterTaxCoding() {
        if (!this.taxCodings) {
            return;
        }
        // get the search keyword
        let search = this.taxCodingFilterCtrl.value;
        if (!search) {
            this.filteredTaxCoding.next(this.taxCodings.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the taxCoding
        this.filteredTaxCoding.next(this.taxCodings.filter((item) => item.code.toLowerCase().indexOf(search) > -1 ||
            item.label.toLowerCase().indexOf(search) > -1));
    }
    /**
     * Check for searchable field then init search service
     */
    initSearchDropdownList(field) {
        // Store the dropdown into variables for filtering
        if (field.id === 'po_documentCurrencyCode') {
            this.getTenantCurrencyOptions()
                .then(tenantCurrencyOptions => {
                field.options = this.toFormOptions(tenantCurrencyOptions);
            });
        }
        // Avoid init dropdown filtering capability in case form has no searchable dropdown
        if (field.params.searchable === true) {
            this.hasSearchDropdown = true;
        }
    }
    getTenantCurrencyOptions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const workspaceId = this.workspaceService.workspaceId.getValue();
            if (isNotNullOrUndefined(workspaceId)) {
                const wp = yield this.workspaceService.getWorkspace(workspaceId).toPromise();
                const response = yield this.currencyService.getCurrenciesByTenant(wp.tenantId).toPromise();
                return response.body;
            }
            else {
                return [];
            }
        });
    }
    toFormOptions(currencies) {
        return currencies.map((currency) => {
            return {
                id: currency.id + '',
                name: currency.code
            };
        });
    }
    /** line search init */
    initOrderLineTaxCodingSearch() {
        if (this.taxCodings) {
            this.filteredTaxCoding.next(this.taxCodings.slice());
        }
        this.taxCodingFilterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
            this.filterTaxCoding();
        });
    }
    loadTaxCodings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const legalEntityId = JSON.parse(localStorage.getItem('legalEntityId'));
            // try to get tax codings by legalId first
            const data = yield this.formService.getTaxCodingsFromAPI(legalEntityId);
            if (data.length < 1) {
                this.taxCodings = yield this.formService.getTaxCodingsFromAPI(null);
            }
            else {
                this.taxCodings = data;
            }
            // Init the search capability of the ExpenseCoding dropdown list
            this.initOrderLineTaxCodingSearch();
        });
    }
    /** Load saved invoiceLine from API */
    loadOrderLines(invoiceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const orderLines = yield this.formService.getOrderLines(invoiceId);
            this.orderLines = orderLines;
            if (orderLines.length > 0) {
                this.updateOrderLinesForm(orderLines);
            }
        });
    }
    /** Subscribe to Angular router and get the url params */
    getRouteParams() {
        this.route.paramMap.subscribe((params) => {
            this.taskId = params.get('taskId');
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }
    // TODO: Refactor to use rxjs for better async requests handling
    getTaskOrderId(taskId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const taskById = yield this.taskService.getTaskByIdFromAPI(taskId);
            this.orderId = taskById.processInstanceVariables.po_invoiceStore_poId;
            this.taskDefName = taskById.name;
            this.processInstanceId = taskById.processInstanceId;
            yield this.loadOrderLines(this.orderId);
            this.getMessageSubscription = this.formService
                .getCommentsFromAPI(this.processInstanceId)
                .subscribe((response) => {
                this.processComments = adaptTaskComments(response.data);
                this.messageCount.next(response.total);
            });
        });
    }
    /** Using Angular FormGroup and FormControl to build the invoiceForm */
    invoiceFormBuilder() {
        const group = {};
        this.invoiceFormApiSubscription = this.route.data.subscribe((data) => {
            this.formMetadata = data.formMetadata;
            this.formMap = formMap(this.formMetadata);
        });
        this.formMetadata.fields.forEach((field) => {
            switch (field.id) {
                case 'po_orderLineTemplate':
                    this.orderLineMetadata = field;
                    group.orderLines = new FormArray([]);
                    break;
                default:
                    break;
            }
            switch (field.type) {
                case 'headline':
                    break;
                case 'date':
                    let date;
                    if (field.value !== null) {
                        date = fixDateFormat(field.value);
                    }
                    group[field.id] = new KontaFormControl(field.id, {
                        value: date,
                        disabled: false
                    });
                    break;
                case 'decimal':
                    console.log('field: ' + field.id);
                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false
                    }, {
                        validators: [Validators.pattern(this.reg)],
                    });
                    break;
                case 'multi-line-text':
                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false
                    });
                    break;
                case 'dropdown':
                    this.initSearchDropdownList(field);
                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false,
                    });
                    break;
                case 'outcomeExtension':
                    this.outcomes.push(consolidateOutcomes(this.formMetadata.outcomes, field));
                    break;
                default:
                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false
                    });
                    break;
            }
        });
        this.poForm = new FormGroup(group);
    }
    /** invoiceForm getter for better lines handling */
    get orderLinesForm() {
        return this.poForm.get('orderLines');
    }
    /** Add new orderLine */
    addOrderLine() {
        const lineGroup = {
            orderLine_reference: new KontaFormControl('orderLine_reference', {
                value: '',
                disabled: false,
            }),
            orderLine_description: new KontaFormControl('orderLine_description', {
                value: '',
                disabled: false,
            }),
            orderLine_quantity: new KontaFormControl('orderLine_quantity', {
                value: '',
                disabled: false,
            }),
            orderLine_unitPrice: new KontaFormControl('orderLine_unitPrice', {
                value: '',
                disabled: false,
            }),
            orderLine_taxCoding: new KontaFormControl('orderLine_taxCoding', {
                value: '',
                disabled: false,
            }),
            orderLine_taxExclusiveAmount: new KontaFormControl('orderLine_taxExclusiveAmount', {
                value: '',
                disabled: false,
            }),
            orderLine_taxAmount: new KontaFormControl('orderLine_taxAmount', {
                value: '',
                disabled: false,
            }),
            orderLine_taxInclusiveAmount: new KontaFormControl('orderLine_taxInclusiveAmount', {
                value: '',
                disabled: false,
            })
        };
        const newOrderLine = new FormGroup(lineGroup);
        this.orderLinesForm.push(newOrderLine);
    }
    /** Delete orderLine from the formArray by line index */
    deleteOrderLine(index) {
        this.orderLinesForm.removeAt(index);
        this.orderLinesForm.markAsDirty();
        this.updateTotalAmounts();
    }
    updateTotalAmounts() {
        this.updateTaSum();
        this.updateTeaSum();
        this.updateTiaSum();
    }
    /** Patch orderLinesForm with data from API */
    updateOrderLinesForm(orderLines) {
        const linesArray = this.poForm.controls.orderLines;
        for (let i = 0; i < orderLines.length; i++) {
            this.addOrderLine();
            const line = orderLines[i];
            const selectedTaxCoding = this.getSelectedTaxCoding(line);
            const patch = {
                orderLine_reference: line.orderLineNumber,
                orderLine_description: line.description,
                orderLine_quantity: line.quantity,
                orderLine_unitPrice: line.unitPrice,
                orderLine_taxCoding: selectedTaxCoding,
                orderLine_taxAmount: line.taxAmount,
                orderLine_taxExclusiveAmount: line.taxExclusiveAmount,
                orderLine_taxInclusiveAmount: line.taxInclusiveAmount,
            };
            linesArray.controls[i].patchValue(patch);
        }
    }
    getSelectedTaxCoding(line) {
        const tCode = searchInitialTaxCoding(line.taxCodings[0], this.taxCodings);
        if (tCode && tCode[0]) {
            return tCode[0];
        }
        else {
            return null;
        }
    }
    getVatRateDisplay(line) {
        if (isNotNullOrUndefined(line.get('orderLine_taxCoding'))) {
            const taxCoding = line.get('orderLine_taxCoding').value;
            return taxCoding.code + ' - ' + taxCoding.label;
        }
        return '';
    }
    // Update the orderLine amount values based on the line index
    updateLineValues(index, taxExclusiveAmount, taxCodingRate) {
        const linesArray = this.poForm.controls.orderLines;
        const normalizedTaxExclusiveAmount = amountParser(parseDecimal(taxExclusiveAmount));
        const normalizedTaxCodingRate = amountParser(taxCodingRate) / 100;
        taxCodingRate === '' || taxExclusiveAmount === ''
            ? linesArray.controls[index].patchValue({
                orderLine_TaxExclusiveAmount: taxExclusiveAmount,
                orderLine_taxAmount: '',
                orderLine_taxInclusiveAmount: '',
            })
            : linesArray.controls[index].patchValue({
                orderLine_taxExclusiveAmount: taxExclusiveAmount,
                orderLine_taxAmount: taxAmount(normalizedTaxExclusiveAmount, normalizedTaxCodingRate).toFixed(2),
                orderLine_taxInclusiveAmount: taxInclusiveAmount(normalizedTaxExclusiveAmount, normalizedTaxCodingRate).toFixed(2),
            });
    }
    updateLineTaxAmount(index, taxExclusiveAmount, taxCodingRate) {
        const linesArray = this.poForm.controls.orderLines;
        const normalizedTaxExclusiveAmount = amountParser(parseDecimal(taxExclusiveAmount));
        const normalizedTaxCodingRate = amountParser(taxCodingRate) / 100;
        taxCodingRate === '' || taxExclusiveAmount === ''
            ? linesArray.controls[index].patchValue({
                orderLine_taxAmount: '',
            })
            : linesArray.controls[index].patchValue({
                orderLine_taxAmount: taxAmount(normalizedTaxExclusiveAmount, normalizedTaxCodingRate).toFixed(2)
            });
    }
    onChangeLineQuantity(event, index) {
        const linesArray = this.poForm.controls.orderLines;
        const quantity = event.target.value;
        const unitPrice = amountParser(linesArray.controls[index].get('orderLine_unitPrice').value);
        linesArray.controls[index].patchValue({
            orderLine_taxExclusiveAmount: (quantity * unitPrice).toFixed(2)
        });
    }
    onChangeLineUnitPrice(event, index) {
        const linesArray = this.poForm.controls.orderLines;
        const unitPrice = event.target.value;
        const quantity = linesArray.controls[index].get('orderLine_quantity').value;
        linesArray.controls[index].patchValue({
            orderLine_taxExclusiveAmount: (quantity * unitPrice).toFixed(2)
        });
    }
    onChangeLineTaxAmount(event, index) {
        const linesArray = this.poForm.controls.orderLines;
        if (isNotNullOrUndefined(event) && isNotNullOrUndefined(event.target)) {
            const taxAmount2 = event.target.value;
            const normalizedTaxAmount = amountParser(parseDecimal(taxAmount2));
            const tea = parseFloat(amountParser(linesArray.controls[index].get('orderLine_taxExclusiveAmount').value).toFixed(2));
            linesArray.controls[index].patchValue({
                orderLine_taxInclusiveAmount: (normalizedTaxAmount + tea).toFixed(2)
            });
        }
        else if (isNotNullOrUndefined(event)) {
            const tea = parseFloat(amountParser(linesArray.controls[index].get('orderLine_taxExclusiveAmount').value).toFixed(2));
            linesArray.controls[index].patchValue({
                orderLine_taxInclusiveAmount: (amountParser(event) + tea).toFixed(2)
            });
        }
        this.updateTaSum();
    }
    onChangeLineTaxExclAmount(event, index) {
        const linesArray = this.poForm.controls.orderLines;
        if (isNotNullOrUndefined(event) && isNotNullOrUndefined(event.target)) {
            const tea = event.target.value;
            const normalizedTea = amountParser(parseDecimal(tea));
            const taxAmount2 = parseFloat(amountParser(linesArray.controls[index].get('orderLine_taxAmount').value).toFixed(2));
            linesArray.controls[index].patchValue({
                orderLine_taxInclusiveAmount: (normalizedTea + taxAmount2).toFixed(2)
            });
        }
        else if (isNotNullOrUndefined(event)) {
            const taxCoding = linesArray.controls[index].get('orderLine_taxCoding').value;
            this.updateLineTaxAmount(index, event, taxCoding.rate);
        }
        this.updateTeaSum();
    }
    onChangeLineTaxInclAmount(event, index) {
        this.updateTiaSum();
    }
    updateTiaSum() {
        const linesArray = this.poForm.controls.orderLines;
        let sum = 0;
        for (const control of linesArray.controls) {
            if (control.get('orderLine_taxInclusiveAmount')) {
                sum += parseFloat(amountParser(parseDecimal(control.get('orderLine_taxInclusiveAmount').value)).toFixed(2));
            }
        }
        this.poForm.patchValue({ po_taxInclusiveAmount: sum.toFixed(2) });
    }
    updateTaSum() {
        const linesArray = this.poForm.controls.orderLines;
        let sum = 0;
        for (const control of linesArray.controls) {
            if (control.get('orderLine_taxAmount')) {
                sum += parseFloat(amountParser(parseDecimal(control.get('orderLine_taxAmount').value)).toFixed(2));
            }
        }
        this.poForm.patchValue({ po_taxAmount: sum.toFixed(2) });
    }
    updateTeaSum() {
        const linesArray = this.poForm.controls.orderLines;
        let sum = 0;
        for (const control of linesArray.controls) {
            if (control.get('orderLine_taxExclusiveAmount')) {
                sum += parseFloat(amountParser(parseDecimal(control.get('orderLine_taxExclusiveAmount').value)).toFixed(2));
            }
        }
        this.poForm.patchValue({ po_taxExclusiveAmount: sum.toFixed(2) });
    }
    /**
     * The vatRate select change event handler.
     * @param value the selected value.
     * @param index index of the orderLines formArray.
     */
    onChangeTaxCodingEvent(value, index) {
        const linesArray = this.poForm.controls.orderLines;
        const taxCoding = value.rate;
        const taxExclusiveAmount = linesArray.controls[index].get('orderLine_taxExclusiveAmount').value;
        this.updateLineValues(index, taxExclusiveAmount, taxCoding.toString());
    }
    isReadOnlyLineField(item, fieldId) {
        const res = _.includes(item.params.readOnlyFields, fieldId);
        return res;
    }
    isRequired(item) {
        return _.includes(this.requiredFieldIds, item.id);
    }
    canAddLines() {
        if (isNotNullOrUndefined(this.orderLineMetadata.params)) {
            const canAddLines = this.orderLineMetadata.params.canAddLines;
            if (_.isBoolean(canAddLines)) {
                return canAddLines;
            }
            else {
                return canAddLines === 'true';
            }
        }
    }
    submitFormWithoutLines(formId, formValues, taskId, outcome) {
        this.formService
            .submitWorkflowForm(formId, formValues, taskId, outcome)
            .subscribe(() => this.navigateToTaskDetails(), () => {
        }, () => (this.submitSuccess = true));
    }
    /**
     * Submit the form values, formId and outcome.
     * @param outcome name of the workflow action
     */
    onSubmit(outcome) {
        this.submitSuccess = false;
        const formData = removeOrderLines(this.poForm.value);
        const formValues = adaptToAPI(formData, this.formMap);
        // TODO: refactor code, there is duplicate code btw handling a form with lines and without
        // Form has no order lines => no need to handle them
        if (!this.orderLinesForm) {
            // Send Form to API
            this.submitFormWithoutLines(this.formMetadata.id, formValues, this.taskId, outcome);
        }
        else { // Form with order lines => handle lines before posting form values
            const orderLinesFormValues = this.invoiceLinesHelper.adaptFormOderLinesToAPI(this.orderLinesForm.value);
            // Send orderLines and form to API
            this.formService
                // Send invoiceLines to API
                .createOrderLines(this.orderId, orderLinesFormValues)
                .subscribe(() => {
            }, () => {
            }, () => {
                // Send Form to API
                this.submitFormWithoutLines(this.formMetadata.id, formValues, this.taskId, outcome);
            });
        }
    }
    handleActionClick(outcome) {
        outcome.params.commentOnAction === true
            ? this.openCommentActionModal(outcome.id)
            : this.onSubmit(outcome.id);
    }
    isDisabled(form, outcome) {
        const disableOnError = outcome.params.disableOnError;
        return (form.invalid || this.emptyLines()) && disableOnError;
    }
    /**  Uses Angular router to navigate to task-details url */
    navigateToTaskDetails() {
        this.router.navigate(['poworkspaces', this.workspaceId, 'tasks']);
    }
    closeTaskViewer() {
        this.navigateToTaskDetails();
    }
    /**
     * @returns true if invoice form is dirty else false.
     */
    hasUnsavedData() {
        return this.poForm.dirty;
    }
    /**
     * Based on form isDirty value prompt user with message to confirm leaving page.
     * @returns true if user can leave the form else false.
     */
    canLeaveForm() {
        if (this.hasUnsavedData()) {
            return this.dialogService.confirm();
        }
        else {
            return of(true);
        }
    }
    /**
     * Send unclaim task request to the API.
     * @param taskId taskId to unclaim.
     */
    unclaimTask(taskId) {
        this.claimApiSubscription = this.taskService
            .unclaimTaskFromAPI(taskId)
            .subscribe(() => console.log('Task unclaimed successfully!'));
    }
    openCommentActionModal(outcome) {
        const dialogRef = this.dialog.open(PoCommentActionComponent, {
            width: '700px',
            height: '300px',
            data: { outcome },
        });
        const subscription = dialogRef.componentInstance.ActionConfirmEvent.subscribe(comment => {
            this.formService.sendCommentsToAPI(this.taskId, comment)
                .toPromise().then(() => {
                this.onSubmit(outcome);
                dialogRef.close();
            });
        });
        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });
    }
    openSearchSupplierModal() {
        const modalData = {
            workspaceId: this.workspaceId,
            filters: this.filters
        };
        const dialogRef = this.dialog.open(PoSearchSupplierComponent, {
            width: '720px',
            height: 'auto',
            data: modalData,
            disableClose: true,
        });
        const subscription = dialogRef.componentInstance.supplierSelectedEvent.subscribe((supplier) => {
            this.updateOrderFormWithSelectedSupplier(supplier);
            dialogRef.close();
        });
        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });
    }
    updateOrderFormWithSelectedSupplier(supplier) {
        const normalizedSupplier = normalizeSupplierForOrderForm(supplier);
        this.poForm.patchValue({
            po_supplierParty_name: normalizedSupplier.po_supplierParty_name,
            po_supplierParty_customerAssignedAccountId: normalizedSupplier.po_supplierParty_customerAssignedAccountId,
            po_supplierParty_legalId: normalizedSupplier.po_supplierParty_legalId,
            po_supplierParty_taxId: normalizedSupplier.po_supplierParty_taxId,
            po_supplierParty_contactElectronicMail: normalizedSupplier.po_supplierParty_contactElectronicMail,
            po_supplierParty_financialAccount: normalizedSupplier.po_supplierParty_financialAccount,
            po_supplierParty_paymentTerms: normalizedSupplier.po_supplierParty_paymentTerms
        }, { emitEvent: true });
        // need to emit valueChanges event only when legalId is set otherwise rule validation will fire for partial
        // supplier object and we'll get false positive
        // this.invoiceForm.patchValue({
        // 	po_supplierParty_legalId: normalizedSupplier.po_supplierParty_legalId,
        // }, {emitEvent: true});
        this.poForm.markAsDirty();
    }
    formatValidationMsg(validationErrors) {
        if (_.isArray(validationErrors)) {
            const join = _.join(validationErrors.map(error => error.message), '. ');
            return join;
        }
        else {
            return null;
        }
    }
    emptyLines() {
        return this.orderLinesForm.length === 0;
    }
    hasErrors() {
        let lineWithErrors = 0;
        for (const line of this.orderLinesForm.controls) {
            for (const key of ['orderLine_description', 'orderLine_quantity', 'orderLine_unitPrice', 'orderLine_taxCoding',
                'orderLine_taxExclusiveAmount', 'orderLine_taxAmoun', 'orderLine_taxInclusiveAmount']) {
                if (line instanceof FormGroup && this.hasAtLeastOneError(line, key)) {
                    lineWithErrors++;
                }
            }
        }
        return lineWithErrors > 0;
    }
    getErrorMsg() {
        let message = '';
        const msgSet = new Set();
        for (const line of this.orderLinesForm.controls) {
            if (line instanceof FormGroup) {
                msgSet.add(this.checkField(line, 'orderLine_description', 'Missing Description'));
                msgSet.add(this.checkField(line, 'orderLine_quantity', 'Missing Quantity'));
                msgSet.add(this.checkField(line, 'orderLine_unitPrice', 'Missing Unit Price'));
                msgSet.add(this.checkField(line, 'orderLine_taxCoding', 'Missing Tax Codes'));
                msgSet.add(this.checkField(line, 'orderLine_taxExclusiveAmount', 'Missing Tax Exc. Amount'));
                msgSet.add(this.checkField(line, 'orderLine_taxAmoun', 'Missing Tax Amount'));
                msgSet.add(this.checkField(line, 'orderLine_taxInclusiveAmount', 'Missing Tax Inc. Amount'));
            }
        }
        message = _.join(Array.from(msgSet.values()), '. ');
        return message;
    }
    checkField(line, key, msgAddition) {
        if (isNotNullOrUndefined(line.controls[key]) &&
            isNotNullOrUndefined(line.controls[key].errors) &&
            isNotNullOrUndefined(line.controls[key].errors.required)) {
            return msgAddition;
        }
        return '';
    }
    hasAtLeastOneError(line, key) {
        if (isNotNullOrUndefined(line.controls[key]) &&
            isNotNullOrUndefined(line.controls[key].errors)) {
            return true;
        }
        return false;
    }
    ngAfterViewInit() {
    }
}
export class PoCommentActionComponent {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.message = new FormControl('', Validators.required);
        this.ActionConfirmEvent = new EventEmitter();
    }
    onNoClick() {
        this.dialogRef.close();
    }
    handleActionConfirm() {
        this.ActionConfirmEvent.emit(this.message.value);
    }
}
export class PoSearchSupplierComponent {
    constructor(fb, supplierMdmService, dialogRef, translateService, data) {
        this.fb = fb;
        this.supplierMdmService = supplierMdmService;
        this.dialogRef = dialogRef;
        this.translateService = translateService;
        this.data = data;
        this.supplierList = new BehaviorSubject([]);
        this.supplierList$ = this.supplierList.asObservable();
        this.supplierSelectedEvent = new EventEmitter();
        this.parameters = new Map();
        this.workspaceId = data.workspaceId;
    }
    ngOnInit() {
        this.initConditions();
        this.initFilters();
        const group = [];
        if (this.data.filters.length === 0) {
            group.push(this.createFilter());
        }
        else {
            for (const f of this.data.filters) {
                group.push(this.fb.group({
                    parameter: new FormControl(f.parameter, [Validators.required]),
                    value: new FormControl(f.value, [Validators.required]),
                }));
            }
        }
        this.form = this.fb.group({
            filters: this.fb.array(group),
        });
        this.parameters.set('name', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('legalId', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('customerAssignedAccountId', {
            type: 'string',
            conditions: this.stringConditions(),
        });
    }
    stringConditions() {
        return [
            new Condition('equals', this.equals),
            new Condition('contains', this.contains),
        ];
    }
    otherTypeConditions() {
        return [
            new Condition('equals', this.equals),
            new Condition('greaterThanOrEqual', this.greaterThanOrEqualTo),
            new Condition('lessThanOrEqual', this.lessThanOrEqualTo),
        ];
    }
    initFilters() {
        let fournisser;
        let nom;
        let ice;
        let identifier;
        this.translateService
            .get('global.provider')
            .subscribe((value) => (fournisser = value));
        this.translateService.get('global.name').subscribe((value) => (nom = value));
        this.translateService
            .get('global.legalID')
            .subscribe((value) => (ice = value));
        this.translateService
            .get('global.identifier')
            .subscribe((value) => (identifier = value));
        this.filterGroup = [
            {
                name: fournisser,
                children: [
                    { label: nom, value: 'name' },
                    { label: ice, value: 'legalId' },
                    { label: identifier, value: 'customerAssignedAccountId' },
                ],
            },
        ];
    }
    initConditions() {
        this.translateService
            .get('filters.equalsTo')
            .subscribe((res) => (this.equals = res));
        this.translateService
            .get('filters.contains')
            .subscribe((res) => (this.contains = res));
        this.translateService
            .get('filters.greaterThanOrEqualTo')
            .subscribe((res) => (this.greaterThanOrEqualTo = res));
        this.translateService
            .get('filters.lessThanOrEqualTo')
            .subscribe((res) => (this.lessThanOrEqualTo = res));
    }
    createFilter() {
        return this.fb.group({
            parameter: new FormControl('', [Validators.required]),
            value: new FormControl('', [Validators.required]),
        });
    }
    onParameterChanges(filter, index) {
        this.filters.setControl(index, (this.filters[index] = this.fb.group({
            parameter: new FormControl(filter.value.parameter, [
                Validators.required,
            ]),
            value: new FormControl('', [Validators.required]),
        })));
    }
    refreshSupplierList(workspaceId) {
        this.supplierMdmService
            .getSuppliersForWorkspace(this.filters.value, workspaceId)
            .subscribe((response) => this.supplierList.next(response));
    }
    getControls() {
        return this.form.get('filters').controls;
    }
    get filters() {
        return this.form.get('filters');
    }
    onNoClick() {
        this.dialogRef.close();
    }
    onSubmit() {
        this.refreshSupplierList(this.workspaceId);
    }
    handleSelect() {
        this.supplierSelectedEvent.emit(this.selectedSupplier);
    }
}
export { ɵ0, ɵ1 };
