import {Injectable} from '@angular/core';
import {Observable, timer} from 'rxjs';
import {concatMap, map, retry} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AttachmentDetails} from '../../models/attachment-details.model';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {Filter} from 'src/app/modules/po-explorer/po-explorer.component';
import * as moment from 'moment';
import {AuthenticationService} from './authentication.service';
import {parseDecimal} from '../../shared/formHepler';
import {PurchaseOrder} from '../../models/purchase-order';

export interface POIds {
    poIds: string[];
    tenantIdentifier: string;
}

@Injectable({
    providedIn: 'root',
})
export class PoService {
    readonly poURL = environment.backendUrl + '/invoicestore/app/api/purchase-orders';
    readonly poIdURL = environment.backendUrl + '/invoicestore/app/api/orderIds';
    readonly poIdsPubStatusURL = environment.backendUrl + '/invoicestore/app/api/pos/pub-status';
    readonly attachmentURL = environment.backendUrl + '/invoicestore/app/api/attached-documents';
    readonly exportUrl = this.poURL + '/download/csv';
    readonly updatePublicationStatusUrl = environment.backendUrl + '/invoicestore/app/api/pos/update-publication-status';
    readonly fetchPublicationStatusUrl = environment.backendUrl + '/invoicestore/app/api/pos/publication-status';

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) {
    }

    getPOListFromAPI(
        sort: string,
        pageIndex: number,
        pageSize: number,
        filters: Filter[],
        workspaceId: string,
        status: string
    ): Observable<any> {
        if (!sort) {
            sort = 'orderDate,desc';
        }
        let httpParams = new HttpParams()
            .set('poLifecycleType.equals', 'MANAGED')
            .set('sort', sort)
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString())
            .set('workspaceId.equals', workspaceId)
            .set('poApprovalStatus.equals', status);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        return this.http.get<any>(this.poURL, {params: httpParams});
    }

    // TODO: REMOVE THIS REQUEST FROM LOADING SPINNER
    // maybe we should add a triggerLoadSpinner toggle params to services ?....
    refreshPOPublicationStatusWithIds(poIds: string[], workspaceId: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        const request$ = this.http.post(this.fetchPublicationStatusUrl, {poIds, workspaceId}, {headers});
        return timer(1_000, 20_000).pipe(
            concatMap((_) => request$),
            retry(5),
            map((response) => response)
        );
    }

    getPOByIDFromAPI(poId: string): Observable<PurchaseOrder> {
        const httpParams = new HttpParams().set('id.equals', poId);

        return this.http.get<PurchaseOrder>(this.poURL, {params: httpParams});
    }

    getPOIdsFromAPI(
        filters: Filter[],
        workspaceId: string,
        status: string
    ): Observable<any> {
        let httpParams = new HttpParams()
            .set('poLifecycleType.equals', 'MANAGED')
            .set('workspaceId.equals', workspaceId)
            .set('poApprovalStatus.equals', status);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        return this.http.get<any>(this.poIdURL, {params: httpParams});
    }

    buildFiltersParam(filters: Filter[], params: HttpParams): HttpParams {
        for (const f of filters) {
            if (
                f.parameter === 'issueDate' ||
                f.parameter === 'receiptDate' ||
                f.parameter === 'approvalDate'
            ) {
                params = params.set(
                    f.parameter.concat('.').concat(f.condition),
                    moment(f.value).format('YYYY-MM-DD').toString()
                );
            } else {
                params = params.set(
                    f.parameter.concat('.').concat(f.condition),
                    parseDecimal(f.value)
                );
            }
        }
        return params;
    }

    /**
     * Get specific poId details
     */
    getAttachmentDetailsFromAPIAsync(
        poId: string,
        documentType: string): Observable<AttachmentDetails[]> {
        const httpParams = new HttpParams()
            .set('poId.equals', poId)
            .set('documentType.equals', documentType);

        return this.http.get<AttachmentDetails[]>(this.attachmentURL, {
            params: httpParams,
        });
    }

    /**
     * Get specific poId details
     */
    getAttachmentDetailsFromAPI(poId: number, documentType: string): Promise<AttachmentDetails[]> {

        const httpParams = new HttpParams()
            .set('poId.equals', poId.toString())
            .set('documentType.equals', documentType);

        return this.http.get<AttachmentDetails[]>(this.attachmentURL, {
            params: httpParams,
        }).toPromise();
    }

    /**
     *
     * Export selected pos
     */

    exportSelectedPOs(poIds: POIds): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/csv');

        return this.http.post(
            this.exportUrl,
            poIds,
            {
                headers,
                responseType: 'text'
            });
    }

    public downloadFile(orderId: number): Observable<HttpEvent<Blob>> {
        const myHeadears = new HttpHeaders();
        myHeadears.set('Content-Type', 'application/json');
        myHeadears.set('Authorization', 'bearer ' + this.authenticationService.getUserToken() + '');
        return this.http.request(
            new HttpRequest(
                'GET',
                `${environment.backendUrl}/invoicestore/app/api/purchase-orders/${orderId}/pdf`,
                // {attachmentType: 'original-file'},
                {
                    headers: myHeadears,
                    reportProgress: true,
                    responseType: 'blob',
                }
            )
        );
    }

    matchGrIr(file: File, poId: string): Observable<HttpEvent<Blob>> {

        const formdata: FormData = new FormData();

        formdata.append('file', file);
        formdata.append('poId', poId);

        const myHeaders = new HttpHeaders();
        myHeaders.set('Content-Type', 'application/json');
        myHeaders.set(
            'Authorization',
            'bearer ' + this.authenticationService.getUserToken() + ''
        );

        const req = new HttpRequest('POST', `${this.poURL}/matchingGR`, formdata, {
            reportProgress: true,
            responseType: 'text',
            headers: myHeaders,
        });
        return this.http.request(req);
    }

    public updatePOPublicationStatus(poIds: string[], workspaceId: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        return this.http.post(
            this.updatePublicationStatusUrl,
            {poIds, workspaceId, publicationStatus: 'ONGOING'},
            {
                headers,
            });
    }

}
