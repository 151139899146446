import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import * as moment from 'moment';
import { parseDecimal } from '../../shared/formHepler';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./authentication.service";
export class RejectedInvoiceService {
    constructor(http, authenticationService) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.invoiceURL = environment.backendUrl + '/invoicestore/app/api/invoices';
        this.invoiceIdURL = environment.backendUrl + '/invoicestore/app/api/invoiceIds';
        this.attachmentURL = environment.backendUrl + '/invoicestore/app/api/attached-documents';
        this.exportUrl = environment.backendUrl + '/invoicestore/app/api/rejected-invoices/download/csv';
        this.updateStatusUrl = environment.backendUrl + '/invoicestore/app/api/invoices/update-status';
    }
    getInvoiceListFromAPI(sort, pageIndex, pageSize, filters, onlyPublished, workspaceId) {
        if (!sort) {
            sort = 'issueDate,desc';
        }
        let httpParams = new HttpParams()
            .set('approvalStatus.equals', 'REJECTED')
            .set('sort', sort)
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString())
            .set('workspaceId.equals', workspaceId);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        if (onlyPublished) {
            httpParams = httpParams.set('publicationStatus.equals', 'PUBLISHED');
        }
        return this.http.get(this.invoiceURL, { params: httpParams });
    }
    getInvoiceByIDFromAPI(invoiceId) {
        const httpParams = new HttpParams().set('id.equals', invoiceId);
        return this.http.get(this.invoiceURL, { params: httpParams });
    }
    getInvoiceIdsFromAPI(filters, onlyPublished, workspaceId) {
        let httpParams = new HttpParams()
            .set('approvalStatus.equals', 'REJECTED')
            .set('workspaceId.equals', workspaceId);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        if (onlyPublished) {
            httpParams = httpParams.set('publicationStatus.equals', 'PUBLISHED');
        }
        return this.http.get(this.invoiceIdURL, { params: httpParams });
    }
    buildFiltersParam(filters, params) {
        for (const f of filters) {
            if (f.parameter === 'issueDate' ||
                f.parameter === 'receiptDate' ||
                f.parameter === 'approvalDate') {
                params = params.set(f.parameter.concat('.').concat(f.condition), moment(f.value).format('YYYY-MM-DD').toString());
            }
            else if (!f.value && f.condition === 'equals') {
                params = params.set(f.parameter.concat('.specified'), 'false');
            }
            else {
                params = params.set(f.parameter.concat('.').concat(f.condition), parseDecimal(f.value));
            }
        }
        return params;
    }
    getAttachmentDetailsFromAPI(invoiceId, documentType) {
        const httpParams = new HttpParams()
            .set('invoiceId.equals', invoiceId)
            .set('documentType.equals', documentType);
        return this.http.get(this.attachmentURL, {
            params: httpParams,
        });
    }
    exportSelectedInvoices(invoiceIds) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/csv');
        return this.http.post(this.exportUrl, invoiceIds, {
            headers,
            responseType: 'text'
        });
    }
    archiveInvoices(invoiceIds) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/csv');
        const httpParams = new HttpParams()
            .set('newStatus', 'ARCHIVED');
        return this.http.post(this.updateStatusUrl, invoiceIds, {
            headers,
            params: httpParams
        });
    }
    downloadFile(invoiceId) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        httpHeaders.set('Authorization', 'bearer ' + this.authenticationService.getUserToken() + '');
        return this.http.request(new HttpRequest('POST', `${environment.backendUrl}/invoicestore/app/api/invoices/${invoiceId}/attachment-content`, { attachmentType: 'original-file' }, {
            headers: httpHeaders,
            reportProgress: true,
            responseType: 'blob',
        }));
    }
}
RejectedInvoiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RejectedInvoiceService_Factory() { return new RejectedInvoiceService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthenticationService)); }, token: RejectedInvoiceService, providedIn: "root" });
