import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/authentication.service";
import * as i3 from "../services/roles.service";
import * as i4 from "ngx-permissions";
export class PermissionAuthGuard {
    constructor(router, activatedRoute, authService, rolesService, permissionsService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.authService = authService;
        this.rolesService = rolesService;
        this.permissionsService = permissionsService;
    }
    canActivate(route, state) {
        // check that the user is authenticated
        if (this.authService.isLoggedIn()) {
            const workspaceId = route.paramMap.get('workspaceId');
            if (workspaceId != null) {
                const routeDataPermissions = !!route && route.data ? route.data.permissions : {};
                return this.userHasPermissionsForWorkspace(routeDataPermissions, workspaceId);
            }
            else {
                return true;
            }
        }
        // this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }
    userHasPermissionsForWorkspace(routeDataPermissions, workspaceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let onlyPermissions;
            onlyPermissions = routeDataPermissions.only;
            const value = yield this.rolesService.getPermissionsByWorkspace(workspaceId).toPromise();
            const myPermissions = this.adaptToPermissions(value);
            if (myPermissions.some(permission => onlyPermissions.includes(permission))) {
                this.permissionsService.loadPermissions(myPermissions);
                return true;
            }
            else {
                return false;
            }
        });
    }
    adaptToPermissions(roles) {
        return roles.map(value => value.name);
    }
}
PermissionAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionAuthGuard_Factory() { return new PermissionAuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.RolesService), i0.ɵɵinject(i4.NgxPermissionsService)); }, token: PermissionAuthGuard, providedIn: "root" });
