import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
export class JwtInterceptor {
    constructor(authService) {
        this.authService = authService;
    }
    intercept(request, next) {
        const accessToken = this.authService.keycloakService.keycloak.token;
        if (accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
        }
        return next.handle(request);
    }
}
JwtInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtInterceptor_Factory() { return new JwtInterceptor(i0.ɵɵinject(i1.AuthenticationService)); }, token: JwtInterceptor, providedIn: "root" });
