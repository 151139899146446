<mat-card class="company-info-card">
    <mat-card-header>
        <mat-card-title class="company-info-card-title">
            Fiche Entreprise
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div
                fxLayout="row"
                fxLayoutWrap
                fxLayoutGap="2%"
                fxLayoutAlign="flex-start"
                class="company-info-row "
        ></div>
    </mat-card-content>

    <mat-card-actions>
        <button mat-button color="primary">EDITER</button>
    </mat-card-actions>
</mat-card>
