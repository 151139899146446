import _ from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../guards/keycloak.service";
export class AuthenticationService {
    constructor(http, keycloakService) {
        this.http = http;
        this.keycloakService = keycloakService;
    }
    logout() {
        localStorage.removeItem('selectedTab');
        localStorage.removeItem('filters');
        this.keycloakService.logout();
    }
    isLoggedIn() {
        return this.keycloakService.isLoggedIn();
    }
    getUserToken() {
        const accessToken = this.keycloakService.getToken();
        return accessToken;
    }
    getUserAuthData() {
        const accessToken = this.keycloakService.keycloak.tokenParsed;
        const profile = this.keycloakService.keycloak.profile;
        const idTokenParsed = this.keycloakService.keycloak.idTokenParsed;
        return this.toAuthUser(accessToken, idTokenParsed);
    }
    toAuthUser(accessToken, idToken) {
        const authUSer = { authorities: [], client_id: '', exp: 0, jti: '', scope: [], userInfo: undefined, user_name: '' };
        authUSer.user_name = accessToken.name;
        authUSer.userInfo = {
            user: {
                id: idToken.email,
                firstName: idToken.given_name,
                lastName: idToken.family_name
            },
            groups: _.map(accessToken.realm_access.roles, (role) => ({
                id: role,
                name: role
            }))
        };
        return authUSer;
    }
    updateToken(minValidity) {
        return this.keycloakService.keycloak.updateToken(minValidity);
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.KeycloakService)); }, token: AuthenticationService, providedIn: "root" });
