import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkspacesRoutingModule} from './workspaces-routing.module';
import {WorkspacesComponent} from './workspaces.component';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutModule} from '@angular/cdk/layout';

import {
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';

@NgModule({
    declarations: [WorkspacesComponent],
    imports: [
        CommonModule,
        WorkspacesRoutingModule,
        FormsModule,
        FlexLayoutModule,
        LayoutModule,
        /* Angular material */
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatGridListModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        TranslateModule,
        MatSidenavModule,
        MatListModule
    ]
})
export class WorkspacesModule {
}
