import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserModule} from '@angular/platform-browser';


/* Konta app interceptors */
import {ErrorInterceptor, JwtInterceptor, LoaderInterceptor} from './core/interceptors';

/* Konta app global modules */
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutModule} from '@angular/cdk/layout';

/* Konta app feature modules */
import {KontaAppModule} from './modules/konta-app/konta-app.module';
import {WorkspacesModule} from './modules/workspaces/workspaces.module';
import {InvoiceProcessingModule} from './modules/invoice-processing/invoice-processing.module';
import {ActivityOverviewModule} from './modules/activity-overview/activity-overview.module';
import {TaskDetailsModule} from './modules/task-details/task-details.module';
import {ParamsModule} from './modules/params/params.module';

/* Konta app components */
import {SpinnerComponent} from './components/spinner/spinner.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';

/* Angular material components */
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
} from '@angular/material';

import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {InvoiceExplorerComponent, MarkPaidSnackBarComponent} from './modules/invoice-explorer/invoice-explorer.component';
import {FilterDialogComponent} from './modules/invoice-explorer/filter-dialog/filter-dialog.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
    ArchiveDialogComponent,
    InvoiceRejectedExplorerComponent
} from './modules/invoice-rejected-explorer/invoice-rejected-explorer.component';
import {
    ArchiveInProgressDialogComponent,
    InvoiceInProgressExplorerComponent
} from './modules/invoice-inprogress-explorer/invoice-inprogress-explorer.component';
import {NgxPermissionsModule} from 'ngx-permissions';
import {PoActivityOverviewModule} from './modules/activity-overview-po/po-activity-overview.module';
import {PoProcessingModule} from './modules/po-processing/po-processing.module';
import {PoTaskDetailsModule} from './modules/po-task-details/po-task-details.module';
import {POExplorerComponent} from './modules/po-explorer/po-explorer.component';
import {POFilterDialogComponent} from './modules/po-explorer/filter-dialog/po-filter-dialog.component';
import {PoRejectedExplorerComponent} from './modules/po-rejected-explorer/po-rejected-explorer.component';
import {PoRejectedFilterDialogComponent} from './modules/po-rejected-explorer/filter-dialog/po-rejected-filter-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {OngoingPoExplorerComponent} from './modules/ongoing-po-explorer/ongoing-po-explorer.component';
import {OngoingPoFilterDialogComponent} from './modules/ongoing-po-explorer/filter-dialog/ongoing-po-filter-dialog.component';
import {
    CodingProposalConfirmDialogComponent,
    PoCopyConfirmDialogComponent
} from './modules/invoice-processing/invoice-processing.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {KeycloakService} from './core/guards/keycloak.service';
import {BundleDownloaderModule} from './modules/bundle-downloader/bundle-downloader.module';

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        PageNotFoundComponent,
        InvoiceExplorerComponent,
        POExplorerComponent,
        OngoingPoExplorerComponent,
        PoRejectedExplorerComponent,
        POFilterDialogComponent,
        OngoingPoFilterDialogComponent,
        FilterDialogComponent,
        PoRejectedFilterDialogComponent,
        InvoiceRejectedExplorerComponent,
        InvoiceInProgressExplorerComponent,
        ArchiveDialogComponent,
        PoCopyConfirmDialogComponent,
        CodingProposalConfirmDialogComponent,
        ArchiveInProgressDialogComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        LayoutModule,
        MatDatepickerModule,
        FlexLayoutModule,
        /* Firebase Config */
        AngularFireModule.initializeApp(environment.firebase),
        /* konta modules */
        KontaAppModule,
        WorkspacesModule,
        ParamsModule,
        ActivityOverviewModule,
        TaskDetailsModule,
        InvoiceProcessingModule,
        BundleDownloaderModule,

        /* PO modules */
        PoActivityOverviewModule,
        PoTaskDetailsModule,
        PoProcessingModule,

        /* Angular material */
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatOptionModule,
        MatSelectModule,
        MatDialogModule,
        MatDatepickerModule,
        MatSortModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxPermissionsModule.forRoot()
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        }
    ],
    entryComponents: [
        FilterDialogComponent,
        ArchiveDialogComponent,
        PoCopyConfirmDialogComponent,
        CodingProposalConfirmDialogComponent,
        ArchiveInProgressDialogComponent,
        POFilterDialogComponent,
        OngoingPoFilterDialogComponent,
        PoRejectedFilterDialogComponent,
        MarkPaidSnackBarComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function initializeKeycloak(keycloakService: KeycloakService) {
    return () => keycloakService.keycloak.init({
        onLoad: 'login-required',
        responseMode: 'query',
        messageReceiveTimeout: 100000
    });
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
