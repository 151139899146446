<mat-drawer-container hasBackdrop>
    <mat-drawer class="invoice-details" #drawer mode="over" position="end">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px">
            <span fxFlex="40px"></span>
            <span class="invoice-details-header" fxFlex>
				<p>{{ viewedPO.orderNumber }}</p>
			</span>


            <button (click)="drawer.close()" class="invoice-details-close-button" color="primary" fxFlex="nogrow"
                    mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">

            <span fxFlex="100%" class="invoice-details-title">
				<h4>{{ 'global.provider'| translate}}</h4>
			</span>

            <span fxFlex="100%" *ngIf="viewedPO.supplierParty?.name">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.name'| translate}}</p></span>
                <span fxFlex="58%" class="invoice-details-value"><p>{{ viewedPO.supplierParty?.name }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.supplierParty?.legalId">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.legalID'| translate}}</p></span>
                <span fxFlex="58%" class="invoice-details-value"
                      *ngIf="viewedPO.supplierParty?.legalId"><p>{{ viewedPO.supplierParty?.legalId }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.supplierParty?.taxId">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.taxIdentification'| translate}}</p></span>
                <span fxFlex="58%" class="invoice-details-value"><p>{{ viewedPO.supplierParty?.taxId }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.supplierParty?.contactElectronicMail">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.contact'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO.supplierParty?.contactElectronicMail }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.supplierParty?.financialAccount">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.bankingInformation'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO.supplierParty?.financialAccount }}</p></span>
            </span>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">
			<span fxFlex="100%" class="invoice-details-title">
				<h4>{{ 'global.poInformation'| translate}}</h4>
			</span>

            <span fxFlex="100%" *ngIf="viewedPO.orderNumber">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.orderNumber'| translate}}</p></span>
                <span fxFlex="58%" class="invoice-details-value"><p>{{ viewedPO.orderNumber }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.orderDate">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.orderDate'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO.orderDate | date: 'mediumDate': '': translateService.currentLang }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.requesterDepartment">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.requesterDepartment'| translate}}</p></span>
                <span fxFlex="58%" class="invoice-details-value"><p>{{ viewedPO.requesterDepartment }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.deliveryDate">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.deliveryDate'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO.deliveryDate | date: 'mediumDate': '': translateService.currentLang }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.creationDate">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.creationDate'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO.creationDate | date: 'mediumDate': '': translateService.currentLang }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.initiatorUserId">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.initiatorUserId'| translate}}</p></span>
                <span fxFlex="58%" class="invoice-details-value"><p>{{ viewedPO.initiatorUserId }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.approvalDate">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.approvalDate'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO.approvalDate | date: 'mediumDate': '': translateService.currentLang }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.approvalUserId">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.approvalUserId'| translate}}</p></span>
                <span fxFlex="58%" class="invoice-details-value"><p>{{ viewedPO.approvalUserId }}</p></span>
            </span>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">
			<span fxFlex="100%" class="invoice-details-title">
				<h4>{{ 'global.amounts'| translate}}</h4>
			</span>

            <span fxFlex="100%" *ngIf="viewedPO.taxExclusiveAmount">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.taxExclusiveAmount'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO.taxExclusiveAmount | number:'': translateService.currentLang }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.taxAmount">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.taxAmount'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO.taxAmount | number:'': translateService.currentLang }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.taxInclusiveAmount">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.taxInclusiveAmount'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO.taxInclusiveAmount | number:'': translateService.currentLang }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO.documentCurrencyCode">
                <span fxFlex="38%"
                      class="invoice-details-key"><p>{{ 'global.documentCurrencyCode'| translate}}</p></span>
                <span fxFlex="58%" class="invoice-details-value"><p>{{ viewedPO.documentCurrencyCode }}</p></span>
            </span>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">
            <span fxFlex></span>
            <button
                    mat-flat-button
                    color="primary"
                    fxFlex="nogrow"
                    class="invoice-details-download-button"
                    (click)="downloadAttachment()">
                {{'global.downloadDocument' | translate}}
            </button>
            <span fxFlex></span>
        </div>
    </mat-drawer>

    <mat-drawer-content>
        <mat-card class="mat-elevation-z0 mat-card-filters">
            <div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="start center">
                <button
                        fxFlex="nogrow"
                        mat-icon-button
                        color="primary"
                        aria-label="Workspace home back button"
                        (click)="navigateToWorkspaceHome()"
                >
                    <mat-icon class="back-icon">arrow_back_ios</mat-icon>
                </button>
                <span fxFlex></span>
                <span fxFlex>{{'poExplorer.numberOfOrders' | translate}} :
					<span class="filter-title">{{ numRows }}</span></span>
                <!-- waiting for bug fixing on publish feature -->
                <!--                <button class="explorer-action" mat-flat-button fxFlex=nogrow color="accent" [disabled]="isEmpty()"-->
                <!--                        (click)="onPublish()">-->
                <!--                    <mat-icon class="button-icon">autorenew</mat-icon>-->
                <!--                    {{'global.publish' | translate}} ({{-->
                <!--                    selectionInvoice.selected.length-->
                <!--                    }})-->
                <!--                </button>-->
                <!--                <button-->
                <!--                        class="explorer-action"-->
                <!--                        mat-flat-button-->
                <!--                        fxFlex="nogrow"-->
                <!--                        color="primary"-->
                <!--                        [disabled]="isEmpty()"-->
                <!--                        (click)="onExport()"-->
                <!--                >-->
                <!--                    <mat-icon class="button-icon">save_alt</mat-icon>-->
                <!--                    {{'global.export' | translate}} ({{-->
                <!--                    selectionPO.selected.length-->
                <!--                    }})-->
                <!--                </button>-->
                <!--                <button-->
                <!--                        class="explorer-action"-->
                <!--                        mat-stroked-button-->
                <!--                        fxFlex="nogrow"-->
                <!--                        color="primary"-->
                <!--                        (click)="openFilterDialog()"-->
                <!--                >-->
                <!--                    <mat-icon class="button-icon">filter_list-->
                <!--                    </mat-icon>-->
                <!--                    {{ filters.length }}-->
                <!--                </button>-->
            </div>
        </mat-card>

        <mat-card class="mat-elevation-z2 mat-card-table">
            <mat-card-title></mat-card-title>
            <table mat-table matSort [dataSource]="dataSource" matSortActive="orderDate" matSortDirection="desc">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox color="primary"
                                      (change)="$event ? masterToggle() : null"
                                      [checked]="selectionPO.hasValue() && isAllSelected()"
                                      [indeterminate]="selectionPO.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox color="primary"
                                      (click)="$event.stopPropagation()"
                                      (change)="$event ? handleOnCheckBoxSelectionChange(row) : null"
                                      [checked]="selectionPO.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="orderDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'po.orderDate'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{fixDateFormat(row.orderDate)| date: 'shortDate': '': translateService.currentLang}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="supplierParty.name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'global.provider'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{ row.supplierParty?.name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="orderNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'po.orderNumber'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{ row.orderNumber }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="taxExclusiveAmount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'global.taxExclusiveAmount'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{ row.taxExclusiveAmount | number:'': translateService.currentLang}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="taxAmount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'global.taxAmount'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{ row.taxAmount | number:'': translateService.currentLang}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="taxInclusiveAmount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'global.taxInclusiveAmount'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{ row.taxInclusiveAmount | number:'': translateService.currentLang}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="documentCurrencyCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'global.documentCurrencyCode'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{ row.documentCurrencyCode }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator
                    [length]="dataSource.totalOperations$ | async"
                    [pageIndex]="0"
                    [pageSize]="20"
                    [pageSizeOptions]="[5, 10, 20, 50]"
                    showFirstLastButtons
            ></mat-paginator>
        </mat-card>
    </mat-drawer-content>
</mat-drawer-container>
