import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {TaskDetailsComponent} from '../../modules/task-details/task-details.component';

@Injectable({
    providedIn: 'root'
})
export class ClearFilterGuard
    implements CanDeactivate<TaskDetailsComponent> {
    canDeactivate(
        component: TaskDetailsComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> | boolean {

        if (!nextState.url.includes(currentState.url)) {
            localStorage.removeItem('filters');
        }

        return true;
    }
}
