import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {ParamsService} from '../../../../core/services';

@Component({
    selector: 'app-workspace-info',
    templateUrl: './workspace-info.component.html',
    styleUrls: ['./workspace-info.component.scss']
})
export class WorkspaceInfoComponent implements OnInit, OnDestroy {
    private workspaceId: string;

    constructor(
        private route: ActivatedRoute,
        private ParamsService: ParamsService
    ) {
    }

    ngOnInit() {
        this.route.parent.paramMap.subscribe(
            params => (this.workspaceId = params.get('workspaceId'))
        );

        this.ParamsService.getCompanyInfo(this.workspaceId).subscribe(data =>
            console.log('workspace info')
        );
    }

    ngOnDestroy() {
        // unsubscribe when component unmount
    }
}
