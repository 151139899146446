import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    adaptExpenseCodingsSearchable,
    adaptTaskComments,
    adaptToAPI,
    amountParser,
    checkFormOutcomesConfig,
    consolidateOutcomes,
    formMap,
    generateFallbackOutcomes,
    IAdapterInvoiceLine,
    IFallbackOutcome,
    InvoiceLinesHelper,
    MY_DATE_FORMATS,
    normalizeSupplier,
    parseDecimal,
    removeInvoiceLines,
    searchInitialAccountCoding,
    searchInitialExpenseCoding,
    searchInitialTaxCoding,
    taxAmount,
    taxInclusiveAmount,
    typeFilteredExpenseCodings,
} from '../../shared/formHepler';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
    AuthenticationService,
    DialogService,
    FormService,
    IAccountCoding,
    IExpenseCoding,
    ILineTemplate,
    InvoiceService,
    IProductTemplateLine,
    ITaxCoding,
    TaskService,
    WorkspacesService,
} from '../../core/services';
import {BehaviorSubject, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {IField, IFormMetadata} from '../../models/form.model';
import {environment} from '../../../environments/environment';
import {AttachmentDetails} from '../../models/attachment-details.model';
import {saveAs} from 'file-saver';
import {CrossFieldErrorMatcher} from 'src/app/shared/crossFieldErrorMatcher';
import {IComment} from '../../models/comment.model';
import {Condition} from '../invoice-explorer/filter-dialog/filter-dialog.component';
import {ISupplier, SupplierMdmService} from '../../core/services/supplier-mdm.service';
import {AuditService} from '../../core/services/audit-invoice.service';
import {KontaFormControl} from '../../shared/KontaFormControl';
import {TranslateService} from '@ngx-translate/core';
import {MatSelect} from '@angular/material';
import {map, takeUntil} from 'rxjs/operators';
import {AuditInvoiceValidator} from './audit-invoice-validator';
import {Constraint} from '../../models/validation-result';
import * as _ from 'lodash';
import {IPurchaseOrderLine} from '../../models/purchase-order';
import {HttpEventType} from '@angular/common/http';

import {AddonService} from '../../core/services/addon.service';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {CurrencyService, ICurrency} from 'src/app/core/services/currency.service';
import {fixDateFormat} from 'src/app/shared/FormatHelper';
import {IExpenseCodingType} from '../../models/task';


export interface SearchSupplierComponentData {
    workspaceId: string;
    filters: Filter[];
}

export interface Filter {
    parameter: string;
    condition: string;
    value: string;
}

export interface IOption {
    id?: string;
    name?: string;
}

@Component({
    selector: 'app-invoice-processing',
    templateUrl: './invoice-processing.component.html',
    styleUrls: ['./invoice-processing.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {useStrict: true},
        },
    ],
})
export class InvoiceProcessingComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('canvas', {static: false}) canvas: ElementRef;
    @ViewChild('documentViewer', {static: false}) documentViewer: ElementRef;
    @ViewChild('commentDrawer', {static: false}) commentDrawer;

    invoiceLineMetadata: ILineTemplate;
    formMetadata: IFormMetadata;
    invoiceForm: FormGroup;
    errorMatcher = new CrossFieldErrorMatcher();
    submitSuccess = false;
    private formMap: Map<string, string>;
    private workspaceId: string;
    private processInstanceId: string;
    private invoiceId: string;
    private taskId: string;
    public taskDefName: string;
    private customerAssignedAccountId: string;
    public attachmentDetails: AttachmentDetails;
    public processComments: IComment[];
    public messageCount = new BehaviorSubject<number>(0);
    public messageCount$: Observable<number> = this.messageCount.asObservable();
    public filters: Filter[] = [];
    outcomes: any[] = [];

    public invoiceLineHelper: InvoiceLinesHelper;
    public hasSearchDropdown: boolean;

    public invoiceLines: IProductTemplateLine[];
    public purchaseOrder: any[];
    public taxCodings: ITaxCoding[];
    public accountCodings: IAccountCoding[];
    public expenseCodingsList: IExpenseCoding[];
    private expenseCodingTypes: IExpenseCodingType[];
    public accountingCostsList: IOption[];
    public projectReferencesList: IOption[];

    /** Variables to handle attachment canvas and pdfViewer */
    private cx: CanvasRenderingContext2D;
    private viewerWidth: number;
    private attachment: Blob;
    public pdfsrc: string;

    /** Subject that emits when the component has been destroyed. */
    protected onDestroy = new Subject<void>();

    /** Variable for searchable dropdown */
    @ViewChild('accountingCost', {static: true}) accountingCost: MatSelect;
    @ViewChild('projectReference', {static: true}) projectReference: MatSelect;
    @ViewChild('taxCoding', {static: true}) taxCoding: MatSelect;
    @ViewChild('accountCoding', {static: true}) accountCoding: MatSelect;

    /** control for the MatSelect filter keyword */
    public accountingCostFilterCtrl: FormControl = new FormControl();
    public projectReferenceFilterCtrl: FormControl = new FormControl();
    public taxCodingFilterCtrl: FormControl = new FormControl();
    public accountCodingFilterCtrl: FormControl = new FormControl();

    /** list of dropdown filtered by search keyword */
    public filteredAccountingCost: ReplaySubject<IOption[]> = new ReplaySubject<IOption[]>(1);
    public filteredProjectReference: ReplaySubject<IOption[]> = new ReplaySubject<IOption[]>(1);
    public filteredTaxCoding: ReplaySubject<ITaxCoding[]> = new ReplaySubject<ITaxCoding[]>(1);
    public filteredAccountCoding: ReplaySubject<IAccountCoding[]> = new ReplaySubject<IAccountCoding[]>(1);

    /** Component observable subscriptions to unsubscribe on ngOnDestroy */
    private invoiceFormApiSubscription: Subscription;
    private sendMessageSubscription: Subscription;
    private getMessageSubscription: Subscription;
    private claimApiSubscription: Subscription;
    private invoiceApiSubscription: Subscription;
    private matchingGrSubscription: Subscription;

    /** Regex used to validate amount fields */
    private readonly reg = /^-?[0-9]?(([.,][0-9]+)|([0-9]+([.,][0-9]+)?))$/;
    private readonly snackBarDuration = 2;

    invoiceMatchingEnable = false;

    private srcResult: any;

    @ViewChild('fileInput', {static: true})
    uploadGrFile: any;


    /** Listen to beforeunload event for reload page and close tab/browser, then execute an event handler function */
    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: any) {
        this.hasUnsavedData() ? (event.returnValue = true) : event.preventDefault();
    }

    @HostListener('window:unload', ['$event'])
    unloadHandler(event: any) {
        this.unclaimTask(this.taskId);
    }

    /** Listen to browser resize event to compute the canvas display */
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {

        if (this.attachmentDetails && this.isAttachmentImage(this.attachmentDetails.mimeType)) {
            this.canvasDrawInit(this.attachment);
        }
    }

    constructor(
        private authenticationService: AuthenticationService,
        private currencyService: CurrencyService,
        private taskService: TaskService,
        private workspaceService: WorkspacesService,
        private invoiceService: InvoiceService,
        private formService: FormService,
        private route: ActivatedRoute,
        private dialogService: DialogService,
        public auditService: AuditService,
        private auditInvoiceValidator: AuditInvoiceValidator,
        private addonService: AddonService,
        private router: Router,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        public translateService: TranslateService
    ) {
        this.getRouteParams();
        this.invoiceFormBuilder(); // initialise: this.invoiceLineMetadata

    }

    async ngOnInit() {
        this.loadAccountingAccount();

        // await needed since
        // it calls loadExpenseCodings that
        // will init this.invoiceLineHelper in underlying calls
        await this.loadTaxCodings();


        this.getTaskInvoiceId(this.taskId);

        if (checkFormOutcomesConfig(this.formMetadata.fields) === false) {
            this.outcomes = generateFallbackOutcomes(this.formMetadata.outcomes);
        }

        // if the process form contain searchable dropdown the init capability
        if (this.hasSearchDropdown === true) {
            this.initSearchDropDownService();
        }


        const tenantIdentifier = JSON.parse(localStorage.getItem('tenantIdentifier'));

        this.matchingGrSubscription = this.addonService.verifyMatchingAddon(tenantIdentifier).pipe(
            map(addons => {
                return addons.filter(addon => addon.addonId === 'com.konta.gr-inv-matching')[0];
            }))
            .subscribe(addon => {
                    this.invoiceMatchingEnable = addon.enabled;
                },
                error => this.invoiceMatchingEnable = false);
    }

    ngAfterViewInit(): void {
        const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
        this.cx = canvasEl.getContext('2d');

        // Set AuditService value change watchers
        const valueChangesArray: Observable<any>[] = [];

        this.auditService.clearValidationErrors();

        const formKeys = Object.keys(this.invoiceForm.controls);

        formKeys.forEach(key => {
            const valueChanges = this.invoiceForm.get(key).valueChanges;
            valueChanges.subscribe((value) => {
                this.auditService.processVariable[key] = value;
                // console.log('AUDIT: this.auditService.processVariable.invoiceLines' + this.auditService.processVariable.invoiceLines);
                this.auditService.processVariable.taskName = this.taskDefName;
                this.auditService.processVariable.expenseCodingTypes = this.invoiceLineHelper.expenseCodingTypes;

                this.auditService.fire();
                // console.log('FIRED By key=' + key + '; value: ' + value);
            });
        });
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
        if (this.invoiceFormApiSubscription) {
            this.invoiceFormApiSubscription.unsubscribe();
        }
        if (this.claimApiSubscription) {
            this.claimApiSubscription.unsubscribe();
        }
        if (this.sendMessageSubscription) {
            this.sendMessageSubscription.unsubscribe();
        }
        if (this.getMessageSubscription) {
            this.getMessageSubscription.unsubscribe();
        }
        if (this.matchingGrSubscription) {
            this.matchingGrSubscription.unsubscribe();
        }
        if (!this.submitSuccess) {
            this.unclaimTask(this.taskId);
        }
    }

    public openComments() {
        this.commentDrawer.open();
    }

    protected filterAccountingCosts() {
        if (!this.accountingCostsList) {
            return;
        }
        // get the search keyword
        let search = this.accountingCostFilterCtrl.value;
        if (!search) {
            this.filteredAccountingCost.next(this.accountingCostsList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the accounting costs
        this.filteredAccountingCost.next(
            this.accountingCostsList.filter(
                (item: IOption) => item.name.toLowerCase().indexOf(search) > -1
            )
        );
    }

    protected filterProjectReferences() {
        if (!this.projectReferencesList) {
            return;
        }
        // get the search keyword
        let search = this.projectReferenceFilterCtrl.value;
        if (!search) {
            this.filteredProjectReference.next(this.projectReferencesList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the project references
        this.filteredProjectReference.next(
            this.projectReferencesList.filter(
                (item: IOption) => item.name.toLowerCase().indexOf(search) > -1
            )
        );
    }

    protected filterTaxCoding() {
        if (!this.taxCodings) {
            return;
        }
        // get the search keyword
        let search = this.taxCodingFilterCtrl.value;

        if (!search) {
            this.filteredTaxCoding.next(this.taxCodings.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the taxCoding
        this.filteredTaxCoding.next(
            this.taxCodings.filter(
                (item) =>
                    item.code.toLowerCase().indexOf(search) > -1 ||
                    item.label.toLowerCase().indexOf(search) > -1
            )
        );
    }

    protected filterAccountCoding() {
        if (!this.accountCodings) {
            return;
        }
        // get the search keyword
        let search = this.accountCodingFilterCtrl.value;

        if (!search) {
            this.filteredAccountCoding.next(this.accountCodings.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the accountCoding
        this.filteredAccountCoding.next(
            this.accountCodings.filter(
                (item) =>
                    item.code.toLowerCase().indexOf(search) > -1 ||
                    item.label.toLowerCase().indexOf(search) > -1
            )
        );
    }

    /**
     * Check for searchable field then init search service
     */
    private initSearchDropdownList(field: IField): void {
        // Store the dropdown into variables for filtering
        if (field.id === 'invoice_accountingCost') {
            this.accountingCostsList = field.options;
        } else if (field.id === 'invoice_projectReference') {
            this.projectReferencesList = field.options;
        } else if (field.id === 'invoice_documentCurrencyCode') {
            this.getTenantCurrencyOptions()
                .then(tenantCurrencyOptions => {
                        field.options = this.toFormOptions(tenantCurrencyOptions);
                    }
                );
        }

        // Avoid init dropdown filtering capability in case form has no searchable dropdown
        if (field.params.searchable === true) {
            this.hasSearchDropdown = true;
        }
    }

    private async getTenantCurrencyOptions(): Promise<ICurrency[]> {
        const workspaceId = this.workspaceService.workspaceId.getValue();
        if (isNotNullOrUndefined(workspaceId)) {
            const wp = await this.workspaceService.getWorkspace(workspaceId).toPromise();
            const response = await this.currencyService.getCurrenciesByTenant(wp.tenantId).toPromise();
            return response.body;
        } else {
            return [];
        }

    }

    private toFormOptions(currencies: ICurrency[]): IOption[] {
        return currencies.map((currency): IOption => {
            return {
                id: currency.id + '',
                name: currency.code
            };
        });

    }

    /** line search init */
    private initInvoiceLineTaxCodingSearch(): void {
        if (this.invoiceLineHelper.taxCodingsStatus) {
            if (this.taxCodings) {
                this.filteredTaxCoding.next(this.taxCodings.slice());
            }
            this.taxCodingFilterCtrl.valueChanges
                .pipe(takeUntil(this.onDestroy))
                .subscribe(() => {
                    this.filterTaxCoding();
                });
        }
    }

    private initInvoiceLineAccountCodingSearch(): void {
        if (!isNotNullOrUndefined(this.invoiceLineHelper)) {
            if (this.accountCodings) {
                this.filteredAccountCoding.next(this.accountCodings.slice());
            }
            this.accountCodingFilterCtrl.valueChanges
                .pipe(takeUntil(this.onDestroy))
                .subscribe(() => {
                    this.filterAccountCoding();
                });
        }
    }

    /** Form search init */
    private initSearchDropDownService(): void {
        this.filteredAccountingCost.next(this.accountingCostsList.slice());
        this.accountingCostFilterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.filterAccountingCosts();
            });

        this.filteredProjectReference.next(this.projectReferencesList.slice());
        this.projectReferenceFilterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.filterProjectReferences();
            });
    }

    private async loadTaxCodings() {
        const legalEntityId = JSON.parse(localStorage.getItem('legalEntityId'));
        // try to get tax codings by legalId first
        const data = await this.formService.getTaxCodingsFromAPI(legalEntityId);

        if (data.length < 1) {
            this.taxCodings = await this.formService.getTaxCodingsFromAPI(null);
            await this.loadExpenseCodings();

        } else {
            this.taxCodings = data;
            await this.loadExpenseCodings();
        }

    }

    private loadAccountingAccount(): void {
        const legalEntityId = JSON.parse(localStorage.getItem('legalEntityId'));
        this.formService.getAccountingAccounts(legalEntityId).subscribe((data) => {
            this.accountCodings = data;
            this.initInvoiceLineAccountCodingSearch();
        });
    }

    private async loadExpenseCodings() {
        const data = await this.formService.getExpenseCodings();

        this.expenseCodingsList = adaptExpenseCodingsSearchable(data);

        // init line template search and lists
        this.generateFilteredExpenseCodingsList();

        // Init the search capability of the ExpenseCoding dropdown list
        this.initInvoiceLineTaxCodingSearch();

    }

    /** Load saved invoiceLine from API */
    private async loadInvoiceLines(invoiceId: string) {
        const invoiceLines = await this.formService.getInvoiceLines(invoiceId);
        this.invoiceLines = invoiceLines;
        if (invoiceLines.length > 0) {
            this.updateInvoiceLinesForm(invoiceLines);
        }
    }

    /** Load the purchase order lines from MDM */
    public loadInvoicePurchaseOrders() {

        // Form has no invoice lines no need for user confirmation before creating new lines
        if (this.invoiceFormHasZeroLines()) {
            this.doPoCopy();
        } else {
            const transKey = 'invoiceProcessing.importLinesConfirmDialog.message';
            let message = '';
            this.translateService.get(transKey).subscribe(
                value => message += value
            );

            const dialogRef = this.dialog.open(PoCopyConfirmDialogComponent, {
                width: '720px',
                height: 'auto',
                data: {message},
                disableClose: true
            });

            dialogRef.afterClosed().subscribe((confirmed) => {
                    if (confirmed) {
                        this.doPoCopy();
                    }
                }
            );
        }
    }

    /** Load Coding Proposals lines */
    public loadInvoiceCodingProposal() {

        // Form has no invoice lines no need for user confirmation before creating new lines
        if (this.invoiceFormHasZeroLines()) {
            this.doCodingProposalCopy();
        } else {
            const transKey = 'invoiceProcessing.codingProposalConfirmDialog.message';
            let message = '';
            this.translateService.get(transKey).subscribe(
                value => message += value
            );

            const dialogRef = this.dialog.open(CodingProposalConfirmDialogComponent, {
                width: '720px',
                height: 'auto',
                data: {message},
                disableClose: true
            });

            dialogRef.afterClosed().subscribe((confirmed) => {
                    if (confirmed) {
                        this.doCodingProposalCopy();
                    }
                }
            );
        }
    }

    private invoiceFormHasZeroLines() {
        return this.invoiceLineForm.controls.length === 0;
    }

    private doPoCopy() {
        this.clearFormArray();

        const customerAssignedAccId = this.invoiceForm.get(
            'invoice_orderReference'
        ).value;

        const orderNumber = this.invoiceForm.get(
            'invoice_supplierParty_customerAssignedAccountId'
        ).value;

        if (
            customerAssignedAccId === null ||
            orderNumber === null ||
            orderNumber === '' ||
            customerAssignedAccId === ''
        ) {
            this.openSnackBar(
                'Référence commande ou identifiant fournisseur non définis'
            );
        } else {
            this.formService
                .getInvoicePurchaseOrderFromAPI(orderNumber, customerAssignedAccId)
                .subscribe((data) => {
                    this.purchaseOrder = data.content;
                    this.updateInvoiceWithPurchaseOrder();
                });
        }
    }


    private doCodingProposalCopy() {
        this.clearFormArray();

        const supplierAccId = this.invoiceForm.get(
            'invoice_supplierParty_customerAssignedAccountId'
        ).value;

        const taxExclusiveAmount = this.invoiceForm.get(
            'invoice_taxExclusiveAmount'
        ).value;


        if (this.customerAssignedAccountId === null || supplierAccId === null || supplierAccId === ''
            || this.customerAssignedAccountId === '') {
            this.openSnackBar('Identifiant fournisseur non défini');
        } else {
            this.formService
                .fetchCodingProposal(this.workspaceId, this.customerAssignedAccountId, supplierAccId, taxExclusiveAmount)
                .subscribe((data) => {
                    this.updateInvoiceWithCodingProposal(data);

                });
        }
    }

    private updateInvoiceWithCodingProposal(data): void {

        // If exactly one matching PO found then copy lines from PO
        const length = data.length;
        if (length === 0) {
            this.openSnackBar('0 ligne importée');
        }

        if (length >= 1) {

            const linesArray = this.invoiceForm.controls.invoiceLines as FormArray;


            for (let i = 0; i < length; i++) {

                this.addInvoiceLine();
                const codingProposalLine = data[i];

                // expense codings
                const poExpCode1 = this.getCodingProposalExpCoding(codingProposalLine, 0);
                const poExpCode2 = this.getCodingProposalExpCoding(codingProposalLine, 1);
                const poExpCode3 = this.getCodingProposalExpCoding(codingProposalLine, 2);
                const poExpCode4 = this.getCodingProposalExpCoding(codingProposalLine, 3);
                const poExpCode5 = this.getCodingProposalExpCoding(codingProposalLine, 4);

                // tax codings
                const poTaxCode = this.getCodingProposalTaxCoding(codingProposalLine);

                // Account Codings
                const poAccountCode = this.getCodingProposalAccountCoding(codingProposalLine);

                const patchValue = {

                    invoice_invoiceLine_orderLineReference: codingProposalLine.orderLineNumber,
                    invoice_invoiceLine_lineDescription: codingProposalLine.description,
                    invoice_invoiceLine_quantity: codingProposalLine.quantity,
                    invoice_invoiceLine_unitPrice: codingProposalLine.unitPrice,

                    invoice_invoiceLine_expenseCoding1: poExpCode1,
                    invoice_invoiceLine_expenseCoding2: poExpCode2,
                    invoice_invoiceLine_expenseCoding3: poExpCode3,
                    invoice_invoiceLine_expenseCoding4: poExpCode4,
                    invoice_invoiceLine_expenseCoding5: poExpCode5,

                    invoice_invoiceLine_taxCoding: poTaxCode,

                    invoice_invoiceLine_accountCoding: poAccountCode,

                    invoice_invoiceLine_lineTaxExclusiveAmount: codingProposalLine.taxExclusiveAmount,
                };


                linesArray.controls[i].patchValue(patchValue);
                if (isNotNullOrUndefined(codingProposalLine.taxExclusiveAmount) && codingProposalLine.taxExclusiveAmount !== '') {
                    this.updateLineAmountValues(i, codingProposalLine.taxExclusiveAmount, poTaxCode.rate);
                }


                this.openSnackBar(`${length} ligne(s) importée(s)`);


            }


        }

    }

    openArchiveDialog(invoiceCount: number): void {

    }

    /** Clear all invoiceLines form */
    private clearFormArray() {
        this.invoiceLineForm.clear();
    }

    /** Generate a filtered list for each expenseCodingType */
    private generateFilteredExpenseCodingsList(): void {
        this.invoiceLineHelper = new InvoiceLinesHelper(
            this.invoiceLineMetadata, this.expenseCodingsList
        );
    }

    /**
     * TODO: Add a debounce on the btn to block or prevent user from downloading attachment multiple time.
     * Download the attachment from the retrieved blob on click btn event
     */
    public downloadAttachment(): void {
        const {mimeType, originalFileName} = this.attachmentDetails;
        const blob = new Blob([this.attachment], {
            type: mimeType,
        });
        saveAs(blob, originalFileName);
    }

    private async getAttachmentDetailsFromApi(invoiceId: string) {
        const response = await this.invoiceService.getAttachmentDetailsFromAPI(invoiceId, 'original-file');
        // TODO: check response isArray !
        this.attachmentDetails = response[0];
    }

    // TODO: Refactor to use rxjs for better async requests handling
    private async getTaskInvoiceId(taskId: string) {

        const taskById = await this.taskService.getTaskByIdFromAPI(taskId);

        const processVariable = taskById.processInstanceVariables;

        this.auditService.processVariable = processVariable;


        this.invoiceId = processVariable.invoice_invoiceStore_invoiceId;
        this.taskDefName = taskById.name;
        this.processInstanceId = taskById.processInstanceId;
        this.customerAssignedAccountId = processVariable.invoice_customerParty_customerAssignedAccountId;

        await this.loadInvoiceLines(this.invoiceId);
        await this.getAttachmentDetailsFromApi(this.invoiceId);
        this.getAttachmentBinaryFromAPI(this.invoiceId);
        // TODO synchronise !
        this.auditService.fire();
        this.getMessageSubscription = this.formService
            .getCommentsFromAPI(this.processInstanceId)
            .subscribe((response) => {
                this.processComments = adaptTaskComments(response.data);
                this.messageCount.next(response.total);
            });

    }

    getSubjectById(id: string) {
        const scope = id.replace(new RegExp('_', 'g'), '.');

        let subject = this.auditService.validationErrors.get(scope);
        if (!subject) {
            this.auditService.validationErrors.set(scope, new BehaviorSubject<Constraint[]>(null));
            subject = this.auditService.validationErrors.get(scope);
        }
        return subject;
    }

    /**
     * Init canvas with image data binary
     * @param attachment the attachment binary
     */
    private canvasDrawInit(attachment: Blob): void {
        const image = new Image();
        image.src = URL.createObjectURL(attachment);
        this.viewerWidth = this.documentViewer.nativeElement.offsetWidth - 4;
        image.onload = () => {
            this.cx.canvas.width = this.viewerWidth;
            this.cx.canvas.height =
                (this.viewerWidth * image.naturalHeight) / image.naturalWidth;
            this.cx.drawImage(
                image,
                0,
                0,
                this.cx.canvas.width,
                this.cx.canvas.height
            );
        };
    }

    /**
     * Generate a blob url for ng2-pdf-viewer
     * @param attachment the attachment binary
     */
    private createPDFString(attachment: Blob): void {
        // Convert blob to ArrayBuffer and get Uint8Array. The issue with this format is that it slows down the ng2-pdf-viewer performance.
        /* const fileReader = new FileReader();
            fileReader.onload = e => {
                let uint8View = new Uint8Array(e.target.result);
                this.pdfsrc = uint8View;
            };
            fileReader.readAsArrayBuffer(attachment); */

        this.pdfsrc = URL.createObjectURL(attachment);
    }

    /**
     * getAttachmentDataFromAPI request callback to store attachment binary.
     * @param blob the attachment binary.
     */
    private setAttachmentBinary(blob: Blob): void {
        this.attachment = blob;
        const {mimeType} = this.attachmentDetails;

        this.isAttachmentImage(mimeType)
            ? this.canvasDrawInit(blob)
            : this.createPDFString(blob);
    }

    /**
     * Check if the mimeType binary is an actual image help to determine if we use pdfViewer or the canvas.
     * @param mimeType the type of binary received from the API
     */
    private isAttachmentImage(mimeType: string): boolean {
        return (
            mimeType === 'image/png' ||
            mimeType === 'image/jpeg' ||
            mimeType === 'image/jpg'
        );
    }

    /**
     * TODO: Refactor to be in the invoice service and use Angular HTTPClient.
     * Send request to API and gets invoice attachment binary.
     * @param invoiceId the invoice id.
     * @param callback callback function for retrieving request binary.
     */
    private getAttachmentBinaryFromAPI(
        invoiceId: string,
        callback = this.setAttachmentBinary.bind(this)
    ): void {
        const url = `${environment.backendUrl}/invoicestore/app/api/invoices/${invoiceId}/attachment-content`;
        const body = {attachmentType: 'original-file'};

        const xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader(
            'Authorization',
            'bearer ' + this.authenticationService.getUserToken() + ''
        );

        xhr.onload = function() {
            if (this.status === 200) {
                callback(this.response);
            } else {
                console.error(xhr.statusText);
            }
        };

        xhr.send(JSON.stringify(body));
    }

    /** Subscribe to Angular router and get the url params */
    private getRouteParams(): void {
        this.route.paramMap.subscribe((params) => {
            this.taskId = params.get('taskId');
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }

    controlItemHasErrors(id: string): boolean {
        const error = this.auditService.validationErrors.get(id);

        return !!error;
    }


    getErrorForControlItem(id: string): string {
        if (this.controlItemHasErrors(id)) {
            const control = this.invoiceForm.controls[id];
            const errors = control.errors;
            return errors[id].message;

        } else {
            return null;
        }
    }

    /** Using Angular FormGroup and FormControl to build the invoiceForm */
    private invoiceFormBuilder(): void {
        const group: any = {};

        this.invoiceFormApiSubscription = this.route.data.subscribe((data) => {
            this.formMetadata = data.formMetadata;
            this.formMap = formMap(this.formMetadata);
        });

        this.formMetadata.fields.forEach((field) => {
            switch (field.type) {
                case 'headline':
                    break;

                case 'date':
                    let date;

                    if (field.value !== null) {
                        date = fixDateFormat(field.value);
                    }

                    group[field.id] = new KontaFormControl(field.id, {
                            value: date,
                            disabled: false
                        }
                    );
                    break;

                case 'decimal':
                    group[field.id] = new KontaFormControl(
                        field.id,
                        {
                            value: field.value,
                            disabled: false
                        },
                        {
                            validators: [Validators.pattern(this.reg)],
                        }
                    );
                    break;

                case 'multi-line-text':
                    group[field.id] = new KontaFormControl(field.id, {
                            value: field.value,
                            disabled: false
                        }
                    );
                    break;

                case 'dropdown':
                    this.initSearchDropdownList(field);

                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false,
                    });

                    break;

                case 'outcomeExtension':
                    this.outcomes.push(
                        consolidateOutcomes(this.formMetadata.outcomes, field)
                    );
                    break;

                case 'invoiceLineTemplate':
                    this.invoiceLineMetadata = field as any;
                    group.invoiceLines = new FormArray([]);
                    break;

                default:
                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false
                    });
                    break;
            }
        });

        this.invoiceForm = new FormGroup(group);
    }

    /** invoiceForm getter for better lines handling */
    get invoiceLineForm() {
        return this.invoiceForm.get('invoiceLines') as FormArray;
    }

    /** Add new invoiceLine */
    public addInvoiceLine(): void {
        const linesGroup = {};
        this.invoiceLineMetadata.params.lineModel.forEach((field) => {
            switch (field.type) {
                case 'expenseCoding':
                    linesGroup[field.id] = new KontaFormControl(field.id, {
                        value: '',
                        disabled: this.invoiceLineMetadata.params.readOnly,
                    });
                    break;
                case 'taxCoding':
                    linesGroup[field.id] = new KontaFormControl(field.id, {
                        value: '',
                        disabled: this.invoiceLineMetadata.params.readOnly,
                    });
                    break;
                case 'accountCoding':
                    linesGroup[field.id] = new KontaFormControl(field.id, {
                        value: '',
                        disabled: this.invoiceLineMetadata.params.readOnly,
                    });
                    break;
                case 'decimal':
                    linesGroup[field.id] = new KontaFormControl(
                        field.id,
                        {
                            value: '',
                            disabled: this.invoiceLineMetadata.params.readOnly,
                        },
                        {
                            validators: [Validators.pattern(this.reg)],
                        }
                    );
                    break;
                case 'text':
                    linesGroup[field.id] = new KontaFormControl(field.id, {
                        value: '',
                        disabled: this.invoiceLineMetadata.params.readOnly,
                    });
                    break;
                default:
                    break;
            }
        });

        const newInvoiceLine = new FormGroup(linesGroup);

        this.invoiceLineForm.push(newInvoiceLine);
    }

    /** Patch invoiceLinesForm with data from API */
    private updateInvoiceLinesForm(invoiceLines: IProductTemplateLine[]) {
        const linesArray = this.invoiceForm.controls.invoiceLines as FormArray;


        for (let i = 0; i < invoiceLines.length; i++) {
            this.addInvoiceLine();

            const line = this.invoiceLines[i];
            const selectedTaxCoding = this.getSelectedTaxCoding(line);
            const selectedAccountCoding = this.getSelectedAccountCoding(line);
            const selectedExpenseCoding1 = this.getSelectedExpenseCoding(line, 0);
            const selectedExpenseCoding2 = this.getSelectedExpenseCoding(line, 1);


            const patch = {
                invoice_invoiceLine_lineDescription: line.lineDescription,
                invoice_invoiceLine_quantity: line.quantity,
                invoice_invoiceLine_unitPrice: line.unitPrice,
                invoice_invoiceLine_orderLineReference: line.orderLineReference,
                invoice_invoiceLine_expenseCoding1: selectedExpenseCoding1,
                invoice_invoiceLine_expenseCoding2: selectedExpenseCoding2,
                invoice_invoiceLine_expenseCoding3: this.getSelectedExpenseCoding(line, 2),
                invoice_invoiceLine_expenseCoding4: this.getSelectedExpenseCoding(line, 3),
                invoice_invoiceLine_expenseCoding5: this.getSelectedExpenseCoding(line, 4),
                invoice_invoiceLine_taxCoding: selectedTaxCoding,
                invoice_invoiceLine_accountCoding: selectedAccountCoding,
                invoice_invoiceLine_lineTaxAmount: line.taxAmount,
                invoice_invoiceLine_lineTaxExclusiveAmount: line.taxExclusiveAmount,
                invoice_invoiceLine_lineTaxInclusiveAmount: line.taxInclusiveAmount,
            };
            linesArray.controls[i].patchValue(patch);
        }
    }


    private getSelectedExpenseCoding(line: IProductTemplateLine, expCodingIndex) {

        const iExpenseCodingType = this.invoiceLineHelper.expenseCodingTypes[expCodingIndex];

        if (iExpenseCodingType) {
            const foundExpCoding = searchInitialExpenseCoding(
                typeFilteredExpenseCodings(
                    iExpenseCodingType.type,
                    line.expenseCodings
                ),
                this.expenseCodingsList
            );
            if (foundExpCoding && foundExpCoding[0]) {
                return foundExpCoding[0];
            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    private getSelectedTaxCoding(line: IProductTemplateLine) {
        if (this.invoiceLineHelper.taxCodingsStatus) {
            const tCode = searchInitialTaxCoding(
                line.taxCodings[0],
                this.taxCodings
            );
            if (tCode && tCode[0]) {
                return tCode[0];
            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    private getSelectedAccountCoding(line: IProductTemplateLine) {
        if (isNotNullOrUndefined(this.invoiceLineHelper) && isNotNullOrUndefined(this.accountCodings)) {
            const tCode = searchInitialAccountCoding(
                line.accountCodings[0],
                this.accountCodings
            );
            if (tCode && tCode[0]) {
                return tCode[0];
            } else {
                return null;
            }

        } else {
            return null;
        }
    }

    private updateInvoiceWithPurchaseOrder(): void {
        const purchaseOrderLength = this.purchaseOrder.length;

        // If exactly one matching PO found then copy lines from PO
        if (purchaseOrderLength >= 2) {
            this.openSnackBar('Référence à compléter');
        }
        if (purchaseOrderLength === 0) {
            this.openSnackBar('0 ligne importée');
        }
        if (purchaseOrderLength === 1) {

            const linesArray = this.invoiceForm.controls.invoiceLines as FormArray;
            const purchaseOrder = this.purchaseOrder[0];
            // check if purchase order array contains any line
            const purchaseOrderLinesLength = this.purchaseOrder[0].purchaseOrderLines.length;

            if (purchaseOrder && purchaseOrderLinesLength > 0) {

                for (let i = 0; i < purchaseOrderLinesLength; i++) {
                    this.addInvoiceLine();
                    const poLine = purchaseOrder.purchaseOrderLines[i];

                    // expense codings
                    const poExpCode1 = this.getPoExpCoding(poLine, 0);
                    const poExpCode2 = this.getPoExpCoding(poLine, 1);
                    const poExpCode3 = this.getPoExpCoding(poLine, 2);
                    const poExpCode4 = this.getPoExpCoding(poLine, 3);
                    const poExpCode5 = this.getPoExpCoding(poLine, 4);

                    // tax codings
                    const poTaxCode = this.getPoTaxCoding(poLine);

                    // Account Codings
                    const poAccountCode = this.getPoAccountCoding(poLine);

                    const patchValue = {

                        invoice_invoiceLine_orderLineReference: poLine.orderLineNumber,
                        invoice_invoiceLine_lineDescription: poLine.description,
                        invoice_invoiceLine_quantity: poLine.quantity,
                        invoice_invoiceLine_unitPrice: poLine.unitPrice,

                        invoice_invoiceLine_expenseCoding1: poExpCode1,
                        invoice_invoiceLine_expenseCoding2: poExpCode2,
                        invoice_invoiceLine_expenseCoding3: poExpCode3,
                        invoice_invoiceLine_expenseCoding4: poExpCode4,
                        invoice_invoiceLine_expenseCoding5: poExpCode5,

                        invoice_invoiceLine_taxCoding: poTaxCode,

                        invoice_invoiceLine_accountCoding: poAccountCode,

                        invoice_invoiceLine_lineTaxAmount: poLine.taxAmount,
                        invoice_invoiceLine_lineTaxExclusiveAmount: poLine.taxExclusiveAmount,
                        invoice_invoiceLine_lineTaxInclusiveAmount: poLine.taxInclusiveAmount,
                    };

                    linesArray.controls[i].patchValue(patchValue);

                    this.openSnackBar(`${purchaseOrderLinesLength} ligne(s) importée(s)`);
                }

            } else {
                this.openSnackBar('0 ligne importée');
            }
        }

    }


    private getPoExpCoding(poLine: IPurchaseOrderLine, expCodingIndex: number) {

        const iExpenseCodingType = this.invoiceLineHelper.expenseCodingTypes[expCodingIndex];

        if (iExpenseCodingType) {
            const poExpCode = searchInitialExpenseCoding(
                typeFilteredExpenseCodings(iExpenseCodingType.type, poLine.expenseCodings),
                this.expenseCodingsList
            );
            const b = poExpCode && poExpCode[0];
            if (b) {
                return poExpCode[0];
            } else {
                return null;
            }

        } else {
            return null;
        }

    }

    private getCodingProposalExpCoding(poLine: IPurchaseOrderLine, expCodingIndex: number) {

        const iExpenseCodingType = this.invoiceLineHelper.expenseCodingTypes[expCodingIndex];

        if (iExpenseCodingType) {
            const poExpCode = searchInitialExpenseCoding(
                typeFilteredExpenseCodings(iExpenseCodingType.type, poLine.expenseCodings),
                this.expenseCodingsList
            );
            const b = poExpCode && poExpCode[0];
            if (b) {
                return poExpCode[0];
            } else {
                return null;
            }

        } else {
            return null;
        }

    }

    // Supports only one account coding
    private getPoAccountCoding(poLine: IPurchaseOrderLine) {
        if (this.invoiceLineHelper.accountCodingsStatus) {
            const poAccCode = searchInitialAccountCoding(
                poLine.accountCodings[0],
                this.accountCodings
            );
            const b = poAccCode && poAccCode[0];
            if (b) {
                return poAccCode[0];
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    // Supports only one account coding
    private getCodingProposalAccountCoding(poLine: IPurchaseOrderLine) {
        if (this.invoiceLineHelper.accountCodingsStatus) {
            const poAccCode = searchInitialAccountCoding(
                poLine.accountCodings[0],
                this.accountCodings
            );
            const b = poAccCode && poAccCode[0];
            if (b) {
                return poAccCode[0];
            } else {
                return null;
            }
        } else {
            return null;
        }
    }


    private getPoTaxCoding(purchaseOrderLine: IPurchaseOrderLine) {
        if (this.invoiceLineHelper.taxCodingsStatus) {
            const taxCode = searchInitialTaxCoding(
                purchaseOrderLine.taxCodings[0],
                this.taxCodings
            );
            if (taxCode && taxCode[0]) {
                return taxCode[0];
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private getCodingProposalTaxCoding(purchaseOrderLine: IPurchaseOrderLine) {
        if (this.invoiceLineHelper.taxCodingsStatus) {
            const taxCode = searchInitialTaxCoding(
                purchaseOrderLine.taxCodings[0],
                this.taxCodings
            );
            if (taxCode && taxCode[0]) {
                return taxCode[0];
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    /** Delete invoiceLine from the formArray by line index */
    public deleteInvoiceLine(index: number): void {
        this.invoiceLineForm.removeAt(index);
        this.invoiceLineForm.markAsDirty();
    }

    /** Update the invoiceLine amount values based on the line index */
    private updateLineAmountValues(index: number, taxExclusiveAmount: string, taxCodingRate: string): void {

        const linesArray = this.invoiceForm.controls.invoiceLines as FormArray;

        const normalizedTaxExclusiveAmount = amountParser(
            parseDecimal(taxExclusiveAmount)
        );
        const normalizedTaxCodingRate = amountParser(taxCodingRate) / 100;

        taxCodingRate === '' || taxExclusiveAmount === ''
            ? linesArray.controls[index].patchValue({
                invoice_invoiceLine_lineTaxExclusiveAmount: taxExclusiveAmount,
                invoice_invoiceLine_lineTaxAmount: '',
                invoice_invoiceLine_lineTaxInclusiveAmount: '',
            })
            : linesArray.controls[index].patchValue({
                invoice_invoiceLine_lineTaxExclusiveAmount: taxExclusiveAmount,
                invoice_invoiceLine_lineTaxAmount: taxAmount(
                    normalizedTaxExclusiveAmount,
                    normalizedTaxCodingRate
                ).toFixed(2),
                invoice_invoiceLine_lineTaxInclusiveAmount: taxInclusiveAmount(
                    normalizedTaxExclusiveAmount,
                    normalizedTaxCodingRate
                ).toFixed(2),
            });
    }

    /**
     * The totalAmount input change event handler.
     * @param event the input change event.
     * @param index index of the invoiceLines formArray.
     * @param attribute the attribute to handle change for
     */
    public onChangeHandler(
        event: any,
        index: number,
        attribute: string
    ) {
        if (attribute === 'lineTaxExclusiveAmount') {
            const linesArray = this.invoiceForm.controls.invoiceLines as FormArray;
            const taxExclusiveAmount = event.target.value;
            const taxCoding = linesArray.controls[index].get(
                'invoice_invoiceLine_taxCoding'
            ).value;

            this.updateLineAmountValues(index, taxExclusiveAmount, taxCoding.rate);

        } else if (attribute === 'quantity') {
            const linesArray = this.invoiceForm.controls.invoiceLines as FormArray;
            const quantity = event.target.value;
            const unitPrice = linesArray.controls[index].get(
                'invoice_invoiceLine_unitPrice'
            ).value;

            this.updateLineTeaValue(index, quantity, unitPrice);

        } else if (attribute === 'unitPrice') {
            const linesArray = this.invoiceForm.controls.invoiceLines as FormArray;
            const unitPrice = event.target.value;
            const quantity = linesArray.controls[index].get(
                'invoice_invoiceLine_quantity'
            ).value;

            this.updateLineTeaValue(index, quantity, unitPrice);
        }
    }

    /** Update invoice line (at provided index) tax exclusive amount  */
    private updateLineTeaValue(index: number, quantity: string, unitPrice: string): void {

        const linesArray = this.invoiceForm.controls.invoiceLines as FormArray;
        const normalizedQuantity = amountParser(parseDecimal(quantity));
        const normalizedUnitPrice = amountParser(parseDecimal(unitPrice));

        const tea = (normalizedQuantity * normalizedUnitPrice).toFixed(2);
        linesArray.controls[index].patchValue({
            invoice_invoiceLine_lineTaxExclusiveAmount: tea
        });

        // there is a better way: use Rx BehaviorSubject with a TeaFormula object for example
        const taxCoding = linesArray.controls[index].get('invoice_invoiceLine_taxCoding').value;
        this.updateLineAmountValues(index, tea, taxCoding.rate);
    }

    /**
     * The vatRate select change event handler.
     * @param value the selected value.
     * @param index index of the invoiceLines formArray.
     */
    public onChangeTaxCodingEvent(value: ITaxCoding, index: number) {
        const linesArray = this.invoiceForm.controls.invoiceLines as FormArray;

        const taxCoding = value.rate;
        const taxExclusiveAmount: string = linesArray.controls[index].get(
            'invoice_invoiceLine_lineTaxExclusiveAmount'
        ).value;

        this.updateLineAmountValues(index, taxExclusiveAmount, taxCoding.toString());
    }

    private submitFormWithoutLines(formId, formValues, taskId, outcome) {
        this.formService
            .submitWorkflowForm(formId, formValues, taskId, outcome)
            .subscribe(
                () => this.navigateToTaskDetails(),
                () => {
                },
                () => (this.submitSuccess = true)
            );
    }

    /**
     * Submit the form values, formId and outcome.
     * @param outcome name of the workflow action
     */
    public onSubmit(outcome: string) {
        this.submitSuccess = false;

        const formData = removeInvoiceLines(this.invoiceForm.value);

        const formValues = adaptToAPI(formData, this.formMap);

        // TODO: refactor code, there is duplicate code btw handling a form with lines and without
        // Form has no invoice lines => no need to handle them
        if (!this.invoiceLineForm) {
            // Send Form to API
            this.submitFormWithoutLines(this.formMetadata.id, formValues, this.taskId, outcome);

        } else { // Form with invoice lines => handle lines before posting form values
            const invoiceLinesFormValues = this.invoiceLineHelper.adaptFormInvoiceLinesToAPI(
                this.invoiceLineForm.value
            );
            const orderLineIdList = this.extractOrderLineIdList(invoiceLinesFormValues);
            // Send invoiceLines and form to API
            this.formService
                // Send invoiceLines to API
                .createInvoiceLines(this.invoiceId, invoiceLinesFormValues)
                .subscribe(
                    () => {
                    },
                    () => {
                    },
                    () => {
                        // Send Form to API
                        this.addOrderLineIdListForReconciliation(formValues, orderLineIdList);
                        this.submitFormWithoutLines(this.formMetadata.id, formValues, this.taskId, outcome);
                    }
                );
        }

    }

    public handleActionClick(outcome: IFallbackOutcome): void {
        outcome.params.commentOnAction === true
            ? this.openCommentActionModal(outcome.id)
            : this.onSubmit(outcome.id);
    }

    public isDisabled(invoiceForm: FormGroup, outcome: IFallbackOutcome): boolean {
        const disableOnError = outcome.params.disableOnError;
        let formInvalid = false;

        // if any of the validation error behaviorSubjects is not empty and it's
        // corresponding scope is present into the invoiceForm
        // => invoiceForm is invalid
        const formIncludedScopes = Object.keys(this.invoiceForm.controls).map(
            controlKey => controlKey.replace(new RegExp('_', 'g'), '.')
        );
        // TODO: should be defined in the form ?
        formIncludedScopes.push('invoice.invoiceLines');

        for (const key of this.auditService.validationErrors.keys()) {
            if (formIncludedScopes.includes(key)) {

                const constraintSubj = this.auditService.validationErrors.get(key);

                if (constraintSubj && constraintSubj.getValue() != null) {
                    formInvalid = true;
                }
            }
        }
        return (disableOnError && formInvalid) || invoiceForm.invalid;
    }

    /**  Uses Angular router to navigate to task-details url */
    public navigateToTaskDetails(): void {
        this.router.navigate(['workspaces', this.workspaceId, 'tasks']);
    }

    public closeTaskViewer(): void {
        this.navigateToTaskDetails();
    }

    /**
     * @returns true if invoice form is dirty else false.
     */
    hasUnsavedData(): boolean {
        return this.invoiceForm.dirty;
    }

    /**
     * Based on form isDirty value prompt user with message to confirm leaving page.
     * @returns true if user can leave the form else false.
     */
    canLeaveForm(): Observable<boolean> {
        if (this.hasUnsavedData()) {
            return this.dialogService.confirm();
        } else {
            return of(true);
        }
    }

    /**
     * Send unclaim task request to the API.
     * @param taskId taskId to unclaim.
     */
    unclaimTask(taskId: string): void {
        this.claimApiSubscription = this.taskService
            .unclaimTaskFromAPI(taskId)
            .subscribe(() => console.log('Task unclaimed successfully!'));
    }

    public openSnackBar(message: string) {
        this.snackBar.openFromComponent(PurchaseOrderSnackBarComponent, {
            duration: this.snackBarDuration * 1000,
            data: message,
        });
    }

    public openCommentActionModal(outcome: string): void {
        const dialogRef = this.dialog.open(CommentActionComponent, {
            width: '700px',
            height: '300px',
            data: {outcome},
        });

        const subscription = dialogRef.componentInstance.ActionConfirmEvent.subscribe(
            comment => {
                this.formService.sendCommentsToAPI(this.taskId, comment)
                    .toPromise().then(() => {
                        this.onSubmit(outcome);
                        dialogRef.close();
                    }
                );
            }
        );

        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });
    }

    public openSearchSupplierModal() {
        const modalData: SearchSupplierComponentData = {
            workspaceId: this.workspaceId,
            filters: this.filters
        };
        const dialogRef = this.dialog.open(SearchSupplierComponent, {
            width: '720px',
            height: 'auto',
            data: modalData,
            disableClose: true,
        });

        const subscription = dialogRef.componentInstance.supplierSelectedEvent.subscribe(
            (supplier: ISupplier) => {
                this.updateInvoiceFormWithSelectedSupplier(supplier);
                dialogRef.close();
            }
        );

        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });
    }

    private updateInvoiceFormWithSelectedSupplier(supplier: ISupplier) {
        const normalizedSupplier = normalizeSupplier(supplier);

        this.invoiceForm.patchValue({
            invoice_supplierParty_name: normalizedSupplier.invoice_supplierParty_name,

            invoice_supplierParty_customerAssignedAccountId: normalizedSupplier.invoice_supplierParty_customerAssignedAccountId,

            invoice_supplierParty_legalId: normalizedSupplier.invoice_supplierParty_legalId,

            invoice_supplierParty_taxId: normalizedSupplier.invoice_supplierParty_taxId,

            invoice_supplierParty_contactElectronicMail: normalizedSupplier.invoice_supplierParty_contactElectronicMail,

            invoice_supplierParty_financialAccount: normalizedSupplier.invoice_supplierParty_financialAccount,

            invoice_supplierParty_paymentTerms: normalizedSupplier.invoice_supplierParty_paymentTerms
        }, {emitEvent: true});

        // need to emit valueChanges event only when legalId is set otherwise rule validation will fire for partial
        // supplier object and we'll get false positive
        // this.invoiceForm.patchValue({
        // 	invoice_supplierParty_legalId: normalizedSupplier.invoice_supplierParty_legalId,
        // }, {emitEvent: true});

        this.invoiceForm.markAsDirty();
    }

    uploadBrFile(files: FileList) {
        if (files && files.length > 0) {
            const file: File = files.item(0);
            let fileReport: any;
            this.invoiceService.matchGrIr(file, this.invoiceId).subscribe((data: any) => {
                if (data.type === HttpEventType.Response) {
                    fileReport = new Blob([data.body], {type: 'text/csv;charset=utf-8'});

                    saveAs(fileReport, 'report-matching-'.concat(this.invoiceId).concat('.csv'));
                    fileReport = undefined;
                    this.uploadGrFile.nativeElement.value = '';
                }
            });
        }
    }

    private extractOrderLineIdList(invoiceLines: IAdapterInvoiceLine[]): string[] {
        return _.map(invoiceLines, 'orderLineReference');
    }

    private addOrderLineIdListForReconciliation(formValues: any, orderLineIdsList: string[]) {
        formValues._transient_recon_poline_refs = this.toCommaSeparated(orderLineIdsList);
    }

    private toCommaSeparated(orderLineRefList: string[]): string {
        if (orderLineRefList) {
            return orderLineRefList.join(',');
        }
        return '';
    }

    formatValidationMsg(validationErrors: Constraint[]): string {
        if (_.isArray(validationErrors)) {
            const join = _.join(validationErrors.map(error => error.message), '. ');
            return join;
        } else {
            return null;
        }
    }
}

@Component({
    selector: 'app-comment-action-model',
    templateUrl: './components/comment-action-modal.html',
})
export class CommentActionComponent {
    message = new FormControl('', Validators.required);
    ActionConfirmEvent = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<CommentActionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    handleActionConfirm() {
        this.ActionConfirmEvent.emit(this.message.value);
    }
}

@Component({
    selector: 'app-search-supplier-modal',
    templateUrl: './components/search-supplier-modal.html',
})
export class SearchSupplierComponent implements OnInit {
    supplierList = new BehaviorSubject<ISupplier[]>([]);
    supplierList$: Observable<ISupplier[]> = this.supplierList.asObservable();
    selectedSupplier: ISupplier;
    supplierSelectedEvent = new EventEmitter();

    parameters = new Map();
    form: FormGroup;
    equals: string;
    contains: string;
    greaterThanOrEqualTo: string;
    lessThanOrEqualTo: string;
    filterGroup: any;
    workspaceId: string;

    constructor(
        private fb: FormBuilder,
        private supplierMdmService: SupplierMdmService,
        public dialogRef: MatDialogRef<SearchSupplierComponent>,
        public translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: SearchSupplierComponentData
    ) {
        this.workspaceId = data.workspaceId;
    }

    ngOnInit(): void {
        this.initConditions();
        this.initFilters();
        const group: FormGroup[] = [];

        if (this.data.filters.length === 0) {
            group.push(this.createFilter());
        } else {
            for (const f of this.data.filters) {
                group.push(
                    this.fb.group({
                        parameter: new FormControl(f.parameter, [Validators.required]),
                        value: new FormControl(f.value, [Validators.required]),
                        // condition: new FormControl(f.condition, [Validators.required]),
                    })
                );
            }
        }

        this.form = this.fb.group({
            filters: this.fb.array(group),
        });

        this.parameters.set('name', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('legalId', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('customerAssignedAccountId', {
            type: 'string',
            conditions: this.stringConditions(),
        });
    }

    stringConditions(): Condition[] {
        return [
            new Condition('equals', this.equals),
            new Condition('contains', this.contains),
        ];
    }

    otherTypeConditions(): Condition[] {
        return [
            new Condition('equals', this.equals),
            new Condition('greaterThanOrEqual', this.greaterThanOrEqualTo),
            new Condition('lessThanOrEqual', this.lessThanOrEqualTo),
        ];
    }

    private initFilters() {
        let fournisser: string;
        let nom: string;
        let ice: string;
        let identifier: string;
        this.translateService
            .get('global.provider')
            .subscribe((value) => (fournisser = value));
        this.translateService.get('global.name').subscribe((value) => (nom = value));
        this.translateService
            .get('global.legalID')
            .subscribe((value) => (ice = value));
        this.translateService
            .get('global.identifier')
            .subscribe((value) => (identifier = value));
        this.filterGroup = [
            {
                name: fournisser,
                children: [
                    {label: nom, value: 'name'},
                    {label: ice, value: 'legalId'},
                    {label: identifier, value: 'customerAssignedAccountId'},
                ],
            },
        ];
    }

    initConditions() {
        this.translateService
            .get('filters.equalsTo')
            .subscribe((res) => (this.equals = res));
        this.translateService
            .get('filters.contains')
            .subscribe((res) => (this.contains = res));
        this.translateService
            .get('filters.greaterThanOrEqualTo')
            .subscribe((res) => (this.greaterThanOrEqualTo = res));
        this.translateService
            .get('filters.lessThanOrEqualTo')
            .subscribe((res) => (this.lessThanOrEqualTo = res));
    }

    createFilter(): FormGroup {
        return this.fb.group({
            parameter: new FormControl('', [Validators.required]),
            value: new FormControl('', [Validators.required]),
        });
    }

    onParameterChanges(filter: any, index: number) {
        this.filters.setControl(
            index,
            (this.filters[index] = this.fb.group({
                parameter: new FormControl(filter.value.parameter, [
                    Validators.required,
                ]),
                value: new FormControl('', [Validators.required]),
            }))
        );
    }

    refreshSupplierList(workspaceId: string): void {
        this.supplierMdmService
            .getSuppliersForWorkspace(this.filters.value, workspaceId)
            .subscribe((response) => this.supplierList.next(response));
    }

    getControls(): any {
        return (this.form.get('filters') as FormArray).controls;
    }

    get filters() {
        return this.form.get('filters') as FormArray;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit() {
        this.refreshSupplierList(this.workspaceId);
    }

    handleSelect() {
        this.supplierSelectedEvent.emit(this.selectedSupplier);
    }
}

@Component({
    selector: 'app-purchase-order-line-snack-bar',
    templateUrl: './components/purchase-order-line-snack-bar.html',
})
export class PurchaseOrderSnackBarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {
    }
}


@Component({
    selector: 'app-po-copy-confim-dialog',
    templateUrl: './dialogs/po-copy-confim-dialog.html'
})
export class PoCopyConfirmDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<PoCopyConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }


}

@Component({
    selector: 'app-coding-proposal-confim-dialog',
    templateUrl: './dialogs/coding-proposal-confim-dialog.html'
})
export class CodingProposalConfirmDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<CodingProposalConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }


}
