import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {Filter} from '../../modules/invoice-explorer/invoice-explorer.component';
import {Observable} from 'rxjs';
import {Invoice} from '../../models/invoice';
import * as moment from 'moment';
import {parseDecimal} from '../../shared/formHepler';
import {AttachmentDetails} from '../../models/attachment-details.model';
import {InvoiceIds} from './invoice.service';

@Injectable({
    providedIn: 'root'
})
export class RejectedInvoiceService {

    readonly invoiceURL = environment.backendUrl + '/invoicestore/app/api/invoices';
    readonly invoiceIdURL = environment.backendUrl + '/invoicestore/app/api/invoiceIds';
    readonly attachmentURL = environment.backendUrl + '/invoicestore/app/api/attached-documents';
    readonly exportUrl = environment.backendUrl + '/invoicestore/app/api/rejected-invoices/download/csv';
    readonly updateStatusUrl = environment.backendUrl + '/invoicestore/app/api/invoices/update-status';

    constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
    }

    public getInvoiceListFromAPI(
        sort: string,
        pageIndex: number,
        pageSize: number,
        filters: Filter[],
        onlyPublished: boolean,
        workspaceId: string): Observable<any> {
        if (!sort) {
            sort = 'issueDate,desc';
        }
        let httpParams = new HttpParams()
            .set('approvalStatus.equals', 'REJECTED')
            .set('sort', sort)
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString())
            .set('workspaceId.equals', workspaceId);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        if (onlyPublished) {
            httpParams = httpParams.set('publicationStatus.equals', 'PUBLISHED');
        }
        return this.http.get<any>(this.invoiceURL, {params: httpParams});
    }

    public getInvoiceByIDFromAPI(invoiceId: string): Observable<Invoice> {
        const httpParams = new HttpParams().set('id.equals', invoiceId);

        return this.http.get<Invoice>(this.invoiceURL, {params: httpParams});
    }

    public getInvoiceIdsFromAPI(
        filters: Filter[],
        onlyPublished,
        workspaceId: string
    ): Observable<any> {
        let httpParams = new HttpParams()
            .set('approvalStatus.equals', 'REJECTED')
            .set('workspaceId.equals', workspaceId);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        if (onlyPublished) {
            httpParams = httpParams.set('publicationStatus.equals', 'PUBLISHED');
        }
        return this.http.get<any>(this.invoiceIdURL, {params: httpParams});
    }

    private buildFiltersParam(filters: Filter[], params: HttpParams): HttpParams {
        for (const f of filters) {
            if (
                f.parameter === 'issueDate' ||
                f.parameter === 'receiptDate' ||
                f.parameter === 'approvalDate'
            ) {
                params = params.set(
                    f.parameter.concat('.').concat(f.condition),
                    moment(f.value).format('YYYY-MM-DD').toString()
                );
            } else if (!f.value && f.condition === 'equals') {
                params = params.set(
                    f.parameter.concat('.specified'),
                    'false'
                );
            } else {
                params = params.set(
                    f.parameter.concat('.').concat(f.condition),
                    parseDecimal(f.value)
                );
            }
        }
        return params;
    }

    public getAttachmentDetailsFromAPI(
        invoiceId: string,
        documentType: string
    ): Observable<AttachmentDetails[]> {
        const httpParams = new HttpParams()
            .set('invoiceId.equals', invoiceId)
            .set('documentType.equals', documentType);

        return this.http.get<AttachmentDetails[]>(this.attachmentURL, {
            params: httpParams,
        });
    }

    public exportSelectedInvoices(invoiceIds: InvoiceIds): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/csv');

        return this.http.post(
            this.exportUrl,
            invoiceIds,
            {
                headers,
                responseType: 'text'
            });
    }

    public archiveInvoices(invoiceIds: string[]): Observable<any> {
        let headers = new HttpHeaders();

        headers = headers.set('Accept', 'application/csv');

        const httpParams = new HttpParams()
            .set('newStatus', 'ARCHIVED');

        return this.http.post(
            this.updateStatusUrl,
            invoiceIds,
            {
                headers,
                params: httpParams
            });


    }

    downloadFile(invoiceId: string): Observable<HttpEvent<Blob>> {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        httpHeaders.set(
            'Authorization',
            'bearer ' + this.authenticationService.getUserToken() + ''
        );
        return this.http.request(
            new HttpRequest(
                'POST',
                `${environment.backendUrl}/invoicestore/app/api/invoices/${invoiceId}/attachment-content`,
                {attachmentType: 'original-file'},
                {
                    headers: httpHeaders,
                    reportProgress: true,
                    responseType: 'blob',
                }
            )
        );
    }
}
