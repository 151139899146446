import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FileUploader} from 'ng2-file-upload';
import {TabService} from 'src/app/core/services/tab.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AuthenticationService, WorkspacesService} from '../../core/services';
import {environment} from '../../../environments/environment';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InvoiceStoreMetrics, WorkspaceStats} from '../../models/workspaces.model';
import {Group, IAuthUser} from '../../models/token-user.model';
import {RolesService} from '../../core/services/roles.service';
import {NgxPermissionsService} from 'ngx-permissions';

const uploadAPI =
    environment.backendUrl + '/invoicestore/app/api/invoice-upload';

interface TaskDefinitions {
    id: string;
    name: string;
    documentation: string;
    nbActiveTasks: number;
}

interface UserTaskDefinitions {
    size: number;
    total: number;
    start: number;
    data: TaskDefinitions[];
}

@Component({
    selector: 'app-activity-overview',
    templateUrl: './activity-overview.component.html',
    styleUrls: ['./activity-overview.component.scss'],
})
export class ActivityOverviewComponent implements OnInit, OnDestroy {
    @ViewChild('fileInput', {static: false}) input: ElementRef;
    private workspaceId: string;
    public authUser: IAuthUser;
    public metrics: WorkspaceStats = {
        workspaceId: undefined,
        myActiveTasksCount: undefined,
        ongoingProcessesCount: undefined,
    };
    public invoiceStoreMetrics: InvoiceStoreMetrics = {
        invoiceReadingCount: undefined,
    };
    public tasks: UserTaskDefinitions = {
        size: undefined,
        total: undefined,
        start: undefined,
        data: [],
    };

    public canAccessWorkspace = new BehaviorSubject<boolean>(false);

    public purchaseInvoiceCounter: number;
    public totalPurchaseInvoiceCounter: number;
    public totalRejectedInvoiceCounter: number;

    private routerSubscription: Subscription;
    private workspaceServiceSubscription: Subscription;
    private workspaceStatServiceSubscription: Subscription;
    private purchaseInvoiceStatsSubscription: Subscription;
    private totalInvoiceStatsSubscription: Subscription;
    private totalRejectedStatsSubscription: Subscription;
    private invoiceStoreSub: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        public workspacesService: WorkspacesService,
        private tabService: TabService,
        public dialog: MatDialog,
        private rolesService: RolesService,
        private permissionsService: NgxPermissionsService
    ) {
        this.getRouterParams();
        this.getAuthUserData();

        this.workspaceServiceSubscription = this.workspacesService
            .getProcessStatsFromAPI(this.workspaceId)
            .subscribe(res => this.tasks = res);

        this.workspaceStatServiceSubscription = this.workspacesService
            .pollWorkspaceStatsFromAPI(this.workspaceId)
            .subscribe(res => this.metrics = res);

        this.purchaseInvoiceStatsSubscription = this.workspacesService
            .refreshPurchaseInvoiceStats(this.workspaceId, 5000)
            .subscribe(data => this.purchaseInvoiceCounter = data);

        this.totalInvoiceStatsSubscription = this.workspacesService
            .refreshTotalPurchaseInvoiceStats(this.workspaceId, 5000)
            .subscribe(data => this.totalPurchaseInvoiceCounter = data);

        this.totalRejectedStatsSubscription = this.workspacesService
            .refreshRejectedInvoiceStats(this.workspaceId, 5000)
            .subscribe(data => this.totalRejectedInvoiceCounter = data);

        this.invoiceStoreSub = this.workspacesService
            .pollInvoiceStoreStats(this.workspaceId)
            .subscribe(res => this.invoiceStoreMetrics.invoiceReadingCount = res);
    }

    ngOnInit(): void {
        this.workspacesService.getWorkspaceName(this.workspaceId).subscribe(
            res => this.canAccessWorkspace.next(true),
            error => this.canAccessWorkspace.next(false)
        );
    }

    private getRouterParams(): void {
        this.routerSubscription = this.route.paramMap.subscribe((params) => {
            this.workspaceId = params.get('workspaceId');
            this.workspacesService.setWorkspaceId(this.workspaceId);
        });
    }

    /**
     * Get the current user data.
     */
    private getAuthUserData(): void {
        this.authUser = this.authenticationService.getUserAuthData();
    }

    /*public myDateAdapter(): string {
        const API_DATE_FORMAT = 'YYYY-MM-DD';
        moment.locale('fr-FR');
        const date = new Date();
        const newDate = date.setDate(date.getDate() - 30);
        return moment(newDate).format(API_DATE_FORMAT);
    }*/

    /**
     * Look for the given groupName in the user groups array.
     * @param groups user group list.
     * @param groupName group name within the user group list.
     * @returns return true if the provided groupName is in the user groups array.
     */
    public userHasGroup(groups: Group[], groupName: string): boolean {
        const result = groups.filter((item) => {
            return item.id === groupName;
        });

        return result.length !== 0;
    }

    /**
     * Handle user click event on the upload button.
     */
    public handleOnClick(): void {
        this.input.nativeElement.click();
    }

    /**
     * Input file change event handler. Gets the FileList from the reference to nativeElement.
     * @param event the file input change event
     */
    public handleOnChange(event: Event): void {
        const fileList = this.fileListToArrayConvertor(
            this.input.nativeElement.files
        );

        this.openDialog(fileList);
    }

    /**
     * Convert the input file event FileList to an array of files
     * @param files files list from the file input change event
     */
    private fileListToArrayConvertor(files: FileList): File[] {
        const fileArray: File[] = [];
        for (let i = 0; i < files.length; i++) {
            fileArray.push(files.item(i));
        }
        return fileArray;
    }

    /**
     * The modal options and injected data.
     * @param fileList The selected fileList from the captured change event on the file input
     */
    private openDialog(fileList: File[]): void {
        const dialogRef = this.dialog.open(UploaderModalComponent, {
            autoFocus: false,
            disableClose: true,
            role: 'dialog',
            width: '720px',
            data: {
                files: fileList,
                workspaceId: this.workspaceId,
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            this.input.nativeElement.value = '';
        });
    }

    /**
     * Navigate the user to task-details module
     * @param taskDefKey index of the taskDef used to setSelectedTab
     */
    public navigateToTaskDetails(taskDefKey: number): void {
        this.tabService.setSelectedIndex(taskDefKey);
        this.router.navigate(['workspaces', this.workspaceId, 'tasks']).then();
    }

    public navigateToInvoiceExplorer(): void {
        this.router
            .navigate(['workspaces', this.workspaceId, 'purchase-invoices'])
            .then();
    }

    public navigateToInvoiceRejectedExplorer(): void {
        this.router
            .navigate(['workspaces', this.workspaceId, 'rejected-invoices'])
            .then();
    }

    public navigateToInvoiceInProgressExplorer(): void {
        this.router
            .navigate(['workspaces', this.workspaceId, 'in-progress-invoices'])
            .then();
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
        this.workspaceServiceSubscription.unsubscribe();
        this.workspaceStatServiceSubscription.unsubscribe();
        this.purchaseInvoiceStatsSubscription.unsubscribe();
        this.totalInvoiceStatsSubscription.unsubscribe();
        this.totalRejectedStatsSubscription.unsubscribe();
        this.invoiceStoreSub.unsubscribe();
    }
}

@Component({
    selector: 'app-uploader-modal',
    templateUrl: './components/invoice-uploader/invoice-uploader.html',
    styleUrls: ['./components/invoice-uploader/invoice-uploader.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UploaderModalComponent implements OnInit, OnDestroy {
    public uploader: FileUploader;
    public response: any;
    public isCompleteAll = false;
    public successCounter = 0;
    public maxFileSize = 10 * 1024 * 1024; // 10Mo

    constructor(
        private authenticationService: AuthenticationService,
        public dialogRef: MatDialogRef<UploaderModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { files: File[]; workspaceId: string }
    ) {
        this.initFileUploader();
        this.uploader.addToQueue(this.data.files);
    }

    ngOnInit(): void {
        this.response = {};
        this.uploader.response.subscribe(
            (res) => (this.response = res ? JSON.parse(res) : JSON.parse('{}'))
        );

        this.uploader.onSuccessItem = () => {
            this.successCounter = this.successCounter + 1;
        };

        this.uploader.onCompleteAll = () => {
            this.isCompleteAll = true;
        };
    }

    ngOnDestroy(): void {
        this.uploader.clearQueue();
        this.uploader.destroy();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    /**
     * Responsible of initializing FileUploader
     */
    private initFileUploader(): void {
        this.uploader = new FileUploader({
            url: uploadAPI,
            authToken: 'Bearer ' + this.authenticationService.getUserToken(),
            allowedMimeType: [
                'image/png',
                'image/jpg',
                'image/jpeg',
                'application/pdf',
            ],
            maxFileSize: this.maxFileSize,
            itemAlias: 'file',
            autoUpload: true,
            disableMultipart: false,
            additionalParameter: {
                workspaceId: this.data.workspaceId,
                initProcess: true,
            },
        });
    }
}
