import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoaderService {
    public loader: Subject<boolean> = new Subject();
    public loader$: Observable<boolean> = this.loader.asObservable();

    constructor(private router$: Router) {
    }

    /**
     * Start the app loading state
     */
    public startLoading(): void {
        const param = this.router$.url.split('/').pop();

        const url = `/workspaces/${param}`;
        const url2 = `/poworkspaces/${param}`;

        if (this.router$.isActive(url, true) || this.router$.isActive(url2, true)) {// match url exactly !
            // skip loader for this url
            this.loader.next(false);

        } else {
            this.loader.next(true);
        }
    }

    /**
     * Stop the app loading state
     */
    public stopLoading(): void {
        this.loader.next(false);
    }
}
