import { keycloakConfig } from './keycloak.config';
import Keycloak from '../../../assets/keycloak-js/keycloak';
import * as i0 from "@angular/core";
export class KeycloakService {
    constructor() {
        this.keycloak = new Keycloak(keycloakConfig);
        this.keycloak.onTokenExpired = () => {
            console.log('token expired', this.keycloak.token);
        };
    }
    getToken() {
        return this.keycloak.token || '';
    }
    isLoggedIn() {
        return this.keycloak.authenticated;
    }
    login() {
        this.keycloak.login();
    }
    logout() {
        this.keycloak.logout();
    }
}
KeycloakService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KeycloakService_Factory() { return new KeycloakService(); }, token: KeycloakService, providedIn: "root" });
