import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    /**
     * Ask user to confirm an action. `message` explains the action and choices.
     * Returns observable resolving to `true`=confirm or `false`=cancel
     */
    confirm(message?: string): Observable<boolean> {
        const confirmation = window.confirm(
            message ||
            'Vous avez des modifications non enregistrées. Souhaitez-vous continuer ?'
        );

        return of(confirmation);
    }
}
