import {Injectable} from '@angular/core';
import {keycloakConfig} from './keycloak.config';
import Keycloak from '../../../assets/keycloak-js/keycloak';

@Injectable({
    providedIn: 'root'
})
export class KeycloakService {
    public keycloak: Keycloak.KeycloakInstance;

    constructor() {
        this.keycloak = new Keycloak(keycloakConfig);

        this.keycloak.onTokenExpired = () => {
            console.log('token expired', this.keycloak.token);
        };

    }

    getToken(): string {
        return this.keycloak.token || '';
    }

    isLoggedIn(): boolean {
        return this.keycloak.authenticated;
    }

    login(): void {
        this.keycloak.login();
    }

    logout(): void {
        this.keycloak.logout();
    }
}
