import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatSelect} from '@angular/material';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {IExpenseCoding} from '../../../../core/services';
import {take, takeUntil} from 'rxjs/operators';
import {IOption} from '../../../../models/form.model';

@Component({
    selector: 'app-expense-coding-field',
    templateUrl: './expense-coding-field.component.html',
    styleUrls: ['./expense-coding-field.component.scss']
})
export class ExpenseCodingFieldComponent implements OnInit {
    @Input() field: any;
    @Input() expenseCodingList: IExpenseCoding[];
    @Input() control: FormControl;

    /** Variable for searchable dropdown */
    @ViewChild('expenseCoding', {static: true}) expenseCoding: MatSelect;

    /** control for the MatSelect filter keyword */
    public expenseCodingFilterCtrl: FormControl = new FormControl();

    /** list of dropdown filtered by search keyword */
    public filteredExpenseCoding: ReplaySubject<IExpenseCoding[]> = new ReplaySubject<IExpenseCoding[]>(1);

    /** Subject that emits when the component has been destroyed. */
    protected onDestroy = new Subject<void>();

    constructor() {
    }

    ngOnInit(): void {
        this.initExpenseCodingSearch();
    }

    protected filterExpenseCoding() {
        if (!this.expenseCoding) {
            return;
        }
        // get the search keyword
        let search = this.expenseCodingFilterCtrl.value;
        if (!search) {
            this.filteredExpenseCoding.next(this.expenseCodingList.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the expense coding list
        this.filteredExpenseCoding.next(
            this.expenseCodingList.filter(
                (item: IExpenseCoding) =>
                    item.code.toLowerCase().indexOf(search) > -1 ||
                    item.label.toLowerCase().indexOf(search) > -1
            )
        );
    }

    private initExpenseCodingSearch() {
        this.filteredExpenseCoding.next((this.expenseCodingList || []).slice());
        this.expenseCodingFilterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.filterExpenseCoding();
            });
    }

    /** Sets the initial value after the expense codings are loaded initially */
    protected setInitialValue(): void {
        this.filteredExpenseCoding
            .pipe(take(1), takeUntil(this.onDestroy))
            .subscribe(() => {
                this.expenseCoding.compareWith = (a: IOption, b: IOption) =>
                    a && b && a.id === b.id;
            });
    }
}
