import { finalize } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/loader.service";
export class LoaderInterceptor {
    constructor(loader$) {
        this.loader$ = loader$;
        this.activeRequests = 0;
    }
    intercept(request, next) {
        this.activeRequests++;
        if (this.activeRequests > 0) {
            this.loader$.startLoading();
        }
        return next.handle(request).pipe(finalize(() => {
            this.activeRequests--;
            if (this.activeRequests === 0) {
                this.loader$.stopLoading();
            }
        }));
    }
}
LoaderInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderInterceptor_Factory() { return new LoaderInterceptor(i0.ɵɵinject(i1.LoaderService)); }, token: LoaderInterceptor, providedIn: "root" });
