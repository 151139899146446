<mat-drawer-container class="invoice-processing-main-container" hasBackdrop>
    <mat-drawer #commentDrawer mode="side" position="end">
        <mat-list class="comment-sheet">
            <mat-list-item class="comment-spacer">
                <h2 matLine class="comment-title">title
                    {{ 'invoiceProcessing.comments' | translate }}
                </h2>
            </mat-list-item>
            <mat-divider></mat-divider>
            <ng-container *ngIf="processComments?.length > 0; else emptyComments">
                <ng-container *ngFor="let comment of processComments">
                    <mat-list-item class="comment-spacer">
                        <p matLine class="comment-header">{{ comment.createdBy }}</p>
                        <p matLine class="comment-content">{{ comment.message }}</p>
                        <p matLine class="comment-date">{{ comment.created }}</p>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container>
            </ng-container>

            <ng-template #emptyComments>
                <p matLine class="no-comment">
                    {{ 'invoiceProcessing.noComments' | translate }}
                </p>
            </ng-template>
        </mat-list>
    </mat-drawer>

    <mat-drawer-content>
        <mat-grid-list cols="2" rowHeight="fit">
            <mat-grid-tile class="form-grid-tile">
                <button
                        mat-icon-button
                        class="close-button"
                        color="primary"
                        (click)="closeTaskViewer()"
                >
                    <mat-icon>clear</mat-icon>
                </button>

                <!-- show comment -->
                <button
                        class="view-comments-button"
                        mat-icon-button
                        type="button"
                        color="primary"
                        (click)="openComments()">
                    <mat-icon
                            *ngIf="(messageCount | async) > 0; else noComment"
                            [matBadge]="messageCount$ | async"
                            matBadgeColor="warn"
                            matBadgePosition="above after"
                    >
                        comment
                    </mat-icon>

                    <ng-template #noComment>
                        <mat-icon>comment</mat-icon>
                    </ng-template>
                </button>

                <div class="form-grid-header">
                    <p>{{ taskDefName }}</p>
                </div>

                <!-- invoiceForm -->
                <mat-card class="mat-elevation-z0">
                    <mat-card-content>
                        <form id="invoiceForm" [formGroup]="invoiceForm" autocomplete="off">
                            <!-- invoice information -->
                            <div
                                    fxLayout="row wrap"
                                    fxLayout.md="row wrap"
                                    fxLayout.sm="column"
                                    fxLayout.xs="column"
                                    fxLayoutGap="1%"
                                    fxLayoutAlign="flex-start"
                            >
                                <ng-container
                                        *ngFor="let item of formMetadata.fields"
                                        [ngSwitch]="item.type"
                                >

                                    <div
                                            *ngSwitchCase="'headline'"
                                            class="invoice-block-title"
                                            [fxFlex]="item.params.layout.size"
                                            [fxFlex.md]="item.params.layout.size"
                                            fxFlex.sm="100%"
                                            fxFlex.xs="100%"
                                    >
                                        <div class="invoice-block-title" fxFlex="nogrow">
                                            {{ item.name }}
                                        </div>

                                        <!-- Supplier search modal -->
                                        <ng-container
                                                *ngIf="
												item.id === 'invoice_supplierParty_headline' &&
												item.readOnly !== true
											"
                                        >
                                            <span fxFlex></span>
                                            <span class="side-info">{{
                                                'global.manualSearch' | translate
                                                }}</span>
                                            <button
                                                    mat-button
                                                    mat-icon-button
                                                    type="button"
                                                    color="inactive"
                                                    fxFlex="nogrow"
                                                    (click)="openSearchSupplierModal()"
                                            >
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </ng-container>
                                    </div>

                                    <mat-form-field
                                            class="invoice-field"
                                            [class.validation-error-highlight]="(getSubjectById(item.id)  | async)?.length > 0"
                                            appearance="outline"
                                            *ngSwitchCase="'text'"
                                            [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                            [floatLabel]="item.params.floatLabel"
                                            [fxFlex]="item.params.layout.size"
                                            [fxFlex.md]="item.params.layout.size"
                                            fxFlex.sm="100%"
                                            fxFlex.xs="100%"
                                    >
                                        <mat-label
                                                [class.validation-error]="(getSubjectById(item.id)  | async)?.length > 0">
                                            {{ item.name }}
                                        </mat-label>
                                        <input
                                                matInput
                                                [type]="item.type"
                                                [formControlName]="item.id"
                                                [readonly]="item.readOnly"
                                                [required]="item.required"

                                        />
                                        <mat-hint class="validation-error"
                                                  *ngIf="getSubjectById(item.id) | async as validationError">

                                            {{formatValidationMsg(validationError)}}

                                        </mat-hint>
                                    </mat-form-field>

                                    <mat-form-field
                                            class="invoice-field"
                                            [class.validation-error-highlight]="(getSubjectById(item.id)  | async)?.length > 0"
                                            appearance="outline"
                                            *ngSwitchCase="'date'"
                                            [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                            [floatLabel]="item.params.floatLabel"
                                            [fxFlex]="item.params.layout.size"
                                            [fxFlex.md]="item.params.layout.size"
                                            fxFlex.sm="100%"
                                            fxFlex.xs="100%"
                                    >
                                        <mat-label
                                                [class.validation-error]="(getSubjectById(item.id)  | async)?.length > 0">
                                            {{ item.name }}
                                        </mat-label>
                                        <input
                                                matInput
                                                [matDatepicker]="picker"
                                                [formControlName]="item.id"
                                                [readonly]="item.readOnly"
                                                [errorStateMatcher]="errorMatcher"
                                        />
                                        <mat-datepicker-toggle
                                                matSuffix
                                                [disabled]="item.readOnly"
                                                [for]="picker"
                                        >
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error *ngIf="invoiceForm.controls[item.id].invalid">
                                            Date invalide
                                        </mat-error>
                                        <mat-hint class="validation-error"
                                                  *ngIf="getSubjectById(item.id) | async as validationError">
                                            {{formatValidationMsg(validationError)}}
                                        </mat-hint>
                                    </mat-form-field>

                                    <mat-form-field
                                            class="invoice-field"
                                            [class.validation-error-highlight]="(getSubjectById(item.id)  | async)?.length > 0"
                                            appearance="outline"
                                            *ngSwitchCase="'multi-line-text'"
                                            [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                            [floatLabel]="item.params.floatLabel"
                                            [fxFlex]="item.params.layout.size"
                                            [fxFlex.md]="item.params.layout.size"
                                            fxFlex.sm="100%"
                                            fxFlex.xs="100%"
                                    >
                                        <mat-label
                                                [class.validation-error]="(getSubjectById(item.id)  | async)?.length > 0">
                                            {{ item.name }}
                                        </mat-label>
                                        <textarea
                                                matInput
                                                [type]="'text'"
                                                [formControlName]="item.id"
                                                [readonly]="item.readOnly"
                                                [required]="item.required"
                                        >
										</textarea>
                                    </mat-form-field>

                                    <mat-form-field
                                            class="invoice-field"
                                            [class.validation-error-highlight]="(getSubjectById(item.id)  | async)?.length > 0"
                                            appearance="outline"
                                            *ngSwitchCase="'decimal'"
                                            [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                            [floatLabel]="item.params.floatLabel"
                                            [fxFlex]="item.params.layout.size"
                                            [fxFlex.md]="item.params.layout.size"
                                            fxFlex.sm="100%"
                                            fxFlex.xs="100%">
                                        <mat-label
                                                [class.validation-error]="(getSubjectById(item.id)  | async)?.length > 0">
                                            {{ item.name }}
                                        </mat-label>
                                        <input
                                                matInput
                                                [type]="'text'"
                                                [formControlName]="item.id"
                                                [readonly]="item.readOnly"
                                                [required]="item.required"
                                                [errorStateMatcher]="errorMatcher"
                                        />
                                        <mat-error *ngIf="invoiceForm.controls[item.id].invalid">{{
                                            'global.incorrectAmount' | translate
                                            }}</mat-error>
                                        <mat-hint class="validation-error"
                                                  *ngIf="getSubjectById(item.id) | async as validationError">
                                            {{formatValidationMsg(validationError)}}
                                        </mat-hint>
                                    </mat-form-field>

                                    <ng-container
                                            *ngIf="
											item.params.searchable === false && item.readOnly !== true
										"
                                    >
                                        <mat-form-field
                                                class="invoice-field"
                                                appearance="outline"
                                                *ngSwitchCase="'dropdown'"
                                                [class.validation-error-highlight]="(getSubjectById(item.id)  | async)?.length > 0"
                                                [floatLabel]="item.params.floatLabel"
                                                [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                [fxFlex]="item.params.layout.size"
                                                [fxFlex.md]="item.params.layout.size"
                                                fxFlex.sm="100%"
                                                fxFlex.xs="100%"
                                        >
                                            <mat-label
                                                    [class.validation-error]="(getSubjectById(item.id)  | async)?.length > 0">
                                                {{ item.name }}
                                            </mat-label>
                                            <mat-select
                                                    [formControlName]="item.id"
                                                    [required]="item.required"
                                            >
                                                <mat-option
                                                        *ngFor="let i of item.options"
                                                        [value]="i.name">
                                                    {{ i.name }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-hint class="validation-error"
                                                      *ngIf="getSubjectById(item.id) | async as validationError">
                                                {{formatValidationMsg(validationError)}}
                                            </mat-hint>
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container
                                            *ngIf="
											item.params.searchable === true &&
											item.id === 'invoice_accountingCost' &&
											item.readOnly !== true
										"
                                    >
                                        <mat-form-field
                                                class="invoice-field"
                                                appearance="outline"
                                                *ngSwitchCase="'dropdown'"
                                                [floatLabel]="item.params.floatLabel"
                                                [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                [fxFlex]="item.params.layout.size"
                                                [fxFlex.md]="item.params.layout.size"
                                                fxFlex.sm="100%"
                                                fxFlex.xs="100%"
                                        >
                                            <mat-label>{{ item.name }}</mat-label>
                                            <mat-select
                                                    [formControlName]="item.id"
                                                    [required]="item.required"
                                                    #accountingCost
                                            >
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                            [formControl]="accountingCostFilterCtrl"
                                                            placeholderLabel="{{
															'invoiceProcessing.search' | translate
														}}"
                                                            noEntriesFoundLabel="{{
															'invoiceProcessing.noResults' | translate
														}}"
                                                    >
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option
                                                        *ngFor="let ac of filteredAccountingCost | async"
                                                        [value]="ac.name"
                                                >
                                                    {{ ac.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container
                                            *ngIf="
											item.params.searchable === true &&
											item.id === 'invoice_projectReference' &&
											item.readOnly !== true
										"
                                    >
                                        <mat-form-field
                                                class="invoice-field"
                                                appearance="outline"
                                                *ngSwitchCase="'dropdown'"
                                                [floatLabel]="item.params.floatLabel"
                                                [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                [fxFlex]="item.params.layout.size"
                                                [fxFlex.md]="item.params.layout.size"
                                                fxFlex.sm="100%"
                                                fxFlex.xs="100%"
                                        >
                                            <mat-label>{{ item.name }}</mat-label>
                                            <mat-select
                                                    [formControlName]="item.id"
                                                    [required]="item.required"

                                                    #projectReference
                                            >
                                                <mat-option>
                                                    <ngx-mat-select-search
                                                            [formControl]="projectReferenceFilterCtrl"
                                                            placeholderLabel="{{
															'invoiceProcessing.search' | translate
														}}"
                                                            noEntriesFoundLabel="{{
															'invoiceProcessing.noResults' | translate
														}}"
                                                    >
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option
                                                        *ngFor="
														let reference of filteredProjectReference | async
													"
                                                        [value]="reference.name"
                                                >
                                                    {{ reference.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container
                                            *ngIf="
											item.params.searchable === false && item.readOnly === true
										"
                                    >
                                        <mat-form-field
                                                class="invoice-field"
                                                appearance="outline"
                                                *ngSwitchCase="'dropdown'"
                                                [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                [floatLabel]="item.params.floatLabel"
                                                [fxFlex]="item.params.layout.size"
                                                [fxFlex.md]="item.params.layout.size"
                                                fxFlex.sm="100%"
                                                fxFlex.xs="100%"
                                        >
                                            <mat-label>{{ item.name }}</mat-label>
                                            <input
                                                    matInput
                                                    placeholder="Placeholder"
                                                    [type]="'text'"
                                                    [formControlName]="item.id"
                                                    [readonly]="item.readOnly"
                                                    [required]="item.required"
                                            />
                                            <mat-icon matSuffix class="inactiveIcon"
                                            >arrow_drop_down
                                            </mat-icon>
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container
                                            *ngIf="
											item.params.searchable === true &&
											item.readOnly === true &&
											item.id === 'invoice_accountingCost'
										"
                                    >
                                        <mat-form-field
                                                class="invoice-field"
                                                appearance="outline"
                                                *ngSwitchCase="'dropdown'"
                                                [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                [floatLabel]="item.params.floatLabel"
                                                [fxFlex]="item.params.layout.size"
                                                [fxFlex.md]="item.params.layout.size"
                                                fxFlex.sm="100%"
                                                fxFlex.xs="100%"
                                        >
                                            <mat-label>{{ item.name }}</mat-label>
                                            <input
                                                    matInput
                                                    placeholder="Placeholder"
                                                    [type]="'text'"
                                                    [formControlName]="item.id"
                                                    [readonly]="item.readOnly"
                                                    [required]="item.required"
                                            />
                                            <mat-icon matSuffix class="inactiveIcon"
                                            >arrow_drop_down
                                            </mat-icon>
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container
                                            *ngIf="
											item.params.searchable === true &&
											item.id === 'invoice_projectReference' &&
											item.readOnly === true
										"
                                    >
                                        <mat-form-field
                                                class="invoice-field"
                                                appearance="outline"
                                                *ngSwitchCase="'dropdown'"
                                                [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                [floatLabel]="item.params.floatLabel"
                                                [fxFlex]="item.params.layout.size"
                                                [fxFlex.md]="item.params.layout.size"
                                                fxFlex.sm="100%"
                                                fxFlex.xs="100%"
                                        >
                                            <mat-label>{{ item.name }}</mat-label>
                                            <input
                                                    matInput
                                                    placeholder="Placeholder"
                                                    [type]="'text'"
                                                    [formControlName]="item.id"
                                                    [readonly]="item.readOnly"
                                                    [required]="item.required"
                                            />
                                            <mat-icon matSuffix class="inactiveIcon">
                                                arrow_drop_down
                                            </mat-icon>
                                        </mat-form-field>
                                    </ng-container>
                                </ng-container>
                            </div>


                            <!-- invoiceLines Title -->
                            <div class="invoice-block-title">
                                <div class="invoice-block-title" fxFlex="nogrow"
                                     [ngClass]="{'validation-error': (getSubjectById('invoice.invoiceLines') | async)?.length > 0}">
                                    {{ invoiceLineMetadata?.name }}
                                </div>
                                <!-- Import purchase orders -->
                                <ng-container>
                                    <span fxFlex></span>
                                    <button mat-button
                                            mat-icon-button
                                            type="button"
                                            color="inactive"
                                            fxFlex="nogrow"
                                            (click)="fileInput.click()"
                                            *ngIf="invoiceMatchingEnable"
                                            matTooltip="{{'invoiceProcessing.uploadBR' | translate}}">
                                        <mat-icon>upload_file</mat-icon>
                                    </button>
                                    <button *ngIf="invoiceLineMetadata?.params.canAddLines === true"
                                            mat-button
                                            mat-icon-button
                                            type="button"
                                            color="inactive"
                                            fxFlex="nogrow"
                                            matTooltip="{{'invoiceProcessing.codingProposal' | translate}}"
                                            (click)="loadInvoiceCodingProposal()">
                                        <mat-icon>auto_fix_high</mat-icon>
                                    </button>
                                    <button *ngIf="invoiceLineMetadata?.params.canAddLines === true"
                                            mat-button
                                            mat-icon-button
                                            type="button"
                                            color="inactive"
                                            fxFlex="nogrow"
                                            matTooltip="{{'invoiceProcessing.importLines' | translate}}"
                                            (click)="loadInvoicePurchaseOrders()">
                                        <mat-icon>loop</mat-icon>
                                    </button>
                                </ng-container>
                            </div>

                            <div class="validation-error-global-message"
                                 *ngIf="getSubjectById('invoice.invoiceLines') | async as validationError">
                                {{formatValidationMsg(validationError)}}
                            </div>

                            <!-- invoiceLine formArray -->
                            <div formArrayName="invoiceLines" *ngIf="invoiceLineForm" fxLayout="row wrap"
                                 fxLayoutAlign="start center">

                                <div fxFlex="grow" *ngFor="let line of invoiceLineForm.controls; let i = index"
                                     [formGroupName]="i" class="invoice-line-color">
                                    <div fxLayout="row wrap"
                                         fxLayout.md="row wrap"
                                         fxLayout.sm="column"
                                         fxLayout.xs="column"
                                         fxLayoutWrap
                                         fxLayoutGap="1%"
                                         fxLayoutAlign="flex-start">
                                        <ng-container *ngFor="let field of invoiceLineMetadata?.params.lineModel"
                                                      [ngSwitch]="field.type">

                                            <app-expense-coding-field *ngSwitchCase="'expenseCoding'"
                                                                      [fxFlex]="field.size"
                                                                      [fxFlex.md]="field.size"
                                                                      fxFlex.sm="100%"
                                                                      fxFlex.xs="100%"
                                                                      [control]="invoiceLineForm.controls[i].get(field.id)"
                                                                      [field]="field"
                                                                      [expenseCodingList]="invoiceLineHelper?.expenseCodingLists.get(field.expenseCodingType)">
                                            </app-expense-coding-field>

                                            <mat-form-field *ngSwitchCase="'accountCoding'"
                                                            class="invoice-field"
                                                            [fxFlex]="field.size"
                                                            [fxFlex.md]="field.size"
                                                            fxFlex.sm="100%"
                                                            fxFlex.xs="100%">
                                                <mat-label>{{ field.label }}</mat-label>
                                                <mat-select
                                                        [formControlName]="field.id"
                                                        #accountCoding>

                                                    <mat-option>
                                                        <ngx-mat-select-search
                                                                [formControl]="accountCodingFilterCtrl"
                                                                placeholderLabel="{{'invoiceProcessing.search' | translate}}"
                                                                noEntriesFoundLabel="{{'invoiceProcessing.noResults' | translate}}"
                                                        >
                                                        </ngx-mat-select-search>
                                                    </mat-option>

                                                    <mat-option
                                                            *ngFor="let accountCoding of filteredAccountCoding | async"
                                                            [value]="accountCoding">
                                                        {{ accountCoding.code }} - {{ accountCoding.label }}
                                                    </mat-option>

                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field *ngSwitchCase="'taxCoding'"
                                                            class="invoice-field"
                                                            [fxFlex]="field.size"
                                                            [fxFlex.md]="field.size"
                                                            fxFlex.sm="100%"
                                                            fxFlex.xs="100%">
                                                <mat-label>{{ field.label }}</mat-label>
                                                <mat-select [formControlName]="field.id"
                                                            (selectionChange)="onChangeTaxCodingEvent($event.value, i)"
                                                            #taxCoding>

                                                    <mat-option>
                                                        <ngx-mat-select-search
                                                                [formControl]="taxCodingFilterCtrl"
                                                                placeholderLabel="{{'invoiceProcessing.search' | translate}}"
                                                                noEntriesFoundLabel="{{'invoiceProcessing.noResults' | translate}}"
                                                        >
                                                        </ngx-mat-select-search>
                                                    </mat-option>

                                                    <mat-option *ngFor="let taxCoding of filteredTaxCoding | async"
                                                                [value]="taxCoding">
                                                        {{ taxCoding.code }} - {{ taxCoding.label }} ({{taxCoding.rate}}
                                                        %)
                                                    </mat-option>

                                                </mat-select>
                                            </mat-form-field>


                                            <mat-form-field *ngSwitchCase="'decimal'"
                                                            class="invoice-field"
                                                            [fxFlex]="field.size"
                                                            [fxFlex.md]="field.size"
                                                            fxFlex.sm="100%"
                                                            fxFlex.xs="100%"
                                            >
                                                <input
                                                        matInput
                                                        [placeholder]="field.label"
                                                        [formControlName]="field.id"
                                                        (change)="onChangeHandler($event,i,field.attribute)"
                                                />
                                            </mat-form-field>

                                            <mat-form-field
                                                    class="invoice-field"
                                                    *ngSwitchCase="'text'"
                                                    [fxFlex]="field.size"
                                                    [fxFlex.md]="field.size"
                                                    fxFlex.sm="100%"
                                                    fxFlex.xs="100%">
                                                <input matInput
                                                       [placeholder]="field.label"
                                                       [formControlName]="field.id"
                                                />
                                            </mat-form-field>

                                        </ng-container>
                                    </div>

                                    <!-- Delete invoice line btn -->
                                    <div fxFlex fxLayoutAlign="end center">
                                        <button
                                                mat-button
                                                mat-icon-button
                                                [disabled]="invoiceLineMetadata.params.readOnly"
                                                type="button"
                                                (click)="deleteInvoiceLine(i)"
                                        >
                                            <mat-icon>clear</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <!-- Add new invoice line btn -->
                            <button *ngIf="invoiceLineForm"
                                    [disabled]="!invoiceLineMetadata?.params?.canAddLines"
                                    mat-button
                                    type="button"
                                    (click)="addInvoiceLine()"
                                    style="width: 100%;"
                            >
                                <mat-icon>add</mat-icon>
                                NOUVELLE LIGNE
                            </button>
                        </form>
                        <input hidden (change)="uploadBrFile($event.target.files)" #fileInput type="file" id="file">
                    </mat-card-content>
                </mat-card>

                <!-- Outcomes -->
                <div class="form-grid-footer">
                    <div *ngFor="let outcome of outcomes">
                        <button
                                mat-raised-button
                                *ngIf="outcome.params.primary; else secondary"
                                color="primary"
                                type="button"
                                form="invoiceForm"
                                [disabled]="isDisabled(invoiceForm, outcome)"
                                (click)="handleActionClick(outcome)">
                            {{ outcome.id }}
                        </button>

                        <ng-template #secondary>
                            <button
                                    mat-button
                                    type="button"
                                    color="primary"
                                    form="invoiceForm"
                                    [disabled]="isDisabled(invoiceForm, outcome)"
                                    (click)="handleActionClick(outcome)"
                            >
                                {{ outcome.id }}
                            </button>
                        </ng-template>
                    </div>
                </div>
            </mat-grid-tile>

            <!-- InvoiceViewer -->
            <mat-grid-tile class="document-grid-tile">
                <div #documentViewer class="document-viewer">
                    <!-- *ngIf="attachmentDetails.mimeType === 'application/pdf'" -->
                    <pdf-viewer
                            *ngIf="pdfsrc !== undefined; else imageViewer"
                            [src]="pdfsrc"
                            [autoresize]="true"
                            [original-size]="false"
                            [render-text]="false"
                    ></pdf-viewer>

                    <ng-template #imageViewer>
                        <canvas #canvas></canvas>
                    </ng-template>
                </div>
                <button
                        class="download-button"
                        mat-fab
                        color="accent"
                        (click)="downloadAttachment()"
                >
                    <mat-icon>save_alt</mat-icon>
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </mat-drawer-content>
</mat-drawer-container>
