import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-params-menu',
    templateUrl: './params-menu.component.html',
    styleUrls: ['./params-menu.component.scss']
})
export class ParamsMenuComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {
    }
}
