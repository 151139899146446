<div class="workspaces-search-bar" fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="start center">
    <span fxFlex></span>
    <mat-form-field fxFlex="400px">
        <mat-label>{{ 'workspaces.entreprise' | translate }}</mat-label>

        <input
                matInput
                (keyup)="applyFilter($event.target.value)"
                name="workspaceName"
                [(ngModel)]="workspaceNameValue"
                type="search"
                placeholder="{{ 'workspaces.find-entreprise' | translate }}"
                #input
        />
        <mat-icon matPrefix class="search-icon">search</mat-icon>
        <button
                mat-button
                mat-icon-button
                matSuffix
                aria-label="Clear"
                *ngIf="workspaceNameValue"
                (click)="clearSearchInput()"
        >
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <span fxFlex></span>
</div>


<mat-drawer-container class="workspace-drawer">

    <mat-drawer mode="side" opened>
        <div class="workspace-filter-title">{{"workspaces.type" | translate}}</div>
        <mat-selection-list class="workspace-options" [(ngModel)]="selectedResourceTypes"
                            (ngModelChange)="onSelectionChange($event)" multiple>
            <mat-list-option color="primary" value="INVOICE">
                <mat-icon matListIcon>insert_drive_file</mat-icon>
                {{"workspaces.invoice-type" | translate}}
            </mat-list-option>
            <mat-list-option color="primary" value="PURCHASE_ORDER">
                <mat-icon matListIcon>shopping_cart</mat-icon>
                {{"workspaces.po-type" | translate}}
            </mat-list-option>
        </mat-selection-list>
    </mat-drawer>

    <mat-drawer-content>

        <mat-card class="mat-elevation-z2" class="workspaces-card">
            <mat-table
                    [dataSource]="dataSource"
                    class="width-table"
                    matSort
                    matSortActive="name"
                    matSortDirection="asc"
                    matSortDisableClear
            >
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef
                                     mat-sort-header>{{ 'workspaces.name' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="myActiveTasksCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'workspaces.my-active-tasks' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.myActiveTasksCount }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="ongoingProcessesCount">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'workspaces.processes-in-progress' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row">{{ row.ongoingProcessesCount }}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        (click)="redirectToActivityOverview(row.id, row.resourceType)"
                ></mat-row>
            </mat-table>

            <mat-paginator
                #paginator
                [pageIndex]="0"
                [pageSize]="20"
                [pageSizeOptions]="[5, 10, 20, 50]"
                showFirstLastButtons
            >
            </mat-paginator>
        </mat-card>
    </mat-drawer-content>
</mat-drawer-container>
