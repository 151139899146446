import { of } from 'rxjs';
import * as i0 from "@angular/core";
export class DialogService {
    /**
     * Ask user to confirm an action. `message` explains the action and choices.
     * Returns observable resolving to `true`=confirm or `false`=cancel
     */
    confirm(message) {
        const confirmation = window.confirm(message ||
            'Vous avez des modifications non enregistrées. Souhaitez-vous continuer ?');
        return of(confirmation);
    }
}
DialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DialogService_Factory() { return new DialogService(); }, token: DialogService, providedIn: "root" });
