import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TaskDetailsRoutingModule} from './task-details-routing.module';
import {TaskClaimedComponent, TaskCompletedComponent, TaskDetailsComponent} from './task-details.component';
import {InvoicesTableComponent} from './components/invoices-table/invoices-table.component';
import {TranslateModule} from '@ngx-translate/core';

import {
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule
} from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {TaskFilterDialogComponent} from './components/filter-dialog/filter-dialog.component';

@NgModule({
    declarations: [
        TaskDetailsComponent,
        InvoicesTableComponent,
        TaskCompletedComponent,
        TaskClaimedComponent,
        TaskFilterDialogComponent
    ],
    entryComponents: [TaskCompletedComponent, TaskClaimedComponent, TaskFilterDialogComponent],
    imports: [
        CommonModule,
        TaskDetailsRoutingModule,
        /* Angular material */
        MatButtonModule,
        MatIconModule,
        MatGridListModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTabsModule,
        MatDialogModule,
        TranslateModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatDatepickerModule,
        MatInputModule
    ]
})
export class TaskDetailsModule {
}
