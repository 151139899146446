import {Injectable} from '@angular/core';
import {Observable, timer} from 'rxjs';
import {concatMap, map, retry} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AttachmentDetails} from '../../models/attachment-details.model';
import {HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest,} from '@angular/common/http';
import {Filter, UpdatePaymentStatusReq} from 'src/app/modules/invoice-explorer/invoice-explorer.component';
import * as moment from 'moment';
import {AuthenticationService} from './authentication.service';
import {Invoice} from '../../models/invoice';
import {parseDecimal} from '../../shared/formHepler';


export interface InvoiceIds {
    invoiceIds: string[];
    tenantIdentifier: string;
}


@Injectable({
    providedIn: 'root',
})
export class InvoiceService {

    readonly invoiceURL = environment.backendUrl + '/invoicestore/app/api/invoices';
    readonly invoiceIdURL = environment.backendUrl + '/invoicestore/app/api/invoiceIds';
    readonly invoiceIdsPubStatusURL = environment.backendUrl + '/invoicestore/app/api/invoices/pub-status';
    readonly attachmentURL = environment.backendUrl + '/invoicestore/app/api/attached-documents';
    readonly exportUrl = this.invoiceURL + '/download/csv';
    readonly updatePublicationStatusUrl = environment.backendUrl + '/invoicestore/app/api/invoices/update-publication-status';
    readonly fetchPublicationStatusUrl = environment.backendUrl + '/invoicestore/app/api/invoices/publication-status';
    readonly updatePaymentStatusUrl = environment.backendUrl + '/invoicestore/app/api/invoices/payment-status';
    readonly paymentStatusCountersReportURL = environment.backendUrl + '/invoicestore/app/api/invoices/reports/payment-status-counters';

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) {
    }

    updatePaymentStatus(updatePaymentStatusReq: UpdatePaymentStatusReq): Observable<any> {
        return this.http.post(this.updatePaymentStatusUrl, updatePaymentStatusReq);
    }

    clearPaymentStatus(invoiceIdArray: string[]): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: invoiceIdArray, // if needed: Convert the body to JSON string: JSON.stringify(body),
        };

        return this.http.delete(this.updatePaymentStatusUrl, httpOptions);
    }

    getPaymentStatusReport(invoiceIds: string[]): Observable<any> {
        return this.http.post(this.paymentStatusCountersReportURL, invoiceIds);
    }

    getInvoiceListFromAPI(
        sort: string,
        pageIndex: number,
        pageSize: number,
        filters: Filter[],
        onlyPublished: boolean,
        workspaceId: string
    ): Observable<any> {
        if (!sort) {
            sort = 'issueDate,desc';
        }
        let httpParams = new HttpParams()
            .set('approvalStatus.equals', 'APPROVED')
            .set('sort', sort)
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString())
            .set('workspaceId.equals', workspaceId);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        if (onlyPublished) {
            httpParams = httpParams.set('publicationStatus.equals', 'PUBLISHED');
        }
        return this.http.get<any>(this.invoiceURL, {params: httpParams});
    }

    // TODO: REMOVE THIS REQUEST FROM LOADING SPINNER
    refreshInvoicePublicationStatusWithIds(invoiceIds: string[], workspaceId: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        const request$ = this.http.post(this.fetchPublicationStatusUrl, {invoiceIds, workspaceId}, {headers});
        return timer(1_000, 20_000).pipe(
            concatMap((_) => request$),
            retry(5),
            map((response) => response)
        );
    }

    getInvoiceByIDFromAPI(invoiceId: string): Observable<Invoice> {
        const httpParams = new HttpParams().set('id.equals', invoiceId);

        return this.http.get<Invoice>(this.invoiceURL, {params: httpParams});
    }

    getInvoiceIdsFromAPI(
        filters: Filter[],
        onlyPublished,
        workspaceId: string
    ): Observable<any> {
        let httpParams = new HttpParams()
            .set('approvalStatus.equals', 'APPROVED')
            .set('workspaceId.equals', workspaceId);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        if (onlyPublished) {
            httpParams = httpParams.set('publicationStatus.equals', 'PUBLISHED');
        }
        return this.http.get<any>(this.invoiceIdURL, {params: httpParams});
    }

    buildFiltersParam(filters: Filter[], params: HttpParams): HttpParams {
        // TODO: refactor: use paramater type instead of a name
        for (const f of filters) {
            if (
                f.parameter === 'issueDate' ||
                f.parameter === 'dueDate' ||
                f.parameter === 'receiptDate' ||
                f.parameter === 'approvalDate' ||
                f.parameter === 'paymentDate'
            ) {
                params = params.set(
                    f.parameter.concat('.').concat(f.condition),
                    moment(f.value).format('YYYY-MM-DD').toString()
                );
            } else {
                params = params.set(
                    f.parameter.concat('.').concat(f.condition),
                    parseDecimal(f.value)
                );
            }
        }
        return params;
    }

    /**
     * Get specific invoiceId details
     */
    getAttachmentDetailsFromAPIAsync(
        invoiceId: string,
        documentType: string): Observable<AttachmentDetails[]> {
        const httpParams = new HttpParams()
            .set('invoiceId.equals', invoiceId)
            .set('documentType.equals', documentType);

        return this.http.get<AttachmentDetails[]>(this.attachmentURL, {
            params: httpParams,
        });
    }

    /**
     * Get specific invoiceId details
     */
    getAttachmentDetailsFromAPI(invoiceId: string, documentType: string): Promise<AttachmentDetails[]> {

        const httpParams = new HttpParams()
            .set('invoiceId.equals', invoiceId)
            .set('documentType.equals', documentType);

        return this.http.get<AttachmentDetails[]>(this.attachmentURL, {
            params: httpParams,
        }).toPromise();
    }

    /**
     *
     * Export selected invoices
     */

    exportSelectedInvoices(invoiceIds: InvoiceIds): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/csv');

        return this.http.post(
            this.exportUrl,
            invoiceIds,
            {
                headers,
                responseType: 'text'
            });
    }

    public downloadFile(invoiceId: string): Observable<HttpEvent<Blob>> {
        const myHeadears = new HttpHeaders();
        myHeadears.set('Content-Type', 'application/json');
        myHeadears.set(
            'Authorization',
            'bearer ' + this.authenticationService.getUserToken() + ''
        );
        return this.http.request(
            new HttpRequest(
                'POST',
                `${environment.backendUrl}/invoicestore/app/api/invoices/${invoiceId}/attachment-content`,
                {attachmentType: 'original-file'},
                {
                    headers: myHeadears,
                    reportProgress: true,
                    responseType: 'blob',
                }
            )
        );
    }

    matchGrIr(file: File, invoiceId: string): Observable<HttpEvent<Blob>> {

        const formdata: FormData = new FormData();

        formdata.append('file', file);
        formdata.append('invoiceId', invoiceId);

        const myHeaders = new HttpHeaders();
        myHeaders.set('Content-Type', 'application/json');
        myHeaders.set(
            'Authorization',
            'bearer ' + this.authenticationService.getUserToken() + ''
        );

        const req = new HttpRequest('POST', `${this.invoiceURL}/matchingGR`, formdata, {
            reportProgress: true,
            responseType: 'text',
            headers: myHeaders,
        });
        return this.http.request(req);
    }

    public updateInvoicePublicationStatus(invoiceIds: string[], workspaceId: string): Observable<any> {
        const headers = new HttpHeaders().set('Accept', 'application/json');
        return this.http.post(
            this.updatePublicationStatusUrl,
            {invoiceIds, workspaceId, publicationStatus: 'ONGOING'},
            {
                headers,
            });
    }


}
