<h2 mat-dialog-title>{{'invoiceExplorer.markAsPaidTitle' | translate}}</h2>

<form #paymentForm="ngForm">
    <mat-dialog-content fxLayout="row wrap" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="flex center">

        <mat-form-field fxFlex="38%" appearance="outline">
            <mat-label>{{'invoiceExplorer.paymentDate' | translate}}</mat-label>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <input matInput [matDatepicker]="picker"
                   [(ngModel)]="paymentDate"
                   name="paymentDate" [placeholder]="'invoiceExplorer.paymentDate' | translate" required>

        </mat-form-field>

        <mat-form-field fxFlex="58%" appearance="outline">
            <mat-label>{{'invoiceExplorer.paymentMeans' | translate}}</mat-label>
            <input matInput [(ngModel)]="paymentMeans" name="paymentMeans"
                   [placeholder]="'invoiceExplorer.paymentMeans' | translate">
        </mat-form-field>

        <mat-form-field fxFlex="98%" appearance="outline">
            <mat-label>{{'invoiceExplorer.paymentReference' | translate}}</mat-label>
            <input matInput [(ngModel)]="paymentReference" name="paymentReference"
                   [placeholder]="'invoiceExplorer.paymentReference' | translate">
        </mat-form-field>

        <mat-checkbox fxFlex="98%" color="primary" name="notifyByEmail"
                      (change)="updateCheckboxState($event.checked)"> {{'invoiceExplorer.notifyByEmail' | translate}} </mat-checkbox>

        <mat-form-field fxFlex="98%" appearance="outline">
            <mat-label>{{'invoiceExplorer.emailRecipients' | translate}}</mat-label>
            <app-chip-list-email-input [formControl]="emailFormControl" [disabled]="!checkboxState"
                                       [placeholder]="'invoiceExplorer.typeRecipientAddresses' | translate">
            </app-chip-list-email-input>
        </mat-form-field>


        <div class="mark-as-paid-warning" *ngIf="totalSelected > 1">
            <mat-icon fxFlex="nogrow" class="button-icon" color="warn">report_problem</mat-icon>
            <span>{{totalSelected | number}} {{'invoiceExplorer.willBeMarkedMsg' | translate}}</span>
        </div>
        <div class="mark-as-paid-warning" *ngIf="alreadyMarkedCount > 0">
            <mat-icon class="button-icon" color="warn">report_problem</mat-icon>
            <span *ngIf="alreadyMarkedCount ===1">{{alreadyMarkedCount | number}} {{'invoiceExplorer.overwritePaymentDataSingular' | translate}}</span>
            <span *ngIf="alreadyMarkedCount > 1">{{alreadyMarkedCount | number}} {{'invoiceExplorer.overwritePaymentData' | translate}}</span>
        </div>
    </mat-dialog-content>
</form>

<mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="cancel()">{{'global.cancel' | translate}}</button>
    <button mat-button color="primary" [disabled]="isNotValidForm(paymentForm)"
            (click)="save()">{{'global.confirm' | translate}}</button>
</mat-dialog-actions>
