import * as tslib_1 from "tslib";
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { InvoiceDataSource } from './invoice-data-source';
import { saveAs } from 'file-saver';
import { tap } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import * as moment from 'moment';
import { fixDateFormat } from 'src/app/shared/FormatHelper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MY_DATE_FORMATS } from '../../shared/formHepler';
import { HttpEventType } from '@angular/common/http';
import { FormControl, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
export class InvoiceExplorerComponent {
    constructor(dialog, invoiceService, authenticationService, workspaceService, router, route, translateService, snackBar) {
        this.dialog = dialog;
        this.invoiceService = invoiceService;
        this.authenticationService = authenticationService;
        this.workspaceService = workspaceService;
        this.router = router;
        this.route = route;
        this.translateService = translateService;
        this.snackBar = snackBar;
        this.displayedColumns = [
            'select',
            'publicationStatus',
            'issueDate',
            'supplierParty.name',
            'invoiceNumber',
            'taxExclusiveAmount',
            'taxAmount',
            'payableAmount',
            'documentCurrencyCode',
            'paymentStatus'
        ];
        this.snackBarDuration = 2;
        this.unpublishedOnly = false;
        this.selectable = true;
        this.filters = [];
        this.selectionInvoice = new SelectionModel(true, []);
        this.allIds = [];
        this.viewedInvoice = {
            note: '',
            status: '',
            invoiceNumber: '',
            orderReference: '',
            supplierParty: {
                name: '',
                customerAssignedAccountId: '',
                legalId: '',
                taxId: '',
                financialAccount: '',
                contactElectronicMail: '',
            },
            taxExclusiveAmount: 0,
            taxAmount: 0,
            payableAmount: 0,
            payeeFinancialAccount: '',
            documentCurrencyCode: '',
            paymentStatus: 'PAID'
        };
        this.getRouteParams();
    }
    openFilterDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '720px';
        dialogConfig.height = 'auto';
        dialogConfig.disableClose = true;
        const map = new Map();
        let publicationStatusOnGoing;
        let publicationStatusSuccess;
        let publicationStatusFailure;
        let publicationStatusEmpty;
        let paymentStatusUndefined;
        let paymentStatusPaid;
        this.translateService
            .get('global.publicationStatusOnGoing')
            .subscribe((value) => (publicationStatusOnGoing = value));
        this.translateService
            .get('global.publicationStatusSuccess')
            .subscribe((value) => (publicationStatusSuccess = value));
        this.translateService
            .get('global.publicationStatusFailure')
            .subscribe((value) => (publicationStatusFailure = value));
        this.translateService
            .get('global.publicationStatusEmpty')
            .subscribe((value) => (publicationStatusEmpty = value));
        map.set('publicationStatus', [
            { label: publicationStatusEmpty, value: 'UNPUBLISHED' },
            { label: publicationStatusOnGoing, value: 'ONGOING' },
            { label: publicationStatusSuccess, value: 'SUCCESS' },
            { label: publicationStatusFailure, value: 'FAILURE' }
        ]);
        this.translateService
            .get('invoiceExplorer.paymentStatusUndefined')
            .subscribe((value) => (paymentStatusUndefined = value));
        this.translateService
            .get('invoiceExplorer.paymentStatusPaid')
            .subscribe((value) => (paymentStatusPaid = value));
        map.set('paymentStatus', [
            { label: paymentStatusUndefined, value: 'UNDEFINED' },
            { label: paymentStatusPaid, value: 'PAID' }
        ]);
        dialogConfig.data = {
            filters: this.filters,
            source: 'invoice',
            listMap: map
        };
        const dialogRef = this.dialog.open(FilterDialogComponent, dialogConfig);
        const sorting = this.sort.active.concat(',').concat(this.sort.direction);
        dialogRef.afterClosed().subscribe((data) => {
            this.selectionInvoice.clear();
            if (data) {
                this.filters = data.filters;
                this.refreshIdsList();
                this.dataSource.loadInvoices(sorting, this.paginator.pageIndex, this.paginator.pageSize, this.filters, this.unpublishedOnly, this.workspaceId);
            }
        });
    }
    handleOnCheckBoxSelectionChange(event, row, id) {
        this.selectionInvoice.toggle(id);
    }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selectionInvoice.selected.length;
        return numSelected === this.numRows;
    }
    masterToggle() {
        this.isAllSelected() ? this.selectionInvoice.clear() : this.selectRows();
        /* this.dataSource.invoiceSubject.subscribe(
            data => data.forEach(row => this.selectionInvoice.select(row))
        );*/
    }
    selectRows() {
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < this.allIds.length; index++) {
            this.selectionInvoice.select(this.allIds[index]);
        }
        /*this.dataSource.invoiceSubject.subscribe(
            data => {
                this.selectionInvoice.clear();
                data.forEach(row => this.selectionInvoice.select(row));
            }
        );*/
    }
    isEmpty() {
        const numSelected = this.selectionInvoice.selected.length;
        return numSelected === 0;
    }
    onChange(ob) {
        this.unpublishedOnly = ob.checked;
        this.selectionInvoice.clear();
        this.refreshIdsList();
        this.loadInvoicesPage();
    }
    viewInvoiceOverview(element) {
        this.viewedInvoice = element;
        this.drawer.open();
    }
    ngOnInit() {
        this.dataSource = new InvoiceDataSource(this.invoiceService);
        this.dataSource.loadInvoices('', 0, 20, this.filters, this.unpublishedOnly, this.workspaceId);
        this.refreshIdsList();
        this.dataSource.totalOperations$.subscribe((length) => (this.numRows = length));
        // this.dataSource.invoiceIdsSubject.subscribe(
        // 	(ids: string[]) => {
        // 		if (ids && ids.length > 0) {
        // 			if (this.invoicePublicationStatusSubscription) {
        // 				this.invoicePublicationStatusSubscription.unsubscribe();
        // 			}
        // 			this.invoicePublicationStatusSubscription = this.invoiceService.refreshInvoicePublicationStatusWithIds(ids, this.workspaceId)
        // 				.subscribe(res => {
        // 					res.updatedPublicationStatus.forEach(element => {
        // 						if (this.dataSource.datasourceMap.has(element.id)) {
        // 							this.dataSource.datasourceMap.get(element.id).publicationStatus = element.publicationStatus;
        // 							this.dataSource.datasourceMap.get(element.id).publicationDate = element.publicationDate;
        // 						}
        // 					});
        // 				});
        // 		}
        // 	}
        // );
        this.sort.active = 'firstName';
        this.sort.direction = 'asc';
    }
    refreshIdsList() {
        this.invoiceService
            .getInvoiceIdsFromAPI(this.filters, this.unpublishedOnly, this.workspaceId)
            .subscribe((value) => (this.allIds = value.map(String)));
    }
    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        merge(this.sort.sortChange, this.paginator.page).subscribe(() => this.loadInvoicesPage());
        this.paginator.page.pipe(tap(() => this.loadInvoicesPage())).subscribe();
    }
    loadInvoicesPage() {
        const sorting = this.sort.active.concat(',').concat(this.sort.direction);
        this.dataSource.loadInvoices(sorting, this.paginator.pageIndex, this.paginator.pageSize, this.filters, this.unpublishedOnly, this.workspaceId);
    }
    openSnackBar(message) {
        this.snackBar.openFromComponent(MarkPaidSnackBarComponent, {
            duration: this.snackBarDuration * 1000,
            data: message,
        });
    }
    onExport() {
        const selectedIds = [];
        this.selectionInvoice.selected.forEach((elem) => selectedIds.push(elem));
        const tenantIdentifier = JSON.parse(localStorage.getItem('tenantIdentifier'));
        this.invoiceService
            .exportSelectedInvoices({ invoiceIds: selectedIds, tenantIdentifier })
            .subscribe((data) => {
            const file = new Blob([data], { type: 'text/csv;charset=utf-8' });
            const date = moment(new Date()).format('YYYY-MM-DD').toString();
            saveAs(file, 'export_facture-'.concat(date).concat('.csv'));
        });
    }
    onMarkAsPaid() {
        const selectedIds = [];
        // TODO: needed ?
        this.selectionInvoice.selected.forEach((elem) => selectedIds.push(elem));
        // get report from API
        const subscription = this.invoiceService.getPaymentStatusReport(selectedIds).subscribe(value => {
            const modalData = {
                toBeMarkedCount: value.undefinedCount,
                alreadyMarkedCount: value.paidCount,
                totalSelected: selectedIds.length
            };
            const dialogRef = this.dialog.open(MarkAsPaidModalComponent, {
                width: '720px',
                height: 'auto',
                data: modalData,
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    // Handle the saved payment data
                    this.invoiceService.updatePaymentStatus(Object.assign({ invoiceIds: selectedIds }, result)).toPromise().then(() => {
                        this.loadInvoicesPage();
                        this.selectionInvoice.clear();
                        this.translateService.get('invoiceExplorer.markAsPaidConfirmation', { invoiceCount: selectedIds.length + '' })
                            .toPromise().then((data) => this.openSnackBar(data));
                    });
                }
            });
        });
    }
    onClearPaidMarker() {
        const selectedIds = [];
        // TODO: needed ?
        this.selectionInvoice.selected.forEach((elem) => selectedIds.push(elem));
        // Prepare modal message
        let transKey = 'invoiceExplorer.';
        transKey += (selectedIds.length <= 1) ? 'clearPaidMarkerMessageSingular' : 'clearPaidMarkerMessage';
        let message = '';
        this.translateService.get(transKey, { invoiceCount: selectedIds.length + '' }).subscribe(value => message += value);
        const modalData = {
            totalSelected: selectedIds.length,
            message: message
        };
        const dialogRef = this.dialog.open(ClearPaidMarkerModalComponent, {
            width: '720px',
            height: 'auto',
            data: modalData,
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // Handle the saved payment data
                this.invoiceService.clearPaymentStatus(selectedIds).toPromise().then(() => {
                    this.loadInvoicesPage();
                    this.selectionInvoice.clear();
                    this.translateService.get('invoiceExplorer.clearPaidMarkerConfirmation', { invoiceCount: selectedIds.length + '' })
                        .toPromise().then((data) => this.openSnackBar(data));
                });
            }
        });
    }
    downloadAttachment() {
        this.getAttachmentDetailsFromApi(this.viewedInvoice.id);
        this.invoiceService
            .downloadFile(this.viewedInvoice.id)
            .subscribe((data) => {
            if (data.type === HttpEventType.Response) {
                const downloadedFile = new Blob([data.body], {
                    type: data.body.type,
                });
                const a = document.createElement('a');
                a.setAttribute('style', 'display:none;');
                document.body.appendChild(a);
                a.download = this.attachmentDetails.originalFileName;
                a.href = URL.createObjectURL(downloadedFile);
                a.target = '_blank';
                a.click();
                document.body.removeChild(a);
            }
        });
    }
    getAttachmentDetailsFromApi(invoiceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.invoiceService.getAttachmentDetailsFromAPI(invoiceId, 'original-file');
            // TODO: check response isArray !
            this.attachmentDetails = response[0];
        });
    }
    ngOnDestroy() {
        if (this.invoiceApiSubscription) {
            this.invoiceApiSubscription.unsubscribe();
        }
    }
    navigateToWorkspaceHome() {
        this.router.navigate(['workspaces', this.workspaceId]);
    }
    getRouteParams() {
        this.route.paramMap.subscribe((params) => {
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }
    fixDateFormat(date) {
        return fixDateFormat(date);
    }
}
export class MarkPaidSnackBarComponent {
    constructor(data) {
        this.data = data;
    }
}
export function emailArrayValidator() {
    return (control) => {
        let isPassed = true;
        if (Array.isArray(control.value)) {
            for (const email of control.value) {
                const innerControl = new FormControl(email, Validators.email);
                if (innerControl.errors && innerControl.errors.email) {
                    isPassed = false;
                    break;
                }
            }
        }
        else {
            isPassed = false;
        }
        return isPassed ? null : { emailArray: true };
    };
}
export function requiredArrayValidator() {
    return (control) => {
        const isPassed = Array.isArray(control.value) && control.value.length > 0;
        return isPassed ? null : { required: true };
    };
}
const ɵ0 = MY_DATE_FORMATS, ɵ1 = { useStrict: true, useUtc: true };
export class MarkAsPaidModalComponent {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.checkboxChecked$ = new Subject();
        this.checkboxState = false;
        this.emailFormControl = new FormControl({ disabled: true }, [requiredArrayValidator(), emailArrayValidator()]);
        this.separatorKeysCodes = [ENTER, COMMA];
        this.toBeMarkedCount = data.toBeMarkedCount;
        this.alreadyMarkedCount = data.alreadyMarkedCount;
        this.totalSelected = data.totalSelected;
        this.checkboxSubscription = this.getCheckboxChecked$().subscribe(state => {
            this.checkboxState = state;
        });
    }
    cancel() {
        this.dialogRef.close();
    }
    save() {
        let emailList;
        if (this.emailFormControl.valid) {
            emailList = this.emailFormControl.value;
        }
        this.dialogRef.close({
            paymentDate: this.paymentDate,
            paymentMeans: this.paymentMeans,
            paymentReference: this.paymentReference,
            recipientEmails: emailList,
        });
    }
    getCheckboxChecked$() {
        return this.checkboxChecked$.asObservable();
    }
    updateCheckboxState(checked) {
        this.checkboxChecked$.next(checked);
        this.emailFormControl.setValue(null);
        this.emailFormControl.setErrors(null);
    }
    isNotValidForm(paymentForm) {
        const emailFormCtlrInvalid = this.checkboxState && isNotNullOrUndefined(this.emailFormControl.errors);
        return !paymentForm.valid || emailFormCtlrInvalid;
    }
}
export class ClearPaidMarkerModalComponent {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.totalSelected = data.totalSelected;
    }
    cancel() {
        this.dialogRef.close();
    }
    save() {
        this.dialogRef.close({});
    }
}
export { ɵ0, ɵ1 };
