import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutModule} from '@angular/cdk/layout';
import {PoProcessingRoutingModule} from './po-processing-routing.module';
import {PoCommentActionComponent, PoProcessingComponent, PoSearchSupplierComponent} from './po-processing.component';
import {
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTooltipModule,
} from '@angular/material';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {TranslateModule} from '@ngx-translate/core';

import {ExpenseCodingFieldComponent} from './expense-coding-field.component';


@NgModule({
    declarations: [
        PoProcessingComponent,
        PoCommentActionComponent,
        PoSearchSupplierComponent,
        ExpenseCodingFieldComponent,
    ],
    entryComponents: [
        PoCommentActionComponent,
        PoSearchSupplierComponent,
    ],
    imports: [
        CommonModule,
        PoProcessingRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        LayoutModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        MatGridListModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSidenavModule,
        MatListModule,
        MatSnackBarModule,
        MatDialogModule,
        MatBadgeModule,
        NgxMatSelectSearchModule,
        TranslateModule,
    ],
    exports: [PoProcessingComponent],
})
export class PoProcessingModule {
}
