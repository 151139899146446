import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TabService implements OnDestroy {

    // tslint:disable-next-line:variable-name
    // TODO: do not disable linter rules, respect them :) or have a good reason
    private _selectedTab = new BehaviorSubject(
        this.getIndexFromLocalStorage() || 0
    );
    public readonly selectedTab$: Observable<number> = this._selectedTab.asObservable();

    constructor() {
    }

    ngOnDestroy(): void {
        localStorage.removeItem('selectedTab');
    }

    /**
     * Set the selectedTab index in localStorage and call next() on behaviorSubject.
     * If no index is stored it calls next() with 0 as default.
     * @param index selectedTab index
     */
    setSelectedIndex(index: number) {
        this.setIndexIntoLocalStorage(index);

        if (this.getIndexFromLocalStorage()) {
            this._selectedTab.next(this.getIndexFromLocalStorage());
        } else {
            this._selectedTab.next(0);
        }
    }

    /**
     * Store selectedTab index in localStorage
     * @param index the index of selectedTab
     */
    setIndexIntoLocalStorage(index: number): void {
        localStorage.setItem('selectedTab', JSON.stringify(index));
    }

    /**
     * Get the selectedTab index from the localStorage
     */
    getIndexFromLocalStorage(): number {
        return JSON.parse(localStorage.getItem('selectedTab'));
    }
}
