import {Injectable} from '@angular/core';
import {AuditService} from '../../core/services/audit-invoice.service';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {KontaFormControl} from '../../shared/KontaFormControl';
import {EMPTY_CONSTRAINT} from '../../models/validation-result';
import {isEqual} from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class AuditInvoiceValidator {
    constructor(private auditService: AuditService, private http: HttpClient) {
    }

    isValid(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {

            const currentControl = control as KontaFormControl;

            if (this.auditService.validationErrors) {

                if (currentControl.id) {
                    //
                    // this.auditService.processVariable[currentControl.id] = control.value; /////

                    const currentControlScope = currentControl.id.replace(new RegExp('_', 'g'), '.');
                    if (this.auditService.validationErrors.size > 0) {


                        const constraintSubj = this.auditService.validationErrors.get(currentControlScope);

                        if (constraintSubj && !isEqual(constraintSubj.getValue(), EMPTY_CONSTRAINT)) {
                            return of({error: true});
                        }
                        return of(null);
                    } else {
                        return of(null);
                    }

                } else {
                    return of(null);
                }
            }

        };

    }
}
