import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Subject, throwError, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { concatMap, map, retry } from 'rxjs/operators';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./authentication.service";
export class TaskService {
    constructor(http, authenticationService) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.processURL = environment.backendUrl + '/kontaflow/task/app/rest/query/user-task-definitions';
        this.tasksURL = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/mytaskswithvars';
        this.poTasksURL = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/mypotaskswithvars';
        this.exportUrl = environment.backendUrl + '/kontaflow/task/app/v2/rest/query/export';
        // environment.backendUrl + '/kontaflow/task/app/rest/query/tasks';
        this.claimURL = environment.backendUrl + '/kontaflow/task/app/rest/tasks';
        // tslint:disable-next-line:variable-name
        this._taskErrors = new Subject();
        this.taskErrors$ = this._taskErrors.asObservable();
        this.filtersChanged = new Subject();
        this.filtersChanged$ = this.filtersChanged.asObservable();
    }
    handleError(error) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        }
        else {
            switch (error.status) {
                case 400:
                    this._taskErrors.next({
                        status: error.status,
                        message: error.error.message,
                        key: error.error.messageKey
                    });
                    break;
                case 404:
                    this._taskErrors.next({
                        status: error.status,
                        message: error.error.message,
                        key: error.error.messageKey
                    });
                    break;
                default:
                    break;
            }
        }
        return throwError('An error has occurred. Try later');
    }
    getProcessDefinitionFromAPI(processId) {
        return this.http.post(this.processURL, {
            processDefinitionId: processId
        });
    }
    /**
     * Http request with timer to poll process metrics
     * @param processDefinitionId the process Id
     * @param interval the timer interval duration for polling
     */
    refreshProcessDefinitionFromAPI(workspaceId, processDefinitionId, interval) {
        const request$ = this.http.post(this.processURL, {
            workspaceId,
            processDefinitionId
        });
        return timer(1000, interval).pipe(concatMap(_ => request$), retry(5), map(response => response));
    }
    getTaskListFromAPI(workspaceId, processId, taskDefinitionKey, processDefinitionId, sortDirection, pageIndex, pageSize, filters) {
        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        httpParams = httpParams.set('page', pageIndex.toString());
        httpParams = httpParams.set('size', pageSize.toString());
        return this.http.post(this.tasksURL, {
            workspaceId,
            processId,
            taskDefinitionKey,
            processDefinitionId,
            state: 'open',
            assignment: 'myTasks',
            sort: sortDirection === 'desc' ? 'created-desc' : 'created-asc',
            includeCompleteProcessInstance: true
        }, { params: httpParams });
    }
    getPoTaskListFromAPI(workspaceId, processId, taskDefinitionKey, processDefinitionId, sortDirection, pageIndex, pageSize, filters) {
        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        httpParams = httpParams.set('page', pageIndex.toString());
        httpParams = httpParams.set('size', pageSize.toString());
        return this.http.post(this.poTasksURL, {
            workspaceId,
            processId,
            taskDefinitionKey,
            processDefinitionId,
            state: 'open',
            assignment: 'myTasks',
            sort: sortDirection === 'desc' ? 'created-desc' : 'created-asc',
            includeCompleteProcessInstance: true
        }, { params: httpParams });
    }
    getTaskByIdFromAPI(taskId) {
        return this.http.get(`${this.claimURL}/${taskId}`).toPromise();
    }
    claimAndGetTaskFromApi(taskId) {
        return this.http.put(`${this.claimURL}/${taskId}/action/claimAndGet`, {
            observe: 'response'
        });
    }
    unclaimTaskFromAPI(taskId) {
        return this.http.put(`${this.claimURL}/${taskId}/action/unclaim`, {
            observe: 'response'
        });
    }
    buildFiltersParam(filters, params) {
        for (const f of filters) {
            if (f.parameter === 'receiptDate') {
                params = params.set(f.parameter.concat('.').concat(f.condition), moment(f.value).valueOf() + '');
            }
            else {
                if (!f.value && f.condition === 'equals') {
                    params = params.set(f.parameter.concat('.specified'), 'false');
                }
                else {
                    params = params.set(f.parameter.concat('.').concat(f.condition), f.value);
                }
            }
        }
        return params;
    }
    getFiltersFromLocalStorage() {
        return JSON.parse(localStorage.getItem('filters'));
    }
    exportTasks(workspaceId, processId, taskDefinitionKey, processDefinitionId, filters) {
        const httpHeaders = new HttpHeaders();
        httpHeaders.set('Content-Type', 'application/json');
        httpHeaders.set('Authorization', 'bearer ' + this.authenticationService.getUserToken() + '');
        let httpParams = new HttpParams();
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        return this.http.request(new HttpRequest('POST', `${this.exportUrl}`, {
            workspaceId,
            processId,
            taskDefinitionKey,
            processDefinitionId,
            state: 'open',
            assignment: 'myTasks',
            includeCompleteProcessInstance: true
        }, {
            headers: httpHeaders,
            reportProgress: true,
            responseType: 'text',
            params: httpParams
        }));
    }
}
TaskService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TaskService_Factory() { return new TaskService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthenticationService)); }, token: TaskService, providedIn: "root" });
