import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutModule} from '@angular/cdk/layout';
import {MatButtonModule, MatCardModule, MatIconModule, MatInputModule, MatListModule} from '@angular/material';
import {ParamsRoutingModule} from './params-routing.module';
import {ParamsComponent} from './params.component';
import {WorkspaceInfoComponent} from './components/workspace-info/workspace-info.component';
import {ParamsMenuComponent} from './components/params-menu/params-menu.component';
import {WorkspaceCategoriesComponent} from './components/workspace-categories/workspace-categories.component';
import {WorkspaceSuppliersComponent} from './components/workspace-suppliers/workspace-suppliers.component';

@NgModule({
    declarations: [
        ParamsComponent,
        ParamsMenuComponent,
        WorkspaceInfoComponent,
        WorkspaceCategoriesComponent,
        WorkspaceSuppliersComponent
    ],
    imports: [
        CommonModule,
        ParamsRoutingModule,
        FormsModule,
        FlexLayoutModule,
        LayoutModule,
        MatCardModule,
        MatInputModule,
        MatListModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        ParamsComponent,
        WorkspaceInfoComponent,
        WorkspaceCategoriesComponent,
        WorkspaceSuppliersComponent
    ]
})
export class ParamsModule {
}
