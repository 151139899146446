import * as i0 from "@angular/core";
export class CanDeactivateGuard {
    canDeactivate(component, currentRoute, currentState, nextState) {
        if (component.submitSuccess) {
            return true;
        }
        else {
            return component.canLeaveForm();
        }
    }
}
CanDeactivateGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanDeactivateGuard_Factory() { return new CanDeactivateGuard(); }, token: CanDeactivateGuard, providedIn: "root" });
