import * as _ from 'lodash';

export function isBlank(value) {
    return _.isEmpty(value) && !_.isNumber(value) || _.isNaN(value);
}

export function notBlank(value) {
    return !isBlank(value);
}

