<mat-card class="mat-elevation-z2">
    <table
            mat-table
            [dataSource]="dataSource"
            class="width-table"
            matSort
            aria-label="Elements"
    >

        <ng-container matColumnDef="invoice_receiptDate">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.receiptDate'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{
                fixDateFormat(row.processInstanceVariables.invoice_receiptDate)| date: 'shortDate': '': translateService.currentLang
                }}
            </td>
        </ng-container>

        <ng-container matColumnDef="invoice_supplierParty_name">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.provider'| translate}}
            </th>

            <ng-container mat-cell *matCellDef="let row">
                <td
                        mat-cell
                        *ngIf="
						row.processInstanceVariables.invoice_supplierParty_name;
						else senderBlock
					"
                >
                    {{ row.processInstanceVariables.invoice_supplierParty_name }}
                </td>
                <ng-template #senderBlock>
                    <td mat-cell class="unresolved">
                        {{ row.processInstanceVariables.invoice_senderParty_name }}
                    </td>
                </ng-template>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="invoice_invoiceNumber">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.invoiceNumber'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.processInstanceVariables.invoice_invoiceNumber }}
            </td>
        </ng-container>

        <ng-container matColumnDef="invoice_orderReference">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.orderReference'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.processInstanceVariables.invoice_orderReference }}
            </td>
        </ng-container>

        <ng-container matColumnDef="invoice_issueDate">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.issueDate'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{
                fixDateFormat(row.processInstanceVariables.invoice_issueDate) | date: 'shortDate': '': translateService.currentLang
                }}
            </td>
        </ng-container>


        <ng-container matColumnDef="invoice_taxExclusiveAmount">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.taxExclusiveAmount'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.processInstanceVariables.invoice_taxExclusiveAmount | number:'': translateService.currentLang}}
            </td>
        </ng-container>

        <ng-container matColumnDef="invoice_payableAmount">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'global.payableAmount'| translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.processInstanceVariables.invoice_payableAmount | number:'': translateService.currentLang}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                (click)="navigateToInvoiceProcessing(row.id)"
        ></tr>
    </table>

    <mat-paginator
            [length]="dataSource.totalOperations$ | async"
            [pageIndex]="0"
            [pageSize]="20"
            [pageSizeOptions]="[5, 10, 20, 50]"
            showFirstLastButtons
    >
    </mat-paginator>
</mat-card>
