import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutModule} from '@angular/cdk/layout';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {InvoiceProcessingRoutingModule} from './invoice-processing-routing.module';
import {
    CommentActionComponent,
    InvoiceProcessingComponent,
    PurchaseOrderSnackBarComponent,
    SearchSupplierComponent,
} from './invoice-processing.component';
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule, MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTooltipModule,
} from '@angular/material';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {TranslateModule} from '@ngx-translate/core';
import {ExpenseCodingFieldComponent} from './components/expense-coding-field/expense-coding-field.component';
import {
    ClearPaidMarkerModalComponent,
    MarkAsPaidModalComponent,
    MarkPaidSnackBarComponent
} from '../invoice-explorer/invoice-explorer.component';
import {ChipListEmailInputComponent} from '../invoice-explorer/markaspaid/chip-list-email/chip-list-email-input.component';

@NgModule({
    declarations: [
        InvoiceProcessingComponent,
        CommentActionComponent,
        SearchSupplierComponent,
        // TODO: should move to invoiceProcessing module ts file
        MarkAsPaidModalComponent,
        ClearPaidMarkerModalComponent,
        MarkPaidSnackBarComponent,
        PurchaseOrderSnackBarComponent,
        ExpenseCodingFieldComponent,
        ChipListEmailInputComponent
    ],
    entryComponents: [
        CommentActionComponent,
        SearchSupplierComponent,
        PurchaseOrderSnackBarComponent,
        MarkAsPaidModalComponent,
        ClearPaidMarkerModalComponent
    ],
    imports: [
        CommonModule,
        InvoiceProcessingRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        LayoutModule,
        PdfViewerModule,
        /* Angular material */
        MatDatepickerModule,
        MatNativeDateModule,
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        MatGridListModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatSidenavModule,
        MatListModule,
        MatSnackBarModule,
        MatDialogModule,
        MatBadgeModule,
        NgxMatSelectSearchModule,
        TranslateModule,
        MatCheckboxModule,
        MatAutocompleteModule,
    ],
    exports: [InvoiceProcessingComponent],
})
export class InvoiceProcessingModule {
}
