import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Filter} from './invoice-inprogress-explorer.component';
import {OngoingInvoiceService} from '../../core/services/ongoing-invoice.service';
import {Task} from '../../models/task';

export class InprogressInvoiceDataSource implements DataSource<Task> {
    public invoiceSubject = new BehaviorSubject<Task[]>([]);
    private totalOperations: BehaviorSubject<number> = new BehaviorSubject(0);

    public readonly totalOperations$: Observable<number> = this.totalOperations.asObservable();

    private inProgressInvoiceServiceSubscription: Subscription;

    constructor(private inProgressInvoiceService: OngoingInvoiceService) {
    }

    connect(collectionViewer: CollectionViewer): Observable<Task[]> {
        return this.invoiceSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.invoiceSubject.complete();
        this.inProgressInvoiceServiceSubscription.unsubscribe();
    }

    public loadTasks(
        workspaceId: string,
        taskDefinitionKey: string,
        processDefinitionId: string,
        sortDirection: string,
        pageIndex: number,
        pageSize: number,
        filters: Filter[]
    ) {
        this.inProgressInvoiceServiceSubscription = this.inProgressInvoiceService
            .getTaskList(
                workspaceId,
                taskDefinitionKey,
                processDefinitionId,
                sortDirection,
                pageIndex,
                pageSize,
                filters
            )
            .pipe(catchError(() => of([])))
            .subscribe(tasks => {
                this.invoiceSubject.next(tasks.data);
                this.totalOperations.next(tasks.total);
            });
    }
}
