import { timer } from 'rxjs';
import { concatMap, map, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import * as moment from 'moment';
import { parseDecimal } from '../../shared/formHepler';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./authentication.service";
export class PoService {
    constructor(http, authenticationService) {
        this.http = http;
        this.authenticationService = authenticationService;
        this.poURL = environment.backendUrl + '/invoicestore/app/api/purchase-orders';
        this.poIdURL = environment.backendUrl + '/invoicestore/app/api/orderIds';
        this.poIdsPubStatusURL = environment.backendUrl + '/invoicestore/app/api/pos/pub-status';
        this.attachmentURL = environment.backendUrl + '/invoicestore/app/api/attached-documents';
        this.exportUrl = this.poURL + '/download/csv';
        this.updatePublicationStatusUrl = environment.backendUrl + '/invoicestore/app/api/pos/update-publication-status';
        this.fetchPublicationStatusUrl = environment.backendUrl + '/invoicestore/app/api/pos/publication-status';
    }
    getPOListFromAPI(sort, pageIndex, pageSize, filters, workspaceId, status) {
        if (!sort) {
            sort = 'orderDate,desc';
        }
        let httpParams = new HttpParams()
            .set('poLifecycleType.equals', 'MANAGED')
            .set('sort', sort)
            .set('page', pageIndex.toString())
            .set('size', pageSize.toString())
            .set('workspaceId.equals', workspaceId)
            .set('poApprovalStatus.equals', status);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        return this.http.get(this.poURL, { params: httpParams });
    }
    // TODO: REMOVE THIS REQUEST FROM LOADING SPINNER
    // maybe we should add a triggerLoadSpinner toggle params to services ?....
    refreshPOPublicationStatusWithIds(poIds, workspaceId) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        const request$ = this.http.post(this.fetchPublicationStatusUrl, { poIds, workspaceId }, { headers });
        return timer(1000, 20000).pipe(concatMap((_) => request$), retry(5), map((response) => response));
    }
    getPOByIDFromAPI(poId) {
        const httpParams = new HttpParams().set('id.equals', poId);
        return this.http.get(this.poURL, { params: httpParams });
    }
    getPOIdsFromAPI(filters, workspaceId, status) {
        let httpParams = new HttpParams()
            .set('poLifecycleType.equals', 'MANAGED')
            .set('workspaceId.equals', workspaceId)
            .set('poApprovalStatus.equals', status);
        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        return this.http.get(this.poIdURL, { params: httpParams });
    }
    buildFiltersParam(filters, params) {
        for (const f of filters) {
            if (f.parameter === 'issueDate' ||
                f.parameter === 'receiptDate' ||
                f.parameter === 'approvalDate') {
                params = params.set(f.parameter.concat('.').concat(f.condition), moment(f.value).format('YYYY-MM-DD').toString());
            }
            else {
                params = params.set(f.parameter.concat('.').concat(f.condition), parseDecimal(f.value));
            }
        }
        return params;
    }
    /**
     * Get specific poId details
     */
    getAttachmentDetailsFromAPIAsync(poId, documentType) {
        const httpParams = new HttpParams()
            .set('poId.equals', poId)
            .set('documentType.equals', documentType);
        return this.http.get(this.attachmentURL, {
            params: httpParams,
        });
    }
    /**
     * Get specific poId details
     */
    getAttachmentDetailsFromAPI(poId, documentType) {
        const httpParams = new HttpParams()
            .set('poId.equals', poId.toString())
            .set('documentType.equals', documentType);
        return this.http.get(this.attachmentURL, {
            params: httpParams,
        }).toPromise();
    }
    /**
     *
     * Export selected pos
     */
    exportSelectedPOs(poIds) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/csv');
        return this.http.post(this.exportUrl, poIds, {
            headers,
            responseType: 'text'
        });
    }
    downloadFile(orderId) {
        const myHeadears = new HttpHeaders();
        myHeadears.set('Content-Type', 'application/json');
        myHeadears.set('Authorization', 'bearer ' + this.authenticationService.getUserToken() + '');
        return this.http.request(new HttpRequest('GET', `${environment.backendUrl}/invoicestore/app/api/purchase-orders/${orderId}/pdf`, 
        // {attachmentType: 'original-file'},
        {
            headers: myHeadears,
            reportProgress: true,
            responseType: 'blob',
        }));
    }
    matchGrIr(file, poId) {
        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('poId', poId);
        const myHeaders = new HttpHeaders();
        myHeaders.set('Content-Type', 'application/json');
        myHeaders.set('Authorization', 'bearer ' + this.authenticationService.getUserToken() + '');
        const req = new HttpRequest('POST', `${this.poURL}/matchingGR`, formdata, {
            reportProgress: true,
            responseType: 'text',
            headers: myHeaders,
        });
        return this.http.request(req);
    }
    updatePOPublicationStatus(poIds, workspaceId) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/json');
        return this.http.post(this.updatePublicationStatusUrl, { poIds, workspaceId, publicationStatus: 'ONGOING' }, {
            headers,
        });
    }
}
PoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PoService_Factory() { return new PoService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AuthenticationService)); }, token: PoService, providedIn: "root" });
