<h1 mat-dialog-title>Tâche déjà traité</h1>
<div mat-dialog-content>
    <p>
        {{data.message}}
    </p>
</div>

<div mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="reloadPage()" cdkFocusInitial>
        RECHARGER LA LISTE
    </button>
    <button mat-button color="primary" mat-dialog-close>FERMER</button>
</div>
