import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService, WorkspacesService} from '../../core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSlideToggleChange, MatSort} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {AttachmentDetails} from '../../models/attachment-details.model';
import {merge, Subscription} from 'rxjs';
import {Filter} from '../invoice-explorer/invoice-explorer.component';
import {Invoice} from '../../models/invoice';
import {FilterDialogComponent} from '../invoice-explorer/filter-dialog/filter-dialog.component';
import {tap} from 'rxjs/operators';
import * as moment from 'moment';
import {HttpEventType} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {RejectedInvoiceService} from '../../core/services/rejected-invoice.service';
import {RejectedInvoiceDataSource} from './rejected-invoice-data-source';
import {TranslateService} from '@ngx-translate/core';
import {fixDateFormat} from 'src/app/shared/FormatHelper';

export interface Filter {
    parameter: string;
    condition: string;
    value: string;
}

@Component({
    selector: 'app-invoice-rejected-explorer',
    templateUrl: './invoice-rejected-explorer.component.html',
    styleUrls: ['./invoice-rejected-explorer.component.scss']
})
export class InvoiceRejectedExplorerComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('drawer', {static: false}) drawer;

    dataSource: RejectedInvoiceDataSource;
    displayedColumns = [
        'select',
        'issueDate',
        'supplierParty.name',
        'invoiceNumber',
        'taxExclusiveAmount',
        'taxAmount',
        'payableAmount',
    ];

    unpublishedOnly = false;
    public filters: Filter[] = [];
    selectionInvoice = new SelectionModel<string>(true, []);
    numRows: number;
    allIds: string[] = [];
    public attachmentDetails: AttachmentDetails;
    private invoiceApiSubscription: Subscription;
    private workspaceId: string;

    viewedInvoice: Invoice = {
        note: '',
        status: '',
        invoiceNumber: '',
        // issueDate: new Date('1-1-1970'),
        // receiptDate: new Date('1-1-1970'),
        // approvalDate: new Date('1-1-1970'),
        orderReference: '',
        supplierParty: {
            name: '',
            customerAssignedAccountId: '',
            legalId: '',
            taxId: '',
            financialAccount: '',
            contactElectronicMail: '',
        },
        taxExclusiveAmount: 0,
        taxAmount: 0,
        payableAmount: 0,
        payeeFinancialAccount: '',
    };


    constructor(
        private dialog: MatDialog,
        private rejectedInvoiceService: RejectedInvoiceService,
        private authenticationService: AuthenticationService,
        private workspaceService: WorkspacesService,
        private router: Router,
        private route: ActivatedRoute,
        public translateService: TranslateService
    ) {
        this.getRouteParams();
    }

    public openFilterDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '720px';
        dialogConfig.height = 'auto';
        dialogConfig.disableClose = true;
        dialogConfig.data = {
            filters: this.filters,
            source: 'rejected'
        };
        const dialogRef = this.dialog.open(FilterDialogComponent, dialogConfig);

        const sorting = this.sort.active.concat(',').concat(this.sort.direction);

        dialogRef.afterClosed().subscribe((data) => {
            this.selectionInvoice.clear();
            if (data) {
                this.filters = data.filters;
                this.refreshIdsList();
                this.dataSource.loadInvoices(
                    sorting,
                    this.paginator.pageIndex,
                    this.paginator.pageSize,
                    this.filters,
                    this.unpublishedOnly,
                    this.workspaceId
                );
            }
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selectionInvoice.selected.length;
        return numSelected === this.numRows;
    }

    masterToggle() {
        this.isAllSelected() ? this.selectionInvoice.clear() : this.selectRows();
    }

    private selectRows() {
        for (const item of this.allIds) {
            this.selectionInvoice.select(item);
        }
    }

    isEmpty() {
        const numSelected = this.selectionInvoice.selected.length;
        return numSelected === 0;
    }

    onChange(ob: MatSlideToggleChange) {
        this.unpublishedOnly = ob.checked;
        this.selectionInvoice.clear();
        this.refreshIdsList();
        this.loadInvoicesPage();
    }

    viewInvoiceOverview(element: Invoice) {
        this.viewedInvoice = element;
        this.drawer.open();
    }

    ngOnInit() {
        this.dataSource = new RejectedInvoiceDataSource(this.rejectedInvoiceService);
        this.dataSource.loadInvoices(
            '',
            0,
            20,
            this.filters,
            this.unpublishedOnly,
            this.workspaceId
        );
        this.refreshIdsList();

        this.dataSource.totalOperations$.subscribe(
            (length: number) => (this.numRows = length)
        );
        this.sort.active = 'firstName';
        this.sort.direction = 'asc';
    }

    refreshIdsList(): void {
        this.rejectedInvoiceService
            .getInvoiceIdsFromAPI(
                this.filters,
                this.unpublishedOnly,
                this.workspaceId
            )
            .subscribe((value) => (this.allIds = value.map(String)));
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        merge(this.sort.sortChange, this.paginator.page).subscribe(() =>
            this.loadInvoicesPage()
        );

        this.paginator.page.pipe(tap(() => this.loadInvoicesPage())).subscribe();
    }

    loadInvoicesPage(): void {
        const sorting = this.sort.active.concat(',').concat(this.sort.direction);
        this.dataSource.loadInvoices(
            sorting,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filters,
            this.unpublishedOnly,
            this.workspaceId
        );
    }

    public onExport() {
        const selectedIds: string[] = [];
        this.selectionInvoice.selected.forEach((elem) => selectedIds.push(elem));
        const tenantIdentifier = JSON.parse(localStorage.getItem('tenantIdentifier'));
        this.rejectedInvoiceService
            .exportSelectedInvoices({invoiceIds: selectedIds, tenantIdentifier})
            .subscribe((data: any) => {
                const file = new Blob([data], {type: 'text/csv;charset=utf-8'});
                const date = moment(new Date()).format('YYYY-MM-DD').toString();
                saveAs(file, 'export_facture-'.concat(date).concat('.csv'));
            });
    }

    openArchiveDialog(invoiceCount: number): void {

        let transKey = 'invoiceRejectedExplorer.archiveConfirmMessage.';
        transKey += (invoiceCount <= 1) ? 'singular' : 'plural';

        let message = '';
        this.translateService.get(transKey, {invoiceCount: invoiceCount + ''}).subscribe(
            value => message += value
        );

        // const message = `You are about to archive ${invoiceCount} invoices. Do you want to proceed anyway?`;

        const dialogRef = this.dialog.open(ArchiveDialogComponent, {
            width: '720px',
            height: 'auto',
            data: {message},
            disableClose: true
        });

        dialogRef.afterClosed().subscribe((confirmed) => {
                if (confirmed) {
                    this.onArchive();
                }
            }
        );
    }

    public onArchive() {
        const selectedIds = [];
        this.selectionInvoice.selected.forEach((elem) => selectedIds.push(elem));

        this.rejectedInvoiceService
            .archiveInvoices(selectedIds)
            .subscribe((data: any) => {
                this.selectionInvoice.clear();
                this.loadInvoicesPage();
                this.refreshIdsList();
            });
    }

    public downloadAttachment() {
        this.getAttachmentDetailsFromApi(this.viewedInvoice.id);
        this.rejectedInvoiceService
            .downloadFile(this.viewedInvoice.id)
            .subscribe((data) => {
                if (data.type === HttpEventType.Response) {
                    const downloadedFile = new Blob([data.body], {
                        type: data.body.type,
                    });
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = this.attachmentDetails.originalFileName;
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            });
    }

    private getAttachmentDetailsFromApi(invoiceId: string) {
        this.invoiceApiSubscription = this.rejectedInvoiceService
            .getAttachmentDetailsFromAPI(invoiceId, 'original-file')
            .subscribe((response) => {
                this.attachmentDetails = response[0];
            });
    }

    ngOnDestroy(): void {
        if (this.invoiceApiSubscription) {
            this.invoiceApiSubscription.unsubscribe();
        }
    }

    public navigateToWorkspaceHome() {
        this.router.navigate(['workspaces', this.workspaceId]);
    }

    private getRouteParams() {
        this.route.paramMap.subscribe((params) => {
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }


    fixDateFormat(date: any) {
        return fixDateFormat(date);
    }
}

@Component({
    selector: 'app-archive-confim-dialog',
    templateUrl: './dialogs/archive-confim-dialog.html'
})
export class ArchiveDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ArchiveDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }


}
