import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class TabService {
    constructor() {
        // tslint:disable-next-line:variable-name
        // TODO: do not disable linter rules, respect them :) or have a good reason
        this._selectedTab = new BehaviorSubject(this.getIndexFromLocalStorage() || 0);
        this.selectedTab$ = this._selectedTab.asObservable();
    }
    ngOnDestroy() {
        localStorage.removeItem('selectedTab');
    }
    /**
     * Set the selectedTab index in localStorage and call next() on behaviorSubject.
     * If no index is stored it calls next() with 0 as default.
     * @param index selectedTab index
     */
    setSelectedIndex(index) {
        this.setIndexIntoLocalStorage(index);
        if (this.getIndexFromLocalStorage()) {
            this._selectedTab.next(this.getIndexFromLocalStorage());
        }
        else {
            this._selectedTab.next(0);
        }
    }
    /**
     * Store selectedTab index in localStorage
     * @param index the index of selectedTab
     */
    setIndexIntoLocalStorage(index) {
        localStorage.setItem('selectedTab', JSON.stringify(index));
    }
    /**
     * Get the selectedTab index from the localStorage
     */
    getIndexFromLocalStorage() {
        return JSON.parse(localStorage.getItem('selectedTab'));
    }
}
TabService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TabService_Factory() { return new TabService(); }, token: TabService, providedIn: "root" });
