import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CurrencyService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = environment.backendUrl + '/invoicestore/app/api/currencies';
    }
    getCurrenciesByTenant(tenantId) {
        const httpParams = new HttpParams()
            .set('size', '10_000')
            .set('sort', 'code')
            .set('tenantId.equals', tenantId);
        return this.http.get(this.resourceUrl, { params: httpParams, observe: 'response' });
    }
}
CurrencyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CurrencyService_Factory() { return new CurrencyService(i0.ɵɵinject(i1.HttpClient)); }, token: CurrencyService, providedIn: "root" });
