import {Component, Inject, OnInit} from '@angular/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_DATE_FORMATS} from '../../../../shared/formHepler';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CrossFieldErrorMatcher} from '../../../../shared/crossFieldErrorMatcher';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Condition, Filter} from '../../../../models/filter-dialog';

// TODO: Reusable components need documentation !
@Component({
    selector: 'app-task-filter-dialog',
    templateUrl: './po-filter-dialog.component.html',
    styleUrls: ['./po-filter-dialog.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
        {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, useStrict: true}}

    ],
})
export class PoTaskFilterDialogComponent implements OnInit {
    parameters = new Map();
    form: FormGroup;
    data: Filter[];
    source: string;
    listMap = new Map<string, any[]>();
    errorMatcher = new CrossFieldErrorMatcher();
    readonly reg = /^-?[0-9]?(([.,][0-9]+)|([0-9]+([.,][0-9]+)?))$/;
    filterGroup: any;
    equals: string;
    contains: string;
    greaterThanOrEqualTo: string;
    lessThanOrEqualTo: string;

    stringConditions(): Condition[] {
        return [
            new Condition('equals', this.equals),
            new Condition('contains', this.contains),
        ];
    }

    listConditions(): Condition[] {
        return [
            new Condition('equals', this.equals)
        ];
    }

    otherTypeConditions(): Condition[] {
        return [
            new Condition('equals', this.equals),
            new Condition('greaterThanOrEqual', this.greaterThanOrEqualTo),
            new Condition('lessThanOrEqual', this.lessThanOrEqualTo),
        ];
    }

    constructor(
        private translateService: TranslateService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<PoTaskFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data
    ) {
        this.data = data.filters;
        this.source = data.source;
        this.listMap = data.listMap;
    }

    ngOnInit() {
        this.initConditions();

        const group: FormGroup[] = [];

        if (this.data.length === 0) {
            group.push(this.createFilter());
        } else {
            for (const f of this.data) {
                group.push(
                    this.fb.group({
                        parameter: new FormControl(f.parameter, [Validators.required]),
                        condition: new FormControl(f.condition, [Validators.required]),
                        value: new FormControl(f.value, [Validators.required]),
                    })
                );
            }
        }

        this.form = this.fb.group({
            filters: this.fb.array(group),
        });
        this.parameters.set('supplierName', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('supplierLegalId', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('receiptDate', {
            type: 'date',
            conditions: this.otherTypeConditions(),
        });
        this.parameters.set('issueDate', {
            type: 'date',
            conditions: this.otherTypeConditions(),
        });
        this.parameters.set('orderReference', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('taxExclusiveAmount', {
            type: 'string',
            conditions: this.otherTypeConditions(),
        });
        this.parameters.set('payableAmount', {
            type: 'string',
            conditions: this.otherTypeConditions(),
        });
        this.parameters.set('taskName', {
            type: 'list',
            conditions: this.listConditions(),
        });
        this.initFilters();
    }

    createFilter(): FormGroup {
        return this.fb.group({
            parameter: new FormControl('', [Validators.required]),
            condition: new FormControl('', [Validators.required]),
            value: new FormControl('', [Validators.required]),
        });
    }

    addItem(): void {
        this.filters.push(this.createFilter());
    }

    onSubmit() {
        if (this.isEmptyForm()) {
            this.dialogRef.close({filters: []});
        } else {
            this.dialogRef.close(this.form.value);
        }
    }

    clearFilters() {
        this.filters.clear();
        this.filters.push(this.createFilter());
    }

    clearFilter(i: number) {
        this.filters.removeAt(i);
    }

    isValidForm(): boolean {
        if (this.isEmptyForm()) {
            return true;
        } else {
            for (const c of this.filters.controls) {
                if (c.value.parameter === 'supplierName') {
                    return true;
                }
                if (!c.valid) {
                    return false;
                }
            }
            return true;
        }
    }

    isEmptyForm(): boolean {
        if (this.form.value.filters.length === 1) {
            return Object.values(this.form.value.filters[0]).every(
                (o) => o === '' || o === undefined
            );
        }
    }

    onParameterChanges(filter: any, index: number) {
        let conditionControl = new FormControl('equals', [Validators.required]);
        if (filter.value.parameter === 'supplierName') {
            conditionControl = new FormControl('contains', [Validators.required]);
        }
        this.filters.setControl(
            index,
            (this.filters[index] = this.fb.group({
                parameter: new FormControl(filter.value.parameter, [
                    Validators.required,
                ]),
                condition: conditionControl,
                value: new FormControl('', [Validators.required]),
            }))
        );
    }

    getControls(): any {
        return (this.form.get('filters') as FormArray).controls;
    }

    get filters() {
        return this.form.get('filters') as FormArray;
    }

    initConditions() {
        this.translateService
            .get('filters.equalsTo')
            .subscribe((res) => (this.equals = res));
        this.translateService
            .get('filters.contains')
            .subscribe((res) => (this.contains = res));
        this.translateService
            .get('filters.greaterThanOrEqualTo')
            .subscribe((res) => (this.greaterThanOrEqualTo = res));
        this.translateService
            .get('filters.lessThanOrEqualTo')
            .subscribe((res) => (this.lessThanOrEqualTo = res));
    }

    initFilters() {
        let fournisser: string;
        let nom: string;
        let references: string;
        let other: string;
        let orderNumber: string;
        let receiptDate: string;
        let issueDate: string;
        let amounts: string;
        let taxExclusiveAmount: string;
        let payableAmount: string;
        let taskName: string;
        let ice: string;
        // let originalFileName: string;
        this.translateService
            .get('global.legalID')
            .subscribe((value) => (ice = value));
        this.translateService
            .get('global.ongoingTask')
            .subscribe((value) => (taskName = value));
        this.translateService
            .get('global.provider')
            .subscribe((value) => (fournisser = value));
        this.translateService.get('global.name').subscribe((value) => (nom = value));
        this.translateService
            .get('global.references')
            .subscribe((value) => (references = value));
        this.translateService
            .get('global.other')
            .subscribe((value) => (other = value));
        this.translateService
            .get('global.orderNo')
            .subscribe((value) => (orderNumber = value));
        this.translateService
            .get('global.receptionDate')
            .subscribe((value) => (receiptDate = value));
        this.translateService
            .get('global.issueDate')
            .subscribe((value) => (issueDate = value));
        this.translateService
            .get('global.taxExclusiveAmount')
            .subscribe((value) => (taxExclusiveAmount = value));
        this.translateService
            .get('global.payableAmount')
            .subscribe((value) => (payableAmount = value));
        this.translateService
            .get('invoiceExplorer.amounts')
            .subscribe((value) => (amounts = value));


        this.filterGroup = [
            {
                name: fournisser,
                children: this.source === 'ongoing' ? [
                    {label: nom, value: 'supplierName'},
                    {label: ice, value: 'supplierLegalId'}
                ] : [{label: nom, value: 'supplierName'}]

            },
            {
                name: references,
                children: [
                    {label: orderNumber, value: 'orderReference'},
                ],
            },
            {
                name: 'Dates',
                children: this.source === 'ongoing' ? [{label: receiptDate, value: 'receiptDate'}]
                    : [{label: receiptDate, value: 'receiptDate'}]
            }
        ];
        if (this.source === 'ongoing') {
            this.filterGroup.push(
                {
                    name: amounts,
                    children: [
                        {label: taxExclusiveAmount, value: 'taxExclusiveAmount'},
                        {label: payableAmount, value: 'payableAmount'}
                    ],
                },
                {
                    name: other,
                    children: [
                        {label: taskName, value: 'taskName'}
                    ],
                });
        }
    }
}

