import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../core/services';

interface ISpinner {
    color: string;
    mode: string;
    value: number;
}

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnDestroy {
    public loadingSubscription: Subscription;
    public spinnerOptions: ISpinner = {
        color: 'primary',
        mode: 'indeterminate',
        value: 50
    };

    constructor(public loaderService: LoaderService) {
    }


    ngOnDestroy(): void {
        this.loadingSubscription.unsubscribe();
    }
}
