import * as tslib_1 from "tslib";
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material';
import { saveAs } from 'file-saver';
import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import * as moment from 'moment';
import { fixDateFormat } from 'src/app/shared/FormatHelper';
import { PoDataSource } from './po-data-source';
import { purchaseOrder } from '../../shared/Constants';
import { PoRejectedFilterDialogComponent } from './filter-dialog/po-rejected-filter-dialog.component';
export class PoRejectedExplorerComponent {
    constructor(dialog, poService, authenticationService, workspaceService, router, route, translateService) {
        this.dialog = dialog;
        this.poService = poService;
        this.authenticationService = authenticationService;
        this.workspaceService = workspaceService;
        this.router = router;
        this.route = route;
        this.translateService = translateService;
        this.PO_APPROVED_STATUS = 'REJECTED';
        this.displayedColumns = [
            'select',
            'orderDate',
            'supplierParty.name',
            'orderNumber',
            'taxExclusiveAmount',
            'taxAmount',
            'taxInclusiveAmount',
            'documentCurrencyCode'
        ];
        this.selectionPO = new SelectionModel(true, []);
        this.unpublishedOnly = false;
        this.selectable = true;
        this.filters = [];
        this.allIds = [];
        this.viewedPO = purchaseOrder;
        this.getRouteParams();
    }
    openFilterDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '720px';
        dialogConfig.height = 'auto';
        dialogConfig.disableClose = true;
        const map = new Map();
        let publicationStatusOnGoing;
        let publicationStatusSuccess;
        let publicationStatusFailure;
        let publicationStatusEmpty;
        this.translateService
            .get('global.publicationStatusOnGoing')
            .subscribe((value) => (publicationStatusOnGoing = value));
        this.translateService
            .get('global.publicationStatusSuccess')
            .subscribe((value) => (publicationStatusSuccess = value));
        this.translateService
            .get('global.publicationStatusFailure')
            .subscribe((value) => (publicationStatusFailure = value));
        this.translateService
            .get('global.publicationStatusEmpty')
            .subscribe((value) => (publicationStatusEmpty = value));
        map.set('publicationStatus', [{ label: publicationStatusEmpty, value: 'UNPUBLISHED' }, {
                label: publicationStatusOnGoing,
                value: 'ONGOING'
            }, { label: publicationStatusSuccess, value: 'SUCCESS' }, { label: publicationStatusFailure, value: 'FAILURE' }]);
        dialogConfig.data = {
            filters: this.filters,
            source: 'po',
            listMap: map
        };
        const dialogRef = this.dialog.open(PoRejectedFilterDialogComponent, dialogConfig);
        const sorting = this.sort.active.concat(',').concat(this.sort.direction);
        dialogRef.afterClosed().subscribe((data) => {
            this.selectionPO.clear();
            if (data) {
                this.filters = data.filters;
                this.refreshIdsList();
                this.dataSource.loadPOs(sorting, this.paginator.pageIndex, this.paginator.pageSize, this.filters, this.workspaceId, this.PO_APPROVED_STATUS);
            }
        });
    }
    handleOnCheckBoxSelectionChange(row) {
        this.selectionPO.toggle(row);
    }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selectionPO.selected.length;
        return numSelected === this.numRows;
    }
    masterToggle() {
        this.isAllSelected() ? this.selectionPO.clear() : this.selectRows();
    }
    selectRows() {
        this.dataSource.poSubject.forEach(data => data.map(po => this.selectionPO.select(po)));
    }
    isEmpty() {
        const numSelected = this.selectionPO.selected.length;
        return numSelected === 0;
    }
    onChange(ob) {
        this.unpublishedOnly = ob.checked;
        this.selectionPO.clear();
        this.refreshIdsList();
        this.loadPOsPage();
    }
    viewPOOverview(element) {
        this.viewedPO = element;
        this.drawer.open();
    }
    ngOnInit() {
        this.dataSource = new PoDataSource(this.poService);
        this.dataSource.loadPOs('', 0, 20, this.filters, this.workspaceId, this.PO_APPROVED_STATUS);
        this.refreshIdsList();
        this.dataSource.totalOperations$.subscribe((length) => (this.numRows = length));
        // this.dataSource.poIdsSubject.subscribe(
        // 	(ids: string[]) => {
        // 		if (ids && ids.length > 0) {
        // 			if (this.poPublicationStatusSubscription) {
        // 				this.poPublicationStatusSubscription.unsubscribe();
        // 			}
        // 			this.poPublicationStatusSubscription = this.poService.refreshInvoicePublicationStatusWithIds(ids, this.workspaceId)
        // 				.subscribe(res => {
        // 					res.updatedPublicationStatus.forEach(element => {
        // 						if (this.dataSource.datasourceMap.has(element.id)) {
        // 							this.dataSource.datasourceMap.get(element.id).publicationStatus = element.publicationStatus;
        // 							this.dataSource.datasourceMap.get(element.id).publicationDate = element.publicationDate;
        // 						}
        // 					});
        // 				});
        // 		}
        // 	}
        // );
        this.sort.active = 'orderDate';
        this.sort.direction = 'desc';
        this.allIds = [...this.dataSource.datasourceMap.keys()];
    }
    refreshIdsList() {
        this.poService.getPOIdsFromAPI(this.filters, this.workspaceId, this.PO_APPROVED_STATUS)
            .subscribe((value) => (this.allIds = value.map(String)));
    }
    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        merge(this.sort.sortChange, this.paginator.page).subscribe(() => this.loadPOsPage());
        this.paginator.page.pipe(tap(() => this.loadPOsPage())).subscribe();
    }
    loadPOsPage() {
        const sorting = this.sort.active.concat(',').concat(this.sort.direction);
        this.dataSource.loadPOs(sorting, this.paginator.pageIndex, this.paginator.pageSize, this.filters, this.workspaceId, this.PO_APPROVED_STATUS);
    }
    onExport() {
        const selectedIds = [];
        this.selectionPO.selected.forEach((elem) => selectedIds.push(String(elem.id)));
        const tenantIdentifier = JSON.parse(localStorage.getItem('tenantIdentifier'));
        this.poService
            .exportSelectedPOs({ poIds: selectedIds, tenantIdentifier })
            .subscribe((data) => {
            const file = new Blob([data], { type: 'text/csv;charset=utf-8' });
            const date = moment(new Date()).format('YYYY-MM-DD').toString();
            saveAs(file, 'export_facture-'.concat(date).concat('.csv'));
        });
    }
    onPublish() {
        // const selectedIds: string[] = [];
        // this.selectionInvoice.selected.forEach((elem) => {
        // 	selectedIds.push(elem);
        // });
        // this.poService
        // 	.updateInvoicePublicationStatus(selectedIds, this.workspaceId)
        // 	.subscribe((data: any) => {
        // 		data.updatedPublicationStatus.forEach(element => {
        // 			if (this.poPublicationStatusSubscription) {
        // 				this.poPublicationStatusSubscription.unsubscribe();
        // 			}
        // 			this.poPublicationStatusSubscription = this.poService.refreshInvoicePublicationStatusWithIds(this.allIds, this.workspaceId)
        // 				.subscribe(res => {
        // 					res.updatedPublicationStatus.forEach(element => {
        // 						if (this.dataSource.datasourceMap.has(element.id)) {
        // 							this.dataSource.datasourceMap.get(element.id).publicationStatus = element.publicationStatus;
        // 							this.dataSource.datasourceMap.get(element.id).publicationDate = element.publicationDate;
        // 						}
        // 					});
        // 				});
        // 		});
        // 	});
    }
    getAttachmentDetailsFromApi(poId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.poService.getAttachmentDetailsFromAPI(poId, 'original-file');
            // TODO: check response isArray !
            this.attachmentDetails = response[0];
        });
    }
    ngOnDestroy() {
        if (this.poApiSubscription) {
            this.poApiSubscription.unsubscribe();
        }
        // if (this.poPublicationStatusSubscription) {
        // 	this.poPublicationStatusSubscription.unsubscribe();
        // }
    }
    navigateToWorkspaceHome() {
        this.router.navigate(['poworkspaces', this.workspaceId]);
    }
    getRouteParams() {
        this.route.paramMap.subscribe((params) => {
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }
    fixDateFormat(date) {
        return fixDateFormat(date);
    }
}
