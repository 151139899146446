<h2 mat-dialog-title>{{'invoiceExplorer.markAsPaidTitle' | translate}}</h2>
<mat-dialog-content>
    <p>
        {{data.message}}
    </p>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="cancel()">{{'global.cancel' | translate}}</button>
    <button mat-button color="primary" (click)="save()">{{'global.confirm' | translate}}</button>
</mat-dialog-actions>
