/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bundle-downloader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./bundle-downloader.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common/http";
import * as i5 from "../../core/services/authentication.service";
var styles_BundleDownloaderComponent = [i0.styles];
var RenderType_BundleDownloaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BundleDownloaderComponent, data: {} });
export { RenderType_BundleDownloaderComponent as RenderType_BundleDownloaderComponent };
export function View_BundleDownloaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "center-container"]], null, null, null, null, null))], null, null); }
export function View_BundleDownloaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bundle-downloader", [], null, null, null, View_BundleDownloaderComponent_0, RenderType_BundleDownloaderComponent)), i1.ɵdid(1, 245760, null, 0, i2.BundleDownloaderComponent, [i3.ActivatedRoute, i4.HttpClient, i5.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BundleDownloaderComponentNgFactory = i1.ɵccf("app-bundle-downloader", i2.BundleDownloaderComponent, View_BundleDownloaderComponent_Host_0, {}, {}, []);
export { BundleDownloaderComponentNgFactory as BundleDownloaderComponentNgFactory };
