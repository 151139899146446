<mat-drawer-container hasBackdrop>
    <!-- DETAILS VIEW -->
    <mat-drawer class="invoice-details" #drawer mode="over" position="end">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px">
            <span fxFlex="40px"></span>
            <span class="invoice-details-header" fxFlex>
				<p>{{ viewedPO?.processInstanceVariables?.po_orderNumber }}</p>
			</span>

            <button (click)="drawer.close()" class="invoice-details-close-button" color="primary" fxFlex="nogrow"
                    mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <mat-divider></mat-divider>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">

            <span fxFlex="100%" class="invoice-details-title">
				<h4>{{ 'global.provider'| translate}}</h4>
			</span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_supplierParty_name">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.name'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_supplierParty_name }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_supplierParty_legalId">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.legalID'| translate}}</p></span>
                <span fxFlex="58%" class="invoice-details-value"
                      *ngIf="viewedPO?.processInstanceVariables?.po_supplierParty_legalId"><p>{{ viewedPO?.processInstanceVariables?.po_supplierParty_legalId }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_supplierParty_taxId">
                            <span fxFlex="38%"
                                  class="invoice-details-key"><p>{{ 'global.taxIdentification'| translate}}</p></span>
                            <span fxFlex="58%"
                                  class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_supplierParty_taxId}}</p></span>
                        </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_supplierParty_contactElectronicMail">
                            <span fxFlex="38%"
                                  class="invoice-details-key"><p>{{ 'global.contact'| translate}}</p></span>
                            <span fxFlex="58%"
                                  class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_supplierParty_contactElectronicMail }}</p></span>
                        </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_supplierParty_financialAccount">
                            <span fxFlex="38%"
                                  class="invoice-details-key"><p>{{ 'global.bankingInformation'| translate}}</p></span>
                            <span fxFlex="58%"
                                  class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_supplierParty_financialAccount }}</p>
                        </span>
                        </span>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">
                    <span fxFlex="100%" class="invoice-details-title">
                        <h4>{{ 'global.poInformation'| translate}}</h4>
                    </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_orderNumber">
                        <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.orderNumber'| translate}}</p></span>
                        <span fxFlex="58%"
                              class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_orderNumber }}</p></span>
                    </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_orderDate">
                        <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.orderDate'| translate}}</p></span>
                        <span fxFlex="58%"
                              class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_orderDate | date: 'mediumDate': '': translateService.currentLang }}</p></span>
                    </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_requesterDepartment">
                        <span fxFlex="38%"
                              class="invoice-details-key"><p>{{ 'po.requesterDepartment'| translate}}</p></span>
                        <span fxFlex="58%"
                              class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_requesterDepartment }}</p></span>
                    </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_deliveryDate">
                        <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.deliveryDate'| translate}}</p></span>
                        <span fxFlex="58%"
                              class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_deliveryDate | date: 'mediumDate': '': translateService.currentLang }}</p></span>
                    </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_creationDate">
                        <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.creationDate'| translate}}</p></span>
                        <span fxFlex="58%"
                              class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_creationDate | date: 'mediumDate': '': translateService.currentLang }}</p></span>
                    </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_initiatorUserId">
                        <span fxFlex="38%"
                              class="invoice-details-key"><p>{{ 'po.initiatorUserId'| translate}}</p></span>
                        <span fxFlex="58%"
                              class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_initiatorUserId }}</p></span>
                    </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_approvalDate">
                        <span fxFlex="38%" class="invoice-details-key"><p>{{ 'po.approvalDate'| translate}}</p></span>
                        <span fxFlex="58%"
                              class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_approvalDate | date: 'mediumDate': '': translateService.currentLang }}</p></span>
                    </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_note">
                        <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.note'| translate}}</p></span>
                        <span fxFlex="58%"
                              class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_note }}</p></span>
                    </span>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">
			<span fxFlex="100%" class="invoice-details-title">
				<h4>{{ 'global.amounts'| translate}}</h4>
			</span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_taxExclusiveAmount">
                <span fxFlex="38%" class="invoice-details-key"><p>{{ 'global.taxExclusiveAmount'| translate}}</p></span>
                <span fxFlex="58%"
                      class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_taxExclusiveAmount | number:'': translateService.currentLang }}</p></span>
            </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_taxAmount">
                            <span fxFlex="38%"
                                  class="invoice-details-key"><p>{{ 'global.taxAmount'| translate}}</p></span>
                            <span fxFlex="58%"
                                  class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_taxAmount | number:'': translateService.currentLang }}</p></span>
                        </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_taxInclusiveAmount">
                            <span fxFlex="38%"
                                  class="invoice-details-key"><p>{{ 'global.taxInclusiveAmount'| translate}}</p></span>
                            <span fxFlex="58%"
                                  class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_taxInclusiveAmount | number:'': translateService.currentLang }}</p></span>
                        </span>

            <span fxFlex="100%" *ngIf="viewedPO?.processInstanceVariables?.po_documentCurrencyCode">
                            <span fxFlex="38%"
                                  class="invoice-details-key"><p>{{ 'global.documentCurrencyCode'| translate}}</p></span>
                            <span fxFlex="58%"
                                  class="invoice-details-value"><p>{{ viewedPO?.processInstanceVariables?.po_documentCurrencyCode }}</p></span>
                        </span>
        </div>

    </mat-drawer>

    <!-- TABLE VIEW -->
    <mat-drawer-content>
        <mat-card class="mat-elevation-z0 mat-card-filters">
            <div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="start center">
                <button
                        fxFlex="nogrow"
                        mat-icon-button
                        color="primary"
                        aria-label="Workspace home back button"
                        (click)="navigateToWorkspaceHome()"
                >
                    <mat-icon class="back-icon">arrow_back_ios</mat-icon>
                </button>
                <span fxFlex></span>
                <span fxFlex>{{'poExplorer.numberOfOrders' | translate}} :
					<span class="filter-title">{{ numRows }}</span></span>
            </div>
        </mat-card>

        <mat-card class="mat-elevation-z2 mat-card-table">
            <mat-card-title></mat-card-title>
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="select" sticky>
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox color="primary"
                                      (change)="$event ? masterToggle() : null"
                                      [checked]="selectionPO.hasValue() && isAllSelected()"
                                      [indeterminate]="selectionPO.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox color="primary"
                                      (click)="$event.stopPropagation()"
                                      (change)="$event ? selectionPO.toggle(row.id.toString()) : null"
                                      [checked]="selectionPO.isSelected(row.id.toString())">
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="orderDate">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'po.orderDate'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{fixDateFormat(row.processInstanceVariables.po_orderDate)| date: 'shortDate': '': translateService.currentLang}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="supplierParty.name">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'global.provider'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{  row.processInstanceVariables.po_supplierParty_name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="orderNumber">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'po.orderNumber'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{ row.processInstanceVariables.po_orderNumber }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="taxExclusiveAmount">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'global.taxExclusiveAmount'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{ row.processInstanceVariables.po_taxExclusiveAmount | number:'': translateService.currentLang}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="taxAmount">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'global.taxAmount'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{  row.processInstanceVariables.po_taxAmount | number:'': translateService.currentLang}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="taxInclusiveAmount">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'global.taxInclusiveAmount'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{  row.processInstanceVariables.po_taxInclusiveAmount | number:'': translateService.currentLang}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="documentCurrencyCode">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'global.documentCurrencyCode'| translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" (click)="viewPOOverview(row)">
                        {{  row.processInstanceVariables.po_documentCurrencyCode }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{ 'global.ongoingTask'| translate}}</th>
                    <td mat-cell *matCellDef="let element"
                        (click)="viewPOOverview(element)">
                        {{ element.name }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <mat-paginator
                    [length]="dataSource.totalOperations$ | async"
                    [pageIndex]="0"
                    [pageSize]="20"
                    [pageSizeOptions]="[5, 10, 20, 50]"
                    showFirstLastButtons
            ></mat-paginator>
        </mat-card>
    </mat-drawer-content>
</mat-drawer-container>
