import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {KontaAppRoutingModule} from './konta-app-routing.module';
import {KontaAppComponent} from './konta-app.component';
import {TopBarComponent} from './components/top-bar/top-bar.component';

import {
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    declarations: [KontaAppComponent, TopBarComponent],
    imports: [
        CommonModule,
        KontaAppRoutingModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatMenuModule,
        TranslateModule
    ],
    exports: [KontaAppComponent]
})
export class KontaAppModule {
}
