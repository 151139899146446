import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FormService {
    constructor(http) {
        this.http = http;
        this.URL = environment.backendUrl + '/kontaflow/task/app/rest/task-forms';
        this.commentURL = environment.backendUrl + '/kontaflow/task/app/rest/process-instances';
        this.sendMessageURL = environment.backendUrl + '/kontaflow/task/app/rest/tasks';
        this.taxCodingsURL = environment.backendUrl + '/invoicestore/app/api/md-tax-codings';
        this.accountCodingURL = environment.backendUrl + '/invoicestore/app/api/accounting-account/';
        this.expenseCodingsURL = environment.backendUrl + '/invoicestore/app/api/md-expense-codings';
        this.invoiceLinesURL = environment.backendUrl + '/invoicestore/app/api/invoices';
        this.orderLinesURL = environment.backendUrl + '/invoicestore/app/api/purchase-orders';
        this.purchaseOrderURL = environment.backendUrl + '/invoicestore/app/api/po-copy/po-candidates';
        this.codingProposalURL = environment.backendUrl + '/invoicestore/app/api/coding-proposal';
    }
    getTaxCodingsFromAPI(legalEntityId) {
        let httpParams = {};
        if (isNotNullOrUndefined(legalEntityId)) {
            httpParams = new HttpParams().set('legalEntityId.equals', legalEntityId)
                .set('size', '10000');
        }
        else {
            httpParams = new HttpParams().set('legalEntityId.specified', 'false')
                .set('size', '10000');
        }
        return this.http.get(this.taxCodingsURL, { params: httpParams }).toPromise();
    }
    getAccountingAccounts(legalEntityId) {
        let httpParams = {};
        httpParams = new HttpParams().set('legalEntityId.equals', legalEntityId)
            .set('size', '10000');
        return this.http.get(this.accountCodingURL, { params: httpParams });
    }
    getExpenseCodings() {
        const httpParams = new HttpParams().set('size', '10000');
        return this.http.get(this.expenseCodingsURL, { params: httpParams }).toPromise();
    }
    getInvoiceFromApi(formId) {
        return this.http.get(this.URL + '/' + formId);
    }
    getInvoicePurchaseOrderFromAPI(customerAssignedAccId, orderNumber) {
        const httpParams = new HttpParams()
            .set('customerAssignedAccId', customerAssignedAccId)
            .set('orderNumber', orderNumber);
        return this.http.get(this.purchaseOrderURL, { params: httpParams });
    }
    fetchCodingProposal(workspaceId, customerAccId, supplierAccId, taxExclusiveAmount) {
        const requestBody = {
            workspaceId,
            customerPartyAccId: customerAccId,
            supplierPartyAccId: supplierAccId,
            taxExclusiveAmount
        };
        return this.http.post(this.codingProposalURL, requestBody);
    }
    submitWorkflowForm(taskId, values, formId, outcome) {
        return this.http.post(`${this.URL}/${formId}`, {
            values,
            formId: taskId,
            outcome,
        });
    }
    sendCommentsToAPI(taskId, message) {
        return this.http.post(`${this.sendMessageURL}/${taskId}/comments`, { message });
    }
    getCommentsFromAPI(processInstanceId) {
        const httpParams = new HttpParams().set('latestFirst', 'true');
        return this.http.get(`${this.commentURL}/${processInstanceId}/comments`, { params: httpParams });
    }
    getInvoiceLines(invoiceId) {
        return this.http.get(`${this.invoiceLinesURL}/${invoiceId}/invoice-lines`).toPromise();
    }
    getOrderLines(orderId) {
        return this.http.get(`${this.orderLinesURL}/${orderId}/order-lines`).toPromise();
    }
    createInvoiceLines(invoiceId, invoiceLines) {
        return this.http.post(`${this.invoiceLinesURL}/${invoiceId}/invoice-lines`, [...invoiceLines]);
    }
    createOrderLines(orderId, orderLines) {
        return this.http.post(`${this.orderLinesURL}/${orderId}/order-lines`, [...orderLines]);
    }
}
FormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormService_Factory() { return new FormService(i0.ɵɵinject(i1.HttpClient)); }, token: FormService, providedIn: "root" });
