import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
function myDateAdapter() {
    const API_DATE_FORMAT = 'YYYY-MM-DD';
    moment.locale('fr-FR');
    const date = new Date();
    const newDate = date.setDate(date.getDate() - 30);
    return moment(newDate).format(API_DATE_FORMAT);
}
export class RolesService {
    constructor(http) {
        this.http = http;
        this.MY_ROLES_URL = environment.backendUrl + '/invoicestore/app/api/my-roles';
    }
    // TODO: do not use IOutcome (form engine model)
    getMyRoles() {
        return this.http
            .get(`${this.MY_ROLES_URL}`);
    }
    // TODO: do not use IOutcome (form engine model)
    getPermissionsByWorkspace(workspaceId) {
        return this.http
            .get(`${this.MY_ROLES_URL}/${workspaceId}`);
    }
}
RolesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RolesService_Factory() { return new RolesService(i0.ɵɵinject(i1.HttpClient)); }, token: RolesService, providedIn: "root" });
