import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {AuthenticationService, WorkspacesService} from '../../core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSlideToggleChange, MatSort} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import {AttachmentDetails} from '../../models/attachment-details.model';
import {Subscription} from 'rxjs';
import {Filter} from '../invoice-explorer/invoice-explorer.component';
import {Invoice} from '../../models/invoice';
import {tap} from 'rxjs/operators';
import * as moment from 'moment';
import {HttpEventType} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {InprogressInvoiceDataSource} from './inprogress-invoice-data-source';
import {TranslateService} from '@ngx-translate/core';
import {OngoingInvoiceService} from '../../core/services/ongoing-invoice.service';
import {TaskFilterDialogComponent} from '../task-details/components/filter-dialog/filter-dialog.component';
import {fixDateFormat} from 'src/app/shared/FormatHelper';


export interface Filter {
    parameter: string;
    condition: string;
    value: string;
}

@Component({
    selector: 'app-invoice-inprogress-explorer',
    templateUrl: './invoice-inprogress-explorer.component.html',
    styleUrls: ['./invoice-inprogress-explorer.component.scss']
})
export class InvoiceInProgressExplorerComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('drawer', {static: false}) drawer;

    dataSource: InprogressInvoiceDataSource;
    displayedColumns = [
        'select',
        'issueDate',
        'supplierParty.name',
        'invoiceNumber',
        'taxExclusiveAmount',
        'taxAmount',
        'payableAmount',
        'name',
    ];

    unpublishedOnly = false;
    public filters: Filter[] = [];
    selectionInvoice = new SelectionModel<string>(true, []);
    numRows: number;
    allIds: string[] = [];
    public attachmentDetails: AttachmentDetails;
    private invoiceApiSubscription: Subscription;
    private workspaceId: string;

    viewedInvoice: any;
    public processDefinitionId: string;

    constructor(
        private dialog: MatDialog,
        private inProgressInvoiceService: OngoingInvoiceService,
        private authenticationService: AuthenticationService,
        private workspaceService: WorkspacesService,
        private router: Router,
        private route: ActivatedRoute,
        public translateService: TranslateService
    ) {
        this.getRouteParams();
    }

    public openFilterDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '720px';
        dialogConfig.height = 'auto';
        dialogConfig.disableClose = true;
        const map = new Map<string, string[]>();
        this.inProgressInvoiceService.getTaskNames(this.workspaceId, this.processDefinitionId).subscribe(resp => {
            map.set('taskName', resp.data);
        });

        dialogConfig.data = {
            filters: this.filters,
            source: 'ongoing',
            listMap: map
        };

        const dialogRef = this.dialog.open(TaskFilterDialogComponent, dialogConfig);


        dialogRef.afterClosed().subscribe((data) => {
            this.selectionInvoice.clear();
            if (data) {
                this.filters = data.filters;
                this.dataSource.loadTasks(
                    this.workspaceId,
                    '',
                    this.processDefinitionId,
                    null,
                    this.paginator.pageIndex,
                    this.paginator.pageSize,
                    this.filters
                );

                this.refreshIdsList();
            }
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selectionInvoice.selected.length;
        return numSelected === this.numRows;
    }

    masterToggle() {
        this.isAllSelected() ? this.selectionInvoice.clear() : this.selectRows();
    }

    private selectRows() {
        for (const item of this.allIds) {
            this.selectionInvoice.select(item);
        }
    }

    isEmpty() {
        const numSelected = this.selectionInvoice.selected.length;
        return numSelected === 0;
    }

    onChange(ob: MatSlideToggleChange) {
        this.unpublishedOnly = ob.checked;
        this.selectionInvoice.clear();
        this.refreshIdsList();
        this.loadInvoicesPage();
    }

    // TODO: should be viewInvoiceOverview(element: Task)
    viewInvoiceOverview(element: Invoice) {
        this.viewedInvoice = element;
        this.drawer.open();
    }

    ngOnInit() {
        this.workspaceService
            .getWorkspaceProcessDefinitionId(this.workspaceId)
            .subscribe(response => {
                this.processDefinitionId = response;
                this.dataSource.loadTasks(
                    this.workspaceId,
                    '',
                    this.processDefinitionId,
                    'asc',
                    0,
                    20,
                    this.filters
                );
                this.refreshIdsList();
            });

        this.dataSource = new InprogressInvoiceDataSource(this.inProgressInvoiceService);

        this.dataSource.totalOperations$.subscribe(
            (length: number) => (this.numRows = length)
        );

    }

    refreshIdsList(): void {
        this.inProgressInvoiceService.getTaskIds(
            this.workspaceId,
            '',
            this.processDefinitionId,
            null,
            this.filters
        ).subscribe(data => this.allIds = data.map(String));
    }

    ngAfterViewInit() {
        this.paginator.page.pipe(tap(() => this.loadInvoicesPage())).subscribe();
    }

    loadInvoicesPage(): void {
        this.dataSource.loadTasks(
            this.workspaceId,
            '',
            this.processDefinitionId,
            null,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filters
        );
    }

    public onExport() {
        const selectedTaskIds: string[] = [];
        this.selectionInvoice.selected.forEach((elem) => {
            selectedTaskIds.push(elem);
        });
        const tenantIdentifier = JSON.parse(localStorage.getItem('tenantIdentifier'));

        this.inProgressInvoiceService
            .exportSelectedTasks({taskIds: selectedTaskIds, tenantIdentifier})
            .subscribe((data: any) => {
                // if (data.type === HttpEventType.Response) {
                const file = new Blob([data], {type: 'text/csv;charset=utf-8'});
                const date = moment(new Date()).format('YYYY-MM-DD').toString();
                saveAs(file, 'export_ongoing-'.concat(date).concat('.csv'));
                // }
            });
    }

    public onCancel() {
        const selectedIds: string[] = [];
        this.selectionInvoice.selected.forEach((elem) => selectedIds.push(elem));
        let transKey = 'ongoingTask.';
        transKey += (selectedIds.length <= 1) ? 'singular' : 'plural';

        let message = '';
        this.translateService.get(transKey, {invoiceCount: selectedIds.length + ''}).subscribe(
            value => message += value
        );

        // const message = `You are about to archive ${invoiceCount} invoices. Do you want to proceed anyway?`;

        const dialogRef = this.dialog.open(ArchiveInProgressDialogComponent, {
            width: '720px',
            height: 'auto',
            data: {message},
            disableClose: true
        });

        dialogRef.afterClosed().subscribe((confirmed) => {
                if (confirmed) {
                    this.inProgressInvoiceService.cancelProcesses(selectedIds).subscribe(() => {
                        this.selectionInvoice.clear();
                        this.loadInvoicesPage();
                    });
                }
            }
        );
    }

    public downloadAttachment(invoiceId: string) {
        this.getAttachmentDetailsFromApi(invoiceId);
        this.inProgressInvoiceService
            .downloadFile(invoiceId)
            .subscribe((data) => {
                if (data.type === HttpEventType.Response) {
                    const downloadedFile = new Blob([data.body], {
                        type: data.body.type,
                    });
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = this.attachmentDetails.originalFileName;
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            });
    }

    private getAttachmentDetailsFromApi(invoiceId: string) {
        this.invoiceApiSubscription = this.inProgressInvoiceService
            .getAttachmentDetailsFromAPI(invoiceId, 'original-file')
            .subscribe((response) => {
                this.attachmentDetails = response[0];
            });
    }

    ngOnDestroy(): void {
        if (this.invoiceApiSubscription) {
            this.invoiceApiSubscription.unsubscribe();
        }
    }

    public navigateToWorkspaceHome() {
        this.router.navigate(['workspaces', this.workspaceId]);
    }

    private getRouteParams() {
        this.route.paramMap.subscribe((params) => {
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }

    fixDateFormat(date: any) {
        return fixDateFormat(date);
    }
}

@Component({
    selector: 'app-archive-confim-dialog',
    templateUrl: './dialogs/archive-confim-dialog.html'
})
export class ArchiveInProgressDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ArchiveInProgressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }


}
