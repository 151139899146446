import {SelectionModel} from '@angular/cdk/collections';
import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {MatCheckboxChange, MatSlideToggleChange, MatSort} from '@angular/material';
import {Invoice} from 'src/app/models/invoice';
import {FilterDialogComponent} from './filter-dialog/filter-dialog.component';
import {InvoiceDataSource} from './invoice-data-source';
import {InvoiceService} from 'src/app/core/services/invoice.service';
import {saveAs} from 'file-saver';
import {tap} from 'rxjs/operators';
import {merge, Subject, Subscription} from 'rxjs';
import * as moment from 'moment';
import {AttachmentDetails} from '../../models/attachment-details.model';
import {AuthenticationService, WorkspacesService} from '../../core/services';
import {TranslateService} from '@ngx-translate/core';
import {fixDateFormat} from 'src/app/shared/FormatHelper';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_DATE_FORMATS} from '../../shared/formHepler';
import {HttpEventType} from '@angular/common/http';
import {AbstractControl, FormControl, NgForm, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

export interface Filter {
    parameter: string;
    condition: string;
    value: string;
}

export interface MarkAsPaidModalData {
    paymentDate?: Date;
    paymentMeans?: string;
    paymentReference?: string;
    toBeMarkedCount?: number;
    alreadyMarkedCount?: number;
    totalSelected?: number;
}

export interface ClearPaidMarkerModalData {
    totalSelected?: number;
    message?: string;
}

export interface UpdatePaymentStatusReq {
    invoiceIds?: string[];
    paymentDate?: Date;
    paymentMeans?: string;
    paymentReference?: string;
    recipientEmails?: string[];
}

@Component({
    selector: 'app-invoice-explorer',
    templateUrl: './invoice-explorer.component.html',
    styleUrls: ['./invoice-explorer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InvoiceExplorerComponent
    implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private dialog: MatDialog,
        private invoiceService: InvoiceService,
        private authenticationService: AuthenticationService,
        private workspaceService: WorkspacesService,
        private router: Router,
        private route: ActivatedRoute,
        public translateService: TranslateService,
        private snackBar: MatSnackBar
    ) {
        this.getRouteParams();
    }

    //  @ViewChild(SelectAutocompleteComponent, {static: false}) multiSelect: SelectAutocompleteComponent;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('drawer', {static: false}) drawer;

    dataSource: InvoiceDataSource;
    displayedColumns = [
        'select',
        'publicationStatus',
        'issueDate',
        'supplierParty.name',
        'invoiceNumber',
        'taxExclusiveAmount',
        'taxAmount',
        'payableAmount',
        'documentCurrencyCode',
        'paymentStatus'
    ];
    private readonly snackBarDuration = 2;
    unpublishedOnly = false;
    selectable = true;
    public filters: Filter[] = [];
    selectionInvoice = new SelectionModel<string>(true, []);
    numRows: number;
    allIds: string[] = [];
    public attachmentDetails: AttachmentDetails;
    private invoiceApiSubscription: Subscription;
    private workspaceId: string;
    viewedInvoice: Invoice = {
        note: '',
        status: '',
        invoiceNumber: '',
        orderReference: '',
        supplierParty: {
            name: '',
            customerAssignedAccountId: '',
            legalId: '',
            taxId: '',
            financialAccount: '',
            contactElectronicMail: '',
        },
        taxExclusiveAmount: 0,
        taxAmount: 0,
        payableAmount: 0,
        payeeFinancialAccount: '',
        documentCurrencyCode: '',
        paymentStatus: 'PAID'
    };

    openFilterDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '720px';
        dialogConfig.height = 'auto';
        dialogConfig.disableClose = true;
        const map = new Map<string, any[]>();
        let publicationStatusOnGoing: string;
        let publicationStatusSuccess: string;
        let publicationStatusFailure: string;
        let publicationStatusEmpty: string;

        let paymentStatusUndefined: string;
        let paymentStatusPaid: string;

        this.translateService
            .get('global.publicationStatusOnGoing')
            .subscribe((value) => (publicationStatusOnGoing = value));
        this.translateService
            .get('global.publicationStatusSuccess')
            .subscribe((value) => (publicationStatusSuccess = value));
        this.translateService
            .get('global.publicationStatusFailure')
            .subscribe((value) => (publicationStatusFailure = value));
        this.translateService
            .get('global.publicationStatusEmpty')
            .subscribe((value) => (publicationStatusEmpty = value));
        map.set('publicationStatus',
            [
                {label: publicationStatusEmpty, value: 'UNPUBLISHED'},
                {label: publicationStatusOnGoing, value: 'ONGOING'},
                {label: publicationStatusSuccess, value: 'SUCCESS'},
                {label: publicationStatusFailure, value: 'FAILURE'}
            ]);

        this.translateService
            .get('invoiceExplorer.paymentStatusUndefined')
            .subscribe((value) => (paymentStatusUndefined = value));
        this.translateService
            .get('invoiceExplorer.paymentStatusPaid')
            .subscribe((value) => (paymentStatusPaid = value));

        map.set('paymentStatus',
            [
                {label: paymentStatusUndefined, value: 'UNDEFINED'},
                {label: paymentStatusPaid, value: 'PAID'}
            ]);

        dialogConfig.data = {
            filters: this.filters,
            source: 'invoice',
            listMap: map
        };
        const dialogRef = this.dialog.open(FilterDialogComponent, dialogConfig);

        const sorting = this.sort.active.concat(',').concat(this.sort.direction);

        dialogRef.afterClosed().subscribe((data) => {
            this.selectionInvoice.clear();
            if (data) {
                this.filters = data.filters;
                this.refreshIdsList();
                this.dataSource.loadInvoices(
                    sorting,
                    this.paginator.pageIndex,
                    this.paginator.pageSize,
                    this.filters,
                    this.unpublishedOnly,
                    this.workspaceId
                );
            }
        });
    }

    public handleOnCheckBoxSelectionChange(event: MatCheckboxChange, row: Invoice, id: string): void {
        this.selectionInvoice.toggle(id);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selectionInvoice.selected.length;
        return numSelected === this.numRows;
    }

    masterToggle() {
        this.isAllSelected() ? this.selectionInvoice.clear() : this.selectRows();
        /* this.dataSource.invoiceSubject.subscribe(
            data => data.forEach(row => this.selectionInvoice.select(row))
        );*/
    }

    private selectRows() {
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < this.allIds.length; index++) {
            this.selectionInvoice.select(this.allIds[index]);
        }
        /*this.dataSource.invoiceSubject.subscribe(
            data => {
                this.selectionInvoice.clear();
                data.forEach(row => this.selectionInvoice.select(row));
            }
        );*/
    }

    isEmpty() {
        const numSelected = this.selectionInvoice.selected.length;
        return numSelected === 0;
    }

    onChange(ob: MatSlideToggleChange) {
        this.unpublishedOnly = ob.checked;
        this.selectionInvoice.clear();
        this.refreshIdsList();
        this.loadInvoicesPage();
    }

    viewInvoiceOverview(element: Invoice) {
        this.viewedInvoice = element;
        this.drawer.open();
    }

    ngOnInit() {
        this.dataSource = new InvoiceDataSource(this.invoiceService);
        this.dataSource.loadInvoices(
            '',
            0,
            20,
            this.filters,
            this.unpublishedOnly,
            this.workspaceId
        );
        this.refreshIdsList();

        this.dataSource.totalOperations$.subscribe(
            (length: number) => (this.numRows = length)
        );
        // this.dataSource.invoiceIdsSubject.subscribe(
        // 	(ids: string[]) => {
        // 		if (ids && ids.length > 0) {
        // 			if (this.invoicePublicationStatusSubscription) {
        // 				this.invoicePublicationStatusSubscription.unsubscribe();
        // 			}
        // 			this.invoicePublicationStatusSubscription = this.invoiceService.refreshInvoicePublicationStatusWithIds(ids, this.workspaceId)
        // 				.subscribe(res => {
        // 					res.updatedPublicationStatus.forEach(element => {
        // 						if (this.dataSource.datasourceMap.has(element.id)) {
        // 							this.dataSource.datasourceMap.get(element.id).publicationStatus = element.publicationStatus;
        // 							this.dataSource.datasourceMap.get(element.id).publicationDate = element.publicationDate;
        // 						}
        // 					});
        // 				});
        // 		}
        // 	}
        // );
        this.sort.active = 'firstName';
        this.sort.direction = 'asc';
    }

    refreshIdsList(): void {
        this.invoiceService
            .getInvoiceIdsFromAPI(
                this.filters,
                this.unpublishedOnly,
                this.workspaceId
            )
            .subscribe((value) => (this.allIds = value.map(String)));
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        merge(this.sort.sortChange, this.paginator.page).subscribe(() =>
            this.loadInvoicesPage()
        );

        this.paginator.page.pipe(tap(() => this.loadInvoicesPage())).subscribe();
    }

    loadInvoicesPage(): void {
        const sorting = this.sort.active.concat(',').concat(this.sort.direction);
        this.dataSource.loadInvoices(
            sorting,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filters,
            this.unpublishedOnly,
            this.workspaceId
        );
    }

    public openSnackBar(message: string) {
        this.snackBar.openFromComponent(MarkPaidSnackBarComponent, {
            duration: this.snackBarDuration * 1000,
            data: message,
        });
    }

    onExport() {
        const selectedIds: string[] = [];
        this.selectionInvoice.selected.forEach((elem) => selectedIds.push(elem));
        const tenantIdentifier = JSON.parse(localStorage.getItem('tenantIdentifier'));
        this.invoiceService
            .exportSelectedInvoices({invoiceIds: selectedIds, tenantIdentifier})
            .subscribe((data: any) => {
                const file = new Blob([data], {type: 'text/csv;charset=utf-8'});
                const date = moment(new Date()).format('YYYY-MM-DD').toString();
                saveAs(file, 'export_facture-'.concat(date).concat('.csv'));
            });
    }

    onMarkAsPaid() {
        const selectedIds: string[] = [];
        // TODO: needed ?
        this.selectionInvoice.selected.forEach((elem) => selectedIds.push(elem));

        // get report from API
        const subscription = this.invoiceService.getPaymentStatusReport(selectedIds).subscribe(
            value => {
                const modalData: MarkAsPaidModalData = {
                    toBeMarkedCount: value.undefinedCount,
                    alreadyMarkedCount: value.paidCount,
                    totalSelected: selectedIds.length
                };

                const dialogRef = this.dialog.open(MarkAsPaidModalComponent, {
                    width: '720px',
                    height: 'auto',
                    data: modalData,
                    disableClose: true,
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        // Handle the saved payment data
                        this.invoiceService.updatePaymentStatus(
                            {
                                invoiceIds: selectedIds,
                                ...result
                            }
                        ).toPromise().then(
                            () => {
                                this.loadInvoicesPage();
                                this.selectionInvoice.clear();
                                this.translateService.get('invoiceExplorer.markAsPaidConfirmation', {invoiceCount: selectedIds.length + ''})
                                    .toPromise().then((data) => this.openSnackBar(data));
                            }
                        );

                    }
                });

            }
        );
    }

    onClearPaidMarker() {
        const selectedIds: string[] = [];
        // TODO: needed ?
        this.selectionInvoice.selected.forEach((elem) => selectedIds.push(elem));

        // Prepare modal message
        let transKey = 'invoiceExplorer.';
        transKey += (selectedIds.length <= 1) ? 'clearPaidMarkerMessageSingular' : 'clearPaidMarkerMessage';

        let message = '';
        this.translateService.get(transKey, {invoiceCount: selectedIds.length + ''}).subscribe(
            value => message += value
        );


        const modalData: ClearPaidMarkerModalData = {
            totalSelected: selectedIds.length,
            message: message
        };

        const dialogRef = this.dialog.open(ClearPaidMarkerModalComponent, {
            width: '720px',
            height: 'auto',
            data: modalData,
            disableClose: true,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                // Handle the saved payment data
                this.invoiceService.clearPaymentStatus(selectedIds).toPromise().then(
                    () => {
                        this.loadInvoicesPage();
                        this.selectionInvoice.clear();
                        this.translateService.get('invoiceExplorer.clearPaidMarkerConfirmation', {invoiceCount: selectedIds.length + ''})
                            .toPromise().then((data) => this.openSnackBar(data));
                    }
                );

            }
        });


    }


    downloadAttachment() {
        this.getAttachmentDetailsFromApi(this.viewedInvoice.id);
        this.invoiceService
            .downloadFile(this.viewedInvoice.id)
            .subscribe((data) => {
                if (data.type === HttpEventType.Response) {
                    const downloadedFile = new Blob([data.body], {
                        type: data.body.type,
                    });
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = this.attachmentDetails.originalFileName;
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            })
        ;
    }

    private async getAttachmentDetailsFromApi(invoiceId: string) {
        const response = await this.invoiceService.getAttachmentDetailsFromAPI(invoiceId, 'original-file');
        // TODO: check response isArray !
        this.attachmentDetails = response[0];
    }

    ngOnDestroy(): void {
        if (this.invoiceApiSubscription) {
            this.invoiceApiSubscription.unsubscribe();
        }
    }

    public navigateToWorkspaceHome() {
        this.router.navigate(['workspaces', this.workspaceId]);
    }

    private getRouteParams() {
        this.route.paramMap.subscribe((params) => {
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }

    fixDateFormat(date: Date): string {
        return fixDateFormat(date);
    }
}


@Component({
    selector: 'app-mark-paid-snack-bar',
    templateUrl: './markaspaid/mark-paid-snack-bar.html'
})
export class MarkPaidSnackBarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {
    }
}

export function emailArrayValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let isPassed = true;

        if (Array.isArray(control.value)) {
            for (const email of control.value) {
                const innerControl: FormControl = new FormControl(
                    email,
                    Validators.email
                );
                if (innerControl.errors && innerControl.errors.email) {
                    isPassed = false;
                    break;
                }
            }
        } else {
            isPassed = false;
        }
        return isPassed ? null : {emailArray: true};
    };
}

export function requiredArrayValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const isPassed: boolean =
            Array.isArray(control.value) && control.value.length > 0;
        return isPassed ? null : {required: true};
    };
}

@Component({
    selector: 'app-payment-dialog',
    templateUrl: './markaspaid/markaspaid-modal.html',
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {useStrict: true, useUtc: true},
        }
    ],
})
export class MarkAsPaidModalComponent {
    paymentDate: Date;
    paymentMeans: string;
    paymentReference: string;
    toBeMarkedCount: number;
    alreadyMarkedCount: number;
    totalSelected: number;
    checkboxChecked$ = new Subject<boolean>();
    private checkboxSubscription: Subscription;
    checkboxState = false;
    public emailFormControl: FormControl = new FormControl(
        {disabled: true},
        [requiredArrayValidator(), emailArrayValidator()]
    );

    public readonly separatorKeysCodes: number[] = [ENTER, COMMA];


    constructor(
        public dialogRef: MatDialogRef<MarkAsPaidModalComponent, UpdatePaymentStatusReq>,
        @Inject(MAT_DIALOG_DATA) public data: MarkAsPaidModalData
    ) {
        this.toBeMarkedCount = data.toBeMarkedCount;
        this.alreadyMarkedCount = data.alreadyMarkedCount;
        this.totalSelected = data.totalSelected;
        this.checkboxSubscription = this.getCheckboxChecked$().subscribe(
            state => {
                this.checkboxState = state;
            }
        );
    }

    cancel(): void {
        this.dialogRef.close();
    }

    save(): void {
        let emailList;
        if (this.emailFormControl.valid) {
            emailList = this.emailFormControl.value;
        }

        this.dialogRef.close({
            paymentDate: this.paymentDate,
            paymentMeans: this.paymentMeans,
            paymentReference: this.paymentReference,
            recipientEmails: emailList,
        });
    }

    public getCheckboxChecked$() {
        return this.checkboxChecked$.asObservable();
    }

    public updateCheckboxState(checked: boolean) {
        this.checkboxChecked$.next(checked);
        this.emailFormControl.setValue(null);
        this.emailFormControl.setErrors(null);
    }

    public isNotValidForm(paymentForm: NgForm) {
        const emailFormCtlrInvalid = this.checkboxState && isNotNullOrUndefined(this.emailFormControl.errors);
        return !paymentForm.valid || emailFormCtlrInvalid;
    }
}

@Component({
    selector: 'app-clear-paid-marker-modal',
    templateUrl: './markaspaid/clear-paid-marker-modal.html',
})
export class ClearPaidMarkerModalComponent {
    totalSelected: number;
    message: string;

    constructor(
        public dialogRef: MatDialogRef<ClearPaidMarkerModalData, UpdatePaymentStatusReq>,
        @Inject(MAT_DIALOG_DATA) public data: ClearPaidMarkerModalData
    ) {
        this.totalSelected = data.totalSelected;
    }

    cancel(): void {
        this.dialogRef.close();
    }

    save(): void {
        this.dialogRef.close({});
    }
}
