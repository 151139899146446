import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {environment} from '../../../environments/environment';
import {concatMap, map, retry, switchMap, toArray} from 'rxjs/operators';
import {TaskService} from './task.service';
import {ConsolidatedWorkspace, Workspace, WorkspaceStats} from '../../models/workspaces.model';

function myDateAdapter(): string {
    const API_DATE_FORMAT = 'YYYY-MM-DD';
    moment.locale('fr-FR');
    const date = new Date();
    const newDate = date.setDate(date.getDate() - 30);
    return moment(newDate).format(API_DATE_FORMAT);
}

@Injectable({providedIn: 'root'})
export class WorkspacesService {

    constructor(private http: HttpClient, private taskService$: TaskService) {
    }

    readonly poURL = environment.backendUrl + '/invoicestore/app/api/purchase-orders';
    readonly MY_WORKSPACES_URL = environment.backendUrl + '/invoicestore/app/api/my-workspaces';
    readonly WORKSPACE_STATS_URL = environment.backendUrl + '/kontaflow/task/app/rest/query/process-metrics';
    readonly WORKSPACE_STATS_URL_2 = environment.backendUrl + '/invoicestore/app/api/invoices?size=1';
    readonly INVOICESTORE_STATS_URL = environment.backendUrl + '/invoicestore/app/api/invoice-uploads/count';

    public workspaceId = new BehaviorSubject<string>('');

    public readonly workspaceId$ = this.workspaceId.asObservable();

    private static toCommaSeparated(arr: string[]): string {
        if (arr) {
            return arr.join(',');
        }
        return '';
    }

    setWorkspaceId(workspaceId: string): void {
        this.workspaceId.next(workspaceId);
    }

    getWorkspace(workspaceId: string): Observable<Workspace> {
        return this.http
            .get<Workspace>(`${this.MY_WORKSPACES_URL}/${workspaceId}`)
            .pipe(map((response) => {
                localStorage.setItem('tenantIdentifier', JSON.stringify(response.tenantIdentifier));
                localStorage.setItem('legalEntityId', JSON.stringify(response.legalEntityId));
                return response;
            }));
    }

    getWorkspaceName(workspaceId: string): Observable<any> {
        return this.http
            .get<Workspace>(`${this.MY_WORKSPACES_URL}/${workspaceId}`)
            .pipe(map((response) => {
                localStorage.setItem('tenantIdentifier', JSON.stringify(response.tenantIdentifier));
                localStorage.setItem('legalEntityId', JSON.stringify(response.legalEntityId));
                return response.name;
            }));
    }

    getWorkspaceProcessDefinitionId(workspaceId: string): Observable<any> {
        return this.http
            .get<any>(`${this.MY_WORKSPACES_URL}/${workspaceId}`)
            .pipe(map((response) => response.processDefId));
    }

    getWorkspacesList(resourceTypes: string[]): Observable<ConsolidatedWorkspace[]> {

        const value = WorkspacesService.toCommaSeparated(resourceTypes);
        const httpParams = new HttpParams()
            .set('resourceTypes', value);

        return this.http.get<any>(this.MY_WORKSPACES_URL, {params: httpParams})
            .pipe(
                switchMap((response: any) => response.content),
                map((item: any) => {
                    this.getWorkspaceStatsFromAPI(item.id, item.processDefId).subscribe(
                        (resp: WorkspaceStats) => {
                            item.ongoingProcessesCount = resp.ongoingProcessesCount;
                            item.myActiveTasksCount = resp.myActiveTasksCount;
                        }
                    );

                    return item;
                }),
                toArray()
            );
    }

    getWorkspaceStatsFromAPI(workspaceId: string, processDefId: string): Observable<WorkspaceStats> {
        return this.http.post<any>(this.WORKSPACE_STATS_URL, {workspaceId, processDefId});
    }

    /**
     * Get the workspace metrics with polling
     */
    pollWorkspaceStatsFromAPI(workspaceId: string): Observable<WorkspaceStats> {
        return this.http.get<any>(`${this.MY_WORKSPACES_URL}/${workspaceId}`).pipe(
            switchMap((response: Workspace) => {
                return this.refreshWorkspaceStatsFromAPI(
                    response.id,
                    response.processDefId,
                    5000
                );
            })
        );
    }

    /**
     * Get the workspace invoice store metrics with polling
     */
    pollInvoiceStoreStats(workspaceId: string) {
        const request$ = this.http.get<any>(`${this.INVOICESTORE_STATS_URL}?workspaceId.equals=${workspaceId}&captured.equals=false`);

        return timer(1_000, 4_000).pipe(
            concatMap((_) => request$),
            retry(10),
            map((response) => response)
        );

    }

    refreshWorkspaceStatsFromAPI(
        workspaceId: string,
        processDefId: string,
        interval: number
    ) {
        const request$ = this.http.post<any>(this.WORKSPACE_STATS_URL, {
            workspaceId,
            processDefId,
        });

        return timer(1000, interval).pipe(
            concatMap((_) => request$),
            retry(10),
            map((response) => response)
        );
    }

    /**
     * Get workspace processId metrics with polling
     */
    getProcessStatsFromAPI(workspaceId: string): Observable<any> {
        return this.http.get<any>(`${this.MY_WORKSPACES_URL}/${workspaceId}`).pipe(
            switchMap((response: Workspace) => {
                return this.taskService$.refreshProcessDefinitionFromAPI(
                    response.id,
                    response.processDefId,
                    5000
                );
            })
        );
    }

    /**
     * Get workspace processId tasks
     */
    getProcessTasksFromAPI(workspaceId: string): Observable<any> {
        return this.http.get<any>(`${this.MY_WORKSPACES_URL}/${workspaceId}`).pipe(
            switchMap((response: Workspace) => {
                return this.taskService$.getProcessDefinitionFromAPI(
                    response.processDefId
                );
            })
        );
    }

    // TODO: STILL USED ?? remove it !
    refreshPurchaseInvoiceStats(workspaceId: string, interval: number): Observable<any> {

        const httpParams = new HttpParams()
            .set('size', '1')
            .set('approvalStatus.equals', 'APPROVED')
            .set('workspaceId.equals', workspaceId)
            .set('approvalDate.greaterOrEqualThan', myDateAdapter());

        const request$ = this.http
            .get<any>(this.WORKSPACE_STATS_URL_2, {
                params: httpParams,
            })
            .pipe(map((response) => response.totalElements));

        return timer(1000, interval).pipe(
            concatMap((_) => request$),
            retry(5),
            map((response) => response)
        );
    }

    // TODO: use /count api
    refreshTotalPurchaseInvoiceStats(
        workspaceId: string,
        interval: number
    ): Observable<any> {
        const httpParams = new HttpParams()
            .set('size', '1')
            .set('approvalStatus.equals', 'APPROVED')
            .set('workspaceId.equals', workspaceId);

        const request$ = this.http
            .get<any>(this.WORKSPACE_STATS_URL_2, {
                params: httpParams,
            })
            .pipe(map((response) => response.totalElements));

        return timer(1000, interval).pipe(
            concatMap((_) => request$),
            retry(5),
            map((response) => response)
        );
    }

    public refreshRejectedInvoiceStats(workspaceId: string, interval: number): Observable<any> {
        const httpParams = new HttpParams()
            .set('size', '1')
            .set('approvalStatus.equals', 'REJECTED')
            .set('workspaceId.equals', workspaceId);

        const request$ = this.http
            .get<any>(this.WORKSPACE_STATS_URL_2, {
                params: httpParams
            }).pipe(map(res => res.totalElements));

        return timer(1000, interval).pipe(
            concatMap(_ => request$),
            retry(5),
            map(res => res)
        );
    }

    public refreshRejectedOrderStats(workspaceId: string, interval: number): Observable<any> {
        const httpParams = new HttpParams()
            .set('size', '1')
            .set('poLifecycleType.equals', 'MANAGED')
            .set('poApprovalStatus.equals', 'REJECTED')
            .set('workspaceId.equals', workspaceId);

        const request$ = this.http.get<any>(this.poURL, {
            params: httpParams
        }).pipe(map(res => res.totalElements));

        return timer(1000, interval).pipe(
            concatMap(_ => request$),
            retry(5),
            map(res => res)
        );
    }

    refreshPurchaseOrderStats(workspaceId: string, interval: number) {
        const httpParams = new HttpParams()
            .set('size', '1')
            .set('poLifecycleType.equals', 'MANAGED')
            .set('poApprovalStatus.equals', 'APPROVED')
            .set('workspaceId.equals', workspaceId);

        const request$ = this.http.get<any>(this.poURL, {
            params: httpParams,
        })
            .pipe(map((response) => response.totalElements));

        return timer(1000, interval).pipe(
            concatMap((_) => request$),
            retry(5),
            map((response) => response)
        );
    }
}
