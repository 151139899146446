export interface ConsolidatedWorkspace {
    id: string;
    resourceType: ResourceType;
    name: string;
    processDefId: string;
    companyInfoId: string;
    companyInfoName: string;
    ongoingProcessesCount: number;
    myActiveTasksCount: number;
}

export interface Workspace {
    tenantId?: string;
    id: string;
    resourceType: ResourceType;
    name: string;
    processDefId: string;
    companyInfoId: string;
    companyInfoName: string;
    tenantIdentifier: string;
    legalEntityId: string;
}

export interface WorkspaceStats {
    workspaceId: string;
    ongoingProcessesCount: number;
    myActiveTasksCount: number;
}

export interface InvoiceStoreMetrics {
    invoiceReadingCount: number;
}

export enum ResourceType {
    INVOICE = 'INVOICE',
    PURCHASE_ORDER = 'PURCHASE_ORDER'
}
