import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ParamsService {
    constructor(http) {
        this.http = http;
        this.URL = environment.backendUrl + '/api/ws';
    }
    getCompanyInfo(workspaceId) {
        return this.http.get(`${this.URL}/${workspaceId}/params/company-info`);
    }
    updateCompanyInfo(workspaceId, data) {
        return this.http.post(`${this.URL}/${workspaceId}/params/company-info`, data);
    }
}
ParamsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ParamsService_Factory() { return new ParamsService(i0.ɵɵinject(i1.HttpClient)); }, token: ParamsService, providedIn: "root" });
