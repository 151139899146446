import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AddonService {
    constructor(http) {
        this.http = http;
        this.FPA_ADDONS_URL = environment.backendUrl + '/invoicestore/app/api/addons';
    }
    verifyMatchingAddon(tenantIdentifier) {
        return this.http.get(`${this.FPA_ADDONS_URL}/${tenantIdentifier}`);
    }
}
AddonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AddonService_Factory() { return new AddonService(i0.ɵɵinject(i1.HttpClient)); }, token: AddonService, providedIn: "root" });
