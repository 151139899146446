<mat-drawer-container class="invoice-processing-main-container" hasBackdrop>
    <mat-drawer #commentDrawer mode="side" position="end">
        <mat-list class="comment-sheet">
            <mat-list-item class="comment-spacer">
                <h2 matLine class="comment-title">title
                    {{ 'invoiceProcessing.comments' | translate }}
                </h2>
            </mat-list-item>
            <mat-divider></mat-divider>
            <ng-container *ngIf="processComments?.length > 0; else emptyComments">
                <ng-container *ngFor="let comment of processComments">
                    <mat-list-item class="comment-spacer">
                        <p matLine class="comment-header">{{ comment.createdBy }}</p>
                        <p matLine class="comment-content">{{ comment.message }}</p>
                        <p matLine class="comment-date">{{ comment.created }}</p>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                </ng-container>
            </ng-container>

            <ng-template #emptyComments>
                <p matLine class="no-comment">
                    {{ 'invoiceProcessing.noComments' | translate }}
                </p>
            </ng-template>
        </mat-list>
    </mat-drawer>

    <mat-drawer-content>
        <mat-grid-list cols="1" rowHeight="fit">
            <mat-grid-tile class="form-grid-tile">

                <button mat-icon-button
                        class="close-button"
                        color="primary"
                        (click)="closeTaskViewer()">
                    <mat-icon>clear</mat-icon>
                </button>

                <!-- show comment -->
                <button
                        class="view-comments-button"
                        mat-icon-button
                        type="button"
                        color="primary"
                        (click)="openComments()"
                >
                    <mat-icon *ngIf="(messageCount | async) > 0; else noComment"
                              [matBadge]="messageCount$ | async"
                              matBadgeColor="warn"
                              matBadgePosition="above after">
                        comment
                    </mat-icon>

                    <ng-template #noComment>
                        <mat-icon>comment</mat-icon>
                    </ng-template>
                </button>

                <div class="form-grid-header">
                    <p>{{ taskDefName }}</p>
                </div>


                <!-- PO Form -->
                <mat-card class="mat-elevation-z0">
                    <mat-card-content>
                        <form id="poForm" [formGroup]="poForm" autocomplete="off">

                            <!-- po data -->
                            <div fxLayout="row wrap"
                                 fxLayout.md="row wrap"
                                 fxLayout.sm="column"
                                 fxLayout.xs="column"
                                 fxLayoutGap="1%"
                                 fxLayoutAlign="flex-start">
                                <ng-container *ngFor="let item of formMetadata.fields"
                                              [ngSwitch]="item.type">

                                    <div *ngSwitchCase="'headline'" class="invoice-block-title"
                                         [fxFlex]="item.params.layout.size"
                                         [fxFlex.md]="item.params.layout.size"
                                         fxFlex.sm="100%"
                                         fxFlex.xs="100%">

                                        <div class="invoice-block-title" fxFlex="nogrow">
                                            {{ item.name }}
                                        </div>

                                        <!-- Supplier search modal -->
                                        <ng-container
                                                *ngIf="item.id === 'po_supplierParty_headline' && item.readOnly !== true">
                                            <span fxFlex></span>
                                            <span class="side-info">{{'global.manualSearch' | translate}}</span>
                                            <button mat-button
                                                    mat-icon-button
                                                    type="button"
                                                    color="inactive"
                                                    fxFlex="nogrow"
                                                    (click)="openSearchSupplierModal()">
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </ng-container>


                                    </div>

                                    <div *ngSwitchCase="'orderLineTemplate'"
                                         style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%; flex-direction: column !important; display: flex; margin-right: 1%;">

                                        <div class="invoice-block-title" fxFlex="100%"
                                             [ngClass]="{'validation-error': hasErrors() || emptyLines()}">
                                            {{ item.name }}
                                        </div>

                                        <div class="validation-error-global-message" *ngIf="hasErrors()">
                                            {{getErrorMsg()}}
                                        </div>
                                        <div class="validation-error-global-message" *ngIf="emptyLines()">
                                            {{'po.linesRequired' | translate}}
                                        </div>


                                        <ng-container>
                                            <!-- PO LINE TEMPLATE-->
                                            <div
                                                    fxLayout="row wrap"
                                                    fxLayout.md="row wrap"
                                                    fxLayout.sm="column"
                                                    fxLayout.xs="column"
                                                    fxLayoutGap="0%"
                                                    fxLayoutAlign="flex center">


                                                <!--  for each item in formArray do: foreach controls render by id -->
                                                <div formArrayName="orderLines" *ngIf="orderLinesForm" fxFlex="100%">

                                                    <div *ngFor="let line of orderLinesForm.controls; let i = index"
                                                         [formGroupName]="i"
                                                         fxLayout="row wrap"
                                                         fxLayout.md="row wrap"
                                                         fxLayout.sm="column"
                                                         fxLayout.xs="column"
                                                         fxLayoutGap="0.5%"
                                                         fxLayoutAlign="flex center"
                                                         fxLayoutAlign="start center">

                                                        <!-- #Order Line Reference -->
                                                        <mat-form-field fxFlex="6%" style="font-size: 0.875rem"
                                                                        [ngClass]="isReadOnlyLineField(item, 'orderLine_reference') && 'invoice-field-readonly'">
                                                            <mat-label>Reference</mat-label>
                                                            <input matInput formControlName="orderLine_reference"
                                                                   type="text"
                                                                   [readonly]="isReadOnlyLineField(item, 'orderLine_reference')"
                                                            />
                                                        </mat-form-field>

                                                        <!-- #Order Line Description -->
                                                        <mat-form-field fxFlex="38%" style="font-size: 0.875rem">
                                                            <mat-label>Description</mat-label>
                                                            <input matInput formControlName="orderLine_description"
                                                                   type="text"
                                                                   [readonly]="isReadOnlyLineField(item, 'orderLine_description')"
                                                                   required
                                                                   [errorStateMatcher]="errorMatcher">
                                                        </mat-form-field>

                                                        <!-- #Order Line Quantity-->
                                                        <mat-form-field fxFlex="7%" style="font-size: 0.875rem">
                                                            <mat-label>Quantity</mat-label>
                                                            <input matInput formControlName="orderLine_quantity"
                                                                   (change)="onChangeLineQuantity($event,i)"
                                                                   type="number"
                                                                   [readonly]="isReadOnlyLineField(item, 'orderLine_quantity')"
                                                                   required
                                                                   [errorStateMatcher]="errorMatcher">
                                                        </mat-form-field>

                                                        <!-- #Order Line Unit Price-->
                                                        <mat-form-field fxFlex="7%" style="font-size: 0.875rem">
                                                            <mat-label>Unit price</mat-label>
                                                            <input matInput formControlName="orderLine_unitPrice"
                                                                   (change)="onChangeLineUnitPrice($event,i)"
                                                                   type="number"
                                                                   [readonly]="isReadOnlyLineField(item, 'orderLine_unitPrice')"
                                                                   required
                                                                   [errorStateMatcher]="errorMatcher">
                                                        </mat-form-field>

                                                        <!-- #Order Line Tax Coding-->
                                                        <ng-container
                                                                *ngIf="!isReadOnlyLineField(item, 'orderLine_taxCoding')">
                                                            <mat-form-field fxFlex="7%" style="font-size: 0.875rem">
                                                                <mat-label>VAT rate</mat-label>
                                                                <mat-select
                                                                        (selectionChange)="onChangeTaxCodingEvent($event.value, i)"
                                                                        formControlName="orderLine_taxCoding"
                                                                        required
                                                                        [errorStateMatcher]="errorMatcher">

                                                                    <mat-option>
                                                                        <ngx-mat-select-search
                                                                                [formControl]="taxCodingFilterCtrl"
                                                                                placeholderLabel="{{'invoiceProcessing.search' | translate}}"
                                                                                noEntriesFoundLabel="{{'invoiceProcessing.noResults' | translate}}">
                                                                            >
                                                                        </ngx-mat-select-search>
                                                                    </mat-option>

                                                                    <mat-option
                                                                            *ngFor="let taxCoding of filteredTaxCoding | async"
                                                                            [value]="taxCoding">
                                                                        {{ taxCoding.code }} - {{ taxCoding.label }}
                                                                    </mat-option>

                                                                </mat-select>
                                                            </mat-form-field>
                                                        </ng-container>

                                                        <ng-container
                                                                *ngIf="isReadOnlyLineField(item, 'orderLine_taxCoding')">
                                                            <!-- #VAT Rate readonly -->
                                                            <mat-form-field fxFlex="7%" style="font-size: 0.875rem">
                                                                <mat-label>VAT rate</mat-label>
                                                                <input matInput formControlName="orderLine_taxCoding"
                                                                       type="text"
                                                                       [readonly]="true"
                                                                       required
                                                                       [errorStateMatcher]="errorMatcher"
                                                                       [value]="getVatRateDisplay(line)"
                                                                >
                                                            </mat-form-field>
                                                        </ng-container>

                                                        <!-- #Order Line Tax Exclusive Amount-->
                                                        <mat-form-field fxFlex="7%" style="font-size: 0.875rem">
                                                            <mat-label>Tax exc. amount</mat-label>
                                                            <input matInput
                                                                   formControlName="orderLine_taxExclusiveAmount"
                                                                   (change)="onChangeLineTaxExclAmount($event,i)"
                                                                   (ngModelChange)="onChangeLineTaxExclAmount($event,i)"
                                                                   type="number"
                                                                   [readonly]="isReadOnlyLineField(item, 'orderLine_taxExclusiveAmount')"
                                                                   required
                                                                   [errorStateMatcher]="errorMatcher">
                                                        </mat-form-field>

                                                        <!-- #Order Line Tax Amount-->
                                                        <mat-form-field fxFlex="7%" style="font-size: 0.875rem">
                                                            <mat-label>Tax amount</mat-label>
                                                            <input matInput formControlName="orderLine_taxAmount"
                                                                   (change)="onChangeLineTaxAmount($event,i)"
                                                                   (ngModelChange)="onChangeLineTaxAmount($event,i)"
                                                                   type="number"
                                                                   [readonly]="isReadOnlyLineField(item, 'orderLine_taxAmount')"
                                                                   required
                                                                   [errorStateMatcher]="errorMatcher">
                                                        </mat-form-field>

                                                        <!-- #Order Line Tax Inclusive Amount-->
                                                        <mat-form-field fxFlex="7%" style="font-size: 0.875rem">
                                                            <mat-label>Tax Inc. Amount</mat-label>
                                                            <input matInput
                                                                   formControlName="orderLine_taxInclusiveAmount"
                                                                   (change)="onChangeLineTaxInclAmount($event,i)"
                                                                   (ngModelChange)="onChangeLineTaxInclAmount($event,i)"
                                                                   type="number"
                                                                   [readonly]="isReadOnlyLineField(item, 'orderLine_taxInclusiveAmount')"
                                                                   required
                                                                   [errorStateMatcher]="errorMatcher">
                                                        </mat-form-field>

                                                        <!-- #Clear line Button-->
                                                        <button mat-button mat-icon-button fxFlex="nogrow"
                                                                [disabled]="!canAddLines()"
                                                                type="button"
                                                                (click)="deleteOrderLine(i)">
                                                            <mat-icon>clear</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <!-- #Add line Button-->
                                                <button mat-button *ngIf="orderLinesForm"
                                                        [disabled]="!canAddLines()"
                                                        type="button"
                                                        (click)="addOrderLine()"
                                                        fxFlex="100%">
                                                    <mat-icon>add</mat-icon>
                                                    ADD LINE
                                                </button>
                                            </div>
                                            <!-- END OF PO LINE TEMPLATE -->


                                        </ng-container>

                                    </div>


                                    <mat-form-field *ngSwitchCase="'text'"
                                                    class="invoice-field"
                                                    appearance="outline"
                                                    [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                    [floatLabel]="item.params.floatLabel"
                                                    [fxFlex]="item.params.layout.size"
                                                    [fxFlex.md]="item.params.layout.size"
                                                    fxFlex.sm="100%"
                                                    fxFlex.xs="100%"
                                    >
                                        <mat-label>
                                            {{ item.name }}
                                        </mat-label>
                                        <input
                                                matInput
                                                [type]="item.type"
                                                [formControlName]="item.id"
                                                [readonly]="item.readOnly"
                                                [required]="isRequired(item)"
                                                [errorStateMatcher]="errorMatcher"
                                        />

                                    </mat-form-field>

                                    <mat-form-field *ngSwitchCase="'date'"
                                                    class="invoice-field"
                                                    appearance="outline"
                                                    [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                    [floatLabel]="item.params.floatLabel"
                                                    [fxFlex]="item.params.layout.size"
                                                    [fxFlex.md]="item.params.layout.size"
                                                    fxFlex.sm="100%"
                                                    fxFlex.xs="100%"
                                    >
                                        <mat-label>
                                            {{ item.name }}
                                        </mat-label>
                                        <input
                                                matInput
                                                [matDatepicker]="picker"
                                                [formControlName]="item.id"
                                                [readonly]="item.readOnly"
                                                [required]="isRequired(item)"
                                                [errorStateMatcher]="errorMatcher"
                                        />
                                        <mat-datepicker-toggle
                                                matSuffix
                                                [disabled]="item.readOnly"
                                                [for]="picker"
                                        >
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error *ngIf="poForm.controls[item.id].invalid">
                                            Date invalide
                                        </mat-error>

                                    </mat-form-field>

                                    <mat-form-field *ngSwitchCase="'multi-line-text'"
                                                    class="invoice-field"
                                                    appearance="outline"
                                                    [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                    [floatLabel]="item.params.floatLabel"
                                                    [fxFlex]="item.params.layout.size"
                                                    [fxFlex.md]="item.params.layout.size"
                                                    fxFlex.sm="100%"
                                                    fxFlex.xs="100%"
                                    >
                                        <mat-label>
                                            {{ item.name }}
                                        </mat-label>
                                        <textarea
                                                matInput
                                                [type]="'text'"
                                                [formControlName]="item.id"
                                                [readonly]="item.readOnly"
                                                [required]="item.required"
                                        >
										</textarea>
                                    </mat-form-field>

                                    <mat-form-field *ngSwitchCase="'decimal'"
                                                    class="invoice-field"
                                                    appearance="outline"
                                                    [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                    [floatLabel]="item.params.floatLabel"
                                                    [fxFlex]="item.params.layout.size"
                                                    [fxFlex.md]="item.params.layout.size"
                                                    fxFlex.sm="100%"
                                                    fxFlex.xs="100%">
                                        <mat-label>
                                            {{ item.name }}
                                        </mat-label>
                                        <input matInput
                                               [type]="'text'"
                                               [formControlName]="item.id"
                                               [readonly]="item.readOnly"
                                               [required]="item.required"
                                               [errorStateMatcher]="errorMatcher"
                                        />
                                        <mat-error *ngIf="poForm.controls[item.id].invalid">
                                            {{'global.incorrectAmount' | translate}}
                                        </mat-error>
                                    </mat-form-field>

                                    <ng-container *ngIf="item.params.searchable === false && item.readOnly !== true">
                                        <mat-form-field
                                                class="invoice-field"
                                                appearance="outline"
                                                *ngSwitchCase="'dropdown'"
                                                [floatLabel]="item.params.floatLabel"
                                                [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                [fxFlex]="item.params.layout.size"
                                                [fxFlex.md]="item.params.layout.size"
                                                fxFlex.sm="100%"
                                                fxFlex.xs="100%"
                                        >
                                            <mat-label>
                                                {{ item.name }}
                                            </mat-label>
                                            <mat-select
                                                    [formControlName]="item.id"
                                                    [errorStateMatcher]="errorMatcher"
                                                    [required]="isRequired(item)"
                                            >
                                                <mat-option
                                                        *ngFor="let i of item.options"
                                                        [value]="i.name">
                                                    {{ i.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </ng-container>

                                    <ng-container
                                            *ngIf="item.params.searchable === false && item.readOnly === true">

                                        <mat-form-field *ngSwitchCase="'dropdown'"
                                                        class="invoice-field"
                                                        appearance="outline"
                                                        [ngClass]="item.readOnly && 'invoice-field-readonly'"
                                                        [floatLabel]="item.params.floatLabel"
                                                        [fxFlex]="item.params.layout.size"
                                                        [fxFlex.md]="item.params.layout.size"
                                                        fxFlex.sm="100%"
                                                        fxFlex.xs="100%"
                                        >
                                            <mat-label>{{ item.name }}</mat-label>
                                            <input
                                                    matInput
                                                    placeholder="Placeholder"
                                                    [type]="'text'"
                                                    [formControlName]="item.id"
                                                    [readonly]="item.readOnly"
                                                    [required]="item.required"
                                            />
                                            <mat-icon matSuffix class="inactiveIcon"
                                            >arrow_drop_down
                                            </mat-icon>
                                        </mat-form-field>
                                    </ng-container>

                                </ng-container>
                            </div>


                        </form>
                    </mat-card-content>
                </mat-card>

                <!-- Outcomes   isDisabled(poForm, outcome)-->
                <div class="form-grid-footer">
                    <div *ngFor="let outcome of outcomes">
                        <button
                                mat-raised-button
                                *ngIf="outcome.params.primary; else secondary"
                                color="primary"
                                type="button"
                                form="poForm"
                                [disabled]="isDisabled(poForm, outcome)"
                                (click)="handleActionClick(outcome)">
                            {{ outcome.id }}
                        </button>

                        <ng-template #secondary>
                            <button
                                    mat-button
                                    type="button"
                                    color="primary"
                                    form="poForm"
                                    [disabled]="isDisabled(poForm, outcome)"
                                    (click)="handleActionClick(outcome)"
                            >
                                {{ outcome.id }}
                            </button>
                        </ng-template>
                    </div>
                </div>
            </mat-grid-tile>

        </mat-grid-list>
    </mat-drawer-content>
</mat-drawer-container>
