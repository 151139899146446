<h1 mat-dialog-title>{{ 'global.filters'| translate}}</h1>

<form [formGroup]="form" (submit)="onSubmit()">
    <mat-dialog-content fxLayout="row wrap" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="flex center"
                        formArrayName="filters">
        <ng-container *ngFor="let filtre of getControls(); let i = index" [formGroupName]="i">
            <!-- #parameter -->
            <mat-form-field fxFlex="26%" style="max-width: 26%;">
                <mat-label>
                    {{ 'global.setting'| translate}}
                </mat-label>
                <mat-select formControlName="parameter" (ngModelChange)="onParameterChanges(filtre,i)">
                    <mat-optgroup *ngFor="let group of filterGroup" label="{{group.name}}">
                        <mat-option *ngFor="let param of group.children" value="{{param.value}}">{{param.label}}
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
            </mat-form-field>

            <!-- #condition -->
            <mat-form-field fxFlex="23%" style="max-width: 23%;">
                <mat-label>Condition</mat-label>
                <mat-select formControlName="condition">
                    <mat-option *ngFor="let cond of parameters.get(filtre?.value.parameter)?.conditions"
                                value="{{cond.value}}">
                        {{cond.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- #value -->
            <mat-form-field fxFlex="37%" *ngIf="parameters.get(filtre?.value.parameter)?.type==='number'"
                            style="max-width: 37%;">
                <input placeholder="{{ 'global.value'| translate}}" matInput type="text" [pattern]="reg"
                       formControlName="value"
                       [errorStateMatcher]="errorMatcher"/>
                <mat-error
                        *ngIf="getControls()[i].controls['value'].invalid">{{'global.incorrectAmount' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="37%" style="max-width: 37%;"
                            *ngIf="!parameters.get(filtre?.value.parameter)?.type || parameters.get(filtre?.value.parameter)?.type==='string'">
                <input placeholder="{{ 'global.value'| translate}}" matInput type="text" formControlName="value"/>
            </mat-form-field>
            <mat-form-field fxFlex="37%" *ngIf="parameters.get(filtre?.value.parameter)?.type==='date'"
                            style="max-width: 37%;">
                <mat-label>Date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="value" [errorStateMatcher]="errorMatcher"/>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error
                        *ngIf="getControls()[i].controls['value'].invalid && getControls()[i].controls['value'].touched">
                    Date invalide
                </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="37%" *ngIf="parameters.get(filtre?.value.parameter)?.type==='list'"
                            style="max-width: 37%;">
                <mat-label>{{ 'global.value'| translate}}</mat-label>
                <mat-select formControlName="value" [errorStateMatcher]="errorMatcher" required>
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let elm of listMap.get(filtre?.value.parameter)" [value]="elm.name">
                        {{elm.name}}
                    </mat-option>
                </mat-select>
                <mat-error
                        *ngIf="getControls()[i].controls['value'].invalid && getControls()[i].controls['value'].touched">
                    Champs obligatoire
                </mat-error>
            </mat-form-field>

            <button fxFlex="nogrow" *ngIf="getControls().length>1" mat-icon-button type="button"
                    (click)="clearFilter(i)">
                <mat-icon>clear</mat-icon>
            </button>
        </ng-container>
        <button type="button" class="explorer-action" mat-button fxFlex=100% (click)="addItem()"
                style="flex: 1 1 100%; box-sizing: border-box; max-width: 100%;">
            <mat-icon class="button-icon">add</mat-icon>
            {{ 'global.addFilter'| translate}}
        </button>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row wrap" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="flex-start">
        <button type="button" mat-button class="explorer-action" fxFlex=nogrow color="primary"
                (click)="clearFilters()"
                style="margin-right: 2%; flex: 0 1 auto; box-sizing: border-box;">{{'global.removeFilter' | translate}}</button>
        <span fxFlex style="margin-right: 2%; flex: 1 1 0%; box-sizing: border-box;"></span>
        <button type="submit" mat-flat-button class="explorer-action" fxFlex=nogrow color="primary"
                [disabled]="!isValidForm()"
                style="flex: 0 1 auto; box-sizing: border-box;">{{'global.showResults' | translate}}</button>
    </mat-dialog-actions>
</form>
