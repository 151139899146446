import {MatDateFormats} from '@angular/material';
import * as moment from 'moment';
import {isEmpty, replace, sortedUniq} from 'lodash';
import {ISupplier} from '../core/services/supplier-mdm.service';
import {IField, IFormMetadata, IOutcome} from '../models/form.model';
import {IComment} from '../models/comment.model';
import {IAccountCoding, IExpenseCoding, ILineModel, ILineTemplate, ITaxCoding} from '../core/services';
import {IExpenseCodingType} from '../models/task';

moment.locale(navigator.language);

export const MY_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

interface INormalizeSupplier {
    invoice_supplierParty_name?: string;
    invoice_supplierParty_legalId?: string;
    invoice_supplierParty_taxId?: string;
    invoice_supplierParty_contactElectronicMail?: string;
    invoice_supplierParty_financialAccount?: string;
    invoice_supplierParty_customerAssignedAccountId?: string;
    invoice_supplierParty_paymentTerms?: number;
    po_supplierParty_name?: string;
    po_supplierParty_legalId?: string;
    po_supplierParty_taxId?: string;
    po_supplierParty_contactElectronicMail?: string;
    po_supplierParty_financialAccount?: string;
    po_supplierParty_customerAssignedAccountId?: string;
    po_supplierParty_paymentTerms?: number;

}

interface IAdaptedExpenseCoding {
    type: string;
    code: string;
    label: string;
}

interface IAdaptedTaxCoding {
    rate: number;
    code: string;
    label: string;
}

export interface IAdapterInvoiceLine {
    index: number;
    orderLineReference: string;
    lineDescription: string;
    quantity: string;
    unitPrice: string;
    taxExclusiveAmount: string;
    taxAmount: string;
    taxInclusiveAmount: string;
    expenseCodings: IAdaptedExpenseCoding[];
    taxCodings: IAdaptedTaxCoding[];
    accountCodings: IAccountCoding[];
}

export interface IAdapterOrderLine {
    index: number;
    orderLineNumber: string;
    description: string;
    quantity: number;
    unitPrice: number;
    taxExclusiveAmount: string;
    taxAmount: string;
    taxInclusiveAmount: string;
    taxCodings: IAdaptedTaxCoding[];
}

export interface IFallbackOutcome {
    id: string;
    name: string;
    type: string;
    required?: boolean;
    readOnly?: boolean;
    overrideId?: boolean;
    params: {
        commentOnAction: boolean;
        disableOnError?: boolean;
        primary: boolean;
    };
}

/**
 * Create a form field type map.
 * @param formData form values
 */
export function formMap(formData: IFormMetadata): Map<string, string> {
    // tslint:disable-next-line:no-shadowed-variable
    const formMap = new Map();

    formData.fields.forEach((item) => {
        formMap.set(item.id, item.type);
    });

    return formMap;
}

/**
 * Date adapter that extract the property date from a moment object.
 *
 * @returns object
 * @param formData form values to submit to API
 * @param formMap Invoice fields type map
 */
// tslint:disable-next-line:no-shadowed-variable
export function adaptToAPI(formData: any, formMap: Map<string, string>): any {
    const obj: any = {};

    const formKeys = Object.keys(formData);

    formKeys.forEach((key) => {
        let fieldType: string;

        fieldType = formMap.get(key);

        switch (fieldType) {
            case 'decimal':
                obj[key] = parseDecimal(formData[key]);
                break;
            case 'date':
                obj[key] = parseDate(formData[key]);
                break;
            default:
                obj[key] = formData[key];
                break;
        }
    });

    return obj;
}

/**
 * return new formData with no invoiceLines
 * @param formData angular form values
 */
export function removeInvoiceLines(formData: any): any {
    return removeFormKey(formData, 'invoiceLines');
}

export function removeOrderLines(formData: any): any {
    return removeFormKey(formData, 'orderLines');
}

function removeFormKey(formData: any, keyName: string) {
    const obj: any = {};
    const formKeys = Object.keys(formData);
    const filteredFormData = formKeys.filter((key) => key !== keyName);
    filteredFormData.forEach((key) => {
        obj[key] = formData[key];
    });
    return obj;
}


/**
 * This function uses moment js to format date.
 * @param date the moment date object created by mat-date-picker
 * @returns string date
 */
function formatDate(date: string | null): string {
    const API_DATE_FORMAT = 'YYYY-M-D';

    if (date !== undefined && date !== null) {
        return moment(date).format(API_DATE_FORMAT);
    } else {
        return null;
    }
}

/**
 * Parse the form date from a moment object to string.
 * @returns null if date is empty or string depending on the date value.
 */
function parseDate(value: any): string | null {
    if (value instanceof Object) {
        return formatDate(value._d);
    } else if (typeof value === 'string') {
        return formatDate(value);
    } else {
        return null;
    }
}

export function adaptTaskComments(value: IComment[]): IComment[] {
    const adaptedComments: IComment[] = [];
    value.forEach((item: IComment) => {
        adaptedComments.push({
            id: item.id,
            message: item.message,
            created: moment(item.created).fromNow(),
            createdBy: item.createdBy,
        });
    });
    return adaptedComments;
}

/**
 * Check for null supplier object properties and replace them with empty string
 * and also get ride of the unused properties like id and such.
 * @param supplier the selected supplier object from the master data.
 * @returns a normalized supplier with not null and unused properties for the form.
 */
export function normalizeSupplier(supplier: ISupplier): INormalizeSupplier {
    const obj: INormalizeSupplier = {};

    const keys = Object.keys(supplier);
    const supplierMap: Map<string, string> = new Map();

    supplierMap.set('name', 'invoice_supplierParty_name');
    supplierMap.set('legalId', 'invoice_supplierParty_legalId');
    supplierMap.set('taxId', 'invoice_supplierParty_taxId');
    supplierMap.set('contactElectronicMail', 'invoice_supplierParty_contactElectronicMail');
    supplierMap.set('financialAccount', 'invoice_supplierParty_financialAccount');
    supplierMap.set('customerAssignedAccountId',
        'invoice_supplierParty_customerAssignedAccountId');
    supplierMap.set('paymentTerms',
        'invoice_supplierParty_paymentTerms');

    keys.forEach((key) => {
        if (
            key !== 'id' &&
            key !== 'workspaces' &&
            key !== 'isActive'
        ) {
            if (supplier[key] !== null) {
                obj[supplierMap.get(key)] = supplier[key];
            } else {
                obj[supplierMap.get(key)] = '';
            }
        }
    });

    return obj;
}

/**
 * Check for null supplier object properties and replace them with empty string
 * and also get ride of the unused properties like id and such.
 * @param supplier the selected supplier object from the master data.
 * @returns a normalized supplier with not null and unused properties for the form.
 */
export function normalizeSupplierForOrderForm(supplier: ISupplier): INormalizeSupplier {
    const obj: INormalizeSupplier = {};

    const keys = Object.keys(supplier);
    const supplierMap: Map<string, string> = new Map();

    supplierMap.set('name', 'po_supplierParty_name');
    supplierMap.set('legalId', 'po_supplierParty_legalId');
    supplierMap.set('taxId', 'po_supplierParty_taxId');
    supplierMap.set('contactElectronicMail', 'po_supplierParty_contactElectronicMail');
    supplierMap.set('financialAccount', 'po_supplierParty_financialAccount');
    supplierMap.set('customerAssignedAccountId',
        'po_supplierParty_customerAssignedAccountId');
    supplierMap.set('paymentTerms',
        'po_supplierParty_paymentTerms');

    keys.forEach((key) => {
        if (
            key !== 'id' &&
            key !== 'workspaces' &&
            key !== 'isActive'
        ) {
            if (supplier[key] !== null) {
                obj[supplierMap.get(key)] = supplier[key];
            } else {
                obj[supplierMap.get(key)] = '';
            }
        }
    });

    return obj;
}

/**
 * The API provide outcomes objects to trigger specific process on the form submition plus outcomes config object as part of the fields data
 * this function consolidate the config and the outcome as a single object for better handeling
 */
export function consolidateOutcomes(
    outcomes: IOutcome[],
    field: IField
): IFallbackOutcome {
    if (
        field.params.commentOnAction !== null &&
        field.params.commentOnAction !== undefined &&
        field.params.primary !== null &&
        field.params.primary !== undefined
    ) {
        return {
            id: field.id,
            name: field.name,
            type: field.type,
            params: {
                commentOnAction: field.params.commentOnAction,
                disableOnError: field.params.disableOnError,
                primary: field.params.primary,
            },
        };
    } else {
        return {
            id: field.id,
            name: field.name,
            type: field.type,
            params: {
                commentOnAction: false,
                disableOnError: field.params.disableOnError,
                primary: true,
            },
        };
    }
}

/**
 * Responsible for checking if the form fields contains any outcome specific config
 * @param formFields from API.
 * @returns return true if fields contains specific outcome config false otherwise
 */
export function checkFormOutcomesConfig(formFields: IField[]): boolean {
    let count = 0;

    formFields.forEach((field) => {
        if (field.type !== 'outcomeExtension') {
            count = count + 1;
        }
    });

    if (count !== formFields.length) {
        return true;
    } else {
        return false;
    }
}

export function generateFallbackOutcomes(
    outcomes: IOutcome[]
): IFallbackOutcome[] {
    const fallbackActions: IFallbackOutcome[] = [];

    outcomes.forEach((item) => {
        fallbackActions.push({
            id: item.name,
            name: item.name,
            type: 'outcomeExtension',
            params: {
                commentOnAction: false,
                disableOnError: false,
                primary: true,
            },
        });
    });

    return fallbackActions;
}

/**
 * Parse and replace the amounts with comma to point.
 * @param value amount string to parse.
 * @returns string parsed
 */
export function parseDecimal(value: string): string {
    const regex = /,/g;
    const result = replace(value, regex, '.');
    return result;
}

/**
 * Helper function to parse amount from string to number return 0 in case of an empty string.
 */
export function amountParser(value: string): number {
    return parseFloat(value) || 0;
}

/**
 * Calculate the taxeTotal from the totalAmount and vatRate values.
 */
export function taxAmount(totalAmount: number, vatRate: number): number {
    return vatRate * totalAmount;
}

/**
 * Calculate legalMonetaryTotal from the totalAmount and vatRate values.
 */
export function taxInclusiveAmount(totalAmount: number, vatRate: number) {
    return (vatRate + 1) * totalAmount;
}

/**
 * Helper class for handling invoice lines expense and tax codings.
 */
export class InvoiceLinesHelper {
    hasTaxCodings: boolean;
    hasAccountCodings: boolean;
    expenseCodings: IExpenseCoding[];
    listTypes: IExpenseCodingType[];
    lineModel: ILineModel[];
    filters: any[];
    expenseCodingLists: Map<string, IExpenseCoding[]> = new Map();
    adaptedLineModel: IExpenseCodingType[];

    constructor(invoiceLines: ILineTemplate, expenseCodings?: IExpenseCoding[], taxCodings?: ITaxCoding[]) {

        this.expenseCodings = expenseCodings;
        if (invoiceLines) {
            this.lineModel = invoiceLines.params.lineModel;
        }

        this.lineModelExpenseCodings();
        this.hasTaxCoding();
        this.hasAccountCoding();

    }

    get generatedExpenseCodingLists() {
        return this.expenseCodingLists;
    }

    get expenseCodingTypes() {
        return this.listTypes;
    }

    get lineModelExpenseCodingsObj() {
        return this.adaptedLineModel;
    }

    get taxCodingsStatus() {
        return this.hasTaxCodings;
    }

    get accountCodingsStatus() {
        return this.hasAccountCodings;
    }

    get expenseCodingsCount() {
        return this.listTypes.length;
    }

    public adaptFormInvoiceLinesToAPI(invoiceLines: any[]): IAdapterInvoiceLine[] {
        const adaptedLines: IAdapterInvoiceLine[] = [];
        if (invoiceLines.length > 0) {

            invoiceLines.forEach((line, index) => {

                adaptedLines.push({
                    index,
                    lineDescription: line.invoice_invoiceLine_lineDescription,
                    quantity: parseDecimal(line.invoice_invoiceLine_quantity),
                    unitPrice: parseDecimal(line.invoice_invoiceLine_unitPrice),
                    orderLineReference: line.invoice_invoiceLine_orderLineReference,
                    taxExclusiveAmount: parseDecimal(line.invoice_invoiceLine_lineTaxExclusiveAmount),
                    taxAmount: parseDecimal(line.invoice_invoiceLine_lineTaxAmount),
                    taxInclusiveAmount: parseDecimal(line.invoice_invoiceLine_lineTaxInclusiveAmount),
                    expenseCodings: this.getExpenseCodings(line),
                    taxCodings: this.extractTaxCodings(line),
                    accountCodings: this.extractAccountCodings(line)

                });
            });
        }

        return adaptedLines;
    }

    public adaptFormOderLinesToAPI(orderLines: any[]): IAdapterOrderLine[] {
        const adaptedLines: IAdapterOrderLine[] = [];
        if (orderLines.length > 0) {

            orderLines.forEach((line, index) => {

                adaptedLines.push({
                    index,
                    orderLineNumber: line.orderLine_reference,
                    description: line.orderLine_description,
                    quantity: line.orderLine_quantity,
                    unitPrice: line.orderLine_unitPrice,
                    taxExclusiveAmount: parseDecimal(line.orderLine_taxExclusiveAmount),
                    taxAmount: parseDecimal(line.orderLine_taxAmount),
                    taxInclusiveAmount: parseDecimal(line.orderLine_taxInclusiveAmount),
                    taxCodings: this.extractOrderTaxCodings(line)
                });
            });
        }

        return adaptedLines;
    }

    private getExpenseCodings(line) {

        const expenseCoding1 = line.invoice_invoiceLine_expenseCoding1;
        const expenseCoding2 = line.invoice_invoiceLine_expenseCoding2;
        const expenseCoding3 = line.invoice_invoiceLine_expenseCoding3;
        const expenseCoding4 = line.invoice_invoiceLine_expenseCoding4;
        const expenseCoding5 = line.invoice_invoiceLine_expenseCoding5;

        const expCodings = [];

        this.addIfNotNull(expCodings, expenseCoding1);
        this.addIfNotNull(expCodings, expenseCoding2);
        this.addIfNotNull(expCodings, expenseCoding3);
        this.addIfNotNull(expCodings, expenseCoding4);
        this.addIfNotNull(expCodings, expenseCoding5);


        return expCodings;
    }

    private addIfNotNull(expCodings: any[], expenseCodingElt: any) {
        if (expenseCodingElt) {
            expCodings.push(
                {
                    type: expenseCodingElt.type,
                    code: expenseCodingElt.code,
                    label: expenseCodingElt.label,
                }
            );
        }
    }

    private extractTaxCodings(line) {
        if (this.hasTaxCoding()) {
            const taxCoding = line.invoice_invoiceLine_taxCoding;
            if (taxCoding !== undefined && !isEmpty(taxCoding)) {
                return [
                    {
                        code: taxCoding.code,
                        label: taxCoding.label,
                        rate: taxCoding.rate,
                    }
                ];
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    private extractOrderTaxCodings(line) {
        const taxCoding = line.orderLine_taxCoding;
        if (taxCoding !== undefined && !isEmpty(taxCoding)) {
            return [
                {
                    code: taxCoding.code,
                    label: taxCoding.label,
                    rate: taxCoding.rate,
                }
            ];
        }
        return [];
    }

    private extractAccountCodings(line) {
        if (this.hasAccountCoding()) {
            const accountCoding = line.invoice_invoiceLine_accountCoding;
            if (accountCoding !== undefined && !isEmpty(accountCoding)) {
                return [{
                    code: accountCoding.code,
                    label: accountCoding.label
                }];
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    // called before initExpenseCodingSearch
    private generateExpenseCodingLists() {
        if (this.lineModel) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.lineModel.length; i++) {
                if (this.lineModel[i].expenseCodingType !== null && this.lineModel[i].type === 'expenseCoding') {
                    this.expenseCodingLists.set(
                        this.lineModel[i].expenseCodingType,
                        this.filterExpenseCodingsByType(this.lineModel[i].expenseCodingType)
                    );
                }
            }
        }
    }

    private filterExpenseCodingsByType(type: string): IExpenseCoding[] {
        return this.expenseCodings.filter((item) => item.type === type);
    }

    private generateExpenseCodingsTypes(): void {
        const expenseCodingTypes: IExpenseCodingType[] = [];

        if (this.lineModel) {
            this.lineModel.forEach((line) => {
                if (line.expenseCodingType !== null && line.type === 'expenseCoding') {
                    expenseCodingTypes.push({id: line.id, type: line.expenseCodingType});
                }
            });
        }

        // Remove duplicate types from array
        this.listTypes = sortedUniq(expenseCodingTypes);
    }

    private lineModelExpenseCodings() {
        this.generateExpenseCodingLists();
        this.generateExpenseCodingsTypes();

        const adaptedLineModel = [];

        this.listTypes.forEach((item, i) => {
            const index = i + 1;

            adaptedLineModel[item.id] = {
                filteredExpenseCodings: `filteredExpenseCoding${index}`,
                formControlName: `expenseCoding${index}FilterCtrl`,
                filterFn: `filterExpenseCoding${index}`,
                expenseCodingType: item.type,
                expenseCodingsList: `expenseCodings${index}`,
            };
        });

        this.adaptedLineModel = adaptedLineModel;
    }

    private hasTaxCoding() {
        if (this.lineModel) {
            const filteredTaxCoding = this.lineModel.filter(
                (item) => item.attribute === 'taxCoding'
            );
            if (filteredTaxCoding.length > 0) {
                this.hasTaxCodings = true;
                return true;
            } else {
                this.hasTaxCodings = false;
                return false;
            }
        }

    }

    private hasAccountCoding() {
        if (this.lineModel) {
            const filteredAccountCoding = this.lineModel.filter(
                (item) => item.attribute === 'accountCoding'
            );
            if (filteredAccountCoding.length > 0) {
                this.hasAccountCodings = true;
                return true;
            } else {
                this.hasAccountCodings = false;
                return false;
            }
        }

    }
}

export function searchInitialExpenseCoding(
    expenseCoding: IExpenseCoding,
    expenseCodingsList: IExpenseCoding[]) {
    if (expenseCoding !== undefined) {
        return expenseCodingsList.filter(
            (item) => (item.code === expenseCoding.code && item.type === expenseCoding.type)
        );
    } else {
        return {type: '', code: '', label: ''};
    }
}

export function typeFilteredExpenseCodings(
    type: string,
    lineExpenseCodings: IExpenseCoding[]
) {
    const result = lineExpenseCodings.filter(
        (expenseCoding) => expenseCoding.type === type
    );

    return result[0];
}

export function searchInitialTaxCoding(taxCoding: ITaxCoding, taxCodingsList: ITaxCoding[]) {

    if (taxCoding !== undefined) {
        return taxCodingsList.filter((item) => item.code === taxCoding.code);
    } else {
        return {code: '', label: '', rate: ''};
    }
}

export function searchInitialAccountCoding(accountCoding: IAccountCoding, accountCodingsList: IAccountCoding[]) {

    if (accountCoding !== undefined) {
        return accountCodingsList.filter((item) => item.code === accountCoding.code);
    } else {
        return {code: '', label: ''};
    }
}

/**
 * check for all availble expense coding properties
 * @param expenseCodings expense codings array
 */
export function adaptExpenseCodingsSearchable(expenseCodings: IExpenseCoding[]): IExpenseCoding[] {

    const adapted: IExpenseCoding[] = [];

    expenseCodings.forEach(
        (item) => {
            adapted.push({
                id: item.id,
                type: item.type,
                code: item.code === null ? '' : item.code,
                label: item.label === null ? '' : item.label,
                tenantId: item.tenantId,
                tenantIdentifier: item.tenantIdentifier,
                workspaceId: item.workspaceId,
            });
        });

    return adapted;
}
