<mat-drawer-container hasBackdrop>
    <mat-drawer class="invoice-details" #drawer mode="over" position="end">
        <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="start center">
            <!--Title when there is no publication configured-->
            <span fxFlex="40px" *ngIf="!viewedInvoice.publicationStatus"></span>
            <span
                    fxFlex
                    class="invoice-details-header"
                    *ngIf="!viewedInvoice.publicationStatus"
            >
				<p>{{ viewedInvoice.invoiceNumber }}</p>
			</span>

            <!--Title when publication is configured and the invoice is ONGOING-->
            <span fxFlex="40px" class="invoice-details-header-icon" *ngIf="viewedInvoice.publicationStatus =='ONGOING'">
        <mat-icon class="inactive-icon">sync</mat-icon>
      </span>
            <span fxFlex class="invoice-details-header" *ngIf="viewedInvoice.publicationStatus == 'ONGOING'">
        <p>{{viewedInvoice.invoiceNumber}}</p>
      </span>

            <!--Title when publication is configured and the invoice is published-->
            <span fxFlex="40px" class="invoice-details-header-icon" *ngIf="viewedInvoice.publicationStatus =='SUCCESS'">
        <mat-icon color="accent">check_circle_outline</mat-icon>
      </span>
            <span fxFlex class="invoice-details-header" *ngIf="viewedInvoice.publicationStatus =='SUCCESS'">
        <p>{{viewedInvoice.invoiceNumber}}</p>
      </span>

            <!--Title when publication is configured and the invoice is not published-->
            <span fxFlex="40px" class="invoice-details-header-icon" *ngIf="viewedInvoice.publicationStatus =='FAILURE'">
        <mat-icon color="warn">error_outline</mat-icon>
      </span>
            <span fxFlex class="invoice-details-header" *ngIf="viewedInvoice.publicationStatus == 'FAILURE'">
        <p>{{viewedInvoice.invoiceNumber}}</p>
      </span>

            <button
                    color="primary"
                    class="invoice-details-close-button"
                    fxFlex="nogrow"
                    mat-icon-button
                    (click)="drawer.close()"
            >
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start"
             *ngIf="viewedInvoice.publicationStatus =='FAILURE'">
            <span fxFlex="100%"
                  class="publication-error"><p>{{ 'global.publicationErrorMsg'| translate}} {{ viewedInvoice.publicationErrorMessage }}</p></span>
        </div>
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">
			<span fxFlex="100%" class="invoice-details-title">
				<h4>{{ 'global.provider'| translate}}</h4>
			</span>
            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.supplierParty?.name"
            >
				<p>{{ 'global.name'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.supplierParty?.name"
            >
				<p>{{ viewedInvoice.supplierParty?.name }}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.supplierParty?.legalId"
            >
				<p>{{ 'global.legalID'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.supplierParty?.legalId"
            >
				<p>{{ viewedInvoice.supplierParty?.legalId }}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.supplierParty?.taxId"
            >
				<p>{{ 'global.taxIdentification'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.supplierParty?.taxId"
            >
				<p>{{ viewedInvoice.supplierParty?.taxId }}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.supplierParty?.contactElectronicMail"
            >
				<p>{{ 'global.contact'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.supplierParty?.contactElectronicMail"
            >
				<p>{{ viewedInvoice.supplierParty?.contactElectronicMail }}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.supplierParty?.financialAccount"
            >
				<p>{{ 'global.bankingInformation'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.supplierParty?.financialAccount"
            >
				<p>{{ viewedInvoice.supplierParty?.financialAccount}}</p>
			</span>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">
			<span fxFlex="100%" class="invoice-details-title">
				<h4>{{ 'global.invoiceInformations'| translate}}</h4>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.issueDate"
            >
				<p>{{ 'global.issueDate'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.issueDate"
            >
				<p>{{ viewedInvoice.issueDate | date: 'mediumDate': '': translateService.currentLang}}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.dueDate"
            >
				<p>{{ 'global.dueDate'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.dueDate"
            >
				<p>{{ viewedInvoice.dueDate | date: 'mediumDate': '': translateService.currentLang }}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.receiptDate"
            >
				<p>{{'global.receptionDate' | translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.receiptDate"
            >
				<p>{{ viewedInvoice.receiptDate | date: 'mediumDate': '': translateService.currentLang }}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.approvalDate"
            >
				<p>{{'global.validationDate' | translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.approvalDate"
            >
				<p>{{ viewedInvoice.approvalDate | date: 'mediumDate': '': translateService.currentLang }}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.publicationDate"
            >
				<p>{{'global.publicationDate' | translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.publicationDate"
            >
				<p>{{ viewedInvoice.publicationDate | date: 'mediumDate': '': translateService.currentLang }}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.invoiceNumber"
            >
				<p>{{ 'global.invoiceNumber'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.invoiceNumber"
            >
				<p>{{ viewedInvoice.invoiceNumber }}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.orderReference">
				<p>{{'global.orderNo' | translate}}</p>
			</span>

            <span fxFlex="58%"
                  class="invoice-details-value"
                  *ngIf="viewedInvoice.orderReference">
				<p>{{ viewedInvoice.orderReference }}</p>
			</span>

            <span fxFlex="38%"
                  class="invoice-details-key"
                  *ngIf="viewedInvoice.accountingDocumentNumber">
				<p>{{'global.accountingDocumentNumber' | translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.accountingDocumentNumber"
            >
				<p>{{ viewedInvoice.accountingDocumentNumber }}</p>
			</span>

            <span fxFlex="38%"
                  class="invoice-details-key"
                  *ngIf="viewedInvoice.accountingLabel">
				<p>{{'global.accountingLabel' | translate}}</p>
			</span>
            <span fxFlex="58%"
                  class="invoice-details-value"
                  *ngIf="viewedInvoice.accountingLabel">
				<p>{{viewedInvoice.accountingLabel}}</p>
			</span>

            <span fxFlex="38%"
                  class="invoice-details-key"
                  *ngIf="viewedInvoice.note">
				<p>{{'global.note' | translate}}</p>
			</span>

            <span fxFlex="58%"
                  class="invoice-details-value"
                  *ngIf="viewedInvoice.note">
				<p>{{viewedInvoice.note}}</p>
			</span>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">
			<span fxFlex="100%" class="invoice-details-title">
				<h4>{{ 'global.amounts'| translate}}</h4>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.taxExclusiveAmount"
            >
				<p>{{ 'global.taxExclusiveAmount'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.taxExclusiveAmount"
            >
				<p>{{ viewedInvoice.taxExclusiveAmount | number:'': translateService.currentLang}}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.taxAmount"
            >
				<p>{{ 'global.totalTaxes'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.taxAmount"
            >
				<p>{{ viewedInvoice.taxAmount | number:'': translateService.currentLang}}</p>
			</span>

            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.payableAmount"
            >
				<p>{{ 'global.payableAmount'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.payableAmount"
            >
				<p>{{ viewedInvoice.payableAmount | number:'': translateService.currentLang}}</p>
			</span>
            <span
                    fxFlex="38%"
                    class="invoice-details-key"
                    *ngIf="viewedInvoice.documentCurrencyCode"
            >
				<p>{{ 'global.documentCurrencyCode'| translate}}</p>
			</span>
            <span
                    fxFlex="58%"
                    class="invoice-details-value"
                    *ngIf="viewedInvoice.documentCurrencyCode"
            >
				<p>{{ viewedInvoice.documentCurrencyCode}}</p>
			</span>
        </div>

        <!-- PAYMENT STATUS DATA -->

        <ng-container *ngIf="viewedInvoice.paymentStatus == 'PAID' ">
            <mat-divider></mat-divider>
            <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">
			<span fxFlex="100%" class="invoice-details-title">
				<h4>{{ 'invoiceExplorer.paymentInfo'| translate}}</h4>
			</span>

                <span fxFlex="38%"
                      class="invoice-details-key">
				<p>{{ 'invoiceExplorer.paymentStatus'| translate}}</p>
			</span>
                <span fxFlex="58%"
                      class="invoice-details-value">
				<p>{{ 'invoiceExplorer.paymentStatusPaid'| translate}}</p>
			</span>

                <span fxFlex="38%"
                      class="invoice-details-key"
                      *ngIf="viewedInvoice.paymentDate"
                >
				<p>{{ 'invoiceExplorer.paymentDate'| translate}}</p>
			</span>
                <span fxFlex="58%"
                      class="invoice-details-value"
                      *ngIf="viewedInvoice.paymentDate">
                <p>{{ viewedInvoice.paymentDate | date: 'mediumDate': '': translateService.currentLang}}</p>
			</span>

                <span fxFlex="38%"
                      class="invoice-details-key"
                      *ngIf="viewedInvoice.paymentMeans">
				<p>{{ 'invoiceExplorer.paymentMeans'| translate}}</p>
			</span>
                <span fxFlex="58%"
                      class="invoice-details-value"
                      *ngIf="viewedInvoice.paymentMeans">
				<p>{{ viewedInvoice.paymentMeans}}</p>
			</span>

                <span fxFlex="38%"
                      class="invoice-details-key"
                      *ngIf="viewedInvoice.paymentReference">
				<p>{{ 'invoiceExplorer.paymentReference'| translate}}</p>
			</span>
                <span fxFlex="58%"
                      class="invoice-details-value"
                      *ngIf="viewedInvoice.paymentReference">
				<p>{{ viewedInvoice.paymentReference}}</p>
			</span>

            </div>
        </ng-container>

        <mat-divider></mat-divider>
        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="flex-start">
            <span fxFlex></span>
            <button
                    mat-flat-button
                    color="primary"
                    fxFlex="nogrow"
                    class="invoice-details-download-button"
                    (click)="downloadAttachment()">
                {{'global.downloadDocument' | translate}}
            </button>
            <span fxFlex></span>
        </div>
    </mat-drawer>

    <mat-drawer-content>
        <mat-card class="mat-elevation-z0 mat-card-filters">
            <div fxLayout="row wrap" fxLayoutGap="4px" fxLayoutAlign="start center">
                <button
                        fxFlex="nogrow"
                        mat-icon-button
                        color="primary"
                        aria-label="Workspace home back button"
                        (click)="navigateToWorkspaceHome()"
                >
                    <mat-icon class="back-icon">arrow_back_ios</mat-icon>
                </button>
                <span fxFlex></span>
                <span fxFlex>{{'invoiceExplorer.numberOfInvoices' | translate}} :
					<span class="filter-title">{{ numRows }}</span></span>
                <!-- waiting for bug fixing on publish feature -->
                <!--                <button class="explorer-action" mat-flat-button fxFlex=nogrow color="accent" [disabled]="isEmpty()"-->
                <!--                        (click)="onPublish()">-->
                <!--                    <mat-icon class="button-icon">autorenew</mat-icon>-->
                <!--                    {{'global.publish' | translate}} ({{-->
                <!--                    selectionInvoice.selected.length-->
                <!--                    }})-->
                <!--                </button>-->
                <button *ngxPermissionsOnly="'explorer.approved.paymentManager'"
                        class="explorer-action" mat-stroked-button fxFlex="nogrow"
                        [disabled]="isEmpty()"
                        (click)="onClearPaidMarker()">
                    <mat-icon class="button-icon">money_off_csred</mat-icon>
                    {{'invoiceExplorer.unmark' | translate}} ({{selectionInvoice.selected.length}})
                </button>

                <button *ngxPermissionsOnly="'explorer.approved.paymentManager'"
                        class="explorer-action" mat-stroked-button fxFlex="nogrow" color="primary"
                        [disabled]="isEmpty()"
                        (click)="onMarkAsPaid()">
                    <mat-icon class="button-icon">attach_money</mat-icon>
                    {{'invoiceExplorer.markAsPaid' | translate}} ({{selectionInvoice.selected.length}})
                </button>

                <button class="explorer-action"
                        mat-flat-button
                        fxFlex="nogrow"
                        color="primary"
                        [disabled]="isEmpty()"
                        (click)="onExport()">
                    <mat-icon class="button-icon">save_alt</mat-icon>
                    {{'global.export' | translate}} ({{selectionInvoice.selected.length}})
                </button>

                <button class="explorer-action"
                        mat-stroked-button
                        fxFlex="nogrow"
                        color="primary"
                        (click)="openFilterDialog()">
                    <mat-icon class="button-icon">filter_list</mat-icon>
                    {{ filters.length }}
                </button>

            </div>
        </mat-card>

        <mat-card class="mat-elevation-z2 mat-card-table">
            <mat-card-title></mat-card-title>
            <table
                    mat-table
                    matSort
                    [dataSource]="dataSource"
                    matSortActive="issueDate"
                    matSortDirection="desc"
            >
                <ng-container matColumnDef="select" sticky>
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                                color="primary"
                                (change)="$event ? masterToggle() : null"
                                [checked]="selectionInvoice.hasValue() && isAllSelected()"
                                [indeterminate]="selectionInvoice.hasValue() && !isAllSelected()"
                        >
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox
                                color="primary"
                                (change)="
								$event ? handleOnCheckBoxSelectionChange($event,row,row.id.toString()) : null"
                                [checked]="selectionInvoice.isSelected(row.id.toString())"
                        >
                        </mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="publicationStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell
                        *matCellDef="let element"
                        (click)="viewInvoiceOverview(element)">

                        <mat-icon *ngIf="element.publicationStatus =='FAILURE'" color="warn">error_outline</mat-icon>
                        <mat-icon *ngIf="element.publicationStatus =='SUCCESS'" color="accent">check_circle_outline
                        </mat-icon>
                        <mat-icon *ngIf="element.publicationStatus =='ONGOING'" class="inactive-icon">sync</mat-icon>
                    </td>
                </ng-container>

                <!-- Issue Date Column -->
                <ng-container matColumnDef="issueDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'global.issueDate'| translate}}
                    </th>
                    <td
                            mat-cell
                            *matCellDef="let element"
                            (click)="viewInvoiceOverview(element)"
                    >
                        {{ element.issueDate | date: 'shortDate': '': translateService.currentLang }}
                    </td>
                </ng-container>

                <!-- Supplier Column -->
                <ng-container matColumnDef="supplierParty.name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'global.provider'| translate}}</th>
                    <td
                            mat-cell
                            *matCellDef="let element"
                            (click)="viewInvoiceOverview(element)"
                    >
                        {{ element.supplierParty?.name }}
                    </td>
                </ng-container>

                <!-- Invoice Number Column -->
                <ng-container matColumnDef="invoiceNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'global.invoiceNumber'| translate}}</th>
                    <td
                            mat-cell
                            *matCellDef="let element"
                            (click)="viewInvoiceOverview(element)"
                    >
                        {{ element.invoiceNumber }}
                    </td>
                </ng-container>

                <!-- Total Amount Column -->
                <ng-container matColumnDef="taxExclusiveAmount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'global.taxExclusiveAmount'| translate}}
                    </th>
                    <td
                            mat-cell
                            *matCellDef="let element"
                            (click)="viewInvoiceOverview(element)"
                    >
                        {{ element.taxExclusiveAmount | number:'1.0-2': translateService.currentLang}}
                    </td>
                </ng-container>

                <!-- Total Tax Column -->
                <ng-container matColumnDef="taxAmount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'global.taxAmount'| translate}}</th>
                    <td
                            mat-cell
                            *matCellDef="let element"
                            (click)="viewInvoiceOverview(element)"
                    >
                        {{ element.taxAmount | number:'1.0-2': translateService.currentLang}}
                    </td>
                </ng-container>

                <!-- Payable Amount Column -->
                <ng-container matColumnDef="payableAmount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'global.payableAmount'| translate}}</th>
                    <td mat-cell *matCellDef="let element" (click)="viewInvoiceOverview(element)">
                        {{ element.payableAmount | number:'1.0-2': translateService.currentLang}}
                    </td>
                </ng-container>

                <!-- Payment Status Column -->
                <ng-container matColumnDef="paymentStatus">
                    <th mat-header-cell *matHeaderCellDef
                        mat-sort-header>{{ 'invoiceExplorer.paymentStatus'| translate}}</th>
                    <td mat-cell
                        *matCellDef="let element"
                        (click)="viewInvoiceOverview(element)">
                        <span class="paid-status"
                              *ngIf="element.paymentStatus =='PAID'">{{ 'invoiceExplorer.paymentStatusPaid'| translate}}</span>

                    </td>
                </ng-container>

                <!-- Currency Column -->
                <ng-container matColumnDef="documentCurrencyCode">
                    <th mat-header-cell *matHeaderCellDef
                        mat-sort-header>{{ 'global.documentCurrencyCode'| translate}}</th>
                    <td mat-cell
                        *matCellDef="let element"
                        (click)="viewInvoiceOverview(element)">
                        {{ element.documentCurrencyCode }}
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator
                    [length]="dataSource.totalOperations$ | async"
                    [pageIndex]="0"
                    [pageSize]="20"
                    [pageSizeOptions]="[5, 10, 20, 50]"
                    showFirstLastButtons
            ></mat-paginator>
        </mat-card>
    </mat-drawer-content>
</mat-drawer-container>
