import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InvoiceProcessingComponent} from './invoice-processing.component';

const routes: Routes = [{path: '', component: InvoiceProcessingComponent}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InvoiceProcessingRoutingModule {
}
