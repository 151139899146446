import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {RolesService} from '../services/roles.service';
import {NgxPermissionsRouterData, NgxPermissionsService, OnlyFn} from 'ngx-permissions';
import {Role} from 'src/app/models/token-user.model';
import {AuthenticationService} from '../services';

@Injectable({providedIn: 'root'})
export class PermissionAuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthenticationService,
        private rolesService: RolesService,
        private permissionsService: NgxPermissionsService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // check that the user is authenticated
        if (this.authService.isLoggedIn()) {
            const workspaceId = route.paramMap.get('workspaceId');
            if (workspaceId != null) {
                const routeDataPermissions: NgxPermissionsRouterData = !!route && route.data ? (route.data.permissions as NgxPermissionsRouterData) : {};
                return this.userHasPermissionsForWorkspace(routeDataPermissions, workspaceId);
            } else {
                return true;
            }
        }

        // this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        return false;
    }

    async userHasPermissionsForWorkspace(routeDataPermissions: NgxPermissionsRouterData, workspaceId: string) {
        let onlyPermissions: string | string[] | OnlyFn;
        onlyPermissions = routeDataPermissions.only;
        const value = await this.rolesService.getPermissionsByWorkspace(workspaceId).toPromise();
        const myPermissions: string[] = this.adaptToPermissions(value);

        if (myPermissions.some(permission => (onlyPermissions as string[]).includes(permission))) {
            this.permissionsService.loadPermissions(myPermissions);
            return true;
        } else {
            return false;
        }
    }

    private adaptToPermissions(roles: Role[]): string[] {
        return roles.map(value => value.name);
    }

}
