import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Filter} from 'src/app/modules/invoice-explorer/invoice-explorer.component';

export interface ISupplier {
    id: string;
    name: string;
    legalId: string;
    taxId: string;
    financialAccount: string;
    contactElectronicMail: string;
    customerAssignedAccountId: string;
    paymentTerms: number;
    isActive: boolean;
    workspaces: any[];
}

@Injectable({
    providedIn: 'root',
})
export class SupplierMdmService {
    readonly URL = environment.backendUrl + '/invoicestore/app/api/suppliers';
    readonly ENTITY_SUPPLIERS_API = environment.backendUrl + '/invoicestore/app/api/suppliers-for-legal-entity';

    constructor(private http: HttpClient) {
    }

    private buildFiltersParam(filters: Filter[], params: HttpParams): HttpParams {
        for (const f of filters) {
            params = params.set(f.parameter.concat('.').concat('contains'), f.value);
        }
        return params;
    }

    getSuppliersFromAPI(filters: any, workspaceId: string): Observable<ISupplier[]> {
        let httpParams = new HttpParams()
            .set('size', '10000')
            .set('isActive.equals', 'true');

        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }
        return this.http.get<any>(this.URL, {params: httpParams});
    }

    getSuppliersForWorkspace(filters: any, workspaceId: string): Observable<ISupplier[]> {

        let httpParams = new HttpParams()
            .set('size', '1_000')
            .set('isActive.equals', 'true');


        if (filters) {
            httpParams = this.buildFiltersParam(filters, httpParams);
        }

        const body = {workspaceId};
        return this.http.post<any>(this.ENTITY_SUPPLIERS_API, body, {params: httpParams});
    }
}
