import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakService} from './keycloak.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private keycloakService: KeycloakService) {
    }


    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        const authenticated = this.keycloakService.keycloak.authenticated;
        console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);
        if (authenticated) {
            // localStorage.setItem('access_token', this.keycloakService.keycloak.token);
            return true; // Redirect back to the original route after successful exchange
        } else {
            await this.keycloakService.keycloak.login();
            return false; // Redirect to Keycloak login page
        }
    }
}
