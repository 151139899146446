/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./params.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/params-menu/params-menu.component.ngfactory";
import * as i3 from "./components/params-menu/params-menu.component";
import * as i4 from "@angular/router";
import * as i5 from "./params.component";
var styles_ParamsComponent = [i0.styles];
var RenderType_ParamsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ParamsComponent, data: {} });
export { RenderType_ParamsComponent as RenderType_ParamsComponent };
export function View_ParamsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "grid-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "params-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-params-menu", [], null, null, null, i2.View_ParamsMenuComponent_0, i2.RenderType_ParamsMenuComponent)), i1.ɵdid(3, 114688, null, 0, i3.ParamsMenuComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "params-main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); }, null); }
export function View_ParamsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-params", [], null, null, null, View_ParamsComponent_0, RenderType_ParamsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ParamsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParamsComponentNgFactory = i1.ɵccf("app-params", i5.ParamsComponent, View_ParamsComponent_Host_0, {}, {}, []);
export { ParamsComponentNgFactory as ParamsComponentNgFactory };
