<h1 mat-dialog-title>{{'invoiceProcessing.importLines' | translate}}</h1>
<mat-dialog-content>
    <p>
        {{data.message}}
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" mat-dialog-close cdkFocusInitial>
        {{'invoiceProcessing.importLinesConfirmDialog.cancel' | translate}}
    </button>
    <button mat-button color="primary" [mat-dialog-close]="true">
        {{'invoiceProcessing.importLinesConfirmDialog.confirm' | translate}}
    </button>
</mat-dialog-actions>



