import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class ParamsService {
    readonly URL = environment.backendUrl + '/api/ws';

    constructor(private http: HttpClient) {
    }

    getCompanyInfo(workspaceId: string) {
        return this.http.get<any>(`${this.URL}/${workspaceId}/params/company-info`);
    }

    updateCompanyInfo(workspaceId: string, data: any): Observable<any> {
        return this.http.post<any>(
            `${this.URL}/${workspaceId}/params/company-info`,
            data
        );
    }
}
