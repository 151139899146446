import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ActivityOverviewRoutingModule} from './activity-overview-routing.module';
import {FileUploadModule} from 'ng2-file-upload';
import {ActivityOverviewComponent, UploaderModalComponent} from './activity-overview.component';

import {FlexLayoutModule} from '@angular/flex-layout';
import {LayoutModule} from '@angular/cdk/layout';

import {
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {NgxPermissionsModule} from 'ngx-permissions';

@NgModule({
    declarations: [ActivityOverviewComponent, UploaderModalComponent],
    entryComponents: [UploaderModalComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        FileUploadModule,
        ActivityOverviewRoutingModule,
        FlexLayoutModule,
        LayoutModule,
        /* Angular material */
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        TranslateModule,
        NgxPermissionsModule
    ],
    exports: [ActivityOverviewComponent]
})
export class ActivityOverviewModule {
}
