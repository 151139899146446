import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./keycloak.service";
export class AuthGuard {
    constructor(router, keycloakService) {
        this.router = router;
        this.keycloakService = keycloakService;
    }
    canActivate(route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const authenticated = this.keycloakService.keycloak.authenticated;
            console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);
            if (authenticated) {
                // localStorage.setItem('access_token', this.keycloakService.keycloak.token);
                return true; // Redirect back to the original route after successful exchange
            }
            else {
                yield this.keycloakService.keycloak.login();
                return false; // Redirect to Keycloak login page
            }
        });
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.KeycloakService)); }, token: AuthGuard, providedIn: "root" });
