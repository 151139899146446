import {SelectionModel} from '@angular/cdk/collections';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSlideToggleChange, MatSort} from '@angular/material';
import {POFilterDialogComponent} from './filter-dialog/po-filter-dialog.component';
import {saveAs} from 'file-saver';
import {tap} from 'rxjs/operators';
import {merge, Subscription} from 'rxjs';
import * as moment from 'moment';
import {AttachmentDetails} from '../../models/attachment-details.model';
import {AuthenticationService, WorkspacesService} from '../../core/services';
import {HttpEventType} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {fixDateFormat} from 'src/app/shared/FormatHelper';
import {PoService} from '../../core/services/po.service';
import {PoDataSource} from './po-data-source';
import {PurchaseOrder} from '../../models/purchase-order';
import {purchaseOrder} from '../../shared/Constants';

export interface Filter {
    parameter: string;
    condition: string;
    value: string;
}

@Component({
    selector: 'app-po-explorer',
    templateUrl: './po-explorer.component.html',
    styleUrls: ['./po-explorer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class POExplorerComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(
        private dialog: MatDialog,
        private poService: PoService,
        private authenticationService: AuthenticationService,
        private workspaceService: WorkspacesService,
        private router: Router,
        private route: ActivatedRoute,
        public translateService: TranslateService,
    ) {
        this.getRouteParams();
    }

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('drawer', {static: false}) drawer;

    private PO_APPROVED_STATUS = 'APPROVED';
    displayedColumns = [
        'select',
        'orderDate',
        'supplierParty.name',
        'orderNumber',
        'taxExclusiveAmount',
        'taxAmount',
        'taxInclusiveAmount',
        'documentCurrencyCode'
    ];

    dataSource: PoDataSource;
    selectionPO = new SelectionModel<PurchaseOrder>(true, []);

    unpublishedOnly = false;
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    public filters: Filter[] = [];
    numRows: number;
    allIds: string[] = [];
    public attachmentDetails: AttachmentDetails;
    private poApiSubscription: Subscription;
    private poPublicationStatusSubscription: Subscription;
    private workspaceId: string;
    viewedPO: PurchaseOrder = purchaseOrder;

    openFilterDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '720px';
        dialogConfig.height = 'auto';
        dialogConfig.disableClose = true;
        const map = new Map<string, any[]>();
        let publicationStatusOnGoing: string;
        let publicationStatusSuccess: string;
        let publicationStatusFailure: string;
        let publicationStatusEmpty: string;
        this.translateService
            .get('global.publicationStatusOnGoing')
            .subscribe((value) => (publicationStatusOnGoing = value));
        this.translateService
            .get('global.publicationStatusSuccess')
            .subscribe((value) => (publicationStatusSuccess = value));
        this.translateService
            .get('global.publicationStatusFailure')
            .subscribe((value) => (publicationStatusFailure = value));
        this.translateService
            .get('global.publicationStatusEmpty')
            .subscribe((value) => (publicationStatusEmpty = value));
        map.set('publicationStatus', [{label: publicationStatusEmpty, value: 'UNPUBLISHED'}, {
            label: publicationStatusOnGoing,
            value: 'ONGOING'
        }, {label: publicationStatusSuccess, value: 'SUCCESS'}, {label: publicationStatusFailure, value: 'FAILURE'}]);
        dialogConfig.data = {
            filters: this.filters,
            source: 'po',
            listMap: map
        };
        const dialogRef = this.dialog.open(POFilterDialogComponent, dialogConfig);

        const sorting = this.sort.active.concat(',').concat(this.sort.direction);

        dialogRef.afterClosed().subscribe((data) => {
            this.selectionPO.clear();
            if (data) {
                this.filters = data.filters;
                this.refreshIdsList();
                this.dataSource.loadPOs(
                    sorting,
                    this.paginator.pageIndex,
                    this.paginator.pageSize,
                    this.filters,
                    this.workspaceId,
                    this.PO_APPROVED_STATUS
                );
            }
        });
    }

    public handleOnCheckBoxSelectionChange(row: PurchaseOrder): void {
        this.selectionPO.toggle(row);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selectionPO.selected.length;
        return numSelected === this.numRows;
    }

    masterToggle() {
        this.isAllSelected() ? this.selectionPO.clear() : this.selectRows();
    }

    private selectRows() {
        this.dataSource.poSubject.forEach(data => data.map(po => this.selectionPO.select(po)));
    }

    isEmpty() {
        const numSelected = this.selectionPO.selected.length;
        return numSelected === 0;
    }

    onChange(ob: MatSlideToggleChange) {
        this.unpublishedOnly = ob.checked;
        this.selectionPO.clear();
        this.refreshIdsList();
        this.loadPOsPage();
    }

    viewPOOverview(element: PurchaseOrder) {
        this.viewedPO = element;
        this.drawer.open();
    }

    ngOnInit() {
        this.dataSource = new PoDataSource(this.poService);
        this.dataSource.loadPOs(
            '',
            0,
            20,
            this.filters,
            this.workspaceId,
            this.PO_APPROVED_STATUS
        );

        this.refreshIdsList();

        this.dataSource.totalOperations$.subscribe(
            (length: number) => (this.numRows = length)
        );
        // this.dataSource.poIdsSubject.subscribe(
        // 	(ids: string[]) => {
        // 		if (ids && ids.length > 0) {
        // 			if (this.poPublicationStatusSubscription) {
        // 				this.poPublicationStatusSubscription.unsubscribe();
        // 			}
        // 			this.poPublicationStatusSubscription = this.poService.refreshInvoicePublicationStatusWithIds(ids, this.workspaceId)
        // 				.subscribe(res => {
        // 					res.updatedPublicationStatus.forEach(element => {
        // 						if (this.dataSource.datasourceMap.has(element.id)) {
        // 							this.dataSource.datasourceMap.get(element.id).publicationStatus = element.publicationStatus;
        // 							this.dataSource.datasourceMap.get(element.id).publicationDate = element.publicationDate;
        // 						}
        // 					});
        // 				});
        // 		}
        // 	}
        // );
        this.sort.active = 'orderDate';
        this.sort.direction = 'desc';
        this.allIds = [...this.dataSource.datasourceMap.keys()];
    }

    refreshIdsList(): void {
        this.poService
            .getPOIdsFromAPI(this.filters, this.workspaceId, this.PO_APPROVED_STATUS)
            .subscribe((value) => (this.allIds = value.map(String)));
    }

    ngAfterViewInit() {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        merge(this.sort.sortChange, this.paginator.page).subscribe(() =>
            this.loadPOsPage()
        );

        this.paginator.page.pipe(tap(() => this.loadPOsPage())).subscribe();
    }

    loadPOsPage(): void {
        const sorting = this.sort.active.concat(',').concat(this.sort.direction);
        this.dataSource.loadPOs(
            sorting,
            this.paginator.pageIndex,
            this.paginator.pageSize,
            this.filters,
            this.workspaceId,
            this.PO_APPROVED_STATUS
        );
    }

    onExport() {
        const selectedIds: string[] = [];
        this.selectionPO.selected.forEach((elem) => selectedIds.push(String(elem.id)));
        const tenantIdentifier = JSON.parse(localStorage.getItem('tenantIdentifier'));
        this.poService
            .exportSelectedPOs({poIds: selectedIds, tenantIdentifier})
            .subscribe((data: any) => {
                const file = new Blob([data], {type: 'text/csv;charset=utf-8'});
                const date = moment(new Date()).format('YYYY-MM-DD').toString();
                saveAs(file, 'export_facture-'.concat(date).concat('.csv'));
            });
    }

    onPublish() {
        // const selectedIds: string[] = [];
        // this.selectionInvoice.selected.forEach((elem) => {
        // 	selectedIds.push(elem);
        // });
        // this.poService
        // 	.updateInvoicePublicationStatus(selectedIds, this.workspaceId)
        // 	.subscribe((data: any) => {
        // 		data.updatedPublicationStatus.forEach(element => {
        // 			if (this.poPublicationStatusSubscription) {
        // 				this.poPublicationStatusSubscription.unsubscribe();
        // 			}
        // 			this.poPublicationStatusSubscription = this.poService.refreshInvoicePublicationStatusWithIds(this.allIds, this.workspaceId)
        // 				.subscribe(res => {
        // 					res.updatedPublicationStatus.forEach(element => {
        // 						if (this.dataSource.datasourceMap.has(element.id)) {
        // 							this.dataSource.datasourceMap.get(element.id).publicationStatus = element.publicationStatus;
        // 							this.dataSource.datasourceMap.get(element.id).publicationDate = element.publicationDate;
        // 						}
        // 					});
        // 				});
        // 		});
        // 	});
    }

    downloadAttachment() {

        const orderId = this.viewedPO.id;
        this.poService.downloadFile(orderId)
            .subscribe((data) => {
                if (data.type === HttpEventType.Response) {
                    const downloadedFile = new Blob([data.body], {
                        type: data.body.type,
                    });
                    const a = document.createElement('a');
                    a.setAttribute('style', 'display:none;');
                    document.body.appendChild(a);
                    a.download = this.viewedPO.orderNumber + '.pdf';
                    a.href = URL.createObjectURL(downloadedFile);
                    a.target = '_blank';
                    a.click();
                    document.body.removeChild(a);
                }
            });
    }

    /*private async getAttachmentDetailsFromApi(poId: number) {
        const response = await this.poService.getAttachmentDetailsFromAPI(poId, 'original-file');
        // TODO: check response isArray !
        this.attachmentDetails = response[0];
    }*/

    ngOnDestroy(): void {
        if (this.poApiSubscription) {
            this.poApiSubscription.unsubscribe();
        }
        // if (this.poPublicationStatusSubscription) {
        // 	this.poPublicationStatusSubscription.unsubscribe();
        // }
    }

    public navigateToWorkspaceHome() {
        this.router.navigate(['poworkspaces', this.workspaceId]);
    }

    private getRouteParams() {
        this.route.paramMap.subscribe((params) => {
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }

    fixDateFormat(date: Date): string {
        return fixDateFormat(date);
    }
}
