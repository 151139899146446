import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';


export interface IAddon {
    addonId: string;
    enabled: boolean;
    name: string;
    url: string;
    iconUrl: string;
}

@Injectable({providedIn: 'root'})
export class AddonService {
    readonly FPA_ADDONS_URL = environment.backendUrl + '/invoicestore/app/api/addons';

    constructor(private http: HttpClient) {
    }

    verifyMatchingAddon(tenantIdentifier: string): Observable<IAddon[]> {
        return this.http.get<any>(`${this.FPA_ADDONS_URL}/${tenantIdentifier}`);
    }
}
