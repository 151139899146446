import { of } from 'rxjs';
import { EMPTY_CONSTRAINT } from '../../models/validation-result';
import { isEqual } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/audit-invoice.service";
import * as i2 from "@angular/common/http";
export class AuditInvoiceValidator {
    constructor(auditService, http) {
        this.auditService = auditService;
        this.http = http;
    }
    isValid() {
        return (control) => {
            const currentControl = control;
            if (this.auditService.validationErrors) {
                if (currentControl.id) {
                    //
                    // this.auditService.processVariable[currentControl.id] = control.value; /////
                    const currentControlScope = currentControl.id.replace(new RegExp('_', 'g'), '.');
                    if (this.auditService.validationErrors.size > 0) {
                        const constraintSubj = this.auditService.validationErrors.get(currentControlScope);
                        if (constraintSubj && !isEqual(constraintSubj.getValue(), EMPTY_CONSTRAINT)) {
                            return of({ error: true });
                        }
                        return of(null);
                    }
                    else {
                        return of(null);
                    }
                }
                else {
                    return of(null);
                }
            }
        };
    }
}
AuditInvoiceValidator.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuditInvoiceValidator_Factory() { return new AuditInvoiceValidator(i0.ɵɵinject(i1.AuditService), i0.ɵɵinject(i2.HttpClient)); }, token: AuditInvoiceValidator, providedIn: "root" });
