import {AfterViewInit, Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
    adaptTaskComments,
    adaptToAPI,
    amountParser,
    checkFormOutcomesConfig,
    consolidateOutcomes,
    formMap,
    generateFallbackOutcomes,
    IAdapterOrderLine,
    IFallbackOutcome,
    InvoiceLinesHelper,
    MY_DATE_FORMATS,
    normalizeSupplierForOrderForm,
    parseDecimal,
    removeOrderLines,
    searchInitialTaxCoding,
    taxAmount,
    taxInclusiveAmount,
} from '../../shared/formHepler';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {
    AuthenticationService,
    DialogService,
    FormService,
    ILineTemplate,
    IProductTemplateLine,
    ITaxCoding,
    TaskService,
    WorkspacesService,
} from '../../core/services';
import {BehaviorSubject, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {IField, IFormMetadata} from '../../models/form.model';
import {CrossFieldErrorMatcher} from 'src/app/shared/crossFieldErrorMatcher';
import {IComment} from '../../models/comment.model';
import {Condition} from '../invoice-explorer/filter-dialog/filter-dialog.component';
import {ISupplier, SupplierMdmService} from '../../core/services/supplier-mdm.service';
import {KontaFormControl} from '../../shared/KontaFormControl';
import {TranslateService} from '@ngx-translate/core';
import {MatSelect} from '@angular/material';
import {takeUntil} from 'rxjs/operators';
import {Constraint} from '../../models/validation-result';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {CurrencyService, ICurrency} from 'src/app/core/services/currency.service';
import {fixDateFormat} from 'src/app/shared/FormatHelper';
import * as _ from 'lodash';


export interface SearchSupplierComponentData {
    workspaceId: string;
    filters: Filter[];
}

export interface Filter {
    parameter: string;
    condition: string;
    value: string;
}

export interface IOption {
    id?: string;
    name?: string;
}

@Component({
    selector: 'app-po-processing',
    templateUrl: './po-processing.component.html',
    styleUrls: ['./po-processing.component.scss'],
    providers: [
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {useStrict: true},
        },
    ],
})
export class PoProcessingComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('commentDrawer', {static: false}) commentDrawer;

    orderLineMetadata: ILineTemplate;
    formMetadata: IFormMetadata;
    poForm: FormGroup;
    errorMatcher = new CrossFieldErrorMatcher();
    submitSuccess = false;
    private formMap: Map<string, string>;
    private workspaceId: string;
    private processInstanceId: string;
    private orderId: string;
    private taskId: string;
    public taskDefName: string;
    public processComments: IComment[];
    public messageCount = new BehaviorSubject<number>(0);
    public messageCount$: Observable<number> = this.messageCount.asObservable();
    public filters: Filter[] = [];
    outcomes: any[] = [];

    public invoiceLinesHelper: InvoiceLinesHelper;
    public hasSearchDropdown: boolean;

    public orderLines: IProductTemplateLine[];
    public taxCodings: ITaxCoding[];
    /** Subject that emits when the component has been destroyed. */
    protected onDestroy = new Subject<void>();

    /** Variable for searchable dropdown */
    @ViewChild('accountingCost', {static: true}) accountingCost: MatSelect;
    @ViewChild('projectReference', {static: true}) projectReference: MatSelect;
    @ViewChild('taxCoding', {static: true}) taxCoding: MatSelect;
    @ViewChild('accountCoding', {static: true}) accountCoding: MatSelect;

    /** control for the MatSelect filter keyword */
    public taxCodingFilterCtrl: FormControl = new FormControl();

    /** list of dropdown filtered by search keyword */
    public filteredTaxCoding: ReplaySubject<ITaxCoding[]> = new ReplaySubject<ITaxCoding[]>(1);

    /** Component observable subscriptions to unsubscribe on ngOnDestroy */
    private invoiceFormApiSubscription: Subscription;
    private sendMessageSubscription: Subscription;
    private getMessageSubscription: Subscription;
    private claimApiSubscription: Subscription;

    /** Regex used to validate amount fields */
    private readonly reg = /^-?[0-9]?(([.,][0-9]+)|([0-9]+([.,][0-9]+)?))$/;

    invoiceMatchingEnable = false;

    private readonly requiredFieldIds = ['po_orderDate', 'po_documentCurrencyCode', 'po_requesterDepartment', 'po_supplierParty_name'];

    /** Listen to beforeunload event for reload page and close tab/browser, then execute an event handler function */
    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: any) {
        this.hasUnsavedData() ? (event.returnValue = true) : event.preventDefault();
    }

    @HostListener('window:unload', ['$event'])
    unloadHandler(event: any) {
        this.unclaimTask(this.taskId);
    }


    constructor(
        private authenticationService: AuthenticationService,
        private currencyService: CurrencyService,
        private taskService: TaskService,
        private workspaceService: WorkspacesService,
        private formService: FormService,
        private route: ActivatedRoute,
        private dialogService: DialogService,
        private router: Router,
        public dialog: MatDialog,
    ) {
        this.getRouteParams();
        this.invoiceFormBuilder(); // initialise: this.invoiceLineMetadata
    }

    async ngOnInit() {

        // await needed since
        // it calls loadExpenseCodings that
        // will init this.invoiceLineHelper in underlying calls
        await this.loadTaxCodings();
        this.invoiceLinesHelper = new InvoiceLinesHelper(this.orderLineMetadata);

        await this.getTaskOrderId(this.taskId);

        if (checkFormOutcomesConfig(this.formMetadata.fields) === false) {
            this.outcomes = generateFallbackOutcomes(this.formMetadata.outcomes);
        }
        //Update amounts after getting po lines
        //see: https://kontatech.atlassian.net/browse/KT-1876
        this.updateTotalAmounts();
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: true});
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
        if (this.invoiceFormApiSubscription) {
            this.invoiceFormApiSubscription.unsubscribe();
        }
        if (this.claimApiSubscription) {
            this.claimApiSubscription.unsubscribe();
        }
        if (this.sendMessageSubscription) {
            this.sendMessageSubscription.unsubscribe();
        }
        if (this.getMessageSubscription) {
            this.getMessageSubscription.unsubscribe();
        }
        if (!this.submitSuccess) {
            this.unclaimTask(this.taskId);
        }
    }

    public openComments() {
        this.commentDrawer.open();
    }


    protected filterTaxCoding() {
        if (!this.taxCodings) {
            return;
        }
        // get the search keyword
        let search = this.taxCodingFilterCtrl.value;

        if (!search) {
            this.filteredTaxCoding.next(this.taxCodings.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the taxCoding
        this.filteredTaxCoding.next(
            this.taxCodings.filter(
                (item) =>
                    item.code.toLowerCase().indexOf(search) > -1 ||
                    item.label.toLowerCase().indexOf(search) > -1
            )
        );
    }

    /**
     * Check for searchable field then init search service
     */
    private initSearchDropdownList(field: IField): void {
        // Store the dropdown into variables for filtering
        if (field.id === 'po_documentCurrencyCode') {
            this.getTenantCurrencyOptions()
                .then(tenantCurrencyOptions => {
                        field.options = this.toFormOptions(tenantCurrencyOptions);
                    }
                );
        }

        // Avoid init dropdown filtering capability in case form has no searchable dropdown
        if (field.params.searchable === true) {
            this.hasSearchDropdown = true;
        }
    }

    private async getTenantCurrencyOptions(): Promise<ICurrency[]> {
        const workspaceId = this.workspaceService.workspaceId.getValue();
        if (isNotNullOrUndefined(workspaceId)) {
            const wp = await this.workspaceService.getWorkspace(workspaceId).toPromise();
            const response = await this.currencyService.getCurrenciesByTenant(wp.tenantId).toPromise();
            return response.body;
        } else {
            return [];
        }

    }

    private toFormOptions(currencies: ICurrency[]): IOption[] {
        return currencies.map((currency): IOption => {
            return {
                id: currency.id + '',
                name: currency.code
            };
        });

    }

    /** line search init */
    private initOrderLineTaxCodingSearch(): void {
        if (this.taxCodings) {
            this.filteredTaxCoding.next(this.taxCodings.slice());
        }
        this.taxCodingFilterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.filterTaxCoding();
            });
    }


    private async loadTaxCodings() {
        const legalEntityId = JSON.parse(localStorage.getItem('legalEntityId'));
        // try to get tax codings by legalId first
        const data = await this.formService.getTaxCodingsFromAPI(legalEntityId);

        if (data.length < 1) {
            this.taxCodings = await this.formService.getTaxCodingsFromAPI(null);
        } else {
            this.taxCodings = data;
        }

        // Init the search capability of the ExpenseCoding dropdown list
        this.initOrderLineTaxCodingSearch();

    }

    /** Load saved invoiceLine from API */
    private async loadOrderLines(invoiceId: string) {
        const orderLines = await this.formService.getOrderLines(invoiceId);
        this.orderLines = orderLines;
        if (orderLines.length > 0) {
            this.updateOrderLinesForm(orderLines);
        }
    }

    /** Subscribe to Angular router and get the url params */
    private getRouteParams(): void {
        this.route.paramMap.subscribe((params) => {
            this.taskId = params.get('taskId');
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }

    // TODO: Refactor to use rxjs for better async requests handling
    private async getTaskOrderId(taskId: string) {

        const taskById = await this.taskService.getTaskByIdFromAPI(taskId);

        this.orderId = taskById.processInstanceVariables.po_invoiceStore_poId;
        this.taskDefName = taskById.name;
        this.processInstanceId = taskById.processInstanceId;

        await this.loadOrderLines(this.orderId);

        this.getMessageSubscription = this.formService
            .getCommentsFromAPI(this.processInstanceId)
            .subscribe((response) => {
                this.processComments = adaptTaskComments(response.data);
                this.messageCount.next(response.total);
            });

    }

    /** Using Angular FormGroup and FormControl to build the invoiceForm */
    private invoiceFormBuilder(): void {
        const group: any = {};

        this.invoiceFormApiSubscription = this.route.data.subscribe((data) => {
            this.formMetadata = data.formMetadata;
            this.formMap = formMap(this.formMetadata);

        });

        this.formMetadata.fields.forEach((field) => {
            switch (field.id) {
                case 'po_orderLineTemplate':
                    this.orderLineMetadata = field as any;
                    group.orderLines = new FormArray([]);
                    break;

                default:
                    break;
            }
            switch (field.type) {
                case 'headline':
                    break;

                case 'date':
                    let date;

                    if (field.value !== null) {
                        date = fixDateFormat(field.value);
                    }

                    group[field.id] = new KontaFormControl(field.id, {
                            value: date,
                            disabled: false
                        }
                    );
                    break;

                case 'decimal':
                    console.log('field: ' + field.id);
                    group[field.id] = new KontaFormControl(
                        field.id,
                        {
                            value: field.value,
                            disabled: false
                        },
                        {
                            validators: [Validators.pattern(this.reg)],
                        }
                    );
                    break;

                case 'multi-line-text':
                    group[field.id] = new KontaFormControl(field.id, {
                            value: field.value,
                            disabled: false
                        }
                    );
                    break;

                case 'dropdown':
                    this.initSearchDropdownList(field);

                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false,

                    });

                    break;

                case 'outcomeExtension':
                    this.outcomes.push(
                        consolidateOutcomes(this.formMetadata.outcomes, field)
                    );
                    break;

                default:
                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false
                    });
                    break;
            }
        });

        this.poForm = new FormGroup(group);
    }

    /** invoiceForm getter for better lines handling */
    get orderLinesForm() {
        return this.poForm.get('orderLines') as FormArray;
    }

    /** Add new orderLine */
    public addOrderLine(): void {

        const lineGroup = {
            orderLine_reference: new KontaFormControl('orderLine_reference', {
                value: '',
                disabled: false,
            }),
            orderLine_description: new KontaFormControl('orderLine_description', {
                value: '',
                disabled: false,
            }),
            orderLine_quantity: new KontaFormControl('orderLine_quantity', {
                value: '',
                disabled: false,
            }),
            orderLine_unitPrice: new KontaFormControl('orderLine_unitPrice', {
                value: '',
                disabled: false,
            }),
            orderLine_taxCoding: new KontaFormControl('orderLine_taxCoding', {
                value: '',
                disabled: false,
            }),
            orderLine_taxExclusiveAmount: new KontaFormControl('orderLine_taxExclusiveAmount', {
                value: '',
                disabled: false,
            }),
            orderLine_taxAmount: new KontaFormControl('orderLine_taxAmount', {
                value: '',
                disabled: false,
            }),
            orderLine_taxInclusiveAmount: new KontaFormControl('orderLine_taxInclusiveAmount', {
                value: '',
                disabled: false,
            })

        };

        const newOrderLine = new FormGroup(lineGroup);

        this.orderLinesForm.push(newOrderLine);
    }

    /** Delete orderLine from the formArray by line index */
    public deleteOrderLine(index: number): void {
        this.orderLinesForm.removeAt(index);
        this.orderLinesForm.markAsDirty();
        this.updateTotalAmounts();
    }

    private updateTotalAmounts() {
        this.updateTaSum();
        this.updateTeaSum();
        this.updateTiaSum();
    }

    /** Patch orderLinesForm with data from API */
    private updateOrderLinesForm(orderLines: IAdapterOrderLine[]) {
        const linesArray = this.poForm.controls.orderLines as FormArray;


        for (let i = 0; i < orderLines.length; i++) {
            this.addOrderLine();

            const line = orderLines[i];
            const selectedTaxCoding = this.getSelectedTaxCoding(line);


            const patch = {
                orderLine_reference: line.orderLineNumber,
                orderLine_description: line.description,
                orderLine_quantity: line.quantity,
                orderLine_unitPrice: line.unitPrice,
                orderLine_taxCoding: selectedTaxCoding,
                orderLine_taxAmount: line.taxAmount,
                orderLine_taxExclusiveAmount: line.taxExclusiveAmount,
                orderLine_taxInclusiveAmount: line.taxInclusiveAmount,
            };
            linesArray.controls[i].patchValue(patch);
        }
    }

    private getSelectedTaxCoding(line: IAdapterOrderLine) {

        const tCode = searchInitialTaxCoding(
            line.taxCodings[0],
            this.taxCodings
        );
        if (tCode && tCode[0]) {
            return tCode[0];
        } else {
            return null;
        }


    }

    public getVatRateDisplay(line: AbstractControl) {
        if (isNotNullOrUndefined(line.get('orderLine_taxCoding'))) {
            const taxCoding = line.get('orderLine_taxCoding').value;
            return taxCoding.code + ' - ' + taxCoding.label;
        }
        return '';
    }

    // Update the orderLine amount values based on the line index
    private updateLineValues(index: number, taxExclusiveAmount: string, taxCodingRate: string): void {

        const linesArray = this.poForm.controls.orderLines as FormArray;

        const normalizedTaxExclusiveAmount = amountParser(
            parseDecimal(taxExclusiveAmount)
        );
        const normalizedTaxCodingRate = amountParser(taxCodingRate) / 100;

        taxCodingRate === '' || taxExclusiveAmount === ''
            ? linesArray.controls[index].patchValue({
                orderLine_TaxExclusiveAmount: taxExclusiveAmount,
                orderLine_taxAmount: '',
                orderLine_taxInclusiveAmount: '',
            })
            : linesArray.controls[index].patchValue({
                orderLine_taxExclusiveAmount: taxExclusiveAmount,
                orderLine_taxAmount: taxAmount(
                    normalizedTaxExclusiveAmount,
                    normalizedTaxCodingRate
                ).toFixed(2),
                orderLine_taxInclusiveAmount: taxInclusiveAmount(
                    normalizedTaxExclusiveAmount,
                    normalizedTaxCodingRate
                ).toFixed(2),
            });
    }

    private updateLineTaxAmount(index: number, taxExclusiveAmount: string, taxCodingRate: string): void {

        const linesArray = this.poForm.controls.orderLines as FormArray;

        const normalizedTaxExclusiveAmount = amountParser(
            parseDecimal(taxExclusiveAmount)
        );
        const normalizedTaxCodingRate = amountParser(taxCodingRate) / 100;

        taxCodingRate === '' || taxExclusiveAmount === ''
            ? linesArray.controls[index].patchValue({
                orderLine_taxAmount: '',
            })
            : linesArray.controls[index].patchValue({
                orderLine_taxAmount: taxAmount(
                    normalizedTaxExclusiveAmount,
                    normalizedTaxCodingRate
                ).toFixed(2)
            });
    }

    public onChangeLineQuantity(event: any, index: number) {
        const linesArray = this.poForm.controls.orderLines as FormArray;

        const quantity = event.target.value;
        const unitPrice = amountParser(linesArray.controls[index].get('orderLine_unitPrice').value);

        linesArray.controls[index].patchValue({
                orderLine_taxExclusiveAmount: (quantity * unitPrice).toFixed(2)
            }
        );
    }

    public onChangeLineUnitPrice(event: any, index: number) {
        const linesArray = this.poForm.controls.orderLines as FormArray;

        const unitPrice = event.target.value;
        const quantity = linesArray.controls[index].get(
            'orderLine_quantity'
        ).value;

        linesArray.controls[index].patchValue({
                orderLine_taxExclusiveAmount: (quantity * unitPrice).toFixed(2)
            }
        );
    }

    public onChangeLineTaxAmount(event: any, index: number) {
        const linesArray = this.poForm.controls.orderLines as FormArray;
        if (isNotNullOrUndefined(event) && isNotNullOrUndefined(event.target)) {
            const taxAmount2 = event.target.value;
            const normalizedTaxAmount = amountParser(
                parseDecimal(taxAmount2)
            );
            const tea = parseFloat(amountParser(linesArray.controls[index].get('orderLine_taxExclusiveAmount').value).toFixed(2));

            linesArray.controls[index].patchValue({
                    orderLine_taxInclusiveAmount: (normalizedTaxAmount + tea).toFixed(2)
                }
            );
        } else if (isNotNullOrUndefined(event)) {
            const tea = parseFloat(amountParser(linesArray.controls[index].get('orderLine_taxExclusiveAmount').value).toFixed(2));
            linesArray.controls[index].patchValue({
                    orderLine_taxInclusiveAmount: (amountParser(event) + tea).toFixed(2)
                }
            );
        }
        this.updateTaSum();
    }

    public onChangeLineTaxExclAmount(event: any, index: number) {
        const linesArray = this.poForm.controls.orderLines as FormArray;

        if (isNotNullOrUndefined(event) && isNotNullOrUndefined(event.target)) {
            const tea = event.target.value;
            const normalizedTea = amountParser(parseDecimal(tea));

            const taxAmount2 = parseFloat(amountParser(linesArray.controls[index].get('orderLine_taxAmount').value).toFixed(2));

            linesArray.controls[index].patchValue(
                {
                    orderLine_taxInclusiveAmount: (normalizedTea + taxAmount2).toFixed(2)
                });
        } else if (isNotNullOrUndefined(event)) {
            const taxCoding = linesArray.controls[index].get('orderLine_taxCoding').value;

            this.updateLineTaxAmount(index, event, taxCoding.rate);
        }

        this.updateTeaSum();
    }

    public onChangeLineTaxInclAmount(event: any, index: number) {
        this.updateTiaSum();
    }


    public updateTiaSum() {
        const linesArray = this.poForm.controls.orderLines as FormArray;
        let sum = 0;
        for (const control of linesArray.controls) {
            if (control.get('orderLine_taxInclusiveAmount')) {
                sum += parseFloat(amountParser(parseDecimal(control.get('orderLine_taxInclusiveAmount').value)).toFixed(2));

            }
        }
        this.poForm.patchValue({po_taxInclusiveAmount: sum.toFixed(2)});
    }

    public updateTaSum() {
        const linesArray = this.poForm.controls.orderLines as FormArray;
        let sum = 0;
        for (const control of linesArray.controls) {
            if (control.get('orderLine_taxAmount')) {
                sum += parseFloat(amountParser(parseDecimal(control.get('orderLine_taxAmount').value)).toFixed(2));
            }
        }
        this.poForm.patchValue({po_taxAmount: sum.toFixed(2)});
    }

    public updateTeaSum() {
        const linesArray = this.poForm.controls.orderLines as FormArray;
        let sum = 0;
        for (const control of linesArray.controls) {
            if (control.get('orderLine_taxExclusiveAmount')) {
                sum += parseFloat(amountParser(parseDecimal(control.get('orderLine_taxExclusiveAmount').value)).toFixed(2));
            }
        }
        this.poForm.patchValue({po_taxExclusiveAmount: sum.toFixed(2)});
    }

    /**
     * The vatRate select change event handler.
     * @param value the selected value.
     * @param index index of the orderLines formArray.
     */
    public onChangeTaxCodingEvent(value: ITaxCoding, index: number) {
        const linesArray = this.poForm.controls.orderLines as FormArray;

        const taxCoding = value.rate;
        const taxExclusiveAmount: string = linesArray.controls[index].get(
            'orderLine_taxExclusiveAmount'
        ).value;

        this.updateLineValues(index, taxExclusiveAmount, taxCoding.toString());
    }

    public isReadOnlyLineField(item: IField, fieldId: string): boolean {
        const res = _.includes(item.params.readOnlyFields, fieldId);
        return res;
    }

    public isRequired(item: IField): boolean {
        return _.includes(this.requiredFieldIds, item.id);
    }


    public canAddLines() {
        if (isNotNullOrUndefined(this.orderLineMetadata.params)) {
            const canAddLines = this.orderLineMetadata.params.canAddLines;
            if (_.isBoolean(canAddLines)) {
                return canAddLines;
            } else {
                return canAddLines === 'true';
            }
        }
    }

    private submitFormWithoutLines(formId, formValues, taskId, outcome) {
        this.formService
            .submitWorkflowForm(formId, formValues, taskId, outcome)
            .subscribe(
                () => this.navigateToTaskDetails(),
                () => {
                },
                () => (this.submitSuccess = true)
            );
    }

    /**
     * Submit the form values, formId and outcome.
     * @param outcome name of the workflow action
     */
    public onSubmit(outcome: string) {
        this.submitSuccess = false;

        const formData = removeOrderLines(this.poForm.value);

        const formValues = adaptToAPI(formData, this.formMap);

        // TODO: refactor code, there is duplicate code btw handling a form with lines and without

        // Form has no order lines => no need to handle them
        if (!this.orderLinesForm) {
            // Send Form to API
            this.submitFormWithoutLines(this.formMetadata.id, formValues, this.taskId, outcome);

        } else { // Form with order lines => handle lines before posting form values
            const orderLinesFormValues = this.invoiceLinesHelper.adaptFormOderLinesToAPI(
                this.orderLinesForm.value
            );

            // Send orderLines and form to API
            this.formService
                // Send invoiceLines to API
                .createOrderLines(this.orderId, orderLinesFormValues)
                .subscribe(
                    () => {
                    },
                    () => {
                    },
                    () => {
                        // Send Form to API
                        this.submitFormWithoutLines(this.formMetadata.id, formValues, this.taskId, outcome);
                    }
                );
        }

    }

    public handleActionClick(outcome: IFallbackOutcome): void {
        outcome.params.commentOnAction === true
            ? this.openCommentActionModal(outcome.id)
            : this.onSubmit(outcome.id);
    }

    public isDisabled(form: FormGroup, outcome: IFallbackOutcome): boolean {
        const disableOnError = outcome.params.disableOnError;
        return (form.invalid || this.emptyLines()) && disableOnError;
    }

    /**  Uses Angular router to navigate to task-details url */
    public navigateToTaskDetails(): void {
        this.router.navigate(['poworkspaces', this.workspaceId, 'tasks']);
    }

    public closeTaskViewer(): void {
        this.navigateToTaskDetails();
    }

    /**
     * @returns true if invoice form is dirty else false.
     */
    hasUnsavedData(): boolean {
        return this.poForm.dirty;
    }

    /**
     * Based on form isDirty value prompt user with message to confirm leaving page.
     * @returns true if user can leave the form else false.
     */
    canLeaveForm(): Observable<boolean> {
        if (this.hasUnsavedData()) {
            return this.dialogService.confirm();
        } else {
            return of(true);
        }
    }

    /**
     * Send unclaim task request to the API.
     * @param taskId taskId to unclaim.
     */
    unclaimTask(taskId: string): void {
        this.claimApiSubscription = this.taskService
            .unclaimTaskFromAPI(taskId)
            .subscribe(() => console.log('Task unclaimed successfully!'));
    }

    public openCommentActionModal(outcome: string): void {
        const dialogRef = this.dialog.open(PoCommentActionComponent, {
            width: '700px',
            height: '300px',
            data: {outcome},
        });

        const subscription = dialogRef.componentInstance.ActionConfirmEvent.subscribe(
            comment => {
                this.formService.sendCommentsToAPI(this.taskId, comment)
                    .toPromise().then(() => {
                        this.onSubmit(outcome);
                        dialogRef.close();
                    }
                );
            }
        );

        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });
    }

    public openSearchSupplierModal() {
        const modalData: SearchSupplierComponentData = {
            workspaceId: this.workspaceId,
            filters: this.filters
        };
        const dialogRef = this.dialog.open(PoSearchSupplierComponent, {
            width: '720px',
            height: 'auto',
            data: modalData,
            disableClose: true,
        });

        const subscription = dialogRef.componentInstance.supplierSelectedEvent.subscribe(
            (supplier: ISupplier) => {
                this.updateOrderFormWithSelectedSupplier(supplier);
                dialogRef.close();
            }
        );

        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });
    }

    private updateOrderFormWithSelectedSupplier(supplier: ISupplier) {
        const normalizedSupplier = normalizeSupplierForOrderForm(supplier);

        this.poForm.patchValue({
            po_supplierParty_name: normalizedSupplier.po_supplierParty_name,

            po_supplierParty_customerAssignedAccountId: normalizedSupplier.po_supplierParty_customerAssignedAccountId,

            po_supplierParty_legalId: normalizedSupplier.po_supplierParty_legalId,

            po_supplierParty_taxId: normalizedSupplier.po_supplierParty_taxId,

            po_supplierParty_contactElectronicMail: normalizedSupplier.po_supplierParty_contactElectronicMail,

            po_supplierParty_financialAccount: normalizedSupplier.po_supplierParty_financialAccount,

            po_supplierParty_paymentTerms: normalizedSupplier.po_supplierParty_paymentTerms

        }, {emitEvent: true});

        // need to emit valueChanges event only when legalId is set otherwise rule validation will fire for partial
        // supplier object and we'll get false positive
        // this.invoiceForm.patchValue({
        // 	po_supplierParty_legalId: normalizedSupplier.po_supplierParty_legalId,
        // }, {emitEvent: true});

        this.poForm.markAsDirty();
    }

    formatValidationMsg(validationErrors: Constraint[]): string {
        if (_.isArray(validationErrors)) {
            const join = _.join(validationErrors.map(error => error.message), '. ');
            return join;
        } else {
            return null;
        }
    }

    emptyLines(): boolean {
        return this.orderLinesForm.length === 0;
    }

    hasErrors(): boolean {
        let lineWithErrors = 0;
        for (const line of this.orderLinesForm.controls) {
            for (const key of ['orderLine_description', 'orderLine_quantity', 'orderLine_unitPrice', 'orderLine_taxCoding',
                'orderLine_taxExclusiveAmount', 'orderLine_taxAmoun', 'orderLine_taxInclusiveAmount']) {
                if (line instanceof FormGroup && this.hasAtLeastOneError(line, key)) {
                    lineWithErrors++;
                }
            }
        }
        return lineWithErrors > 0;
    }

    getErrorMsg(): string {
        let message = '';
        const msgSet = new Set<string>();

        for (const line of this.orderLinesForm.controls) {
            if (line instanceof FormGroup) {
                msgSet.add(this.checkField(line, 'orderLine_description', 'Missing Description'));
                msgSet.add(this.checkField(line, 'orderLine_quantity', 'Missing Quantity'));
                msgSet.add(this.checkField(line, 'orderLine_unitPrice', 'Missing Unit Price'));
                msgSet.add(this.checkField(line, 'orderLine_taxCoding', 'Missing Tax Codes'));
                msgSet.add(this.checkField(line, 'orderLine_taxExclusiveAmount', 'Missing Tax Exc. Amount'));
                msgSet.add(this.checkField(line, 'orderLine_taxAmoun', 'Missing Tax Amount'));
                msgSet.add(this.checkField(line, 'orderLine_taxInclusiveAmount', 'Missing Tax Inc. Amount'));
            }
        }
        message = _.join(Array.from(msgSet.values()), '. ');
        return message;
    }

    private checkField(line: FormGroup, key: string, msgAddition: string) {
        if (isNotNullOrUndefined(line.controls[key]) &&
            isNotNullOrUndefined(line.controls[key].errors) &&
            isNotNullOrUndefined(line.controls[key].errors.required)) {
            return msgAddition;
        }
        return '';
    }

    private hasAtLeastOneError(line: FormGroup, key: string) {
        if (isNotNullOrUndefined(line.controls[key]) &&
            isNotNullOrUndefined(line.controls[key].errors)) {
            return true;
        }
        return false;
    }

    ngAfterViewInit(): void {

    }


}

@Component({
    selector: 'app-comment-action-model',
    templateUrl: './comment-action-modal.html',
})
export class PoCommentActionComponent {
    message = new FormControl('', Validators.required);
    ActionConfirmEvent = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<PoCommentActionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    handleActionConfirm() {
        this.ActionConfirmEvent.emit(this.message.value);
    }
}

@Component({
    selector: 'app-search-supplier-modal',
    templateUrl: './search-supplier-modal.html',
})
export class PoSearchSupplierComponent implements OnInit {

    supplierList = new BehaviorSubject<ISupplier[]>([]);
    supplierList$: Observable<ISupplier[]> = this.supplierList.asObservable();
    selectedSupplier: ISupplier;
    supplierSelectedEvent = new EventEmitter();

    parameters = new Map();
    form: FormGroup;
    equals: string;
    contains: string;
    greaterThanOrEqualTo: string;
    lessThanOrEqualTo: string;
    filterGroup: any;
    workspaceId: string;

    constructor(
        private fb: FormBuilder,
        private supplierMdmService: SupplierMdmService,
        public dialogRef: MatDialogRef<PoSearchSupplierComponent>,
        public translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: SearchSupplierComponentData
    ) {
        this.workspaceId = data.workspaceId;
    }

    ngOnInit(): void {
        this.initConditions();
        this.initFilters();
        const group: FormGroup[] = [];

        if (this.data.filters.length === 0) {
            group.push(this.createFilter());
        } else {
            for (const f of this.data.filters) {
                group.push(
                    this.fb.group({
                        parameter: new FormControl(f.parameter, [Validators.required]),
                        value: new FormControl(f.value, [Validators.required]),
                        // condition: new FormControl(f.condition, [Validators.required]),
                    })
                );
            }
        }

        this.form = this.fb.group({
            filters: this.fb.array(group),
        });

        this.parameters.set('name', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('legalId', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('customerAssignedAccountId', {
            type: 'string',
            conditions: this.stringConditions(),
        });
    }

    stringConditions(): Condition[] {
        return [
            new Condition('equals', this.equals),
            new Condition('contains', this.contains),
        ];
    }

    otherTypeConditions(): Condition[] {
        return [
            new Condition('equals', this.equals),
            new Condition('greaterThanOrEqual', this.greaterThanOrEqualTo),
            new Condition('lessThanOrEqual', this.lessThanOrEqualTo),
        ];
    }

    private initFilters() {
        let fournisser: string;
        let nom: string;
        let ice: string;
        let identifier: string;
        this.translateService
            .get('global.provider')
            .subscribe((value) => (fournisser = value));
        this.translateService.get('global.name').subscribe((value) => (nom = value));
        this.translateService
            .get('global.legalID')
            .subscribe((value) => (ice = value));
        this.translateService
            .get('global.identifier')
            .subscribe((value) => (identifier = value));
        this.filterGroup = [
            {
                name: fournisser,
                children: [
                    {label: nom, value: 'name'},
                    {label: ice, value: 'legalId'},
                    {label: identifier, value: 'customerAssignedAccountId'},
                ],
            },
        ];
    }

    initConditions() {
        this.translateService
            .get('filters.equalsTo')
            .subscribe((res) => (this.equals = res));
        this.translateService
            .get('filters.contains')
            .subscribe((res) => (this.contains = res));
        this.translateService
            .get('filters.greaterThanOrEqualTo')
            .subscribe((res) => (this.greaterThanOrEqualTo = res));
        this.translateService
            .get('filters.lessThanOrEqualTo')
            .subscribe((res) => (this.lessThanOrEqualTo = res));
    }

    createFilter(): FormGroup {
        return this.fb.group({
            parameter: new FormControl('', [Validators.required]),
            value: new FormControl('', [Validators.required]),
        });
    }

    onParameterChanges(filter: any, index: number) {
        this.filters.setControl(
            index,
            (this.filters[index] = this.fb.group({
                parameter: new FormControl(filter.value.parameter, [
                    Validators.required,
                ]),
                value: new FormControl('', [Validators.required]),
            }))
        );
    }

    refreshSupplierList(workspaceId: string): void {
        this.supplierMdmService
            .getSuppliersForWorkspace(this.filters.value, workspaceId)
            .subscribe((response) => this.supplierList.next(response));
    }

    getControls(): any {
        return (this.form.get('filters') as FormArray).controls;
    }

    get filters() {
        return this.form.get('filters') as FormArray;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmit() {
        this.refreshSupplierList(this.workspaceId);
    }

    handleSelect() {
        this.supplierSelectedEvent.emit(this.selectedSupplier);
    }
}

