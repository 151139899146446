import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { adaptExpenseCodingsSearchable, adaptTaskComments, adaptToAPI, amountParser, checkFormOutcomesConfig, consolidateOutcomes, formMap, generateFallbackOutcomes, InvoiceLinesHelper, MY_DATE_FORMATS, normalizeSupplier, parseDecimal, removeInvoiceLines, searchInitialAccountCoding, searchInitialExpenseCoding, searchInitialTaxCoding, taxAmount, taxInclusiveAmount, typeFilteredExpenseCodings, } from '../../shared/formHepler';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, of, ReplaySubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { saveAs } from 'file-saver';
import { CrossFieldErrorMatcher } from 'src/app/shared/crossFieldErrorMatcher';
import { Condition } from '../invoice-explorer/filter-dialog/filter-dialog.component';
import { KontaFormControl } from '../../shared/KontaFormControl';
import { map, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { HttpEventType } from '@angular/common/http';
import { isNotNullOrUndefined } from 'codelyzer/util/isNotNullOrUndefined';
import { fixDateFormat } from 'src/app/shared/FormatHelper';
const ɵ0 = MY_DATE_FORMATS, ɵ1 = { useStrict: true };
export class InvoiceProcessingComponent {
    constructor(authenticationService, currencyService, taskService, workspaceService, invoiceService, formService, route, dialogService, auditService, auditInvoiceValidator, addonService, router, dialog, snackBar, translateService) {
        this.authenticationService = authenticationService;
        this.currencyService = currencyService;
        this.taskService = taskService;
        this.workspaceService = workspaceService;
        this.invoiceService = invoiceService;
        this.formService = formService;
        this.route = route;
        this.dialogService = dialogService;
        this.auditService = auditService;
        this.auditInvoiceValidator = auditInvoiceValidator;
        this.addonService = addonService;
        this.router = router;
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.translateService = translateService;
        this.errorMatcher = new CrossFieldErrorMatcher();
        this.submitSuccess = false;
        this.messageCount = new BehaviorSubject(0);
        this.messageCount$ = this.messageCount.asObservable();
        this.filters = [];
        this.outcomes = [];
        /** Subject that emits when the component has been destroyed. */
        this.onDestroy = new Subject();
        /** control for the MatSelect filter keyword */
        this.accountingCostFilterCtrl = new FormControl();
        this.projectReferenceFilterCtrl = new FormControl();
        this.taxCodingFilterCtrl = new FormControl();
        this.accountCodingFilterCtrl = new FormControl();
        /** list of dropdown filtered by search keyword */
        this.filteredAccountingCost = new ReplaySubject(1);
        this.filteredProjectReference = new ReplaySubject(1);
        this.filteredTaxCoding = new ReplaySubject(1);
        this.filteredAccountCoding = new ReplaySubject(1);
        /** Regex used to validate amount fields */
        this.reg = /^-?[0-9]?(([.,][0-9]+)|([0-9]+([.,][0-9]+)?))$/;
        this.snackBarDuration = 2;
        this.invoiceMatchingEnable = false;
        this.getRouteParams();
        this.invoiceFormBuilder(); // initialise: this.invoiceLineMetadata
    }
    /** Listen to beforeunload event for reload page and close tab/browser, then execute an event handler function */
    beforeUnloadHandler(event) {
        this.hasUnsavedData() ? (event.returnValue = true) : event.preventDefault();
    }
    unloadHandler(event) {
        this.unclaimTask(this.taskId);
    }
    /** Listen to browser resize event to compute the canvas display */
    onResize(event) {
        if (this.attachmentDetails && this.isAttachmentImage(this.attachmentDetails.mimeType)) {
            this.canvasDrawInit(this.attachment);
        }
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loadAccountingAccount();
            // await needed since
            // it calls loadExpenseCodings that
            // will init this.invoiceLineHelper in underlying calls
            yield this.loadTaxCodings();
            this.getTaskInvoiceId(this.taskId);
            if (checkFormOutcomesConfig(this.formMetadata.fields) === false) {
                this.outcomes = generateFallbackOutcomes(this.formMetadata.outcomes);
            }
            // if the process form contain searchable dropdown the init capability
            if (this.hasSearchDropdown === true) {
                this.initSearchDropDownService();
            }
            const tenantIdentifier = JSON.parse(localStorage.getItem('tenantIdentifier'));
            this.matchingGrSubscription = this.addonService.verifyMatchingAddon(tenantIdentifier).pipe(map(addons => {
                return addons.filter(addon => addon.addonId === 'com.konta.gr-inv-matching')[0];
            }))
                .subscribe(addon => {
                this.invoiceMatchingEnable = addon.enabled;
            }, error => this.invoiceMatchingEnable = false);
        });
    }
    ngAfterViewInit() {
        const canvasEl = this.canvas.nativeElement;
        this.cx = canvasEl.getContext('2d');
        // Set AuditService value change watchers
        const valueChangesArray = [];
        this.auditService.clearValidationErrors();
        const formKeys = Object.keys(this.invoiceForm.controls);
        formKeys.forEach(key => {
            const valueChanges = this.invoiceForm.get(key).valueChanges;
            valueChanges.subscribe((value) => {
                this.auditService.processVariable[key] = value;
                // console.log('AUDIT: this.auditService.processVariable.invoiceLines' + this.auditService.processVariable.invoiceLines);
                this.auditService.processVariable.taskName = this.taskDefName;
                this.auditService.processVariable.expenseCodingTypes = this.invoiceLineHelper.expenseCodingTypes;
                this.auditService.fire();
                // console.log('FIRED By key=' + key + '; value: ' + value);
            });
        });
    }
    validateAllFormFields(formGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
        if (this.invoiceFormApiSubscription) {
            this.invoiceFormApiSubscription.unsubscribe();
        }
        if (this.claimApiSubscription) {
            this.claimApiSubscription.unsubscribe();
        }
        if (this.sendMessageSubscription) {
            this.sendMessageSubscription.unsubscribe();
        }
        if (this.getMessageSubscription) {
            this.getMessageSubscription.unsubscribe();
        }
        if (this.matchingGrSubscription) {
            this.matchingGrSubscription.unsubscribe();
        }
        if (!this.submitSuccess) {
            this.unclaimTask(this.taskId);
        }
    }
    openComments() {
        this.commentDrawer.open();
    }
    filterAccountingCosts() {
        if (!this.accountingCostsList) {
            return;
        }
        // get the search keyword
        let search = this.accountingCostFilterCtrl.value;
        if (!search) {
            this.filteredAccountingCost.next(this.accountingCostsList.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the accounting costs
        this.filteredAccountingCost.next(this.accountingCostsList.filter((item) => item.name.toLowerCase().indexOf(search) > -1));
    }
    filterProjectReferences() {
        if (!this.projectReferencesList) {
            return;
        }
        // get the search keyword
        let search = this.projectReferenceFilterCtrl.value;
        if (!search) {
            this.filteredProjectReference.next(this.projectReferencesList.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the project references
        this.filteredProjectReference.next(this.projectReferencesList.filter((item) => item.name.toLowerCase().indexOf(search) > -1));
    }
    filterTaxCoding() {
        if (!this.taxCodings) {
            return;
        }
        // get the search keyword
        let search = this.taxCodingFilterCtrl.value;
        if (!search) {
            this.filteredTaxCoding.next(this.taxCodings.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the taxCoding
        this.filteredTaxCoding.next(this.taxCodings.filter((item) => item.code.toLowerCase().indexOf(search) > -1 ||
            item.label.toLowerCase().indexOf(search) > -1));
    }
    filterAccountCoding() {
        if (!this.accountCodings) {
            return;
        }
        // get the search keyword
        let search = this.accountCodingFilterCtrl.value;
        if (!search) {
            this.filteredAccountCoding.next(this.accountCodings.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the accountCoding
        this.filteredAccountCoding.next(this.accountCodings.filter((item) => item.code.toLowerCase().indexOf(search) > -1 ||
            item.label.toLowerCase().indexOf(search) > -1));
    }
    /**
     * Check for searchable field then init search service
     */
    initSearchDropdownList(field) {
        // Store the dropdown into variables for filtering
        if (field.id === 'invoice_accountingCost') {
            this.accountingCostsList = field.options;
        }
        else if (field.id === 'invoice_projectReference') {
            this.projectReferencesList = field.options;
        }
        else if (field.id === 'invoice_documentCurrencyCode') {
            this.getTenantCurrencyOptions()
                .then(tenantCurrencyOptions => {
                field.options = this.toFormOptions(tenantCurrencyOptions);
            });
        }
        // Avoid init dropdown filtering capability in case form has no searchable dropdown
        if (field.params.searchable === true) {
            this.hasSearchDropdown = true;
        }
    }
    getTenantCurrencyOptions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const workspaceId = this.workspaceService.workspaceId.getValue();
            if (isNotNullOrUndefined(workspaceId)) {
                const wp = yield this.workspaceService.getWorkspace(workspaceId).toPromise();
                const response = yield this.currencyService.getCurrenciesByTenant(wp.tenantId).toPromise();
                return response.body;
            }
            else {
                return [];
            }
        });
    }
    toFormOptions(currencies) {
        return currencies.map((currency) => {
            return {
                id: currency.id + '',
                name: currency.code
            };
        });
    }
    /** line search init */
    initInvoiceLineTaxCodingSearch() {
        if (this.invoiceLineHelper.taxCodingsStatus) {
            if (this.taxCodings) {
                this.filteredTaxCoding.next(this.taxCodings.slice());
            }
            this.taxCodingFilterCtrl.valueChanges
                .pipe(takeUntil(this.onDestroy))
                .subscribe(() => {
                this.filterTaxCoding();
            });
        }
    }
    initInvoiceLineAccountCodingSearch() {
        if (!isNotNullOrUndefined(this.invoiceLineHelper)) {
            if (this.accountCodings) {
                this.filteredAccountCoding.next(this.accountCodings.slice());
            }
            this.accountCodingFilterCtrl.valueChanges
                .pipe(takeUntil(this.onDestroy))
                .subscribe(() => {
                this.filterAccountCoding();
            });
        }
    }
    /** Form search init */
    initSearchDropDownService() {
        this.filteredAccountingCost.next(this.accountingCostsList.slice());
        this.accountingCostFilterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
            this.filterAccountingCosts();
        });
        this.filteredProjectReference.next(this.projectReferencesList.slice());
        this.projectReferenceFilterCtrl.valueChanges
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
            this.filterProjectReferences();
        });
    }
    loadTaxCodings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const legalEntityId = JSON.parse(localStorage.getItem('legalEntityId'));
            // try to get tax codings by legalId first
            const data = yield this.formService.getTaxCodingsFromAPI(legalEntityId);
            if (data.length < 1) {
                this.taxCodings = yield this.formService.getTaxCodingsFromAPI(null);
                yield this.loadExpenseCodings();
            }
            else {
                this.taxCodings = data;
                yield this.loadExpenseCodings();
            }
        });
    }
    loadAccountingAccount() {
        const legalEntityId = JSON.parse(localStorage.getItem('legalEntityId'));
        this.formService.getAccountingAccounts(legalEntityId).subscribe((data) => {
            this.accountCodings = data;
            this.initInvoiceLineAccountCodingSearch();
        });
    }
    loadExpenseCodings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = yield this.formService.getExpenseCodings();
            this.expenseCodingsList = adaptExpenseCodingsSearchable(data);
            // init line template search and lists
            this.generateFilteredExpenseCodingsList();
            // Init the search capability of the ExpenseCoding dropdown list
            this.initInvoiceLineTaxCodingSearch();
        });
    }
    /** Load saved invoiceLine from API */
    loadInvoiceLines(invoiceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const invoiceLines = yield this.formService.getInvoiceLines(invoiceId);
            this.invoiceLines = invoiceLines;
            if (invoiceLines.length > 0) {
                this.updateInvoiceLinesForm(invoiceLines);
            }
        });
    }
    /** Load the purchase order lines from MDM */
    loadInvoicePurchaseOrders() {
        // Form has no invoice lines no need for user confirmation before creating new lines
        if (this.invoiceFormHasZeroLines()) {
            this.doPoCopy();
        }
        else {
            const transKey = 'invoiceProcessing.importLinesConfirmDialog.message';
            let message = '';
            this.translateService.get(transKey).subscribe(value => message += value);
            const dialogRef = this.dialog.open(PoCopyConfirmDialogComponent, {
                width: '720px',
                height: 'auto',
                data: { message },
                disableClose: true
            });
            dialogRef.afterClosed().subscribe((confirmed) => {
                if (confirmed) {
                    this.doPoCopy();
                }
            });
        }
    }
    /** Load Coding Proposals lines */
    loadInvoiceCodingProposal() {
        // Form has no invoice lines no need for user confirmation before creating new lines
        if (this.invoiceFormHasZeroLines()) {
            this.doCodingProposalCopy();
        }
        else {
            const transKey = 'invoiceProcessing.codingProposalConfirmDialog.message';
            let message = '';
            this.translateService.get(transKey).subscribe(value => message += value);
            const dialogRef = this.dialog.open(CodingProposalConfirmDialogComponent, {
                width: '720px',
                height: 'auto',
                data: { message },
                disableClose: true
            });
            dialogRef.afterClosed().subscribe((confirmed) => {
                if (confirmed) {
                    this.doCodingProposalCopy();
                }
            });
        }
    }
    invoiceFormHasZeroLines() {
        return this.invoiceLineForm.controls.length === 0;
    }
    doPoCopy() {
        this.clearFormArray();
        const customerAssignedAccId = this.invoiceForm.get('invoice_orderReference').value;
        const orderNumber = this.invoiceForm.get('invoice_supplierParty_customerAssignedAccountId').value;
        if (customerAssignedAccId === null ||
            orderNumber === null ||
            orderNumber === '' ||
            customerAssignedAccId === '') {
            this.openSnackBar('Référence commande ou identifiant fournisseur non définis');
        }
        else {
            this.formService
                .getInvoicePurchaseOrderFromAPI(orderNumber, customerAssignedAccId)
                .subscribe((data) => {
                this.purchaseOrder = data.content;
                this.updateInvoiceWithPurchaseOrder();
            });
        }
    }
    doCodingProposalCopy() {
        this.clearFormArray();
        const supplierAccId = this.invoiceForm.get('invoice_supplierParty_customerAssignedAccountId').value;
        const taxExclusiveAmount = this.invoiceForm.get('invoice_taxExclusiveAmount').value;
        if (this.customerAssignedAccountId === null || supplierAccId === null || supplierAccId === ''
            || this.customerAssignedAccountId === '') {
            this.openSnackBar('Identifiant fournisseur non défini');
        }
        else {
            this.formService
                .fetchCodingProposal(this.workspaceId, this.customerAssignedAccountId, supplierAccId, taxExclusiveAmount)
                .subscribe((data) => {
                this.updateInvoiceWithCodingProposal(data);
            });
        }
    }
    updateInvoiceWithCodingProposal(data) {
        // If exactly one matching PO found then copy lines from PO
        const length = data.length;
        if (length === 0) {
            this.openSnackBar('0 ligne importée');
        }
        if (length >= 1) {
            const linesArray = this.invoiceForm.controls.invoiceLines;
            for (let i = 0; i < length; i++) {
                this.addInvoiceLine();
                const codingProposalLine = data[i];
                // expense codings
                const poExpCode1 = this.getCodingProposalExpCoding(codingProposalLine, 0);
                const poExpCode2 = this.getCodingProposalExpCoding(codingProposalLine, 1);
                const poExpCode3 = this.getCodingProposalExpCoding(codingProposalLine, 2);
                const poExpCode4 = this.getCodingProposalExpCoding(codingProposalLine, 3);
                const poExpCode5 = this.getCodingProposalExpCoding(codingProposalLine, 4);
                // tax codings
                const poTaxCode = this.getCodingProposalTaxCoding(codingProposalLine);
                // Account Codings
                const poAccountCode = this.getCodingProposalAccountCoding(codingProposalLine);
                const patchValue = {
                    invoice_invoiceLine_orderLineReference: codingProposalLine.orderLineNumber,
                    invoice_invoiceLine_lineDescription: codingProposalLine.description,
                    invoice_invoiceLine_quantity: codingProposalLine.quantity,
                    invoice_invoiceLine_unitPrice: codingProposalLine.unitPrice,
                    invoice_invoiceLine_expenseCoding1: poExpCode1,
                    invoice_invoiceLine_expenseCoding2: poExpCode2,
                    invoice_invoiceLine_expenseCoding3: poExpCode3,
                    invoice_invoiceLine_expenseCoding4: poExpCode4,
                    invoice_invoiceLine_expenseCoding5: poExpCode5,
                    invoice_invoiceLine_taxCoding: poTaxCode,
                    invoice_invoiceLine_accountCoding: poAccountCode,
                    invoice_invoiceLine_lineTaxExclusiveAmount: codingProposalLine.taxExclusiveAmount,
                };
                linesArray.controls[i].patchValue(patchValue);
                if (isNotNullOrUndefined(codingProposalLine.taxExclusiveAmount) && codingProposalLine.taxExclusiveAmount !== '') {
                    this.updateLineAmountValues(i, codingProposalLine.taxExclusiveAmount, poTaxCode.rate);
                }
                this.openSnackBar(`${length} ligne(s) importée(s)`);
            }
        }
    }
    openArchiveDialog(invoiceCount) {
    }
    /** Clear all invoiceLines form */
    clearFormArray() {
        this.invoiceLineForm.clear();
    }
    /** Generate a filtered list for each expenseCodingType */
    generateFilteredExpenseCodingsList() {
        this.invoiceLineHelper = new InvoiceLinesHelper(this.invoiceLineMetadata, this.expenseCodingsList);
    }
    /**
     * TODO: Add a debounce on the btn to block or prevent user from downloading attachment multiple time.
     * Download the attachment from the retrieved blob on click btn event
     */
    downloadAttachment() {
        const { mimeType, originalFileName } = this.attachmentDetails;
        const blob = new Blob([this.attachment], {
            type: mimeType,
        });
        saveAs(blob, originalFileName);
    }
    getAttachmentDetailsFromApi(invoiceId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const response = yield this.invoiceService.getAttachmentDetailsFromAPI(invoiceId, 'original-file');
            // TODO: check response isArray !
            this.attachmentDetails = response[0];
        });
    }
    // TODO: Refactor to use rxjs for better async requests handling
    getTaskInvoiceId(taskId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const taskById = yield this.taskService.getTaskByIdFromAPI(taskId);
            const processVariable = taskById.processInstanceVariables;
            this.auditService.processVariable = processVariable;
            this.invoiceId = processVariable.invoice_invoiceStore_invoiceId;
            this.taskDefName = taskById.name;
            this.processInstanceId = taskById.processInstanceId;
            this.customerAssignedAccountId = processVariable.invoice_customerParty_customerAssignedAccountId;
            yield this.loadInvoiceLines(this.invoiceId);
            yield this.getAttachmentDetailsFromApi(this.invoiceId);
            this.getAttachmentBinaryFromAPI(this.invoiceId);
            // TODO synchronise !
            this.auditService.fire();
            this.getMessageSubscription = this.formService
                .getCommentsFromAPI(this.processInstanceId)
                .subscribe((response) => {
                this.processComments = adaptTaskComments(response.data);
                this.messageCount.next(response.total);
            });
        });
    }
    getSubjectById(id) {
        const scope = id.replace(new RegExp('_', 'g'), '.');
        let subject = this.auditService.validationErrors.get(scope);
        if (!subject) {
            this.auditService.validationErrors.set(scope, new BehaviorSubject(null));
            subject = this.auditService.validationErrors.get(scope);
        }
        return subject;
    }
    /**
     * Init canvas with image data binary
     * @param attachment the attachment binary
     */
    canvasDrawInit(attachment) {
        const image = new Image();
        image.src = URL.createObjectURL(attachment);
        this.viewerWidth = this.documentViewer.nativeElement.offsetWidth - 4;
        image.onload = () => {
            this.cx.canvas.width = this.viewerWidth;
            this.cx.canvas.height =
                (this.viewerWidth * image.naturalHeight) / image.naturalWidth;
            this.cx.drawImage(image, 0, 0, this.cx.canvas.width, this.cx.canvas.height);
        };
    }
    /**
     * Generate a blob url for ng2-pdf-viewer
     * @param attachment the attachment binary
     */
    createPDFString(attachment) {
        // Convert blob to ArrayBuffer and get Uint8Array. The issue with this format is that it slows down the ng2-pdf-viewer performance.
        /* const fileReader = new FileReader();
            fileReader.onload = e => {
                let uint8View = new Uint8Array(e.target.result);
                this.pdfsrc = uint8View;
            };
            fileReader.readAsArrayBuffer(attachment); */
        this.pdfsrc = URL.createObjectURL(attachment);
    }
    /**
     * getAttachmentDataFromAPI request callback to store attachment binary.
     * @param blob the attachment binary.
     */
    setAttachmentBinary(blob) {
        this.attachment = blob;
        const { mimeType } = this.attachmentDetails;
        this.isAttachmentImage(mimeType)
            ? this.canvasDrawInit(blob)
            : this.createPDFString(blob);
    }
    /**
     * Check if the mimeType binary is an actual image help to determine if we use pdfViewer or the canvas.
     * @param mimeType the type of binary received from the API
     */
    isAttachmentImage(mimeType) {
        return (mimeType === 'image/png' ||
            mimeType === 'image/jpeg' ||
            mimeType === 'image/jpg');
    }
    /**
     * TODO: Refactor to be in the invoice service and use Angular HTTPClient.
     * Send request to API and gets invoice attachment binary.
     * @param invoiceId the invoice id.
     * @param callback callback function for retrieving request binary.
     */
    getAttachmentBinaryFromAPI(invoiceId, callback = this.setAttachmentBinary.bind(this)) {
        const url = `${environment.backendUrl}/invoicestore/app/api/invoices/${invoiceId}/attachment-content`;
        const body = { attachmentType: 'original-file' };
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Authorization', 'bearer ' + this.authenticationService.getUserToken() + '');
        xhr.onload = function () {
            if (this.status === 200) {
                callback(this.response);
            }
            else {
                console.error(xhr.statusText);
            }
        };
        xhr.send(JSON.stringify(body));
    }
    /** Subscribe to Angular router and get the url params */
    getRouteParams() {
        this.route.paramMap.subscribe((params) => {
            this.taskId = params.get('taskId');
            this.workspaceId = params.get('workspaceId');
            this.workspaceService.setWorkspaceId(this.workspaceId);
        });
    }
    controlItemHasErrors(id) {
        const error = this.auditService.validationErrors.get(id);
        return !!error;
    }
    getErrorForControlItem(id) {
        if (this.controlItemHasErrors(id)) {
            const control = this.invoiceForm.controls[id];
            const errors = control.errors;
            return errors[id].message;
        }
        else {
            return null;
        }
    }
    /** Using Angular FormGroup and FormControl to build the invoiceForm */
    invoiceFormBuilder() {
        const group = {};
        this.invoiceFormApiSubscription = this.route.data.subscribe((data) => {
            this.formMetadata = data.formMetadata;
            this.formMap = formMap(this.formMetadata);
        });
        this.formMetadata.fields.forEach((field) => {
            switch (field.type) {
                case 'headline':
                    break;
                case 'date':
                    let date;
                    if (field.value !== null) {
                        date = fixDateFormat(field.value);
                    }
                    group[field.id] = new KontaFormControl(field.id, {
                        value: date,
                        disabled: false
                    });
                    break;
                case 'decimal':
                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false
                    }, {
                        validators: [Validators.pattern(this.reg)],
                    });
                    break;
                case 'multi-line-text':
                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false
                    });
                    break;
                case 'dropdown':
                    this.initSearchDropdownList(field);
                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false,
                    });
                    break;
                case 'outcomeExtension':
                    this.outcomes.push(consolidateOutcomes(this.formMetadata.outcomes, field));
                    break;
                case 'invoiceLineTemplate':
                    this.invoiceLineMetadata = field;
                    group.invoiceLines = new FormArray([]);
                    break;
                default:
                    group[field.id] = new KontaFormControl(field.id, {
                        value: field.value,
                        disabled: false
                    });
                    break;
            }
        });
        this.invoiceForm = new FormGroup(group);
    }
    /** invoiceForm getter for better lines handling */
    get invoiceLineForm() {
        return this.invoiceForm.get('invoiceLines');
    }
    /** Add new invoiceLine */
    addInvoiceLine() {
        const linesGroup = {};
        this.invoiceLineMetadata.params.lineModel.forEach((field) => {
            switch (field.type) {
                case 'expenseCoding':
                    linesGroup[field.id] = new KontaFormControl(field.id, {
                        value: '',
                        disabled: this.invoiceLineMetadata.params.readOnly,
                    });
                    break;
                case 'taxCoding':
                    linesGroup[field.id] = new KontaFormControl(field.id, {
                        value: '',
                        disabled: this.invoiceLineMetadata.params.readOnly,
                    });
                    break;
                case 'accountCoding':
                    linesGroup[field.id] = new KontaFormControl(field.id, {
                        value: '',
                        disabled: this.invoiceLineMetadata.params.readOnly,
                    });
                    break;
                case 'decimal':
                    linesGroup[field.id] = new KontaFormControl(field.id, {
                        value: '',
                        disabled: this.invoiceLineMetadata.params.readOnly,
                    }, {
                        validators: [Validators.pattern(this.reg)],
                    });
                    break;
                case 'text':
                    linesGroup[field.id] = new KontaFormControl(field.id, {
                        value: '',
                        disabled: this.invoiceLineMetadata.params.readOnly,
                    });
                    break;
                default:
                    break;
            }
        });
        const newInvoiceLine = new FormGroup(linesGroup);
        this.invoiceLineForm.push(newInvoiceLine);
    }
    /** Patch invoiceLinesForm with data from API */
    updateInvoiceLinesForm(invoiceLines) {
        const linesArray = this.invoiceForm.controls.invoiceLines;
        for (let i = 0; i < invoiceLines.length; i++) {
            this.addInvoiceLine();
            const line = this.invoiceLines[i];
            const selectedTaxCoding = this.getSelectedTaxCoding(line);
            const selectedAccountCoding = this.getSelectedAccountCoding(line);
            const selectedExpenseCoding1 = this.getSelectedExpenseCoding(line, 0);
            const selectedExpenseCoding2 = this.getSelectedExpenseCoding(line, 1);
            const patch = {
                invoice_invoiceLine_lineDescription: line.lineDescription,
                invoice_invoiceLine_quantity: line.quantity,
                invoice_invoiceLine_unitPrice: line.unitPrice,
                invoice_invoiceLine_orderLineReference: line.orderLineReference,
                invoice_invoiceLine_expenseCoding1: selectedExpenseCoding1,
                invoice_invoiceLine_expenseCoding2: selectedExpenseCoding2,
                invoice_invoiceLine_expenseCoding3: this.getSelectedExpenseCoding(line, 2),
                invoice_invoiceLine_expenseCoding4: this.getSelectedExpenseCoding(line, 3),
                invoice_invoiceLine_expenseCoding5: this.getSelectedExpenseCoding(line, 4),
                invoice_invoiceLine_taxCoding: selectedTaxCoding,
                invoice_invoiceLine_accountCoding: selectedAccountCoding,
                invoice_invoiceLine_lineTaxAmount: line.taxAmount,
                invoice_invoiceLine_lineTaxExclusiveAmount: line.taxExclusiveAmount,
                invoice_invoiceLine_lineTaxInclusiveAmount: line.taxInclusiveAmount,
            };
            linesArray.controls[i].patchValue(patch);
        }
    }
    getSelectedExpenseCoding(line, expCodingIndex) {
        const iExpenseCodingType = this.invoiceLineHelper.expenseCodingTypes[expCodingIndex];
        if (iExpenseCodingType) {
            const foundExpCoding = searchInitialExpenseCoding(typeFilteredExpenseCodings(iExpenseCodingType.type, line.expenseCodings), this.expenseCodingsList);
            if (foundExpCoding && foundExpCoding[0]) {
                return foundExpCoding[0];
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    getSelectedTaxCoding(line) {
        if (this.invoiceLineHelper.taxCodingsStatus) {
            const tCode = searchInitialTaxCoding(line.taxCodings[0], this.taxCodings);
            if (tCode && tCode[0]) {
                return tCode[0];
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    getSelectedAccountCoding(line) {
        if (isNotNullOrUndefined(this.invoiceLineHelper) && isNotNullOrUndefined(this.accountCodings)) {
            const tCode = searchInitialAccountCoding(line.accountCodings[0], this.accountCodings);
            if (tCode && tCode[0]) {
                return tCode[0];
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    updateInvoiceWithPurchaseOrder() {
        const purchaseOrderLength = this.purchaseOrder.length;
        // If exactly one matching PO found then copy lines from PO
        if (purchaseOrderLength >= 2) {
            this.openSnackBar('Référence à compléter');
        }
        if (purchaseOrderLength === 0) {
            this.openSnackBar('0 ligne importée');
        }
        if (purchaseOrderLength === 1) {
            const linesArray = this.invoiceForm.controls.invoiceLines;
            const purchaseOrder = this.purchaseOrder[0];
            // check if purchase order array contains any line
            const purchaseOrderLinesLength = this.purchaseOrder[0].purchaseOrderLines.length;
            if (purchaseOrder && purchaseOrderLinesLength > 0) {
                for (let i = 0; i < purchaseOrderLinesLength; i++) {
                    this.addInvoiceLine();
                    const poLine = purchaseOrder.purchaseOrderLines[i];
                    // expense codings
                    const poExpCode1 = this.getPoExpCoding(poLine, 0);
                    const poExpCode2 = this.getPoExpCoding(poLine, 1);
                    const poExpCode3 = this.getPoExpCoding(poLine, 2);
                    const poExpCode4 = this.getPoExpCoding(poLine, 3);
                    const poExpCode5 = this.getPoExpCoding(poLine, 4);
                    // tax codings
                    const poTaxCode = this.getPoTaxCoding(poLine);
                    // Account Codings
                    const poAccountCode = this.getPoAccountCoding(poLine);
                    const patchValue = {
                        invoice_invoiceLine_orderLineReference: poLine.orderLineNumber,
                        invoice_invoiceLine_lineDescription: poLine.description,
                        invoice_invoiceLine_quantity: poLine.quantity,
                        invoice_invoiceLine_unitPrice: poLine.unitPrice,
                        invoice_invoiceLine_expenseCoding1: poExpCode1,
                        invoice_invoiceLine_expenseCoding2: poExpCode2,
                        invoice_invoiceLine_expenseCoding3: poExpCode3,
                        invoice_invoiceLine_expenseCoding4: poExpCode4,
                        invoice_invoiceLine_expenseCoding5: poExpCode5,
                        invoice_invoiceLine_taxCoding: poTaxCode,
                        invoice_invoiceLine_accountCoding: poAccountCode,
                        invoice_invoiceLine_lineTaxAmount: poLine.taxAmount,
                        invoice_invoiceLine_lineTaxExclusiveAmount: poLine.taxExclusiveAmount,
                        invoice_invoiceLine_lineTaxInclusiveAmount: poLine.taxInclusiveAmount,
                    };
                    linesArray.controls[i].patchValue(patchValue);
                    this.openSnackBar(`${purchaseOrderLinesLength} ligne(s) importée(s)`);
                }
            }
            else {
                this.openSnackBar('0 ligne importée');
            }
        }
    }
    getPoExpCoding(poLine, expCodingIndex) {
        const iExpenseCodingType = this.invoiceLineHelper.expenseCodingTypes[expCodingIndex];
        if (iExpenseCodingType) {
            const poExpCode = searchInitialExpenseCoding(typeFilteredExpenseCodings(iExpenseCodingType.type, poLine.expenseCodings), this.expenseCodingsList);
            const b = poExpCode && poExpCode[0];
            if (b) {
                return poExpCode[0];
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    getCodingProposalExpCoding(poLine, expCodingIndex) {
        const iExpenseCodingType = this.invoiceLineHelper.expenseCodingTypes[expCodingIndex];
        if (iExpenseCodingType) {
            const poExpCode = searchInitialExpenseCoding(typeFilteredExpenseCodings(iExpenseCodingType.type, poLine.expenseCodings), this.expenseCodingsList);
            const b = poExpCode && poExpCode[0];
            if (b) {
                return poExpCode[0];
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    // Supports only one account coding
    getPoAccountCoding(poLine) {
        if (this.invoiceLineHelper.accountCodingsStatus) {
            const poAccCode = searchInitialAccountCoding(poLine.accountCodings[0], this.accountCodings);
            const b = poAccCode && poAccCode[0];
            if (b) {
                return poAccCode[0];
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    // Supports only one account coding
    getCodingProposalAccountCoding(poLine) {
        if (this.invoiceLineHelper.accountCodingsStatus) {
            const poAccCode = searchInitialAccountCoding(poLine.accountCodings[0], this.accountCodings);
            const b = poAccCode && poAccCode[0];
            if (b) {
                return poAccCode[0];
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    getPoTaxCoding(purchaseOrderLine) {
        if (this.invoiceLineHelper.taxCodingsStatus) {
            const taxCode = searchInitialTaxCoding(purchaseOrderLine.taxCodings[0], this.taxCodings);
            if (taxCode && taxCode[0]) {
                return taxCode[0];
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    getCodingProposalTaxCoding(purchaseOrderLine) {
        if (this.invoiceLineHelper.taxCodingsStatus) {
            const taxCode = searchInitialTaxCoding(purchaseOrderLine.taxCodings[0], this.taxCodings);
            if (taxCode && taxCode[0]) {
                return taxCode[0];
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }
    /** Delete invoiceLine from the formArray by line index */
    deleteInvoiceLine(index) {
        this.invoiceLineForm.removeAt(index);
        this.invoiceLineForm.markAsDirty();
    }
    /** Update the invoiceLine amount values based on the line index */
    updateLineAmountValues(index, taxExclusiveAmount, taxCodingRate) {
        const linesArray = this.invoiceForm.controls.invoiceLines;
        const normalizedTaxExclusiveAmount = amountParser(parseDecimal(taxExclusiveAmount));
        const normalizedTaxCodingRate = amountParser(taxCodingRate) / 100;
        taxCodingRate === '' || taxExclusiveAmount === ''
            ? linesArray.controls[index].patchValue({
                invoice_invoiceLine_lineTaxExclusiveAmount: taxExclusiveAmount,
                invoice_invoiceLine_lineTaxAmount: '',
                invoice_invoiceLine_lineTaxInclusiveAmount: '',
            })
            : linesArray.controls[index].patchValue({
                invoice_invoiceLine_lineTaxExclusiveAmount: taxExclusiveAmount,
                invoice_invoiceLine_lineTaxAmount: taxAmount(normalizedTaxExclusiveAmount, normalizedTaxCodingRate).toFixed(2),
                invoice_invoiceLine_lineTaxInclusiveAmount: taxInclusiveAmount(normalizedTaxExclusiveAmount, normalizedTaxCodingRate).toFixed(2),
            });
    }
    /**
     * The totalAmount input change event handler.
     * @param event the input change event.
     * @param index index of the invoiceLines formArray.
     * @param attribute the attribute to handle change for
     */
    onChangeHandler(event, index, attribute) {
        if (attribute === 'lineTaxExclusiveAmount') {
            const linesArray = this.invoiceForm.controls.invoiceLines;
            const taxExclusiveAmount = event.target.value;
            const taxCoding = linesArray.controls[index].get('invoice_invoiceLine_taxCoding').value;
            this.updateLineAmountValues(index, taxExclusiveAmount, taxCoding.rate);
        }
        else if (attribute === 'quantity') {
            const linesArray = this.invoiceForm.controls.invoiceLines;
            const quantity = event.target.value;
            const unitPrice = linesArray.controls[index].get('invoice_invoiceLine_unitPrice').value;
            this.updateLineTeaValue(index, quantity, unitPrice);
        }
        else if (attribute === 'unitPrice') {
            const linesArray = this.invoiceForm.controls.invoiceLines;
            const unitPrice = event.target.value;
            const quantity = linesArray.controls[index].get('invoice_invoiceLine_quantity').value;
            this.updateLineTeaValue(index, quantity, unitPrice);
        }
    }
    /** Update invoice line (at provided index) tax exclusive amount  */
    updateLineTeaValue(index, quantity, unitPrice) {
        const linesArray = this.invoiceForm.controls.invoiceLines;
        const normalizedQuantity = amountParser(parseDecimal(quantity));
        const normalizedUnitPrice = amountParser(parseDecimal(unitPrice));
        const tea = (normalizedQuantity * normalizedUnitPrice).toFixed(2);
        linesArray.controls[index].patchValue({
            invoice_invoiceLine_lineTaxExclusiveAmount: tea
        });
        // there is a better way: use Rx BehaviorSubject with a TeaFormula object for example
        const taxCoding = linesArray.controls[index].get('invoice_invoiceLine_taxCoding').value;
        this.updateLineAmountValues(index, tea, taxCoding.rate);
    }
    /**
     * The vatRate select change event handler.
     * @param value the selected value.
     * @param index index of the invoiceLines formArray.
     */
    onChangeTaxCodingEvent(value, index) {
        const linesArray = this.invoiceForm.controls.invoiceLines;
        const taxCoding = value.rate;
        const taxExclusiveAmount = linesArray.controls[index].get('invoice_invoiceLine_lineTaxExclusiveAmount').value;
        this.updateLineAmountValues(index, taxExclusiveAmount, taxCoding.toString());
    }
    submitFormWithoutLines(formId, formValues, taskId, outcome) {
        this.formService
            .submitWorkflowForm(formId, formValues, taskId, outcome)
            .subscribe(() => this.navigateToTaskDetails(), () => {
        }, () => (this.submitSuccess = true));
    }
    /**
     * Submit the form values, formId and outcome.
     * @param outcome name of the workflow action
     */
    onSubmit(outcome) {
        this.submitSuccess = false;
        const formData = removeInvoiceLines(this.invoiceForm.value);
        const formValues = adaptToAPI(formData, this.formMap);
        // TODO: refactor code, there is duplicate code btw handling a form with lines and without
        // Form has no invoice lines => no need to handle them
        if (!this.invoiceLineForm) {
            // Send Form to API
            this.submitFormWithoutLines(this.formMetadata.id, formValues, this.taskId, outcome);
        }
        else { // Form with invoice lines => handle lines before posting form values
            const invoiceLinesFormValues = this.invoiceLineHelper.adaptFormInvoiceLinesToAPI(this.invoiceLineForm.value);
            const orderLineIdList = this.extractOrderLineIdList(invoiceLinesFormValues);
            // Send invoiceLines and form to API
            this.formService
                // Send invoiceLines to API
                .createInvoiceLines(this.invoiceId, invoiceLinesFormValues)
                .subscribe(() => {
            }, () => {
            }, () => {
                // Send Form to API
                this.addOrderLineIdListForReconciliation(formValues, orderLineIdList);
                this.submitFormWithoutLines(this.formMetadata.id, formValues, this.taskId, outcome);
            });
        }
    }
    handleActionClick(outcome) {
        outcome.params.commentOnAction === true
            ? this.openCommentActionModal(outcome.id)
            : this.onSubmit(outcome.id);
    }
    isDisabled(invoiceForm, outcome) {
        const disableOnError = outcome.params.disableOnError;
        let formInvalid = false;
        // if any of the validation error behaviorSubjects is not empty and it's
        // corresponding scope is present into the invoiceForm
        // => invoiceForm is invalid
        const formIncludedScopes = Object.keys(this.invoiceForm.controls).map(controlKey => controlKey.replace(new RegExp('_', 'g'), '.'));
        // TODO: should be defined in the form ?
        formIncludedScopes.push('invoice.invoiceLines');
        for (const key of this.auditService.validationErrors.keys()) {
            if (formIncludedScopes.includes(key)) {
                const constraintSubj = this.auditService.validationErrors.get(key);
                if (constraintSubj && constraintSubj.getValue() != null) {
                    formInvalid = true;
                }
            }
        }
        return (disableOnError && formInvalid) || invoiceForm.invalid;
    }
    /**  Uses Angular router to navigate to task-details url */
    navigateToTaskDetails() {
        this.router.navigate(['workspaces', this.workspaceId, 'tasks']);
    }
    closeTaskViewer() {
        this.navigateToTaskDetails();
    }
    /**
     * @returns true if invoice form is dirty else false.
     */
    hasUnsavedData() {
        return this.invoiceForm.dirty;
    }
    /**
     * Based on form isDirty value prompt user with message to confirm leaving page.
     * @returns true if user can leave the form else false.
     */
    canLeaveForm() {
        if (this.hasUnsavedData()) {
            return this.dialogService.confirm();
        }
        else {
            return of(true);
        }
    }
    /**
     * Send unclaim task request to the API.
     * @param taskId taskId to unclaim.
     */
    unclaimTask(taskId) {
        this.claimApiSubscription = this.taskService
            .unclaimTaskFromAPI(taskId)
            .subscribe(() => console.log('Task unclaimed successfully!'));
    }
    openSnackBar(message) {
        this.snackBar.openFromComponent(PurchaseOrderSnackBarComponent, {
            duration: this.snackBarDuration * 1000,
            data: message,
        });
    }
    openCommentActionModal(outcome) {
        const dialogRef = this.dialog.open(CommentActionComponent, {
            width: '700px',
            height: '300px',
            data: { outcome },
        });
        const subscription = dialogRef.componentInstance.ActionConfirmEvent.subscribe(comment => {
            this.formService.sendCommentsToAPI(this.taskId, comment)
                .toPromise().then(() => {
                this.onSubmit(outcome);
                dialogRef.close();
            });
        });
        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });
    }
    openSearchSupplierModal() {
        const modalData = {
            workspaceId: this.workspaceId,
            filters: this.filters
        };
        const dialogRef = this.dialog.open(SearchSupplierComponent, {
            width: '720px',
            height: 'auto',
            data: modalData,
            disableClose: true,
        });
        const subscription = dialogRef.componentInstance.supplierSelectedEvent.subscribe((supplier) => {
            this.updateInvoiceFormWithSelectedSupplier(supplier);
            dialogRef.close();
        });
        dialogRef.afterClosed().subscribe(() => {
            subscription.unsubscribe();
        });
    }
    updateInvoiceFormWithSelectedSupplier(supplier) {
        const normalizedSupplier = normalizeSupplier(supplier);
        this.invoiceForm.patchValue({
            invoice_supplierParty_name: normalizedSupplier.invoice_supplierParty_name,
            invoice_supplierParty_customerAssignedAccountId: normalizedSupplier.invoice_supplierParty_customerAssignedAccountId,
            invoice_supplierParty_legalId: normalizedSupplier.invoice_supplierParty_legalId,
            invoice_supplierParty_taxId: normalizedSupplier.invoice_supplierParty_taxId,
            invoice_supplierParty_contactElectronicMail: normalizedSupplier.invoice_supplierParty_contactElectronicMail,
            invoice_supplierParty_financialAccount: normalizedSupplier.invoice_supplierParty_financialAccount,
            invoice_supplierParty_paymentTerms: normalizedSupplier.invoice_supplierParty_paymentTerms
        }, { emitEvent: true });
        // need to emit valueChanges event only when legalId is set otherwise rule validation will fire for partial
        // supplier object and we'll get false positive
        // this.invoiceForm.patchValue({
        // 	invoice_supplierParty_legalId: normalizedSupplier.invoice_supplierParty_legalId,
        // }, {emitEvent: true});
        this.invoiceForm.markAsDirty();
    }
    uploadBrFile(files) {
        if (files && files.length > 0) {
            const file = files.item(0);
            let fileReport;
            this.invoiceService.matchGrIr(file, this.invoiceId).subscribe((data) => {
                if (data.type === HttpEventType.Response) {
                    fileReport = new Blob([data.body], { type: 'text/csv;charset=utf-8' });
                    saveAs(fileReport, 'report-matching-'.concat(this.invoiceId).concat('.csv'));
                    fileReport = undefined;
                    this.uploadGrFile.nativeElement.value = '';
                }
            });
        }
    }
    extractOrderLineIdList(invoiceLines) {
        return _.map(invoiceLines, 'orderLineReference');
    }
    addOrderLineIdListForReconciliation(formValues, orderLineIdsList) {
        formValues._transient_recon_poline_refs = this.toCommaSeparated(orderLineIdsList);
    }
    toCommaSeparated(orderLineRefList) {
        if (orderLineRefList) {
            return orderLineRefList.join(',');
        }
        return '';
    }
    formatValidationMsg(validationErrors) {
        if (_.isArray(validationErrors)) {
            const join = _.join(validationErrors.map(error => error.message), '. ');
            return join;
        }
        else {
            return null;
        }
    }
}
export class CommentActionComponent {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.message = new FormControl('', Validators.required);
        this.ActionConfirmEvent = new EventEmitter();
    }
    onNoClick() {
        this.dialogRef.close();
    }
    handleActionConfirm() {
        this.ActionConfirmEvent.emit(this.message.value);
    }
}
export class SearchSupplierComponent {
    constructor(fb, supplierMdmService, dialogRef, translateService, data) {
        this.fb = fb;
        this.supplierMdmService = supplierMdmService;
        this.dialogRef = dialogRef;
        this.translateService = translateService;
        this.data = data;
        this.supplierList = new BehaviorSubject([]);
        this.supplierList$ = this.supplierList.asObservable();
        this.supplierSelectedEvent = new EventEmitter();
        this.parameters = new Map();
        this.workspaceId = data.workspaceId;
    }
    ngOnInit() {
        this.initConditions();
        this.initFilters();
        const group = [];
        if (this.data.filters.length === 0) {
            group.push(this.createFilter());
        }
        else {
            for (const f of this.data.filters) {
                group.push(this.fb.group({
                    parameter: new FormControl(f.parameter, [Validators.required]),
                    value: new FormControl(f.value, [Validators.required]),
                }));
            }
        }
        this.form = this.fb.group({
            filters: this.fb.array(group),
        });
        this.parameters.set('name', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('legalId', {
            type: 'string',
            conditions: this.stringConditions(),
        });
        this.parameters.set('customerAssignedAccountId', {
            type: 'string',
            conditions: this.stringConditions(),
        });
    }
    stringConditions() {
        return [
            new Condition('equals', this.equals),
            new Condition('contains', this.contains),
        ];
    }
    otherTypeConditions() {
        return [
            new Condition('equals', this.equals),
            new Condition('greaterThanOrEqual', this.greaterThanOrEqualTo),
            new Condition('lessThanOrEqual', this.lessThanOrEqualTo),
        ];
    }
    initFilters() {
        let fournisser;
        let nom;
        let ice;
        let identifier;
        this.translateService
            .get('global.provider')
            .subscribe((value) => (fournisser = value));
        this.translateService.get('global.name').subscribe((value) => (nom = value));
        this.translateService
            .get('global.legalID')
            .subscribe((value) => (ice = value));
        this.translateService
            .get('global.identifier')
            .subscribe((value) => (identifier = value));
        this.filterGroup = [
            {
                name: fournisser,
                children: [
                    { label: nom, value: 'name' },
                    { label: ice, value: 'legalId' },
                    { label: identifier, value: 'customerAssignedAccountId' },
                ],
            },
        ];
    }
    initConditions() {
        this.translateService
            .get('filters.equalsTo')
            .subscribe((res) => (this.equals = res));
        this.translateService
            .get('filters.contains')
            .subscribe((res) => (this.contains = res));
        this.translateService
            .get('filters.greaterThanOrEqualTo')
            .subscribe((res) => (this.greaterThanOrEqualTo = res));
        this.translateService
            .get('filters.lessThanOrEqualTo')
            .subscribe((res) => (this.lessThanOrEqualTo = res));
    }
    createFilter() {
        return this.fb.group({
            parameter: new FormControl('', [Validators.required]),
            value: new FormControl('', [Validators.required]),
        });
    }
    onParameterChanges(filter, index) {
        this.filters.setControl(index, (this.filters[index] = this.fb.group({
            parameter: new FormControl(filter.value.parameter, [
                Validators.required,
            ]),
            value: new FormControl('', [Validators.required]),
        })));
    }
    refreshSupplierList(workspaceId) {
        this.supplierMdmService
            .getSuppliersForWorkspace(this.filters.value, workspaceId)
            .subscribe((response) => this.supplierList.next(response));
    }
    getControls() {
        return this.form.get('filters').controls;
    }
    get filters() {
        return this.form.get('filters');
    }
    onNoClick() {
        this.dialogRef.close();
    }
    onSubmit() {
        this.refreshSupplierList(this.workspaceId);
    }
    handleSelect() {
        this.supplierSelectedEvent.emit(this.selectedSupplier);
    }
}
export class PurchaseOrderSnackBarComponent {
    constructor(data) {
        this.data = data;
    }
}
export class PoCopyConfirmDialogComponent {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
}
export class CodingProposalConfirmDialogComponent {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
}
export { ɵ0, ɵ1 };
