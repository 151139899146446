export class ValidationResult {
    constructor(public constraints: Constraint[]) {
    }
}

export class Constraint {
    constructor(public scope: string, public severityLevel: string, public message: string) {
    }
}

// @Deprecated
export const EMPTY_CONSTRAINT = new Constraint(null, null, null);
