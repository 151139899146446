import * as i0 from "@angular/core";
export class CanDeactivatePoGuard {
    canDeactivate(component, currentRoute, currentState, nextState) {
        if (component.submitSuccess) {
            return true;
        }
        else {
            return component.canLeaveForm();
        }
    }
}
CanDeactivatePoGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanDeactivatePoGuard_Factory() { return new CanDeactivatePoGuard(); }, token: CanDeactivatePoGuard, providedIn: "root" });
