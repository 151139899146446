<mat-card class="params-sidenav-menu">
    <mat-nav-list role="list">
        <mat-list-item [routerLink]="['general']" role="listitem"
        >
            <mat-icon mat-list-icon>business</mat-icon>
            Informations
            générales
        </mat-list-item
        >
        <mat-list-item [routerLink]="['categories']" role="listitem"
        >
            <mat-icon mat-list-icon>label</mat-icon>
            Catégories
        </mat-list-item
        >
        <mat-list-item [routerLink]="['suppliers']" role="listitem"
        >
            <mat-icon mat-list-icon>local_shipping
            </mat-icon
            >
            Fournisseurs
        </mat-list-item
        >
    </mat-nav-list>
</mat-card>
