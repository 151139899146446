export interface Filter {
    parameter: string;
    condition: string;
    value: string;
}

export class Condition {
    constructor(public value: string, public label: string) {
    }
}
