import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IOutcome} from '../../models/form.model';

function myDateAdapter(): string {
    const API_DATE_FORMAT = 'YYYY-MM-DD';
    moment.locale('fr-FR');
    const date = new Date();
    const newDate = date.setDate(date.getDate() - 30);
    return moment(newDate).format(API_DATE_FORMAT);
}

@Injectable({providedIn: 'root'})
export class RolesService {
    readonly MY_ROLES_URL = environment.backendUrl + '/invoicestore/app/api/my-roles';

    constructor(private http: HttpClient) {
    }

    // TODO: do not use IOutcome (form engine model)
    getMyRoles(): Observable<IOutcome[]> {
        return this.http
            .get<IOutcome[]>(`${this.MY_ROLES_URL}`);
    }

    // TODO: do not use IOutcome (form engine model)
    getPermissionsByWorkspace(workspaceId: string): Observable<IOutcome[]> {
        return this.http
            .get<IOutcome[]>(`${this.MY_ROLES_URL}/${workspaceId}`);
    }

}
