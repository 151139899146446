// Needed for Safari compatibility
export function fixDateFormat(nonFormattedDate: any): string {

    if (nonFormattedDate) {
        const dateStr = nonFormattedDate.toString().replace(/[-,]/g, '/');
        const parsedDate = new Date(dateStr);
        // Check that parsing result is a Date object
        if (parsedDate instanceof Date && !!parsedDate.getDate()) {
            return parsedDate.toISOString();
        } else {
            return '';
        }

    } else {
        return '';
    }
}

