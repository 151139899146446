<mat-form-field class="invoice-field">
    <mat-label>{{ field.label }}</mat-label>
    <mat-select [formControl]="control" #expenseCoding>
        <mat-option>
            <ngx-mat-select-search
                    [formControl]="expenseCodingFilterCtrl"
                    placeholderLabel="{{'invoiceProcessing.search' | translate}}"
                    noEntriesFoundLabel="{{'invoiceProcessing.noResults' | translate}}"
            >
            </ngx-mat-select-search>
        </mat-option>
        <mat-option
                *ngFor="let expenseCoding of filteredExpenseCoding | async"
                [value]="expenseCoding"
        >
            {{ expenseCoding.code }}
            <ng-container
                    *ngIf="expenseCoding.code !== '' && expenseCoding.label !== ''"
            > -
            </ng-container>
            {{ expenseCoding.label }}
        </mat-option>
    </mat-select>
</mat-form-field>
