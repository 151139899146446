import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface IUser {
    id: string;
    login: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    email: string;
    tenantId: string;
    groups?: any[];
    privileges?: any[];
    notificationEnabled: boolean;
    isTechnicalAccount: boolean;
}

@Injectable({providedIn: 'root'})
export class UserService {
    readonly REGULAR_USERS_URL = environment.backendUrl + '/kontaflow/task/app/users/regularUsers/emails';

    constructor(private http: HttpClient) {
    }

    getRegularUsers(filter: string): Observable<IUser[]> {
        const httpParams = new HttpParams().set('filter', filter);
        return this.http.post<any>(this.REGULAR_USERS_URL, null, {params: httpParams});
    }

    getRegularUserEmails(filter: string): Observable<string[]> {
        return this.getRegularUsers(filter)
            .pipe(
                map(response => response.map(user => user.id))
            );

    }


}
