import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {BundleDownloaderComponent} from './bundle-downloader.component';
import {BundleDownloaderRoutingModule} from './bundle-downloader-routing.module';

@NgModule({
    declarations: [BundleDownloaderComponent],
    entryComponents: [BundleDownloaderComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        BundleDownloaderRoutingModule
    ],
    exports: [BundleDownloaderComponent]
})
export class BundleDownloaderModule {
}
