<mat-card class="categories-card">
    <mat-card-header>
        <mat-card-title>
            Catégories
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        Aucune catégorie configurée
    </mat-card-content>
</mat-card>
