import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Invoice} from '../../models/invoice';
import {Filter} from './invoice-rejected-explorer.component';
import {RejectedInvoiceService} from '../../core/services/rejected-invoice.service';

export class RejectedInvoiceDataSource implements DataSource<Invoice> {
	public invoiceSubject = new BehaviorSubject<Invoice[]>([]);
	private totalOperations: BehaviorSubject<number> = new BehaviorSubject(0);

	public readonly totalOperations$: Observable<number> = this.totalOperations.asObservable();

	private rejectedInvoiceServiceSubscription: Subscription;

	constructor(private rejectedInvoiceService: RejectedInvoiceService) {
	}

	connect(collectionViewer: CollectionViewer): Observable<Invoice[]> {
		return this.invoiceSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.invoiceSubject.complete();
		this.rejectedInvoiceServiceSubscription.unsubscribe();
	}

	public loadInvoices(
		sortDirection: string,
		pageIndex: number,
		pageSize: number,
		filter: Filter[],
		onlyPublished: boolean,
		workspaceId: string
	) {
		this.rejectedInvoiceServiceSubscription = this.rejectedInvoiceService
			.getInvoiceListFromAPI(
				sortDirection,
				pageIndex,
				pageSize,
				filter,
				onlyPublished,
				workspaceId
			)
			.pipe(catchError(() => of([])))
			.subscribe(invoices => {
				this.invoiceSubject.next(invoices.content);
				this.totalOperations.next(invoices.totalElements);
			});
	}
}
