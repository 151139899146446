import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Location} from '@angular/common';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {TaskService} from '../services';

@Injectable({
    providedIn: 'root'
})
export class CanClaimTaskGuard implements CanActivate {
    constructor(
        private location: Location,
        private router: Router,
        private taskService: TaskService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // Resolve data here (not in a resolver) since guard needs API response
        //
        const comingFromTaskDetails = this.location.path().endsWith('/tasks');

        return this.taskService
            .claimAndGetTaskFromApi(route.params.taskId)
            .pipe(
                catchError((error, e) => {
                    switch (error.status) {
                        case 400:
                        case 403:
                        case 404:
                            if (comingFromTaskDetails) {
                                this.taskService.handleError(error);
                            } else {
                                // TODO: tackle back boutton from list to claim ;)
                                this.router.navigate(['/notfound']);
                            }
                            break;

                        default:
                            break;
                    }
                    return throwError('An error has occurred. Try later');
                })
            )
            .pipe(
                map(formMetadata => {
                    route.data = {formMetadata};
                    return true;
                })
            );
    }
}
